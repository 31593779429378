import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import InstanceStore, { InstanceActions } from 'src/stores/InstanceStore';
import SchemaStore, { SchemaActions } from 'src/stores/SchemaStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import config, { DEVSERVER_PREPROD_API_URL } from 'src/helpers/api/config';
import faker from 'faker/locale/fr';
import { buildQueryString } from 'src/helpers/misc';

import ObjectRefEditor from 'src/pages/components/resource/PropertyEditor/ObjectRefEditor';
// import InputAny from 'src/pages/components/resource/PropertyEditor/oldEditors/InputAny';

import InitSiteToolButton, {
  TEMPLATE_INSTANCE_NAME,
} from 'src/pages/components/InitSiteToolButton';
import {
  Button,
  OldForm,
  Switch,
  OldInput,
  OldSelect,
  Input,
  Text,
  Field,
  Control,
  Label,
} from 'src/components';
import SchemaMigrator from './SchemaMigrator';

export default class SelectInstanceAndToolsPage extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      collectionList: [],
      copyResult: '',
      open: false,
      categoriesTocreate: [],
      createMenuAndPages: true,
      contentsPageToCreate: { TextContent: true },
      listsPageToCreate: {},
      checkLocalPayments: true,
      checkDbResult: {},
    };

    this.stores = [InstanceStore, SchemaStore];
  }

  didMount() {
    this.onChangeSource(this.state.instanceName); // TODO only when instanceName loaded?
    this.loadServerSchema();
  }

  loadServerSchema() {
    if (config.PUF_ENV !== 'staging') {
      API._request(
        'get',
        'global/data/schema',
        { __admin: true },
        null,
        null,
        DEVSERVER_PREPROD_API_URL
      ).then((data) =>
        this.setState({
          serverSchema: _.keyBy(data, '_id'),
        })
      );
    }
  }

  onChangeSource(src) {
    this.setState({ src });
    let srcColl = this.state.srcColl;

    API.get(src + '/collections').then((collectionList) =>
      this.setState({
        collectionList,
        srcColl: _.includes(collectionList, srcColl)
          ? srcColl
          : collectionList[0],
      })
    );
  }

  copy() {
    let { src, dst, srcColl } = this.state;
    API.post(this.state.instanceName + '/tools/copyCollection', {
      src,
      dst,
      collection: srcColl,
    }).then(
      (res) => this.setState({ copyResult: 'ok' }),
      (err) => this.setState({ copyResult: err.message })
    );
  }

  copyToTarget = (key) => {
    API._request(
      'put',
      'global/data/schema/' + key,
      { __admin: true },
      this.state.globalSchema[key],
      null,
      DEVSERVER_PREPROD_API_URL
    ).then((res) => {
      alert(
        key +
          ' copié vers le schéma serveur : ' +
          (res.error ? res.error.message : 'ok')
      );
      SchemaActions.load();
      this.loadServerSchema();
    });
  };

  copyToSource = (key) => {
    API._request(
      'put',
      'global/data/schema/' + key,
      { __admin: true },
      this.state.serverSchema[key],
      null
    ).then((res) => {
      alert(
        key +
          ' copié vers le schéma local : ' +
          (res.error ? res.error.message : 'ok')
      );
      SchemaActions.load();
      this.loadServerSchema();
    });
  };

  generateTestData = () => {
    let gi = 0;
    for (let i = 1; i <= 10; i++) {
      API.post(this.state.instanceName + '/data/Tag', {
        _id: 'tag' + i,
        label: 'Tag ' + i,
        _isTest: true,
      });
    }

    // TODO other types...

    for (let i = 0; i < 25; i++) {
      // https://rawgit.com/Marak/faker.js/master/examples/browser/index.html

      API.post(this.state.instanceName + '/data/Author', {
        _id: 'au_test_' + i,
        _cls: 'Author',
        label: faker.name.findName() + ' ' + i,
        shortDesc:
          "Description courte de l'auteur n° " +
          i +
          ', ' +
          faker.lorem.sentences(),
        biography:
          "<h2>Biographie l'<i>auteur</i> n° " +
          i +
          '</h2><p>' +
          faker.lorem.paragraphs(_.random(3, 15), '</p><p>') +
          '</p>',
        image: '/upload/author' + (gi % 25) + '.jpg',
        _isTest: true,
      });
      gi++;
    }

    API.get(this.state.instanceName + '/data/Category').then((contentTypes) =>
      contentTypes.forEach(({ _id: contentType, label: ctLabel }) => {
        for (let i = 0; i < 50; i++) {
          let d = moment().subtract(i, 'month');
          let v = _.random(1000);

          API.post(this.state.instanceName + '/data/TextContent', {
            _id: 'co_test_' + contentType + i,
            status: 'published',
            _cls: 'TextContent',
            contentType: contentType,
            label: 'Contenu ' + ctLabel + ' ' + i,
            desc:
              'Description du contenu de type ' +
              ctLabel +
              ' publié le ' +
              d.format('L') +
              ' avec ' +
              v +
              ' vues',
            body:
              _.random(3) === 0
                ? '<h2>Texte du contenu ' +
                  i +
                  '</h2><p>' +
                  faker.lorem.paragraphs(_.random(3, 20), '</p><p>') +
                  '</p>'
                : null,
            image: '/upload/test' + (gi % 200) + '.jpg',
            mainTags: _.sampleSize(_.range(10), _.random(5)).map((i) => ({
              _cls: 'Tag',
              _id: 'tag' + i,
              _isRef: true,
            })),
            viewCount: v,
            publishTs: d.unix(),
            _isTest: true,
            authors: _.sampleSize(_.range(25), _.random(3)).map((i) => ({
              _cls: 'Author',
              _id: 'au_test_' + i,
              _isRef: true,
            })),
          });
          gi++;
        }
      })
    );
  };

  deleteTestData = () => {
    API.get(this.state.instanceName + '/data/TextContent').then((contents) =>
      contents.forEach((c) => {
        if (c._isTest || /^Contenu \w+ \d+$/i.test(c.label)) {
          API.del(
            this.state.instanceName +
              '/data/TextContent/' +
              encodeURIComponent(c._id)
          );
        }
      })
    );
  };

  deleteSiteData = () => {
    let { instanceName } = this.state;

    if (TEMPLATE_INSTANCE_NAME === instanceName || 'global' === instanceName) {
      console.error("Can't delete", instanceName);
      return;
    }

    if (
      window.confirm(
        'TOUTES les données du site ' +
          this.state.instanceName +
          'seront effacées.\nContinuer ?'
      )
    ) {
      API.post(this.state.instanceName + '/clear');
    }
  };

  checkDb = async () => {
    if (this.state.checkingDB) return;

    this.setState({ checkingDB: true });
    await API.post(`${this.state.instanceName}/tools/checkDb`).then(
      (res) => this.setState({ checkDbResult: res }),
      (err) => alert('Erreur: ' + err.message)
    );
    this.setState({ checkingDB: false });
  };

  nextCheckAllPayments(afterId) {
    const { relink, ignoreMetadata } = this.state;

    API.get(`${this.state.instanceName}/tools/checkAllPayments`, {
      afterId,
      limit: 100,
      relink,
      ignoreMetadata,
    }).then(
      (res) => {
        if (!_.isEmpty(res)) {
          this.setState({
            checkAllPaymentsResult: (
              this.state.checkAllPaymentsResult || []
            ).concat(res),
          });
          this.nextCheckAllPayments(_.last(res)[1]);
        } else {
          this.setState({ checkAllPaymentsFinished: true });
        }
      },
      (err) => alert('Erreur: ' + err.message)
    );
  }

  checkAllPayments = () => {
    if (this.state.checkLocalPayments) {
      API.get(`${this.state.instanceName}/tools/checkAllPayments`, {
        local: true,
      }).then(
        (res) => this.setState({ checkAllLocalPaymentsResult: res }),
        (err) => alert('Erreur: ' + err.message)
      );
    }

    this.setState({
      checkAllPaymentsResult: [],
      checkAllPaymentsFinished: false,
    });
    this.nextCheckAllPayments(this.state.checkPaymentsAfterId || undefined);
  };

  temp_restore_ovh = () => {
    // OVHFIRE
    API.get(this.state.instanceName + '/temp_restore_ovh', {
      beginTs: 1615258800, //  2021-03-09 04:00
      endTs: 1615467600, //  2021-03-11 14:00
    }).then((ovhRes) => this.setState({ ovhRes }));
  };

  rebuildPurchaseCount = () => {
    API.post(`${this.state.instanceName}/tools/rebuildPurchaseCount`).then(
      (res) => alert('ok !'),
      (err) => alert('Erreur: ' + err.message)
    );
  };

  rebuildLikes = () => {
    API.post(`${this.state.instanceName}/tools/rebuildLikes`).then(
      (res) => alert('ok !'),
      (err) => alert('Erreur: ' + err.message)
    );
  };

  dumpSchemaToSourceFile = () => {
    API.post(this.state.instanceName + '/dumpSchemaToSource').then(
      (res) => alert('ok !'),
      (err) => alert('Erreur: ' + err.message)
    );
  };

  // TO BE REMOVED
  dedupTags = () => {
    API.post(`${this.state.instanceName}/tools/dedupTags`).then(
      (res) =>
        alert(
          'ok !\nCONSEIL : maintenant, utilisez "effacer tous les brouillons"'
        ),
      (err) => alert('Erreur: ' + err.message)
    );
  };

  render() {
    const {
      serverSchema,
      globalSchema,
      checkingDB,
      checkDbResult,
    } = this.state;

    return (
      <div className="wrapper tools-page">
        <OldForm type="primary">
          <OldSelect
            value={this.state.instanceName}
            onChange={(name) => InstanceActions.set(name)}
            options={this.state.instanceList}
            label="Base de test à utiliser"
            required={true}
          />

          {TEMPLATE_INSTANCE_NAME !== this.state.instanceName && (
            <InitSiteToolButton />
          )}

          <div>
            <Button onClick={this.temp_restore_ovh}>
              🔥🔥🔥 OVH FIRE 🔥🔥🔥
            </Button>
          </div>
          <ul>
            {_.map(
              this.state.ovhRes,
              (res) =>
                (res.user ||
                  res.payment ||
                  res.error ||
                  res.userError ||
                  res.paymentError) && (
                  <li key={res.id}>
                    <h5>{res.id}</h5>
                    {res.user && (
                      <div>
                        {res.user._id} <b>{res.user.login}</b>
                      </div>
                    )}
                    {res.payment && (
                      <div>
                        {res.payment._id}{' '}
                        {_.map(res.payment.products, '_id').join()}
                      </div>
                    )}
                    {res.error && <div className="error">{res.error}</div>}
                    {res.userError && (
                      <div className="error">{res.userError}</div>
                    )}
                    {res.paymentError && (
                      <div className="error">{res.paymentError}</div>
                    )}
                  </li>
                )
            )}
          </ul>
          <div>
            <Button onClick={this.generateTestData}>
              Générer des contenus de test
            </Button>
          </div>

          <div>
            <Button onClick={this.deleteTestData}>
              Supprimer les contenus de test (expérimental)
            </Button>
          </div>

          <div>
            <Button onClick={this.deleteSiteData}>Vider le site</Button>
          </div>

          <div>
            <Button onClick={this.dumpSchemaToSourceFile}>
              Dump du schéma pour versionning
            </Button>
          </div>

          <div className="check-db">
            <Button onClick={this.checkDb} disabled={checkingDB}>
              {checkingDB ? 'Checking DB' : 'Check DB'}
            </Button>

            {Object.keys(checkDbResult).length > 0 && (
              <ul className="check-db-result">
                {Object.keys(checkDbResult).map((schemaId) => (
                  <li key={schemaId}>
                    <b>{schemaId}</b>

                    <ul>
                      {Object.keys(checkDbResult[schemaId]).map(
                        (propertyKey) => (
                          <li key={propertyKey}>
                            <b>{propertyKey}</b>
                            <pre>
                              {JSON.stringify(
                                checkDbResult[schemaId][propertyKey],
                                null,
                                2
                              )}
                            </pre>
                          </li>
                        )
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div>
            <Button onClick={this.dedupTags}>Dédoublonner les tags</Button>
          </div>

          <div>
            <Button onClick={this.checkAllPayments}>
              Vérifier les paiements
            </Button>
            <OldInput
              label="ID Stripe(!) du premier paiement à vérifier :"
              value={this.state.checkPaymentsAfterId}
              onChange={(v) => this.setState({ checkPaymentsAfterId: v })}
            />
            <Switch
              label="Vérifier les paiements locaux"
              value={this.state.checkLocalPayments}
              onChange={(checkLocalPayments) =>
                this.setState({ checkLocalPayments })
              }
            />
            <Switch
              label="Ignorer le payment ID dans Stripe (OVH FIRE 🔥)"
              value={this.state.ignoreMetadata}
              onChange={(ignoreMetadata) =>
                this.setState({ ignoreMetadata, relink: false })
              }
            />
            <Switch
              label="Re-lier les paiements (OVH FIRE 🔥 ABOWEB)"
              value={this.state.relink}
              onChange={(relink) =>
                this.setState({ relink, ignoreMetadata: false })
              }
            />
            {this.state.checkAllLocalPaymentsResult && <h4>Paiements PUF:</h4>}
            {_.map(
              this.state.checkAllLocalPaymentsResult,
              (r, i) => r && <div key={i}>{r}</div>
            )}
            {this.state.checkAllPaymentsResult && <h3>Paiements Stripe:</h3>}
            {_.map(
              this.state.checkAllPaymentsResult,
              (r, i) =>
                r[0] && (
                  <div key={i}>
                    <span className="error">{r[0]}</span>
                    <span>Intent: {r[1]}</span>
                    <span>Payment: {r[2]}</span>
                  </div>
                )
            )}
            {this.state.checkAllPaymentsFinished && 'Fini !'}
          </div>

          <div>
            <Button onClick={this.rebuildLikes}>
              Recalculer les votes/likes
            </Button>
          </div>

          <div>
            <Button onClick={this.rebuildPurchaseCount}>
              Recalculer les ventes
            </Button>
          </div>

          <div>
            <Button
              href={
                config.API_URL +
                '/' +
                this.state.instanceName +
                '/data/exportSubscriptions?__admin=true'
              }
              target="_blank"
            >
              Mettre à jour et exporter les abonnements
            </Button>
          </div>

          <hr />

          <div>
            <Text isTitle element="h5" size={5}>
              Générer des codes cadeaux
            </Text>

            <Field>
              <Label>Produits pour lequel générer un code cadeau</Label>

              <Control>
                <ObjectRefEditor
                  style={{ marginTop: 10 }}
                  propertySchema={{
                    label: 'Produit',
                    objectType: ['Event', 'SubscriptionFormula'],
                  }}
                  onChange={(productRefForGiftCodes) =>
                    this.setState({ productRefForGiftCodes })
                  }
                  value={this.state.productRefForGiftCodes}
                />
              </Control>
            </Field>

            <Field>
              <Label>Quantité</Label>

              <Control>
                <Input
                  style={{ marginTop: 10 }}
                  type="number"
                  value={this.state.countForGiftCodes || ''}
                  onChange={(newValue) =>
                    this.setState({ countForGiftCodes: newValue })
                  }
                />
              </Control>
            </Field>

            <Field>
              <Label>Utiliser un code cadeau partagé</Label>

              <Control>
                <ObjectRefEditor
                  style={{ marginTop: 10 }}
                  propertySchema={{
                    label: 'Code partagé',
                    objectType: ['MasterGiftCode'],
                  }}
                  onChange={(masterGiftCodeRef) =>
                    this.setState({ masterGiftCodeRef })
                  }
                  value={this.state.masterGiftCodeRef}
                />
              </Control>
            </Field>

            <Button
              href={`${config.API_URL}/${
                this.state.instanceName
              }/tools/generateGifts?${buildQueryString({
                productRef: this.state.productRefForGiftCodes,
                count: this.state.countForGiftCodes,
                masterGiftCodeRef: this.state.masterGiftCodeRef,
              })}`}
              target="_blank"
              disabled={
                !this.state.productRefForGiftCodes ||
                !this.state.countForGiftCodes
              }
            >
              Générer
            </Button>
          </div>

          <hr />

          <div>Copier les données :</div>
          <OldSelect
            value={this.state.src}
            onChange={(src) => this.onChangeSource(src)}
            options={this.state.instanceList}
            label="Base source"
          />
          <OldSelect
            value={this.state.srcColl}
            onChange={(srcColl) => this.setState({ srcColl })}
            options={this.state.collectionList.map((v) => ({
              value: v,
              label: v,
            }))}
            label="Collection source"
          />
          <div>... vers</div>
          <OldSelect
            value={this.state.dst}
            onChange={(dst) => this.setState({ dst })}
            options={this.state.instanceList}
            label="Base dest"
          />
          {this.state.dst && this.state.dst !== this.state.src && (
            <Button onClick={() => this.copy()}>COPIER</Button>
          )}
          {this.state.copyResult}

          {serverSchema && globalSchema && (
            <div>
              <hr />
              <SchemaMigrator
                source={globalSchema}
                target={serverSchema}
                copyToSource={this.copyToSource}
                copyToTarget={this.copyToTarget}
              />
            </div>
          )}
        </OldForm>
      </div>
    );
  }
}
