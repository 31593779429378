const CartButton = {
  _id: 'cartButton',
  frontComponent: 'CartButton',
  label: 'Bouton panier',
  icon: 'shopping-cart',
  optionsSchema: [
    { key: 'label', label: 'Libellé', type: 'string' },
    {
      key: 'pageId',
      label: 'Page de paiement du panier (sur bouton valider)',
      type: 'object_id',
      objectType: 'Page',
    },
    {
      key: 'displayIfEmpty',
      label: 'Afficher si le panier est vide',
      type: 'bool',
      default: false,
    },
  ],
  forPage: true,
  forEntity: false,
};

export default CartButton;
