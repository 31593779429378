// @flow
import * as React from 'react';

import type { Tab } from './';

import { Icon } from 'src/components';
import GetresponseSynchroTab from './shared/GetResponseSynchroTab';

const resubRuleCustomTabs: Array<Tab> = [
  {
    to: 'get-response-synchro',
    label: (
      <span>
        <Icon name="mail-bulk" /> Synchro GetResponse
      </span>
    ),
    content: GetresponseSynchroTab,
  },
];

export default resubRuleCustomTabs;
