import * as React from 'react';

import { Text, Box } from 'src/components';

export default function usedAndNotDefinedComponents({ componentIds }) {
  return (
    <Box additionalClassName="used-but-not-defined-components">
      <Text isTitle element="h3">
        {componentIds.length} composants utilisés mais non définis
      </Text>

      <ul>
        {componentIds.sort().map((componentId) => (
          <li key={componentId}>
            <b>{componentId}</b>
          </li>
        ))}
      </ul>
    </Box>
  );
}
