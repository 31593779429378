const EntityTitle = {
  _id: 'label',
  label: 'Titre',
  icon: 'font',
  frontComponent: 'EntityTitle',
  optionsSchema: [
    { key: 'useH1', label: 'Utiliser <h1>', type: 'bool', default: false },
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'objet",
      type: 'bool',
      default: true,
    },
    { key: 'freeLabel', label: 'Etiquette si gratuit', type: 'string' },
    { key: 'premiumLabel', label: 'Etiquette si payant', type: 'string' },
    {
      key: 'truncateLength',
      label: 'Longueur maximale du texte (en nbre de caractères)',
      type: 'int',
      min: 0,
    },
  ],
  forEntity: true,
};

export default EntityTitle;
