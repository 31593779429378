// @flow
import * as React from 'react';
import moment from 'moment';

import { Button, Flex, Icon } from 'src/components';

type Props = {
  startDate: moment,
  setStartDate: (moment) => void,
  endDate: moment,
  setEndDate: (moment) => void,
};

export default function Footer({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props): React.Node {
  const canGoToNextPeriod = startDate.unix() < moment().unix();

  const goToPreviousPeriod = React.useCallback(() => {
    const distanceTs = endDate.unix() - startDate.unix();

    const newStartDate = moment.unix(startDate.unix() - distanceTs);
    const newEndDate = moment.unix(endDate.unix() - distanceTs);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [startDate, endDate, setStartDate, setEndDate]);

  const goToNextPeriod = React.useCallback(() => {
    if (!canGoToNextPeriod) return;

    const distanceTs = endDate.unix() - startDate.unix();

    const newStartDate = moment.unix(startDate.unix() + distanceTs);
    const newEndDate = moment.unix(endDate.unix() + distanceTs);

    setStartDate(newStartDate);
    setEndDate(newEndDate);
  }, [startDate, endDate, setStartDate, setEndDate, canGoToNextPeriod]);

  return (
    <Flex style={{ justifyContent: 'space-between', marginTop: 10 }}>
      <Button onClick={goToPreviousPeriod}>
        <Icon name="arrow-left" style={{ marginRight: 5 }} />
        Période précédente
      </Button>
      <Button onClick={goToNextPeriod} disabled={!canGoToNextPeriod}>
        Période suivante <Icon name="arrow-right" style={{ marginLeft: 5 }} />
      </Button>
    </Flex>
  );
}
