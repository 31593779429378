// @flow

import * as React from 'react';
import moment from 'moment';

import {
  Table,
  THead,
  Tr,
  Th,
  Td,
  TBody,
  LoadingOverlay,
} from 'src/components';

type Props = {|
  stats: any,
  error: string,
  params: { startTs: number, endTs: number },
|};

export default function GenericStatsTable({
  stats,
  error,
  params,
}: Props): React.Node {
  const { startTs, endTs } = params;
  const loading = !stats && !error;

  const periodTs = endTs - startTs;
  const previousStartDate = moment.unix(startTs - periodTs);

  return (
    <div style={{ position: 'relative', marginTop: 20 }}>
      <Table striped hoverable>
        <THead>
          <Tr key="header">
            <Th>Intitulé</Th>
            <Th>
              De {previousStartDate.format('LL')} au{' '}
              {moment.unix(startTs).format('LL')}
            </Th>
            <Th>
              De {moment.unix(startTs).format('LL')} au{' '}
              {moment.unix(endTs).format('LL')}
            </Th>
          </Tr>
        </THead>

        <TBody>
          {Object.keys(stats || {}).map((item, index) => {
            return (
              <Tr key={item}>
                <Td>{stats[item].label}</Td>
                <Td>{stats[item].data?.startDateValue ?? null}</Td>
                <Td>{stats[item].data?.endDateValue ?? null}</Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>

      {loading && <LoadingOverlay show />}
    </div>
  );
}
