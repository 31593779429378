import React, { useState, useContext } from 'react';
import icons from 'megadraft/lib/icons';
import Slider from 'rc-slider';

import CustomIcons from 'src/images/Icons/Icons';
import 'rc-slider/assets/index.css';

import { APIContext } from 'src/pages/contexts';

import {
  BlockContent,
  BlockData,
  BlockInput,
  BlockWrapper,
  BlockControls,
  BlockActionGroup,
} from 'megadraft/lib/components/plugin/';
import ImageBlockStyle from './ImageBlockStyle';
import Dropdown from 'megadraft/lib/components/Dropdown';

//the behaviour of the image plugin
export default function ImageBlock(props) {
  const { container, blockProps, data } = props;
  const { getImageUrl } = useContext(APIContext);
  //slider value (zoom)
  const [value, setValue] = useState(Number.parseInt(data.display) || 50); // retro-compat values like "medium"

  const actions = [
    {
      key: 'delete',
      icon: icons.DeleteIcon,
      action: container.remove,
    },
  ];

  // caption ( légende) handler
  const _handleCaptionChange = (event) => {
    event.stopPropagation();
    container.updateData({ caption: event.target.value });
  };

  const _handleRightsHolderChange = (event) => {
    event.stopPropagation();
    container.updateData({ rightsHolder: event.target.value });
  };

  const _handleAlignChange = (newValue) => {
    container.updateData({ align: newValue });
  };
  // slider/ zoom handler
  const _onSliderChange = (desiredValue) => {
    setValue(desiredValue);
    container.updateData({ display: desiredValue.toString() });
  };
  const _imgSize = (display, position) => {
    let width;
    let float;
    switch (position) {
      case 'left':
        float = 'left';
        break;
      case 'right':
        float = 'right';
        break;
      default:
        float = 'none';
    }
    switch (display) {
      case value: {
        width = value.toString() + '%';
        break;
      }
      default:
        width = '100%';
    }

    return Object.assign({}, ImageBlockStyle.image, { width, float });
  };

  const readOnly = blockProps.getInitialReadOnly();

  //values that will be displayed on the toolbar of the image plugin
  const defaults = {
    defaultDisplay: value,
    displayOptions: [
      { key: 'verySmall', icon: icons.MediaSmallIcon, label: '33%' },
      { key: 'small', icon: icons.MediaSmallIcon, label: '50%' },
      { key: 'mediumLarge', icon: icons.MediaMediumIcon, label: '66%' },
      { key: 'medium', icon: icons.MediaMediumIcon, label: '75%' },
      { key: 'big', icon: icons.MediaBigIcon, label: '100%' },
    ],
    defaultAlign: 'center',
    alignOptions: [
      { key: 'left', icon: CustomIcons.LeftIcon, label: 'GAUCHE' },
      { key: 'center', icon: CustomIcons.CenterIcon, label: 'CENTRÉE' },
      { key: 'right', icon: CustomIcons.RightIcon, label: 'DROITE' },
    ],
    test: [
      { key: 'left', icon: CustomIcons.LeftIcon, label: 'GAUCHE' },
      { key: 'left', icon: CustomIcons.LeftIcon, label: 'GAUCHE' },
      { key: 'left', icon: CustomIcons.LeftIcon, label: 'GAUCHE' },
    ],
  };
  let options = blockProps.plugin.options || {};
  options = { ...defaults, ...options };

  let dropdownWrapperClass =
    'd-inline-block position-absolute float-left overflow-visible';

  // UI of the image plugin
  return (
    <BlockWrapper readOnly={readOnly}>
      {!readOnly && (
        <BlockControls>
          <div style={{ display: 'flex' }}>
            <div
              className={dropdownWrapperClass}
              style={{ width: '130px', minHeight: '20px' }}
            >
              <div style={{ marginRight: '24px' }}>
                <div style={{ textAlign: 'center' }}>{value}%</div>
                <Slider
                  min={0}
                  max={100}
                  onChange={_onSliderChange}
                  value={value}
                  railStyle={{
                    height: 14,
                  }}
                  handleStyle={{
                    height: 12,
                    width: 12,
                    marginTop: 0,
                    backgroundColor: 'black',
                    border: 0,
                  }}
                  trackStyle={{
                    background: 'none',
                  }}
                />
              </div>
            </div>
            <div
              className={dropdownWrapperClass}
              style={{
                width: '130px',
                minHeight: '20px',
                left: '130px',
                marginTop: '19px',
              }}
            >
              <Dropdown
                items={options.alignOptions}
                selected={data.align || options.defaultAlign}
                onChange={_handleAlignChange}
              />
            </div>
            <div
              className={dropdownWrapperClass}
              style={{
                width: '130px',
                minHeight: '20px',
                left: '130px',
                marginTop: '19px',
              }}
            ></div>
          </div>
          <BlockActionGroup items={actions} />
        </BlockControls>
      )}
      <BlockContent
        style={{
          position: 'initial',
        }}
      >
        <div
          style={{
            backgroundColor: 'white ',
          }}
        >
          <img
            src={getImageUrl(data.src)}
            style={_imgSize(value, data.align)}
            alt=""
          />
          <div style={{ lineHeight: 'normal', textAlign: 'left' }}>
            {data.caption}
          </div>
        </div>
      </BlockContent>

      <BlockData>
        <BlockInput
          placeholder={'Légende'}
          value={data.caption}
          onChange={_handleCaptionChange}
          readOnly={readOnly}
        />

        <BlockInput
          placeholder={"Mention droits d'auteur"}
          value={data.rightsHolder}
          onChange={_handleRightsHolderChange}
          readOnly={readOnly}
        />
      </BlockData>
    </BlockWrapper>
  );
}
