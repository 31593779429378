import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import API from 'src/helpers/api/API';

import { Hierarchy } from 'src/pages/SectionRouter/sectionSpecificPages/superAdmin/SiteHierarchyPage';
import { useResourceContext, useGlobal } from 'src/hooks';

export default function PageHierarchyTab() {
  const [complexComponents, setComplexComponents] = React.useState(null);
  const { resource: page } = useResourceContext();
  const { siteId, globalSchema } = useGlobal();

  useEffectOnce(() => {
    const effect = async () => {
      const { data } = await API.get(siteId + '/data/ComplexComponent');

      setComplexComponents(data);
    };

    effect();
  });

  if (!complexComponents) return null;

  return (
    <div className="FormBase--primary site-hierarchy-wrapper">
      <a href={`/${siteId}/super-admin/site-hierarchy`}>
        Fil de fer global du site
      </a>
      <Hierarchy
        pageOrComponent={page}
        ccs={complexComponents}
        d={1}
        globalSchema={globalSchema}
      />
    </div>
  );
}
