import ContentStats from './Stats';

export default function getContentCustomProperty(resource, propertySchema) {
  switch (propertySchema.key) {
    case '_stats':
      return ContentStats;
    default:
      return null;
  }
}
