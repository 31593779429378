const CommentList = {
  _id: 'comments',
  icon: 'comments',
  label: 'Commentaires',
  frontComponent: 'Comments',
  forEntity: true,
  optionsSchema: [
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        // TODO import from Front / ENTITY_COMPONENTS_MAP
        { value: 'list', label: 'Liste des commentaires' },
        { value: 'count', label: 'Nombre de commentaires' },
      ],
      required: true, // unusual
      default: 'list',
    },
    {
      key: 'foldable',
      label: 'Cliquer pour ouvrir',
      description: "L'utilisateur doit cliquer pour afficher les commentaires",
      type: 'bool',
      default: false,
      required: true,
      condition: { f$: "obj.mode !== 'count'" },
    },
    {
      key: 'commentsToLoad',
      label: 'Nombre de commentaires à charger initialement',
      type: 'int',
      min: 1,
    },
    {
      key: 'commentsToLoadMore',
      label:
        'Nombre de commentaires supplémentaires à charger (bouton "plus de commentaires")',
      type: 'int',
      min: 1,
    },
    {
      key: 'answersToLoad',
      label: 'Nombre de réponses (par commentaire) à charger initialement',
      type: 'int',
      min: 1,
    },
    {
      key: 'version',
      label: 'Version à utiliser',
      type: 'int',
      min: 1,
      max: 2,
    },
  ],
};

export default CommentList;
