// @flow
import * as React from 'react';

import type { ResourceSchema } from 'src/types/models';

export type SchemaContextType = {
  schema: ResourceSchema,
  path: string,
};

export default (React.createContext<SchemaContextType | null>(
  null
): React.Context<SchemaContextType | null>);
