// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { ResourceSchema } from 'src/types/models';
import type {
  PropertyFilterBy,
  PropertySortBy,
  PropertySetFilterBy,
  PropertySetSortBy,
  SortByValue,
} from '../useResourcePaginatedData';

import { Button, Icon, THead, Th, Tr, Flex } from 'src/components';
import Filter from './Filter';

const FILTERABLE_TYPES = [
  'enum',
  'timestamp',
  'bool',
  'string',
  'int',
  'richtext',
  'object_id',
  'object_ref',
];
const SORTABLE_TYPES = ['enum', 'timestamp', 'bool', 'string', 'int'];

type Props = {|
  sortBy: PropertySortBy,
  filterBy: PropertyFilterBy,
  setSortBy: PropertySetSortBy,
  setFilterBy: PropertySetFilterBy,
  schema: ResourceSchema,
  columnProperties: any,
  selectable: boolean,
|};

const getNextSortBy = (current?: SortByValue): SortByValue => {
  switch (current) {
    case 1:
      return -1;
    case -1:
      return null;
    case null:
    default:
      return 1;
  }
};

export default function TableHead({
  schema,
  sortBy,
  filterBy,
  setSortBy,
  setFilterBy,
  columnProperties,
  selectable,
}: Props): React.Node {
  return (
    <THead>
      <Tr>
        {selectable && <Th />}
        <Th>Actions</Th>

        {columnProperties.map((propertySchema) => {
          const { key, type, label } = propertySchema;

          const title = label || key;
          const filtered = key in filterBy;
          const filterable = FILTERABLE_TYPES.includes(type);
          const sortable =
            SORTABLE_TYPES.includes(type) || propertySchema.sortable;
          const propertySortBy = sortBy[key];
          const propertyFilterBy = filterBy[key];

          return (
            <Th
              additionalClassName={classnames({
                searching: filtered,
              })}
              key={key}
            >
              <Flex direction="column">
                <Flex
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Flex style={{ alignItems: 'center' }}>
                    <span>{title}</span>
                    {sortable && (
                      <Button
                        size="small"
                        style={{ marginRight: 5 }}
                        isActive={!!propertySortBy}
                        isText
                        onClick={() =>
                          setSortBy(key, getNextSortBy(propertySortBy))
                        }
                      >
                        <Icon
                          name={
                            (propertySortBy === 1 && 'sort-up') ||
                            (propertySortBy === -1 && 'sort-down') ||
                            'sort'
                          }
                        />
                      </Button>
                    )}
                  </Flex>

                  {filterable && (
                    <Button
                      isActive={filtered}
                      onClick={() => setFilterBy(key, !filtered)}
                      size="small"
                      isText
                    >
                      <Icon name="filter" />
                    </Button>
                  )}
                </Flex>

                {filtered && (
                  <Filter
                    propertySchema={propertySchema}
                    value={propertyFilterBy}
                    filterBy={(prop, type, newValue) => {
                      setFilterBy(key, true, newValue);
                    }}
                  />
                )}
              </Flex>
            </Th>
          );
        })}
      </Tr>
    </THead>
  );
}
