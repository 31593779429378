const EntityContentType = {
  _id: 'contentType',
  label: 'Catégorie',
  icon: 'tags',
  frontComponent: 'EntityContentType',
  optionsSchema: [
    {
      key: 'displayFirstTag',
      label: 'Afficher aussi le premier tag',
      type: 'bool',
    },
  ],
  forEntity: true,
};

export default EntityContentType;
