// @flow
import type { Tab } from '../';

import VideoStreamsTab from './VideoStreamsTab';

const userCustomTabs: Array<Tab> = [
  {
    to: 'streams',
    icon: 'file-video',
    label: 'URLs & fichiers',
    content: VideoStreamsTab,
  },
];

export default userCustomTabs;
