import classNames from 'classnames';
import React from 'react';
import { useSchemaContext } from 'src/hooks';

export default function NamespaceChainRenderer({
  mode,
  value: namespace,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { chain } = resource;
  const { path: schemaPath } = useSchemaContext();

  if (!chain) return null;

  return (
    <nav class="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
      <ul>
        {chain.map((chain, i) => (
          <li
            key={chain.path}
            className={i === chain.length - 1 ? classNames('is-active') : ''}
          >
            <a href={`${schemaPath}/${chain._id}/edit`}>{chain.path}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
