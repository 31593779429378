// @flow
import * as React from 'react';

import type { GlobalSchema } from 'src/types/models';
import type { SectionName } from 'src/types/routing';
import type { ContextRouter, Match } from 'react-router-dom';

import { useGlobal } from 'src/hooks';
import { toPath } from 'src/helpers/string';
import SECTIONS from 'src/pages/constants/sections';
import { canUserAccessSection } from 'src/helpers/models/users';
import sectionHardcodedRoutes from './hardcodedRoutes';
import { getResourcePath } from 'src/helpers/url';
import { getSectionSchemas } from 'src/helpers/models/schema';

import { Route, Redirect, Switch } from 'react-router-dom';
import ResourceRouter from '../ResourceRouter';

type Props = {|
  ...ContextRouter,
  id: SectionName,
|};

export default function SectionRouter({ match, id }: Props): React.Node {
  const section = SECTIONS[id];
  const { globalSchema, siteId, user } = useGlobal();
  const { defaultPathname } = section;

  if (!canUserAccessSection(user, section)) {
    return <Redirect to={toPath(`/${siteId}`)} />;
  }

  const options = { match, globalSchema, siteId };
  const hardcodedRoutes = buildHardcodedRoutes(id, options);
  const schemaRoutes = buildSchemaRoutes(id, options);

  return (
    <Switch>
      {hardcodedRoutes}
      {schemaRoutes}
      {defaultPathname && (
        <Route>
          <Redirect to={toPath(`/${siteId}${defaultPathname}`)} />
        </Route>
      )}
    </Switch>
  );
}

type Options = {|
  globalSchema: GlobalSchema,
  match: Match,
  siteId: string,
|};

const buildHardcodedRoutes = (
  sectionId: SectionName,
  { globalSchema, match }: Options
): Array<React.Node> => {
  const hardcodedRoutes = sectionHardcodedRoutes[sectionId] || [];

  return hardcodedRoutes.map((route, i) => {
    const { component: Component, pathname, exact } = route;
    const fullPathname = toPath(`${match.path}/${pathname}`);

    return (
      <Route
        key={pathname}
        sensitive
        path={fullPathname}
        exact={exact ?? true}
        render={(routeProps) => <Component {...routeProps} {...route} />}
      />
    );
  });
};

const buildSchemaRoutes = (
  sectionId: SectionName,
  { globalSchema, siteId }: Options
): Array<React.Node> => {
  const sectionSchemas = getSectionSchemas(sectionId, {
    siteId,
    globalSchema,
  });

  return sectionSchemas.map((schema) => {
    const path = getResourcePath(siteId, schema);

    return (
      <Route
        path={path}
        key={path}
        sensitive
        render={(routeProps) => (
          <ResourceRouter {...routeProps} schema={schema} />
        )}
      />
    );
  });
};
