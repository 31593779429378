const EntityPaywallTag = {
  _id: 'EntityPaywallTag',
  label: 'Tag du paywall (abonné)',
  icon: 'dollar-sign',
  frontComponent: 'EntityPaywallTag',
  forEntity: true,
  optionsSchema: [],
};

export default EntityPaywallTag;
