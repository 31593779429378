// @flow
import * as React from 'react';
import ResourceAPIConnector from 'src/helpers/api/ResourceAPIConnector';

import type { ResourceSchema, Resource } from 'src/types/models';

export type ResourceContextType<RT: Resource> = {|
  schema: ResourceSchema,
  resource: RT,
  connector: ResourceAPIConnector,
  path: string,
  dirty?: boolean,
  setDirty?: (boolean) => void,
  apiPath: string,
|};

export const ResourceContext = (React.createContext<ResourceContextType<Resource> | null>(
  null
): React.Context<ResourceContextType<Resource> | null>);

export type ResourceReferencesContextType = {|
  references: Object,
  setReferences: (Object) => void,
|};

export const ResourceReferencesContext = (React.createContext<ResourceReferencesContextType>(
  {
    references: {},
    setReferences: () => {},
  }
): React.Context<ResourceReferencesContextType>);

export default function withResourceContext<Config>(
  Component: React.AbstractComponent<{|
    ...Config,
    ...ResourceContextType<Resource>,
  |}>
): React.AbstractComponent<Config> {
  return function WithResourceContext(props: Config) {
    return (
      <ResourceContext.Consumer>
        {(context) => {
          if (!context) return null;
          return <Component {...context} {...props} />;
        }}
      </ResourceContext.Consumer>
    );
  };
}

export const ResourceConditionContext = (React.createContext<Object>(
  {}
): React.Context<Object>);
