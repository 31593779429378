import React from 'react';

import { Modal } from 'src/components';
import LayoutPicker from './LayoutPicker';

export default function LayoutPickerModal({ open, onClose, onSelect }) {
  return (
    <Modal
      modal={false}
      open={open}
      onClose={onClose}
      title="Diviser le bloc :"
    >
      <LayoutPicker
        selectInnerLayout={(cols) => {
          onSelect(cols);
          onClose();
        }}
        draggable={false}
      />
    </Modal>
  );
}
