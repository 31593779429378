// @flow
import * as React from 'react';
import classnames from 'classnames';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import type { Style, BulmaSize } from '../types';

import Icon from '../Icon';

export type Item = {
  label: string,
  icon?: string,
  active?: boolean,
  to?: string,
  exact?: boolean,
  disabled?: boolean,
  onClick?: () => any,
};

type Align = 'left' | 'centered' | 'right';

type Props = {|
  items: Array<Item>,
  align?: Align,
  size?: BulmaSize,
  isBoxed?: boolean,
  isToggle?: boolean,
  fullWidth?: boolean,
  additionalClassName?: string,
  style?: Style,
|};

export default function Tabs({
  items,
  align,
  size,
  isBoxed,
  isToggle,
  fullWidth,
  additionalClassName,
  style,
}: Props): React.Node {
  const location = useLocation();
  const sizeClassName = !!size ? `is-${size}` : '';
  const alignClassName = !!align ? `is-${align}` : '';
  const className = classnames(
    'tabs',
    sizeClassName,
    alignClassName,
    additionalClassName,
    {
      'is-boxed': !!isBoxed,
      'is-toggle': !!isToggle,
      'is-fullwidth': !!fullWidth,
    }
  );

  return (
    <div className={className} style={style}>
      <ul>
        {items.map(
          ({
            label,
            active: explicitActive,
            icon,
            to,
            onClick,
            disabled,
            exact,
          }) => {
            const active =
              explicitActive ||
              (to ? matchPath(location.pathname, { path: to, exact }) : false);

            return (
              <li
                className={classnames({
                  'is-active': active,
                  'is-disabled': disabled,
                })}
                key={to || label}
              >
                {!!to ? (
                  <NavLink
                    to={to}
                    disabled={disabled}
                    activeClassName="is-active"
                  >
                    {!!icon && <Icon name={icon} />}
                    {label}
                  </NavLink>
                ) : (
                  <a onClick={onClick} disabled={disabled}>
                    {!!icon && <Icon name={icon} />}
                    {label}
                  </a>
                )}
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
}
