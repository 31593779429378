import React from 'react';
import _ from 'lodash';

import EditFileIcon from 'src/images/edit-file-icon.svg';
import { InstanceStore, SchemaStore } from 'src/stores';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import { duration } from 'src/helpers/misc';
import { getResourcePath } from 'src/helpers/url';

import { NavLink } from 'react-router-dom';
import { Pagination, Switch, LoadingOverlay } from 'src/components';
import ExportSection from './ExportSection';

const DIMENSIONS = [
  {
    key: 'anonymous',
    label: 'anonyme',
    filter: {
      key: { userStatus: 'anonymous' },
    },
    selected: true,
  },
  {
    key: 'freemium',
    label: 'freemium',
    filter: {
      key: { userStatus: 'freemium' },
    },
    selected: true,
  },
  {
    key: 'premium',
    label: 'premium',
    filter: {
      key: { userStatus: 'premium' },
    },
    selected: true,
  },
  {
    key: 'playlist',
    label: 'playlist',
    filter: (v) => v.key.mode === 'pl_full' || v.key.mode === 'pl_trailer',
  },
  {
    key: 'no_playlist',
    label: 'page contenu',
    filter: (v) => v.key.mode === 'full' || v.key.mode === 'trailer',
  },
  {
    key: 'x_trailer',
    label: 'extrait',
    filter: (v) => v.key.mode === 'trailer' || v.key.mode === 'pl_trailer',
  },
  {
    key: 'x_full',
    label: 'complet',
    filter: (v) => v.key.mode === 'full' || v.key.mode === 'pl_full',
  },
  {
    key: 'total',
    label: 'TOTAL',
    selected: true,
  },
];

const COUNT_PER_PAGE = 10;
export default class PlayStatsPage extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      selDim: _.mapValues(_.keyBy(DIMENSIONS, 'key'), 'selected'),
      page: 0,
      totalVideoCount: -1,
      stats: [],
      loading: true,
    };

    this.stores = [SchemaStore, InstanceStore];
  }

  didMount = async () => {
    const { instanceName } = this.state;

    const totalVideoCount = await API.get(`${instanceName}/data/Video/count`);

    this.setState({ totalVideoCount }, this.fetchStats);
  };

  setPage = (newPage) => {
    this.setState(
      {
        page: newPage,
      },
      this.fetchStats
    );
  };

  fetchStats = async () => {
    const { totalVideoCount, instanceName, page } = this.state;
    this.setState({ loading: true });

    if (totalVideoCount <= 0) return;

    const stats = await API.get(`${instanceName}/statistics/play`, {
      offset: page * COUNT_PER_PAGE,
      limit: COUNT_PER_PAGE,
    });

    this.setState({ stats, loading: false });
  };

  render() {
    const {
      totalVideoCount,
      page,
      stats,
      instanceName,
      globalSchema,
      loading,
    } = this.state;

    let groupStats = _(stats)
      .groupBy('key.entityId')
      .map((stat, entityId) => ({
        stats: stat,
        entity: stat[0].entity, // TODO mieux
        entityId,
        views: _.sumBy(stat, 'views'),
      }))
      .sortBy('views')
      .reverse()
      .value();

    const selDim = this.state.selDim;
    const dimensions = DIMENSIONS.filter((d) => selDim[d.key]);

    return (
      <div className="PlayStatsPage">
        <h1>Stats de lecture</h1>

        <h2>Classement vues seulement</h2>

        <div className="statistics-view">
          <div className="stats-dim-selectors">
            {DIMENSIONS.map((d) => (
              <Switch
                style={{ marginRight: 20 }}
                color="success"
                value={selDim[d.key]}
                key={d.key}
                onChange={(v) =>
                  this.setState({
                    selDim: { ...this.state.selDim, [d.key]: v },
                  })
                }
                label={d.label}
              />
            ))}
          </div>

          <div style={{ position: 'relative' }}>
            <Pagination
              onChange={this.setPage}
              currentPage={page}
              countPerPage={COUNT_PER_PAGE}
              totalCount={totalVideoCount}
            />

            <table className="EntityStatsView-table">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={dimensions.length} className="playCount">
                    lectures
                  </th>
                  <th colSpan={dimensions.length} className="viewCount">
                    vues
                  </th>
                  <th colSpan={dimensions.length} className="users">
                    utilisateurs
                  </th>
                  <th colSpan={dimensions.length} className="time">
                    temps de lecture moyen
                  </th>
                  <th colSpan={dimensions.length} className="percent">
                    % de lecture moyen
                  </th>
                </tr>
                <tr>
                  <th></th>
                  {_.times(5, (i) =>
                    dimensions.map((c) => (
                      <th key={c.key + i}>
                        <span className="vertical">{c.label}</span>
                      </th>
                    ))
                  )}
                </tr>
              </thead>

              <tbody>
                {groupStats.map((stat) => {
                  const { entity } = stat;

                  if (!entity) return null; // The entity has been deleted (or there's a big bug)

                  const schema = globalSchema[entity._cls];

                  return (
                    <tr key={stat.entityId}>
                      <td className="label">
                        {stat.entity && (
                          <NavLink
                            title="Editer"
                            to={`/${getResourcePath(instanceName, schema)}/${
                              stat.entity._id
                            }/edit`}
                          >
                            <img src={EditFileIcon} alt="Editer le contenu" />
                          </NavLink>
                        )}{' '}
                        {(stat.entity && stat.entity.label) || stat.entityId}
                      </td>
                      {dimensions.map((c) => (
                        <td key={c.key} className={c.key + ' playCount value'}>
                          {_.sumBy(
                            c.filter
                              ? _.filter(stat.stats, c.filter)
                              : stat.stats,
                            'count'
                          )}
                        </td>
                      ))}
                      {dimensions.map((c) => (
                        <td key={c.key} className={c.key + ' viewCount value'}>
                          {_.sumBy(
                            c.filter
                              ? _.filter(stat.stats, c.filter)
                              : stat.stats,
                            'views'
                          )}
                        </td>
                      ))}
                      {dimensions.map((c) => (
                        <td key={c.key} className={c.key + ' userCount value'}>
                          {_.sumBy(
                            c.filter
                              ? _.filter(stat.stats, c.filter)
                              : stat.stats,
                            'distinctUsers'
                          )}
                        </td>
                      ))}
                      {dimensions.map((c) => (
                        <td key={c.key} className={c.key + ' avgTime value'}>
                          {duration(
                            _.sumBy(
                              c.filter
                                ? _.filter(stat.stats, c.filter)
                                : stat.stats,
                              (r) => r.avgTime * r.count
                            ) /
                              _.sumBy(
                                c.filter
                                  ? _.filter(stat.stats, c.filter)
                                  : stat.stats,
                                'count'
                              ),
                            true
                          )}
                        </td>
                      ))}
                      {dimensions.map((c) => (
                        <td key={c.key} className={c.key + ' avgPercent value'}>
                          {Math.round(
                            (_.sumBy(
                              c.filter
                                ? _.filter(stat.stats, c.filter)
                                : stat.stats,
                              (r) => r.avgPercent * r.count
                            ) *
                              100) /
                              _.sumBy(
                                c.filter
                                  ? _.filter(stat.stats, c.filter)
                                  : stat.stats,
                                'count'
                              )
                          )}
                          %
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <LoadingOverlay show={loading} />
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <ExportSection />
        </div>
      </div>
    );
  }
}
