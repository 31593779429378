// @flow
import * as React from 'react';
import { groupBy, sortBy } from 'lodash';

import type { ResourcePropertySchema, Resource } from 'src/types';
import type { OnPropertyChange } from '../';

import { useGlobal, useSchemaContext } from 'src/hooks';
import { filterProperty, transformProperty } from './helpers';
import { ResourceConditionContext } from 'src/pages/contexts/ResourceContext';

import SchemaCustomFields from './SchemaCustomFields';
import PropertyGroup from './PropertyGroup';
import { Tabs } from 'src/components';

type Props = {|
  resource: Resource,
  propertySchemas: Array<ResourcePropertySchema>,
  isSidebar?: boolean,
  onPropertyChange: OnPropertyChange,
  disabled?: boolean,
|};

export default function DefaultResourceEditor({
  resource,
  propertySchemas,
  isSidebar,
  onPropertyChange,
  disabled,
}: Props): React.Node {
  const { user, globalSchema, site } = useGlobal();
  const resourceConditionContext = React.useContext(ResourceConditionContext);
  const { schema } = useSchemaContext();
  const { propertyOrder = {} } = schema;

  const formattedPropertySchemas = propertySchemas
    .filter((propertySchema) =>
      filterProperty(propertySchema, {
        resource,
        user,
        site: site.site,
        ...resourceConditionContext,
      })
    )
    .map((propertySchema) =>
      transformProperty(propertySchema, {
        resource,
        globalSchema,
      })
    );

  const propertyGroups = groupBy(
    formattedPropertySchemas,
    (property) => property.group || 'Autre'
  );

  const availableGroups = sortBy(Object.keys(propertyGroups), (groupLabel) => {
    const index = (schema.propertiesGroups || []).indexOf(groupLabel);
    return index === -1 ? 100 : index;
  });

  const [currentGroup, setCurrentGroup] = React.useState(availableGroups[0]);

  return (
    <div className="resource-form-default-editor">
      <SchemaCustomFields
        schema={schema}
        resource={resource}
        isSidebar={isSidebar}
      />

      {availableGroups.length > 1 && (
        <Tabs
          isBoxed
          fullWidth
          items={availableGroups.map((groupLabel) => ({
            label: groupLabel,
            onClick: () => setCurrentGroup(groupLabel),
            active: currentGroup === groupLabel,
          }))}
        />
      )}

      <PropertyGroup
        label={currentGroup}
        propertySchemas={propertyGroups[currentGroup] || []}
        resource={resource}
        onPropertyChange={onPropertyChange}
        disabled={disabled}
        hasManyGroups={availableGroups.length > 1}
        propertyOrder={propertyOrder[currentGroup]}
      />
    </div>
  );
}
