// @flow
import * as React from 'react';
import classnames from 'classnames';

import { useResizeDetector } from 'react-resize-detector';
import { useSetRecoilState } from 'recoil';

import { useGlobal } from 'src/hooks';
import { headerHeightState } from 'src/recoil/atoms';
import { canUserAccessSection } from 'src/helpers/models/users';
import SECTIONS from 'src/pages/constants/sections';
import { toPath } from 'src/helpers/string';

import { NavLink } from 'react-router-dom';
import { Icon } from 'src/components';
import VersionTag from './VersionTag';
import InstanceSelect from './InstanceSelect';
import UserDropdown from './UserDropdown';

export default function Header(): React.Node {
  const { user, siteId } = useGlobal();

  // Header size watcher (to adjust app content available height)
  const setHeaderState = useSetRecoilState(headerHeightState);
  const onResize = React.useCallback(
    (_width, height) => setHeaderState(height),
    [setHeaderState]
  );

  const { ref } = useResizeDetector({
    handleHeight: true,
    refreshMode: 'debounce',
    refreshRate: 200,
    onResize,
  });

  // Dropdown active states
  const [mobileMenuDisplayed, setMobileMenuDisplayed] = React.useState(false);

  // Burger menu for mobile view
  const burgerMenu = (
    <a
      role="button"
      className="navbar-burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
      onClick={() => setMobileMenuDisplayed(!mobileMenuDisplayed)}
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  );

  return (
    <nav className="navbar has-shadow" ref={ref}>
      <InstanceSelect />
      {burgerMenu}

      <div
        className={classnames('navbar-menu', {
          'is-active': mobileMenuDisplayed,
        })}
      >
        {/* Various sections navigation items */}
        <div className="navbar-start">
          {Object.keys(SECTIONS)
            .filter((sectionId) => {
              const section = SECTIONS[sectionId];
              return (
                canUserAccessSection(user, section) &&
                sectionId !== 'superadmin'
              );
            })
            .map((sectionId) => {
              const section = SECTIONS[sectionId];

              return (
                <NavLink
                  key={sectionId}
                  to={toPath(`/${siteId}${section.pathname}`)}
                  activeClassName="is-active"
                  className="navbar-item"
                  onClick={() => setMobileMenuDisplayed(false)}
                >
                  <Icon name={section.icon} />
                  <span>{section.label}</span>
                </NavLink>
              );
            })}
        </div>

        <div className="navbar-end">
          {/* Super admin navigation item */}
          {user.superAdmin && (
            <NavLink
              className="navbar-item"
              to={toPath(`/${siteId}/super-admin`)}
              activeClassName="is-active"
              onClick={() => setMobileMenuDisplayed(false)}
            >
              <Icon name={SECTIONS.superadmin.icon} />
              <span>{SECTIONS.superadmin.label}</span>
            </NavLink>
          )}

          {/* User info dropdown */}
          <UserDropdown user={user} />
        </div>
      </div>

      {user?.isDeveloper && <VersionTag />}
    </nav>
  );
}
