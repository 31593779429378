// @flow
import * as React from 'react';

import type { ResourceReferencesContextType } from 'src/pages/contexts/ResourceContext';

import { ResourceReferencesContext } from 'src/pages/contexts/ResourceContext';
import invariant from 'src/helpers/invariant';

// RT <> ResourceType
export default function useResourceReferencesContext(): ResourceReferencesContextType {
  const resourceReferencesContext = React.useContext(ResourceReferencesContext);

  invariant(!!resourceReferencesContext, 'Resource context should exist');

  return ((resourceReferencesContext: any): ResourceReferencesContextType);
}
