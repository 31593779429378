// @flow
import type { Resource, ResourceSchema } from 'src/types/models';

import API from './API';
import { getSchemaFetchPath } from 'src/helpers/url';

export type Callbacks = {|
  onSave?: (Resource) => any,
  onDestroy?: (Resource) => any,
|};

export default class ResourceAPIConnector {
  siteId: string;
  schema: ResourceSchema;
  id: ?string; // Null when resource does not exist
  callbacks: ?Callbacks;

  constructor(
    siteId: string,
    schema: ResourceSchema,
    id: ?string,
    callbacks?: Callbacks
  ) {
    this.siteId = siteId;
    this.schema = schema;
    this.id = id;
    // $FlowIgnore
    this.callbacks = callbacks || {};
  }

  static checkExistence(
    siteId: string,
    schema: ResourceSchema,
    id: string
  ): Promise<Resource | null> {
    return new this(siteId, schema, id).exists();
  }

  _buildAPIUrl(isDraft: boolean = false): string {
    return `${getSchemaFetchPath(this.siteId, this.schema)}${
      this.id ? '/' + encodeURIComponent(this.id) : ''
    }`;
  }

  async exists(): Promise<Resource | null> {
    const url = this._buildAPIUrl();
    const { resource } = await API.get<Resource>(url, this.additionalParams());
    return !!resource;
  }

  load(isDraft: boolean = false): Promise<Resource> {
    const url = this._buildAPIUrl(isDraft);

    return !!this.id
      ? API.get<Resource>(url, this.additionalParams())
      : API.get<Resource>(`${url}/initialValue`, this.additionalParams());
  }

  async save(resource: Resource, isDraft: boolean = false): Promise<Resource> {
    const updatedResource = await (!!this.id
      ? this.update(resource, isDraft)
      : this.create(resource, isDraft));

    if (this.callbacks?.onSave) await this.callbacks.onSave(updatedResource);

    return updatedResource;
  }

  async destroy(isDraft: boolean = false): Promise<Resource> {
    const url = this._buildAPIUrl(isDraft);
    const deletedResource = await API.del<Resource>(
      url,
      this.additionalParams()
    );
    if (this.callbacks?.onDestroy)
      await this.callbacks.onDestroy(deletedResource);
    return deletedResource;
  }

  async restore(query: Object): Promise<Resource> {
    const url = `${this._buildAPIUrl()}/restore`;
    const restoredResource = await API.post<Resource>(
      url,
      this.additionalParams(),
      undefined,
      query
    );
    return restoredResource;
  }

  async update(
    resource: Resource,
    isDraft: boolean = false
  ): Promise<Resource> {
    const url = this._buildAPIUrl(isDraft);
    return API.put<Resource>(
      url,
      resource,
      `ch_put_${url}`,
      this.additionalParams()
    );
  }

  async create(
    resource: Resource,
    isDraft: boolean = false
  ): Promise<Resource> {
    const url = this._buildAPIUrl(isDraft);
    const createdResource = await API.post<Resource>(
      url,
      resource,
      `ch_post_${url}`,
      this.additionalParams()
    );
    this.id = createdResource._id;
    return createdResource;
  }

  additionalParams: Object = () => {
    const baseParams = { depDepth: 1, serialize: true };
    if (this.schema.customApiPath)
      return { ...baseParams, schemaId: this.schema._id };
    return baseParams;
  };
}
