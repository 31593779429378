const EntityPrice = {
  _id: 'entityPrice',
  frontComponent: 'EntityPrice',
  label: 'Prix',
  icon: 'euro-sign',
  optionsSchema: [
    { key: 'label', label: 'Libellé avant le prix', type: 'string' },
    {
      key: 'currencyType',
      label: 'Unité',
      type: 'enum',
      default: 'symbol',
      values: [
        { label: '€', value: 'symbol' },
        { label: 'EUR', value: 'code' },
        { label: 'euro', value: 'name' },
      ],
    },
    {
      key: 'forceDigits',
      label: 'Toujours afficher les centimes (même si zéro)',
      type: 'bool',
    },
  ],
  forEntity: true,
};

export default EntityPrice;
