import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { toHumanReadable as dateToHumanReadable } from 'src/helpers/dates';
import API from 'src/helpers/api/API';
import { useResourceContext, useGlobal } from 'src/hooks';

import StripeSubscriptionRenderer from 'src/pages/components/resource/PropertyRenderer/new/customPropertyRenderers/StripeSubscriptionRenderer';
import { EmptyState } from 'src/components';

const StripeLink = (props) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={
      'https://dashboard.stripe.com/' +
      props.path +
      '/' +
      encodeURIComponent(props.id)
    }
  >
    {props.label}
  </a>
);

export default function UserSubscriptionTab(props) {
  const { resource: user } = useResourceContext();
  const { siteId } = useGlobal();
  const [formula, setFormula] = React.useState(null);

  useEffectOnce(() => {
    const fetchFormula = async () => {
      const fetchFormula = await API.get(
        `${siteId}/data/SubscriptionFormula`,
        null
      );

      if (user?.subscription?.formulaId) setFormula(fetchFormula);
    };

    fetchFormula();
  });

  if (!user) return null;

  const { subscription, stripeSubscription } = user;

  if (!subscription && !stripeSubscription) {
    return (
      <EmptyState
        title="Aucune souscription à afficher"
        icon="search"
        style={{ padding: '80px 20px' }}
      />
    );
  }

  return (
    <div className="FormBase--primary">
      {subscription && (
        <div>
          {(user.freeAccessUntil || user.__fl_sub_active) && (
            <div>
              <h2>Accès spécial</h2>
              {user.freeAccessUntil && (
                <div>
                  Accès gratuit jusqu'au :{' '}
                  {dateToHumanReadable({
                    ts: user.freeAccessUntil,
                    format: 'YYYY-MM-DD HH:mm',
                  })}
                </div>
              )}
              {user.__fl_sub_active && (
                <div>Abonnement couplé Lafrancelibre.tv</div>
              )}
            </div>
          )}

          {formula && (
            <div>
              <h2>Abonnement {formula.label || formula._id}</h2>

              <div>
                {subscription.isValid ? (
                  <span className="success green">Valide</span>
                ) : (
                  <span className="error">Invalide</span>
                )}
              </div>

              <div>
                Depuis le :{' '}
                {dateToHumanReadable({
                  ts: subscription.startTs,
                  format: 'YYYY-MM-DD HH:mm',
                })}
              </div>

              <div>
                Valide jusqu'au :{' '}
                {dateToHumanReadable({
                  ts: subscription.endTs,
                  format: 'YYYY-MM-DD HH:mm',
                })}
              </div>

              <div>
                Dernier paiement :<div></div>
              </div>
            </div>
          )}
        </div>
      )}

      {stripeSubscription && (
        <div>
          <h2>Stripe</h2>

          <h3>Abonnement</h3>
          <StripeSubscriptionRenderer {...stripeSubscription} />

          <h3>Liens dashboard Stripe</h3>
          <ul>
            <li>
              <StripeLink
                path="customers"
                id={user.stripeCustomerId}
                label="Client"
              />
            </li>
            <li>
              <StripeLink
                path="subscriptions"
                id={stripeSubscription.id}
                label="Abonnement"
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
