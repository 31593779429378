import * as React from 'react';

import { Tag } from 'src/components';

export default function ObjectRefRenderer({
  mode = 'cell',
  value: ref,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!ref) return null;

  const { _id, _cls: objectType } = ref;

  return (
    <Tag>
      {objectType} - {_id}
    </Tag>
  );
}
