// @flow
import * as React from 'react';

import type { Resource } from 'src/types/models';
import type {
  PropertySetSortBy,
  PropertySetFilterBy,
  PropertySortBy,
  PropertyFilterBy,
} from '../useResourcePaginatedData';
import type { CustomItemAction } from '../';

import { useSchemaContext } from 'src/hooks';

import { Table as RawTable, TBody } from 'src/components';
import TableHead from './Head';
import ResourceRow from './Row';

type Props = {|
  items: Array<Resource>,
  sortBy: PropertySortBy,
  filterBy: PropertyFilterBy,
  setFilterBy: PropertySetFilterBy,
  setSortBy: PropertySetSortBy,
  refetch: () => Promise<any>,
  getItemCustomActions?: (Resource) => Array<CustomItemAction>,
  onSelect?: (Resource) => any,
  selectedIds?: Array<string>,
  openInANewTab: boolean,
  canDelete: boolean,
|};

function ResourceTableContent({
  items,
  sortBy,
  filterBy,
  setFilterBy,
  setSortBy,
  refetch,
  getItemCustomActions,
  onSelect,
  selectedIds,
  openInANewTab,
  canDelete,
}: Props): React.Node {
  const { schema } = useSchemaContext();
  const { propertiesList } = schema;
  const columnProperties = React.useMemo(
    () =>
      propertiesList.some((propertySchema) => !!propertySchema.column)
        ? propertiesList.filter((propertySchema) => !!propertySchema.column)
        : propertiesList,
    [propertiesList]
  );

  return (
    <RawTable striped hoverable fullwidth>
      <TableHead
        schema={schema}
        setFilterBy={setFilterBy}
        setSortBy={setSortBy}
        sortBy={sortBy}
        filterBy={filterBy}
        columnProperties={columnProperties}
        selectable={!!onSelect}
      />

      <TBody>
        {items.map((item, i) => {
          const isSelected = !!selectedIds?.includes(item._id);

          return (
            <ResourceRow
              key={item._id}
              item={item}
              isSelected={isSelected}
              onSelect={onSelect}
              openInANewTab={openInANewTab}
              columnProperties={columnProperties}
              // $FlowIgnore
              getActions={getItemCustomActions}
              refetch={refetch}
              canDelete={canDelete}
            />
          );
        })}
      </TBody>
    </RawTable>
  );
}

export default (React.memo(ResourceTableContent): React.ComponentType<Props>);
