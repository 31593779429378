const CountDown = {
  _id: 'countdown',
  frontComponent: 'Countdown',
  label: 'Compte à rebours',
  icon: 'clock',
  optionsSchema: [
    { key: 'endTs', label: 'Date de fin', type: 'timestamp', required: true },
    {
      key: 'beginTs',
      label: 'Date de début (si applicable)',
      type: 'timestamp',
      required: true,
    },
  ],
  forPage: true,
  site: 'demo',
};

export default CountDown;
