// @flow

import * as React from 'react';
import classnames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import { useGlobal } from 'src/hooks';
import { InstanceActions } from 'src/stores/InstanceStore';

type Props = {|
  children?: React.Node,
|};

export default function InstanceSelect({ children }: Props): React.Node {
  const { siteId, sites, user } = useGlobal();
  const [dropdownActive, activateDropdown] = React.useState(false);
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => activateDropdown(false));

  const filteredsites =
    user.siteIds?.length > 0
      ? sites.filter((instance) => user.siteIds.includes(instance.value))
      : sites;

  return (
    <div
      id="instance-select"
      className={classnames('navbar-item', 'has-dropdown', {
        'is-active': dropdownActive,
      })}
      ref={ref}
    >
      <a
        className="navbar-link"
        onClick={() => activateDropdown(!dropdownActive)}
        style={{ display: 'flex', alignItems: 'center', height: '100%' }}
      >
        {children}
        <span style={{ marginLeft: !!children ? 10 : 0 }}>{siteId}</span>
      </a>

      <div className="navbar-dropdown is-boxed">
        {filteredsites
          .filter((instance) => instance.id !== siteId)
          .sort((a, b) => {
            return a.value.localeCompare(b.value);
          })
          .map((instance) => (
            <a
              className={classnames('navbar-item', {
                'is-active': instance.value === siteId,
              })}
              onClick={() => {
                InstanceActions.set(instance.value);
                activateDropdown(false);
              }}
              key={instance.value}
            >
              {instance.value}
            </a>
          ))}
      </div>
    </div>
  );
}
