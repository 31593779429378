const TournamentThumbnail = {
  _id: 'TournamentThumbnail',
  frontComponent: 'TournamentThumbnail',
  label: "Thumbnail d'un tournoi",
  icon: 'euro-sign',
  optionsSchema: [
    {
      key: 'tournamentId',
      label: 'Référence du tournoi',
      type: 'object_id',
      objectType: 'Tournament',
    },
    {
      key: 'title',
      label: 'Titre',
      type: 'string',
    },
    {
      key: 'subtitle',
      label: 'Second titre',
      type: 'string',
    },
  ],
  forPage: false,
  ofEntity: true,
};

export default TournamentThumbnail;
