// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  children: React.Node,
  title?: string,
  type?: 'primary',
  hasFixedFooter?: boolean,
|};

function OldForm({ type, title, children, hasFixedFooter }: Props): React.Node {
  const className = classnames('FormBase', {
    [`FormBase--${type || ''}`]: !!type,
    'has-fixed-footer': !!hasFixedFooter,
  });

  return (
    <form className={className}>
      <div className="FormBase-inner">
        {title && <h2 className="FormBase-title">{title}</h2>}

        {children}
      </div>
    </form>
  );
}

OldForm.defaultProps = {
  type: 'primary',
  hasFixedFooter: false,
};

export default OldForm;
