import React from 'react';

import { Tag } from 'src/components';

export default function NamespaceRenderer({
  mode,
  value: namespace,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!namespace) return null;

  return <Tag color="dark">{namespace}</Tag>;
}
