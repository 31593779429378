import IDSchema from '../specificProperties/ID';
import TypePropertySchema from '../specificProperties/Type';

const CommonPropertySchema = [
  { ...IDSchema, key: 'key', label: 'Clé', group: 'Général' },
  {
    key: 'label',
    label: 'Libellé',
    type: 'string',
    sortable: true,
    group: 'Général',
  },
  {
    key: 'group',
    label: 'Groupe de la propriété',
    type: 'string',
    group: 'Général',
  },
  TypePropertySchema,
  {
    key: 'description',
    label: 'Description',
    type: 'string',
    max: 500,
    group: 'Général',
  },
  {
    key: 'hint',
    type: 'richtext',
    label: 'Informations additionnelles',
    group: 'Général',
  },
  {
    key: 'required',
    label: 'Requis',
    type: 'bool',
    default: false,
    group: 'Général',
  },
  {
    key: 'readOnly',
    label: 'Lecture seule',
    type: 'bool',
    default: false,
    group: 'Avancé',
  },
  {
    key: 'createOnly',
    label: 'Non modifiable après création',
    type: 'bool',
    default: false,
    group: 'Avancé',
  },
  {
    key: 'sortable',
    label: 'Triable dans la table',
    type: 'bool',
    default: false,
    group: 'Affichage',
  },
  {
    key: 'filterable',
    label: 'Filtrable dans la table',
    type: 'bool',
    default: false,
    group: 'Affichage',
  },
  {
    key: 'toggleable',
    label:
      'L\'utilisateur doit appuyer sur "afficher" pour afficher et éditer la propriété',
    type: 'bool',
    default: false,
    group: 'Affichage',
  },
  {
    key: 'column',
    label: 'Afficher dans le tableau',
    type: 'bool',
    group: 'Affichage',
  },
  {
    key: 'sideBar',
    label: 'Sur la page de la ressource, afficher dans la sidebar',
    type: 'bool',
    group: 'Affichage',
  },
  {
    key: 'notDuplicable',
    label:
      "Lors de la duplication d'un élément de ce modèle, la propriété ne sera pas dupliquée",
    type: 'bool',
    group: 'Avancé',
  },
  {
    key: 'excludedFromMongooseSchema',
    label:
      'Ne sera pas sauvegardé en base données (sera transformée en une autre propriété avant sauvegarde)',
    type: 'bool',
    default: false,
    condition: { f$: 'adminUser.isDeveloper' },
    group: 'Avancé',
  },
  {
    key: 'notDisplayableInAdmin',
    label: 'Ne sera jamais affiché dans le backoffice',
    type: 'bool',
    default: false,
    condition: { f$: 'adminUser.isDeveloper' },
    group: 'Avancé',
  },

  {
    key: 'export',
    label: "Inclure dans l'export",
    type: 'bool',
    group: 'Avancé',
  },
];

export default CommonPropertySchema;
