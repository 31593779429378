// @flow
import * as React from 'react';

import type { ResourceType } from 'src/types';

import ExportShow from './Export';

const getCustomShowComponent = (
  resourceType: ResourceType
): ?React.AbstractComponent<*> => {
  if (resourceType === 'Export') return ExportShow;
  return null;
};

export default getCustomShowComponent;
