// @flow
import * as React from 'react';

import type { BulmaColor, Style } from '../types';

import classnames from 'classnames';

type Props = {|
  children: React.Node,
  color: BulmaColor,
  light?: boolean,
  onClose?: () => any,
  activeTime?: ?number,
  style?: Style,
  additionalClassName?: string,
|};

export default function Notification({
  children,
  color,
  onClose,
  light,
  style,
  additionalClassName,
  activeTime,
}: Props): React.Node {
  const className = classnames('notification', additionalClassName, {
    'is-link': color === 'link',
    'is-primary': color === 'primary',
    'is-info': color === 'info',
    'is-success': color === 'success',
    'is-warning': color === 'warning',
    'is-danger': color === 'danger',
    'is-light': !!light,
  });

  React.useEffect(() => {
    if (activeTime && onClose) {
      setTimeout(onClose, activeTime);
    }
  }, [activeTime, onClose]);

  return (
    <div className={className} style={style}>
      {!!onClose && <button className="delete" onClick={onClose} />}
      {children}
    </div>
  );
}

Notification.defaultProps = {
  light: false,
  activeTime: 3000,
};
