// @flow

import * as React from 'react';
import { isArray, filter, includes, isEmpty, find } from 'lodash';
import classnames from 'classnames';

import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { OldLabel } from 'src/components';

type Value = string | number;

export type Option = {|
  label: string,
  value: Value,
  selected?: boolean,
|};

type Props = {|
  value: Value,
  label: string,
  options: Array<Option>,
  handleFocus: (e: SyntheticFocusEvent<*>, hasFocus: boolean) => any,
  onChange: (null | Value | Array<Value>) => any,
  disabled?: boolean,
  multiple?: boolean,
  tooltip?: string,
  required?: boolean,
  creatable?: boolean,
  valueRenderer?: (Value) => string,
|};

type DefaultProps = {|
  multiple: boolean,
  required: boolean,
  disabled: boolean,
  creatable: boolean,
|};

type State = {|
  hasFocus: boolean,
|};

export default class OldSelectCustom extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    multiple: false,
    required: false,
    disabled: false,
    creatable: false,
  };

  state: State = {
    hasFocus: false,
  };

  handleFocus(e: SyntheticFocusEvent<*>, hasFocus: boolean) {
    const { handleFocus } = this.props;
    this.setState({ hasFocus });
    handleFocus && handleFocus(e, hasFocus);
  }

  onMultipleOptionChange: (options: ?Array<Option>) => void = (options) => {
    const { multiple, onChange } = this.props;

    if (!multiple)
      throw new Error(
        'Boolean "multiple" sould be set to true if multiple options can be selected'
      );
    if (!options) throw new Error('Options should be an array');

    const value = options.length === 0 ? null : options.map((o) => o.value);

    if (options.length === 1 && value) {
      onChange(value[0]);
      return;
    }

    onChange(value);
  };

  onSingleOptionChange: (Option | null) => any = (option) => {
    const { onChange } = this.props;
    onChange(!!option ? option.value : null);
  };

  render(): React.Node {
    const {
      label,
      options,
      value,
      disabled,
      required,
      valueRenderer,
      creatable,
      multiple,
      tooltip,
    } = this.props;
    const { hasFocus } = this.state;

    const formElementClass = classnames(
      'FormElement',
      'FormElement-input-select-element',
      {
        'FormElement--disabled': disabled,
        'FormElement--focus': hasFocus,
      }
    );

    let selectOptions = filter(options).map((o) => ({
      value: o.value,
      label: valueRenderer ? valueRenderer(o.value) : o.label,
    }));

    if (!required)
      selectOptions = [
        {
          value: null,
          label: <span className="empty-option-label">(aucun)</span>,
        },
        ...selectOptions,
      ];

    let selectedOption;

    if (multiple) {
      let values = isArray(value) ? value : [value];
      selectedOption = filter(selectOptions, (o) => includes(values, o.value));

      if (value && isEmpty(selectedOption)) {
        // for creatable
        selectedOption = values.map((v) => ({
          label: v,
          value: v,
          __isNew__: true,
        }));
      }
    } else {
      selectedOption = find(selectOptions, { value: value });

      if (value && !selectedOption) {
        // for creatable
        selectedOption = {
          label: valueRenderer ? valueRenderer(value) : '' + value,
          value,
          __isNew__: true,
        };
      }
    }

    const ControlComponent = creatable ? CreatableSelect : ReactSelect;

    return (
      <div className={formElementClass}>
        <div className="flex-wrapper flex-column FormElement-inner">
          <OldLabel
            label={label}
            required={required}
            tooltip={tooltip}
            htmlFor="input-select"
          />

          <div className="FormElement-input-select-wrapper">
            <ControlComponent
              name="FormElement-input-select"
              value={selectedOption}
              isMulti={multiple || false}
              className="FormElement-input-select"
              onChange={(option: Option | Array<Option>) => {
                if (multiple && Array.isArray(option)) {
                  const options = option;
                  this.onMultipleOptionChange(options);
                } else if (!multiple && !Array.isArray(option)) {
                  this.onSingleOptionChange(option);
                }
              }}
              onFocus={(e) => this.handleFocus(e, true)}
              onBlur={(e) => this.handleFocus(e, false)}
              options={selectOptions}
              isDisabled={disabled}
              disabled={disabled}
              isClearable={!required}
              classNamePrefix="FormElement-input-select-inner"
            />
          </div>
        </div>
      </div>
    );
  }
}
