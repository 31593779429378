const TypePropertySchema = {
  key: 'type',
  label: 'Type',
  type: 'enum',
  required: true,
  group: 'Général',
  values: [
    { value: 'string', label: 'string' },
    { value: 'int', label: 'int' },
    { value: 'float', label: 'float' },
    { value: 'bool', label: 'bool' },
    { value: 'enum', label: 'enum' },
    { value: 'list', label: 'list' },
    { value: 'object', label: 'object' },
    { value: 'layout', label: 'layout' },
    { value: 'object_id', label: 'object ID' },
    { value: 'object_ref', label: 'object ref' },
    { value: 'timestamp', label: 'timestamp' },
    { value: 'file', label: 'file' },
    { value: 'richtext', label: 'richtext' },
    { value: 'color', label: 'couleur' },
    { value: 'Font', label: 'police' },
    { value: 'Link', label: 'lien' },
    { value: 'slug', label: 'slug' },
    { value: 'mongooseRef', label: 'mongooseRef' },
  ],
};

export default TypePropertySchema;
