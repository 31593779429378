Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports['default'] = void 0;

const _leftIcon = _interopRequireDefault(require('./leftIcon'));
const _centerIcon = _interopRequireDefault(require('./centerIcon'));
const _rightIcon = _interopRequireDefault(require('./rightIcon'));
const _h1Icon = _interopRequireDefault(require('./h1'));
const _h2Icon = _interopRequireDefault(require('./h2'));
const _h3Icon = _interopRequireDefault(require('./h3'));
const _h4Icon = _interopRequireDefault(require('./h4'));
const _h5Icon = _interopRequireDefault(require('./h5'));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const icons = {
  LeftIcon: _leftIcon['default'],
  CenterIcon: _centerIcon['default'],
  RightIcon: _rightIcon['default'],
  H1Icon: _h1Icon['default'],
  H2Icon: _h2Icon['default'],
  H3Icon: _h3Icon['default'],
  H4Icon: _h4Icon['default'],
  H5Icon: _h5Icon['default'],
};

const _default = icons;
exports['default'] = _default;
