// @flow
import * as React from 'react';

import getCustomMainActions from './resourceSpecific/mainActions';
import { useGlobal, useSchemaContext } from 'src/hooks';

import { NavLink } from 'react-router-dom';
import { Text, Button, Icon, Flex } from 'src/components';

type Props = {|
  children: React.Node,
|};

export default function CollectionHeader({ children }: Props): React.Node {
  const { user, siteId } = useGlobal();
  const { schema, path } = useSchemaContext();

  const customMainActions = React.useMemo(
    () => getCustomMainActions(schema._id),
    [schema._id]
  );

  return (
    <div className="resource-collection-header">
      <Flex style={{ justifyContent: 'space-between' }}>
        <Text size={4} element="h1" isTitle>
          {schema.label || schema._id}
        </Text>

        <Flex verticalAlign>
          {customMainActions.map(({ id, content: Action }) => (
            <Action key={id} />
          ))}

          {user.isDeveloper && schema._id !== 'schema' && (
            <NavLink
              to={`/${siteId}/super-admin/schemas/${schema._id}/edit`}
              title="Editer le modèle de donnée (schema) de l'objet"
              style={{ marginRight: 10 }}
            >
              <Button>
                <Icon name="tools" />
              </Button>
            </NavLink>
          )}

          <NavLink to={`${path}/new`}>
            <Button>+ Créer</Button>
          </NavLink>
        </Flex>
      </Flex>

      {children}
    </div>
  );
}
