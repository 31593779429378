import DataSourceSchema from '../specificProperties/DataSource';

const EnumPropertySchema = [
  {
    key: 'values',
    label: 'Valeurs',
    type: 'list',
    unique: 'value',
    itemSchema: {
      type: 'object',
      propertiesList: [
        { key: 'value', type: 'string', required: true },
        { key: 'label', type: 'string', required: true },
      ],
    },
    group: 'Général',
  },
  DataSourceSchema,
];

export default EnumPropertySchema;
