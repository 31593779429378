// @flow
import _ from 'lodash';

import type { Config, Environment } from 'src/types/environment';

export const DEVSERVER_PREPROD_API_URL = 'https://puf_admin.fractal-it.fr:8443';
export const PRODSERVER_PROD_API_URL = 'https://adminapi.mica.media';
export const LAFURIA_PROD_API_URL = 'https://adminapi.lafuria.fr';
export const STAGING_API_URL = 'https://staging-admin.mica.media/api';
const API_URL = process.env.REACT_APP_API_URL;
const FRONT_URL = process.env.REACT_APP_FRONT_URL;
const PUF_ENV: Environment = (process.env.REACT_APP_PUF_ENV: any);

const getConfig = (): Config => {
  let config = {};
  const hostname = window && window.location.hostname;

  if (hostname === 'localhost') {
    config = {
      API_URL: API_URL || 'https://local-admin.fractal-it.fr:8443',
      PUF_ENV: PUF_ENV || 'dev',
    };
  } else if (
    hostname === 'puf_admin.fractal-it.fr' ||
    _.endsWith(hostname, 'admin_preprod.fractal-it.fr') ||
    hostname === 'dev.fractal-it.fr' ||
    hostname === 'puf.lby.emkt.dev' ||
    _.endsWith(hostname, '--db-staging.mica.media') ||
    hostname === 'admin-preprod.mica.media' || // alias of admin--db-staging
    hostname === 'staging-admin.mica.media' ||
    hostname === 'test-admin.mica.media' ||
    hostname === '127.0.0.1'
  ) {
    config = {
      API_URL: API_URL || DEVSERVER_PREPROD_API_URL,
      PUF_ENV: PUF_ENV || 'staging',
    };
  } else if (hostname === 'lafuria--admin.mica.media') {
    config = {
      API_URL: API_URL || LAFURIA_PROD_API_URL,
      PUF_ENV: PUF_ENV || 'prod',
    };
  } else if (
    _.endsWith(hostname, 'admin_prod.fractal-it.fr') ||
    hostname === 'admin.mica.media'
  ) {
    config = {
      API_URL: API_URL || PRODSERVER_PROD_API_URL,
      PUF_ENV: PUF_ENV || 'prod',
    };
  } else if (
    _.endsWith(hostname, '--db-prod.mica.media') ||
    hostname === 'local.for.prod'
  ) {
    config = {
      API_URL: PRODSERVER_PROD_API_URL,
      PUF_ENV: 'prod',
    };
  } else {
    // not used
    config = {
      API_URL: API_URL || '/api',
      PUF_ENV: PUF_ENV || 'prod', // depends... // TODO
    };
  }

  config = {
    ...config,
    FRONT_URL,
  };

  return config;
};

// https://developers.google.com/fonts/docs/developer_api#APIKey
export const GOOGLE_API_KEY = 'AIzaSyBCkg957VENQBQGsS-SsdvjnoEpHMAjcfk';

const config = getConfig();
console.log('config is', config);

export default (config: Config);
