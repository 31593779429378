import React from 'react';

import { Modal, Tag, Text, Box } from 'src/components';
import ComponentPicker from './ComponentPicker';

export default function ComponentPickerModal({
  open,
  onClose,
  availableComponents,
  complexComponents,
  onPick,
  itemClickedContent,
}) {
  return (
    <Modal
      modal={false}
      open={open}
      onClose={onClose}
      title="Sélectionner un composant :"
    >
      {
        // TODO plus beau
        itemClickedContent &&
          itemClickedContent.contents &&
          itemClickedContent.contents.length > 0 && (
            <Box style={{ border: '1px solid' }}>
              <Text isTitle element="h2" size={5}>
                Composants déjà affectés à ce bloc :
              </Text>
              <div
                className="tags"
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {itemClickedContent.contents.map((item, i) => (
                  <Tag
                    key={`${item.component._id}[${i}]`}
                    color="success"
                    size="large"
                    light
                  >
                    {item.component.label}
                  </Tag>
                ))}
              </div>
            </Box>
          )
      }

      <ComponentPicker
        availableComponents={availableComponents}
        complexComponents={complexComponents}
        onPickComponent={onPick}
      />

      <div className="Modal-dialog">
        <span className="Modal-dialog-button" onClick={onClose}>
          OK
        </span>
      </div>
    </Modal>
  );
}
