// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from './types';

import { Icon, Text, Centered } from './';

type Props = {|
  title: string,
  icon?: string,
  style?: Style,
  additionalClassName?: string,
  size?: 'small' | 'normal' | 'large',
|};

export default function EmptyState({
  title,
  icon,
  style,
  additionalClassName,
  size,
}: Props): React.Node {
  const iconSize = {
    small: '1x',
    normal: '2x',
    large: '3x',
  }[size || 'large'];

  return (
    <Centered
      additionalClassName={classnames('emptystate', additionalClassName)}
      style={style}
    >
      {/* $FlowIgnore */}
      {icon && <Icon name={icon} size={iconSize} />}
      <Text element="p" style={{ marginTop: 20 }} align="centered">
        {title}
      </Text>
    </Centered>
  );
}

EmptyState.defaultProps = {
  size: 'large',
};
