export const CharactersCounterProperties = [
  {
    key: 'charactersCounter',
    label: 'Activer le compteur de caractères/mots',
    type: 'bool',
    default: false,
  },
  {
    key: 'wordsCounter',
    label: 'Activer le compteur de caractères/mots',
    type: 'bool',
    default: false,
  },
  {
    key: 'expectedCharactersCount',
    label: 'Mettre un nombre de caractères attendu',
    type: 'int',
    condition: {
      f$: 'obj.charactersCounter',
    },
  },
  {
    key: 'expectedWordsCount',
    label: 'Mettre un nombre de mots attendu',
    type: 'int',
    condition: {
      f$: 'obj.wordsCounter',
    },
  },
];
