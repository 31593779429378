// @flow
import * as React from 'react';
import Reflux from 'reflux';

import SchemaStore from 'src/stores/SchemaStore';
import UserStore from 'src/stores/UserStore';
import InstanceStore from 'src/stores/InstanceStore';

const stores = {
  SchemaStore,
  UserStore,
  InstanceStore,
};

type StoreName = 'SchemaStore' | 'UserStore' | 'InstanceStore';

export default function useStore<StoreState>(storeName: StoreName): StoreState {
  const storeClass = stores[storeName];

  const [storeState, setStoreState] = React.useState({
    ...(storeClass.defaultState || {}),
    ...Reflux.getGlobalState()[storeName],
  });

  React.useEffect(() => {
    const store = getStore(storeClass);

    if (!Reflux.serverMode && !!store.listen) {
      return store.listen((newStoreState) => {
        setStoreState((previousStoreState) => ({
          ...previousStoreState,
          ...newStoreState,
        }));
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return storeState;
}

function getStore(store: any) {
  if (typeof store !== 'function') return store;

  var storeId = store.id;
  // if there is NOT a .singleton property on the store then this store has not been initialized yet, so do so
  if (!store.singleton) {
    store.singleton = new store();
    if (storeId) {
      Reflux.stores[storeId] = store.singleton;
    }
  }
  // before we weren't sure if we were working with an instance or class, so now we know an instance is created set it
  // to the variables we were using so that we can just continue on knowing it's the instance we're working with
  store = store.singleton;
  // the instance should have an .id property as well if the class does, so set that here
  store.id = storeId;
  // if there is an id and there is a global state property for this store then merge
  // the properties from that global state into the default state of the store AND then
  // set the global state to that new state (since it may have previously been partial)
  if (storeId && Reflux.GlobalState[storeId]) {
    for (var key in Reflux.GlobalState[storeId]) {
      store.state[key] = Reflux.GlobalState[storeId][key];
    }
    Reflux.GlobalState[storeId] = store.state;
    // otherwise (if it has an id) set the global state to the default state of the store
  } else if (storeId) {
    Reflux.GlobalState[storeId] = store.state;
  }

  return store;
  // if no id, then no messing with global state
}
