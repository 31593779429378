// @flow
import * as React from 'react';

import type { Style } from 'src/components/types';

type Props = {
  children?: any,
  fullWidth?: boolean,
  style?: Style,
};

export default function PullRight({
  children,
  fullWidth,
  style = {},
}: Props): React.Node {
  if (fullWidth) {
    style = {
      ...style,
      width: '100%',
    };
  }

  return (
    <div className="is-clearfix" style={style}>
      <div className="is-pulled-right">{children}</div>
    </div>
  );
}
