const Separator = {
  _id: 'Separator',
  label: 'Séparateur',
  icon: 'cut',
  frontComponent: 'Separator',
  optionsSchema: [
    { key: 'margin', label: 'Marge au dessus / en dessous', type: 'int' },
    { key: 'width', label: 'Largeur (en %)', type: 'int' },
  ],
  forPage: true,
};

export default Separator;
