import _ from 'lodash';

export function stopPropagation(func, ...args) {
  return (e) => {
    e.preventDefault();
    e.stopPropagation();
    return func(...args);
  };
}

/**
 * { a: 1, ... } --> [ {key:"a", value: 1}, ... ]
 *
 * @param map
 */
export function map2KvList(map) {
  return _.map(map, (value, key) => ({ key, value }));
}

/**
 * { a: {x: 1}, ... } --> [ {key:"a", x: 1}, ... ]
 *
 * @param map
 */
export function map2List(map, keyPropName = 'key') {
  return _.map(map, (value, key) => ({ ...value, [keyPropName]: key }));
}

/**
 * [ {key:"a", x: 1}, ... ] --> { a: {x: 1}, ... }
 *
 * @param list
 */
export function list2Map(list) {
  return _(list).filter('key').keyBy('key').value();
}

/**
 * [ {key:"a", x: 1}, ... ] --> { a: {x: 1}, ... }
 *
 * @param list
 */
export function equalOrIncludes(listOrValue, valueToCompare) {
  return _.isArray(listOrValue)
    ? _.includes(listOrValue, valueToCompare)
    : listOrValue === valueToCompare;
}

/**
 * Serialize parameters map to query string using JSON encoding for values
 * @param params
 * @returns {string}
 */
export function serializeQueryString(params, json = true) {
  return _.map(
    params,
    (v, k) =>
      encodeURIComponent(k) +
      '=' +
      encodeURIComponent(json ? JSON.stringify(v) : v)
  ).join('&');
}

/**
 * return UID for this execution (NOT global UID!)
 */
var g_uid = 1;

export function getUid() {
  return g_uid++;
}

function safeJsonParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error(`JSON error in "${str}"`, e);
    return null;
  }
}

export function parseQueryString(str) {
  return _.fromPairs(
    (str[0] === '?' ? str.slice(1) : str)
      .split('&')
      .map((kv) => kv.split('='))
      .filter((pair) => pair.length === 2)
      .map(([k, v]) => [
        decodeURIComponent(k),
        safeJsonParse(decodeURIComponent(v)),
      ])
  );
}

/**
 * Makes a GET query string from a parameter dict.
 * { a: 1 } => "?a=1"
 * Values are URL-encoded but not keys.
 *
 * @param parameter dict.
 */
export const buildQueryString = (params) =>
  Object.keys(params)
    .filter((k) => typeof params[k] !== 'undefined')
    .map((k) => k + '=' + encodeURIComponent(JSON.stringify(params[k])))
    .join('&');

export function updateQueryString(str, newValues) {
  let params = parseQueryString(str || '');
  return buildQueryString({
    ...params,
    ...newValues,
  });
}

export function regexEsc(str) {
  return str.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function multiReplace(str, replacementMap) {
  let re = new RegExp(_.keys(replacementMap).map(regexEsc).join('|'), 'g');

  return str.replace(re, function (matched) {
    return replacementMap[matched];
  });
}

export function multiReplaceI(str, replacementMap) {
  let re = new RegExp(_.keys(replacementMap).map(regexEsc).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return replacementMap[matched.toLowerCase()];
  });
}

export function deepMap(value, fn) {
  if (_.isPlainObject(value)) {
    return fn(_.mapValues(value, (item) => deepMap(item, fn)));
  } else if (_.isArray(value)) {
    return fn(_.map(value, (item) => deepMap(item, fn)));
  }
  return fn(value);
}

export function duration(durationInSeconds, displaySeconds = true) {
  durationInSeconds = durationInSeconds || 0;
  let h = Math.floor(durationInSeconds / 3600);
  let m = Math.floor((durationInSeconds - h * 3600) / 60);
  let s = Math.floor(durationInSeconds - h * 3600 - m * 60);
  return (
    (h === 0
      ? displaySeconds
        ? ''
        : '0:'
      : h < 10
      ? '0' + h + ':'
      : h + ':') +
    (m < 10 ? '0' + m : m) +
    (displaySeconds ? ':' + (s < 10 ? '0' + s : s) : '')
  );
}

export const TC_FPS = 25;

/**
 * Convert "HH:MM:SS:FF" timecode to seconds (or null if error).
 * Set module var "TC_FPS" to set default FPS.
 */
export function timecode2seconds(tc, fps = TC_FPS, loose) {
  let parts = tc.toString().split(/[:;.]/);
  if (!parts || parts.length < 3) {
    console.warn('Invalid tc: ', tc);
    return null;
  }
  parts.reverse();
  return (
    (1 * parts[0]) / fps +
    1 * parts[1] +
    1 * parts[2] * 60 +
    1 * (parts[3] || 0) * 3600
  );
}

/**
 * Convert time in seconds to "HH:MM:SS:FF" timecode (or null if error).
 * Set module var "TC_FPS" to set default FPS.
 */
export function seconds2timecode(ts, fps = TC_FPS) {
  if (ts < 0) {
    console.warn('Invalid time for TC: ', ts);
    return null;
  }
  let h = Math.floor(ts / 3600);
  let m = Math.floor((ts % 3600) / 60);
  let s = Math.floor(ts % 60);
  let i = Math.floor((ts * fps) % fps);
  return (
    (h >= 10 ? h : '0' + h) +
    ':' +
    (m >= 10 ? m : '0' + m) +
    ':' +
    (s >= 10 ? s : '0' + s) +
    ':' +
    (i >= 10 ? i : '0' + i)
  );
}
/**
 * Used to replace in controlled components:
 *  value={this.state.name}
 *  onChange={value => this.setState({name: value})}
 *
 * @param _this
 * @param name
 * @returns {{onChange: (function(*): *), value: *}}
 */
export function bindInput(_this, name) {
  return {
    value: _this.state[name],
    onChange: (value) => _this.setState({ [name]: value }),
  };
}

/**
 * Recognise garbage generated by draft-js-export-html when the content is actually empty
 */
export function isGarbageFromDraftEditor(str) {
  str = str.html || str;
  return ['<p>null</p>', '<p><br></p>'].includes(str);
}

/**
 * Return an array from an array or a single value:
 * [a, b] => [a, b]
 * a => [a]
 */
export function asArray(value) {
  return _.isArray(value) ? value : [value];
}
