// @flow
import * as React from 'react';
import { useRecoilState } from 'recoil';

import { notificationCenterState } from 'src/recoil/atoms';

import { Notification } from 'src/components';

export default function NotificationCenter(): React.Node {
  const [notificationCenter, setNotificationCenter] = useRecoilState(
    notificationCenterState
  );

  if (notificationCenter.length === 0) {
    return null;
  }

  return (
    <div className="notification-center">
      {notificationCenter.map(({ type, text, id, time }) => (
        <Notification
          key={id}
          color={type}
          activeTime={time}
          onClose={() => {
            setNotificationCenter(
              notificationCenter.filter(
                (notification) => notification.id !== id
              )
            );
          }}
        >
          {text}
        </Notification>
      ))}
    </div>
  );
}
