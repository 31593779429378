const PageTitle = {
  _id: 'pageTitle',
  label: 'Titre de la page',
  icon: 'font',
  frontComponent: 'PageTitle',
  optionsSchema: [
    { key: 'useH1', label: 'Utiliser <h1>', type: 'bool', default: false }, // oui ?
  ],
  forPage: true,
};

export default PageTitle;
