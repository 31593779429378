// @flow
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useGlobal, useResourceContext } from 'src/hooks';
import { getResourceSchemaPath } from 'src/helpers/url';
import { SchemaActions } from 'src/stores/SchemaStore';
import { isDevelopment } from 'src/helpers/env';

import getCustomActions from './resourceSpecific/actions';
import CopyResourceModal from 'src/pages/components/resource/CopyResourceModal';
import {
  Icon,
  Flex,
  Text,
  Button,
  Dropdown,
  DropdownMenuItem,
} from 'src/components';

type Props = {|
  navigationType?: 'routing' | 'state',
  children?: React.Node,
|};

export default function ResourceHeader({
  navigationType,
  children,
}: Props): React.Node {
  const { siteId, user } = useGlobal();
  const { resource, schema, path, dirty } = useResourceContext();
  const [isCopyModalOpened, toggleCopyModal] = React.useState(false);
  const history = useHistory();
  const newResource = !resource._id;
  const label = resource.label || resource._id;
  const customActions = getCustomActions(schema._id);

  return (
    <div className="resource-header">
      <div style={{ marginBottom: 30 }}>
        <Flex
          verticalAlign
          style={{ marginBottom: 10, justifyContent: 'space-between' }}
        >
          <Flex verticalAlign>
            {!schema.isSingleton && navigationType === 'routing' && (
              <Button
                isText
                id="back-button"
                style={{ marginRight: 10 }}
                onClick={() => {
                  const navigate =
                    !dirty ||
                    window.confirm(
                      'Êtes-vous sûr de vouloir quitter sans enregistrer vos modifications ?'
                    );

                  if (navigate) history.push(path);
                }}
              >
                <Icon name="angle-left" />
              </Button>
            )}

            <Text
              isTitle
              size={4}
              element="h1"
              style={{ marginBottom: 0, marginRight: 10 }}
            >
              <Text size={6} transformation="lowercase">
                {newResource && 'Creation de '}
                {schema.label}{' '}
              </Text>
              {label && (
                <Text weight="semibold" transformation="uppercase">
                  "{label}"
                </Text>
              )}
            </Text>
          </Flex>

          <Flex verticalAlign>
            {customActions.length > 0 &&
              customActions.map((Action) => (
                <Action style={{ marginRight: 10 }} />
              ))}

            <Dropdown
              trigger={(toggleDropdown) => (
                <Button onClick={toggleDropdown}>
                  <Icon name="ellipsis-v" />
                </Button>
              )}
            >
              {(user.isDeveloper || user.superAdmin) && (
                <DropdownMenuItem
                  onClick={() => toggleCopyModal(true)}
                  title="Copier"
                >
                  <Text>
                    <Icon name="copy" /> Copier vers ...
                  </Text>
                </DropdownMenuItem>
              )}
              {user.isDeveloper && schema._id !== 'schema' && (
                <>
                  <DropdownMenuItem
                    to={getResourceSchemaPath(siteId, schema)}
                    title="Editer le modèle de donnée (schema) de l'objet"
                  >
                    <Text>
                      <Icon name="tools" /> Accéder au schéma
                    </Text>
                  </DropdownMenuItem>

                  {isDevelopment() && (
                    <DropdownMenuItem onClick={SchemaActions.load}>
                      <Text>
                        <Icon name="redo" style={{ marginRight: 5 }} />
                        <span>Recharger les schémas</span>
                      </Text>
                    </DropdownMenuItem>
                  )}
                </>
              )}
            </Dropdown>
          </Flex>
        </Flex>

        <div>
          <Text weight="bold">Création</Text> :{' '}
          {resource.createdAt
            ? moment(resource.createdAt).format('LLL')
            : '(manquant)'}
          {' - '}
          <Text weight="bold">Dernière MAJ : </Text>
          {resource.updatedAt
            ? moment(resource.updatedAt).format('LLL')
            : '(manquant)'}
        </div>
      </div>

      {isCopyModalOpened && (
        <CopyResourceModal open onClose={() => toggleCopyModal(false)} />
      )}

      {children}
    </div>
  );
}
