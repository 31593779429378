// @flow
import * as React from 'react';

import type { Tab } from '../';

import { Icon } from 'src/components';
import ExportTab from './ExportTab';

const accountingExportItemCustomTabs: Array<Tab> = [
  {
    to: 'accounting-export',
    label: (
      <span>
        <Icon name="book-open" /> Export Comptable
      </span>
    ),
    content: ExportTab,
  },
];

export default accountingExportItemCustomTabs;
