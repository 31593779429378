import { CharactersCounterProperties } from './shared';

const StringPropertySchema = [
  {
    key: 'min',
    label: 'Nombre minimum de caractères',
    type: 'int',
    required: false,
    group: 'Général',
  },
  {
    key: 'max',
    label: 'Nombre maximum de caractères',
    type: 'int',
    required: false,
    group: 'Général',
  },
  {
    key: 'searchable',
    type: 'bool',
    required: false,
    default: false,
    label: "A ajouter dans l'index de recherche textuelle",
    group: 'Avancé',
  },
  {
    key: 'searchIndexWeight',
    label: 'Pondération du champ pour la recherche',
    type: 'int',
    min: 1,
    max: 10,
    default: 1,
    required: false,
    description:
      'Plus un champ a de caractères, plus son index doit être important (ex: label = 1, description = 3, body = 7)',
    group: 'Avancé',
  },

  {
    key: 'multiline',
    label: 'Multiligne',
    type: 'bool',
    default: false,
    group: 'Général',
  },

  {
    key: 'default',
    label: 'Valeur par défaut',
    type: 'string',
    required: false,
    default: null,
    group: 'Général',
  },
  ...CharactersCounterProperties,
];

export default StringPropertySchema;
