// @flow
import * as React from 'react';
import Tooltip from 'rc-tooltip';

type Props = {|
  content: string | { html: string }, // object with html when richtext
|};

export default function QuestionMarkTooltip({ content }: Props): React.Node {
  return (
    <span className="FormElement-tooltip">
      <Tooltip
        placement="top"
        trigger={['hover']}
        overlay={
          <span className="FormElement-tooltip-text">
            {typeof content === 'string' ? (
              content
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: content.html,
                }}
              />
            )}
          </span>
        }
      >
        <span className="FormElement-tooltip-trigger">?</span>
      </Tooltip>
    </span>
  );
}
