// @flow

export const PERIOD = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const PERIOD_LABELS = {
  DAY: 'Jour',
  WEEK: 'Semaine',
  MONTH: 'Mois',
};
