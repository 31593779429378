import { MODE_PROPERTY } from '../constants';

const AskAQuestion = {
  _id: 'askAQuestion',
  label: 'Poser une question',
  icon: 'question',
  frontComponent: 'AskAQuestion',
  optionsSchema: [
    {
      key: 'label',
      label: 'Titre du composant',
      description: 'Sera affiché sur le site',
      type: 'string',
    },
    {
      key: 'placeholder',
      label: 'Texte de description dans le champ de saisie (placeholder)',
      type: 'string',
    },
    {
      key: 'questionType',
      label: 'Type de contribution',
      type: 'enum',
      values: [
        // TODO // Warning copy from Question schema
        {
          value: 'question',
          label: 'Question',
        },
        {
          value: 'proposition',
          label: 'Proposition',
        },
        {
          value: 'coupDeGueule',
          label: 'Coup de gueule',
        },
        {
          value: 'pledge',
          label: 'Militantisme',
        },
      ],
      default: 'question',
    },
    {
      key: 'showTheme',
      label: 'Afficher le champ "thématique"',
      type: 'bool',
      default: false,
      condition: { f$: "obj.questionType === 'proposition'" },
    },
    // TODO inplement in all AskAQuestion components (currently only in liscia)
    {
      key: 'textAfter',
      label: 'Une fois la question envoyée, afficher',
      type: 'richtext',
      condition: { f$: '!obj.pageIdAfter' },
    },
    {
      key: 'pageIdAfter',
      label: 'Une fois la question envoyée, aller à la page',
      type: 'object_id',
      objectType: 'Page',
      condition: { f$: '!obj.textAfter' },
    },
    {
      key: 'max',
      label: 'Longueur maximum (en caractères) de la question posée',
      type: 'int',
      min: 0,
    },
    MODE_PROPERTY,
  ],
  forPage: true,
};

export default AskAQuestion;
