const ContentPlaybackProgress = {
  _id: 'ContentplaybackProgress',
  frontComponent: 'ContentPlaybackProgress',
  label: 'Avancement de la lecture',
  icon: 'play-circle',
  forEntity: true,
  hasEntities: false,
};

export default ContentPlaybackProgress;
