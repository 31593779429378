// @flow
import type { SectionName, SectionOrder } from 'src/types/routing';

const SECTION_ITEMS_ORDER: { [sectionName: SectionName]: Array<string> } = {
  site: [
    'site-options',
    'pages',
    'complex-components',
    'styles',
    'user-profile-templates',
    'external-services',
    'content-types',
    'countries',
    'email-templates',
    'encoding-profiles',
    'pop-ups',
    'question-categories',
    'right-owners',
  ],
  contents: [
    'answers',
    'arrondissements',
    'authors',
    'bookmarks',
    'text-contents',
    'documents',
    'links',
    'magazine-articles',
    'magazine-issues',
    'namespaces',
    'news',
    'newsletters',
    'questions',
    'quizzes',
    'series',
    'shop-items',
    'surveys',
    'tags',
    'videos',
  ],
  products: [
    'conferences',
    'conference-groups',
    'events',
    'resub-rules',
    'subscription-formulas',
    'trainings',
    'training-groups',
    'training-periods',
    'training-requests',
  ],
  encoding: ['encoding', 'error', 'all'],
  data: ['views', 'sales'],
  users: [
    'users',
    'aboweb-action-logs',
    'newsletters-subscribers',
    'user-bug-reports',
    'user-comments',
    'user-contributions',
    'user-quizz-answers',
    'user-watcheds',
  ],
  payments: [
    'purchases',
    'payments',
    'configuration',
    'accounting-export-items',
    'accounting-export-payout', // obsolete
    'native-discount',
    'rights',
    'crowd-funding-campaigns',
    'crowd-funding-campaign-donations',
  ],
  admin: [
    'magellan-options',
    'newspaper-configuration',
    'paywall-configuration',
    'rss-configuration',
  ],
  tools: ['images'],
  superadmin: [
    'sites',
    'admin-users',
    'video-servers',
    'tools',
    'schemas',
    'schema-preview',
    'site-hierarchy',
    'compare-data',
    'component-finder',
  ],
};

const buildSectionItemOrder = (sectionName: SectionName): SectionOrder => {
  const order = {};

  SECTION_ITEMS_ORDER[sectionName].forEach((itemPathname, index) => {
    order[itemPathname] = index;
  });

  return order;
};

export default buildSectionItemOrder;
