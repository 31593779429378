// @flow
import * as React from 'react';
import classnames from 'classnames';

import { Icon } from 'src/components';

type Props = {|
  show: boolean,
  color?: 'grey',
|};

export default function LoadingOverlay({ show, color }: Props): React.Node {
  const timeoutId = React.useRef(null);
  const [internalShow, setInternalShow] = React.useState(false);

  React.useEffect(() => {
    if (show) {
      clearTimeout(timeoutId.current);
      setInternalShow(true);
    } else {
      timeoutId.current = setTimeout(() => setInternalShow(false), 300);
    }
  }, [show]);

  if (!internalShow) return null;

  return (
    <div className={classnames('loading-overlay', color)}>
      <Icon name="spinner" size="2x" pulse />
    </div>
  );
}
