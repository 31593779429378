/* eslint-disable no-new-func */

import * as Joi from 'joi-browser';
import _ from 'lodash';

class Type {
  // str => val, error
  static validateFromString(str, schema) {
    throw new Error(
      'Implementation error: You must implement ' +
        typeof this +
        '.validateFromString()'
    );
  }

  static validate(rawValue, schema) {
    throw new Error(
      'Implementation error: You must implement ' + typeof this + '.validate()'
    );
  }

  // val => str
  static toString(v) {
    return _.isNil(v) ? '' : v.toString();
  }

  static isEqual(v1, v2) {
    return _.isEqual(v1, v2);
  }

  static _addMethodsToValidator(validator, schema, rules) {
    rules.forEach((rule) => {
      if (!_.isNil(schema[rule])) {
        if (validator[rule]) {
          try {
            validator = validator[rule](schema[rule]);
          } catch (e) {
            console.error(
              `"Criterion ${rule}(${schema[rule]}) error for type ${schema.type}: ${e.message}`
            );
          }
        } else {
          console.warn(
            'Criterion ' + rule + ' invalid for type ' + schema.type
          );
        }
      }
    });
    return validator;
  }
}

export class Str extends Type {
  static validateFromString(str, schema) {
    return this.validate(str, schema);
  }

  static validate(rawValue, schema) {
    if ((_.isNil(rawValue) || rawValue === '') && !schema.required)
      return { value: null, error: null };
    let { value, error } = this.getValidator(schema).validate(rawValue);
    return { value, error: error && error.message };
  }

  static getValidator(schema) {
    let validator = Joi.string().label(
      schema.label || schema.key || schema.type
    );
    if (!schema.required) validator = validator.allow('');
    return this._addMethodsToValidator(validator, schema, [
      'min',
      'max',
      'required',
    ]);
  }
}

export class Number extends Type {
  static validateFromString(str, schema) {
    if (str === '') str = null;
    return this.validate(str, schema);
  }

  static validate(rawValue, schema) {
    if (_.isNil(rawValue) && !schema.required)
      return { value: null, error: null };
    let { value, error } = this.getValidator(schema).validate(rawValue);
    return { value, error: error && error.message };
  }

  static getValidator(schema) {
    let validator = Joi.number().label(
      schema.label || schema.key || schema.type
    );
    return this._addMethodsToValidator(validator, schema, [
      'min',
      'max',
      'required',
    ]);
  }
}

export class Int extends Number {
  static getValidator(schema) {
    let validator = super.getValidator(schema);
    return validator.integer();
  }
}

export class Enum extends Type {
  static validate(rawValue, schema) {
    if (_.isNil(rawValue) && !schema.required)
      return { value: null, error: null };
    let { value, error } = this.getValidator(schema).validate(rawValue);
    return { value, error: error && error.message };
  }

  static getValidator(schema) {
    let validator = Joi.any();
    if (schema.values && !schema.dataSource) {
      validator = validator.valid(_.filter(_.map(schema.values, 'value')));
    }
    return validator;
  }
}
export class Obj extends Type {
  static validate(rawValue, schema) {
    if (_.isNil(rawValue) && !schema.required)
      return { value: null, error: null };
    let { value, error } = this.getValidator(schema).validate(rawValue);
    return { value, error: error && error.message };
  }

  static getValidator(schema) {
    let validator = Joi.object();
    return validator;
  }
}

export class List extends Type {
  static validate(rawValue, schema) {
    if (_.isNil(rawValue) && !schema.required)
      return { value: null, error: null };
    let { value, error } = this.getValidator(schema).validate(rawValue);
    return { value, error: error && error.message };
  }

  static getValidator(schema) {
    let validator = Joi.array().label(
      schema.label || schema.key || schema.type
    );
    return this._addMethodsToValidator(validator, schema, [
      'min',
      'max',
      'length',
      'required',
    ]);
    // TODO unique - https://github.com/hapijs/joi/blob/v14.3.1/API.md#arrayuniquecomparator-options
  }
}

export class Func extends Type {
  static validateFromString(str, schema) {
    console.debug('validateFromString', str);
    return this.validate({ f$: str }, schema);
  }

  static toString(v) {
    return _.isNil(v) ? '' : v.f$;
  }

  static compile(value) {
    return Function(
      'obj',
      'adminUser',
      `"use strict"; return !obj ? null : (${value.f$})`
    );
  }

  static validate(rawValue, schema) {
    if (_.isNil(rawValue) && !schema.required)
      return { value: null, error: null };
    try {
      this.compile(rawValue)({});
      return { value: rawValue, error: null };
    } catch (e) {
      console.error(e);
      return { value: rawValue, error: e.message };
    }
  }
}

export class Json extends Type {
  static validateFromString(str, schema) {
    console.debug('validateFromString', str);
    try {
      return this.validate(JSON.parse(str), schema);
    } catch (e) {
      console.error(e);
      return { value: null, error: e.message };
    }
  }

  static toString(v) {
    return JSON.stringify(v, null, 4);
  }

  static validate(rawValue, schema) {
    if (_.isNil(rawValue) && !schema.required)
      return { value: null, error: null };
    // TODO validate with actual schema type
    return { value: rawValue, error: null };
  }
}
