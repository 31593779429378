// @flow
/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from 'react';
import classnames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';

import invariant from 'src/helpers/invariant';

import type { Style } from 'src/components/types';
import { Icon } from './';

type Props = {|
  to?: string,
  children: ?React.Node,
  style?: Style,
  openInNewTab?: boolean,
  disabled?: boolean,
  onClick?: () => any,
  onKeyDown?: (KeyboardEvent) => any,
  title?: string,
  className?: ?string,
  additionalClassName?: string,
  tabIndex?: number,
|};

export default function Link({
  to,
  children,
  style,
  openInNewTab,
  title,
  disabled,
  onClick,
  onKeyDown,
  additionalClassName,
  className: classNameProp,
  tabIndex,
}: Props): React.Node {
  const actuallyDisabled = disabled || (!to && !onClick);
  const className =
    classNameProp === null
      ? ''
      : classNameProp ||
        classnames('reveal_link', 'link', additionalClassName, {
          'is-disabled': actuallyDisabled,
        });

  if (openInNewTab) {
    return (
      <a
        title={title}
        href={to}
        className={className}
        target="_blank"
        style={style}
        tabIndex={tabIndex}
        rel="noreferrer"
      >
        {children}{' '}
        <Icon
          size="sm"
          name="external-link-alt"
          style={{ verticalAlign: 'text-bottom' }}
        />
      </a>
    );
  }

  if (actuallyDisabled) {
    return (
      <a className={className} style={style} tabIndex={tabIndex}>
        {children}
      </a>
    );
  }

  if (onClick) {
    return (
      <a
        onClick={onClick}
        className={className}
        style={style}
        onKeyDown={onKeyDown}
        tabIndex={tabIndex}
      >
        {children}
      </a>
    );
  }

  invariant(to, 'to must be defined at this point');

  return (
    <ReactRouterLink
      style={style}
      className={className}
      to={to}
      tabIndex={tabIndex}
    >
      {children}
    </ReactRouterLink>
  );
}
