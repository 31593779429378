// @flow
import * as React from 'react';

import { Text } from 'src/components';

import ComponentPicker from './components/ComponentPicker';
import LayoutPicker from './components/LayoutPicker';

type Props = {||};

export default function Toolbox(props: Props): React.Node {
  return (
    <div className="toolbox">
      <ComponentPicker />

      <Text element="h3" size={4} style={{ marginBottom: 20 }}>
        Blocs colonnes
      </Text>

      <LayoutPicker />
    </div>
  );
}
