const IntegerPropertySchema = [
  {
    key: 'min',
    label: 'Minimum',
    type: 'int',
    required: false,
    group: 'Général',
  },
  {
    key: 'max',
    label: 'Maximum',
    type: 'int',
    required: false,
    group: 'Général',
  },
  {
    key: 'default',
    label: 'Valeur par défaut',
    type: 'int',
    required: false,
    default: null,
    group: 'Général',
  },
];

export default IntegerPropertySchema;
