import _ from 'lodash';
import React from 'react';

import InstanceStore from 'src/stores/InstanceStore';
import SchemaStore from 'src/stores/SchemaStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import { deepMap, multiReplace } from 'src/helpers/misc';

import { Modal, Button, Switch, OldInput } from 'src/components';
import TagSelection from './TagSelection';

export const TEMPLATE_INSTANCE_NAME = 'MODELE-DE-SITE-VIERGE';

export default class InitSiteToolButton extends ComponentBase {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      categoriesTocreate: [],
      createMenuAndPages: true,
      contentsPageToCreate: { TextContent: true },
      listsPageToCreate: {},
    };

    this.stores = [InstanceStore, SchemaStore];
  }

  openModal = () => {
    this.setState({ open: true });
  };

  initSite = () => {
    let {
      categoriesTocreate,
      createMenuAndPages,
      contentsPageToCreate,
      listsPageToCreate,
      siteTitleToCreate,
    } = this.state;

    let instanceName = this.props.instanceName || this.state.instanceName;

    if (TEMPLATE_INSTANCE_NAME === instanceName || 'global' === instanceName) {
      console.error("Can't init", instanceName);
      return;
    }

    const copyFromTemplate = (resourcePathname, variables, newId) => {
      console.log('Create', resourcePathname, 'from', TEMPLATE_INSTANCE_NAME);

      return API.get(TEMPLATE_INSTANCE_NAME + '/data/' + resourcePathname).then(
        (obj) => {
          let res = deepMap(obj, (p) =>
            _.isString(p) ? multiReplace(p, variables) : p
          );
          if (newId) res._id = newId;
          API.put(instanceName + '/data/' + resourcePathname, res);
        }
      );
    };

    const addLog = (x) =>
      this.setState((s) => ({ ...s, initLog: [...(s.initLog || []), x] }));

    let variables = {
      '{SITE_TITLE}': siteTitleToCreate,
      // TODO other
    };

    console.log('Create header, footer, options');

    copyFromTemplate('Config/footer', variables);
    copyFromTemplate('Config/options', variables);

    console.log('Create home page from template');
    copyFromTemplate('Page/home', variables);

    addLog(<span>configuration créée</span>);

    console.log('Copy Complex Components'); // CCC
    API.get(TEMPLATE_INSTANCE_NAME + '/data/ComplexComponent').then((res) =>
      res.forEach((cc) => API.post(instanceName + '/data/ComplexComponent', cc))
    );

    let menuItems = [];

    categoriesTocreate.forEach((cat) => {
      let catId = _.camelCase(cat);
      console.log('Create category', cat, catId);
      API.post(instanceName + '/data/Category', {
        _id: catId, // TODO check for duplicate ID ?
        label: cat,
      });

      if (createMenuAndPages) {
        console.log('Create page for category', cat, '+ add to menu');
        API.get(TEMPLATE_INSTANCE_NAME + '/data/Page/CATEGORY_PAGE').then(
          (page) => {
            let newPage = deepMap(page, (p) => {
              if (_.isString(p)) {
                p = multiReplace(p, {
                  ...variables,
                  '{CATEGORY_LABEL}': cat,
                });
              } else if (_.isObject(p) && p._cls === 'ContentRequester') {
                p.type = 'TextContent';
                p.contentType = catId;
              }
              return p;
            });
            newPage._id = 'page_' + catId;
            API.post(instanceName + '/data/Page', newPage).then(
              () =>
                addLog(
                  <span>
                    Page pour catégorie <strong>{cat}</strong> créée
                  </span>
                ),
              (err) =>
                addLog(
                  <span className="error">
                    Erreur création page catégorie <strong>{cat}</strong> :{' '}
                    {err.message}
                  </span>
                )
            );
          }
        );

        menuItems.push({
          _cls: 'MenuItem',
          label: cat,
          pageId: 'page_' + catId,
        });
      }

      return null;
    });

    _(contentsPageToCreate)
      .pickBy()
      .keys() // checked types
      .forEach((type) => {
        API.get(TEMPLATE_INSTANCE_NAME + '/data/Page/OBJECT_PAGE') // basic object template page
          .then((objPage) => {
            API.get(TEMPLATE_INSTANCE_NAME + '/data/Page/' + type) // try specific template page
              .then((specificPage) => {
                if (specificPage)
                  console.log('got specific page for type', type);
                let page = specificPage || objPage;

                console.log('Create page for type', type);
                let newPage = deepMap(page, (p) =>
                  _.isString(p)
                    ? multiReplace(p, {
                        ...variables,
                        '{OBJECT_LABEL}': this.state.globalSchema[type].label,
                      })
                    : p
                );

                newPage.objectType = type;
                newPage._id = type + '_page';

                API.post(instanceName + '/data/Page', newPage).then(
                  () =>
                    addLog(
                      <span>
                        Page pour un <strong>{type}</strong> créée
                      </span>
                    ),
                  (err) =>
                    addLog(
                      <span className="error">
                        Erreur création page <strong>{type}</strong> :{' '}
                        {err.message}
                      </span>
                    )
                );
              });
          });

        if (listsPageToCreate[type]) {
          API.get(TEMPLATE_INSTANCE_NAME + '/data/Page/OBJECT_LIST_PAGE').then(
            (page) => {
              let newPage = deepMap(page, (p) => {
                if (_.isString(p)) {
                  p = multiReplace(p, {
                    ...variables,
                    '{OBJECT_LABEL}': this.state.globalSchema[type].label,
                  });
                } else if (_.isObject(p) && p._cls === 'ContentRequester') {
                  p.type = type;
                }
                return p;
              });
              newPage._id = type + '_list';
              API.post(instanceName + '/data/Page', newPage).then(
                () =>
                  addLog(
                    <span>
                      Page liste <strong>{type}</strong> créée
                    </span>
                  ),
                (err) =>
                  addLog(
                    <span className="error">
                      Erreur création page liste <strong>{type}</strong> :{' '}
                      {err.message}
                    </span>
                  )
              );
            }
          );

          menuItems.push({
            _cls: 'MenuItem',
            label: 'Les ' + this.state.globalSchema[type].label,
            pageId: type + '_list',
          });
        }
      });

    if (createMenuAndPages) {
      API.get(TEMPLATE_INSTANCE_NAME + '/data/Config/header').then((header) => {
        let newHeader = deepMap(header, (p) =>
          _.isString(p) ? multiReplace(p, variables) : p
        );
        newHeader.menuItems = [...(newHeader.menuItems || []), ...menuItems];
        API.put(instanceName + '/data/Config/header', newHeader).then(
          () => addLog(<span>En-tête et menu créés</span>),
          (err) =>
            addLog(
              <span className="error">
                Erreur création En-tête et menu : {err.message}
              </span>
            )
        );
      });
    } else {
      copyFromTemplate('Config/header', variables);
    }
  };

  render() {
    let { globalSchema, initLog } = this.state;
    let instanceName = this.props.instanceName || this.state.instanceName;

    let contentObjectSchemas = _(globalSchema)
      .values()
      .filter({ isContent: true })
      .value();

    return (
      <div>
        <Button onClick={this.openModal}>
          Initialiser le site (créer les pages et composants de base)...
        </Button>
        <Modal
          modal={true}
          open={this.state.open}
          buttons={{
            ok: !initLog && 'Initialiser le site',
            cancel: 'Fermer',
          }}
          onClose={(bt) =>
            bt === 'ok' ? this.initSite() : this.setState({ open: false })
          }
        >
          <h2>Initialiser le site {instanceName}</h2>
          <div>
            Permet de créer des pages et une configuration de base pour démarrer
            un site vierge
          </div>

          {!initLog ? (
            <React.Fragment>
              <OldInput
                value={this.state.siteTitleToCreate}
                onChange={(siteTitleToCreate) =>
                  this.setState({ siteTitleToCreate })
                }
                label="Titre du site"
                required={true}
              />
              <TagSelection
                value={this.state.categoriesTocreate}
                onChange={(categoriesTocreate) =>
                  this.setState({ categoriesTocreate })
                }
                label="Créer les catégories de contenu (rubriques) suivantes:"
                placeholder="Saisir le nom + <Tab> ou <Entrée>"
              />
              <Switch
                value={this.state.createMenuAndPages}
                onChange={(createMenuAndPages) =>
                  this.setState({ createMenuAndPages })
                }
                label="Créer le menu et les pages correspondantes"
              />
              Créer des "pages contenu" pour afficher les objets suivants :
              {contentObjectSchemas.map((sch) => (
                <Switch
                  key={sch._id}
                  value={this.state.contentsPageToCreate[sch._id]}
                  onChange={(create) =>
                    this.setState((s) =>
                      _.set(
                        _.cloneDeep(s),
                        ['contentsPageToCreate', sch._id],
                        create
                      )
                    )
                  }
                  label={sch.label}
                />
              ))}
              Créer des pages pour listant tous les objets suivants :
              {contentObjectSchemas.map(
                (sch) =>
                  this.state.contentsPageToCreate[sch._id] && (
                    <Switch
                      key={sch._id}
                      value={this.state.listsPageToCreate[sch._id]}
                      onChange={(create) =>
                        this.setState((s) =>
                          _.set(
                            _.cloneDeep(s),
                            ['listsPageToCreate', sch._id],
                            create
                          )
                        )
                      }
                      label={sch.label}
                    />
                  )
              )}
            </React.Fragment>
          ) : (
            initLog.map((item, i) => (
              <div key={i} className="log-item">
                {item}
              </div>
            ))
          )}
        </Modal>
      </div>
    );
  }
}
