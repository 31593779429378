import _ from 'lodash';

import type { ResourceSchema, Resource, GlobalSchema } from 'src/types/models';

import { Int, Str, Number, List, Enum, Func } from './_SchemaTypes';
import { equalOrIncludes } from 'src/helpers/misc';
import { getResourceLabel } from 'src/helpers/models/schema';

export function makeRef(obj: any, { resourceSchema }: any): any {
  if (_.isNil(obj)) return null;

  if (!obj._cls || !obj._id) {
    console.error(
      "Can't make a ref, need an object with _cls and _id, got",
      obj
    );
    return obj;
  }

  return {
    _id: obj._id,
    _cls: obj._cls,
    _isRef: true,
    label: resourceSchema ? getResourceLabel(obj, resourceSchema) : obj.label,
  };
}

export function addRef(obj: any): any {
  if (_.isNil(obj)) return null;

  if (!obj._cls || !obj._id) {
    console.error(
      "Can't make a ref, need an object with _cls and _id, got",
      obj
    );
    return obj;
  }

  return {
    ...obj,
    _isRef: true,
  };
}

function resolve(value: any, obj: any, adminUser: User) {
  if (_.isFunction(value)) {
    try {
      return value(obj); // TODO bind / use "this" ?
    } catch (e) {
      console.warn('Exception resolving schema', value, obj, e);
      return null;
    }
  } else if (value && value.f$) {
    try {
      return Func.compile(value)(obj, adminUser);
    } catch (e) {
      console.warn('Exception resolving schema', value, obj, e);
      return null;
    }
  } else {
    return value;
  }
}

type ExtendedResourceSchema = {|
  ...ResourceSchema,
  _r_: boolean,
  f$: any,
|};

export default class Schema {
  /**
   * Recursively resolve schema:
   *   - exclude property if condition() returns false
   *   - execute function values
   *
   * @param schema
   * @param obj
   * @returns {*}
   */
  static resolveSchema(
    schema: ExtendedResourceSchema,
    obj: any,
    adminUser: User
  ): any {
    if (schema && schema._r_) {
      console.warn('already resolved', schema, obj);
      return;
    }

    if (_.isPlainObject(schema) && !schema.f$) {
      // First resolve current schema
      return _.mapValues(
        //_.assign( // debug
        _.pickBy(
          schema,
          (v, k) =>
            !(
              _.isObject(v) &&
              v.condition &&
              !resolve(v.condition, obj, adminUser)
            )
        ),
        //   schema._id && {_r_: true}), // debug
        (v) => this.resolveSchema(v, obj, adminUser)
      );
    } else if (_.isArray(schema)) {
      return _.map(
        _.filter(
          schema,
          (v) =>
            !(
              _.isObject(v) &&
              v.condition &&
              !resolve(v.condition, obj, adminUser)
            )
        ),
        (v) => this.resolveSchema(v, obj, adminUser)
      );
    } else {
      return resolve(schema, obj, adminUser);
    }
  }

  /**
   * Get object's schema if needed (from global schema) and resolve it, otherwise do nothing
   * @param schema
   * @param globalSchema
   * @param resource
   * @returns {*}
   */
  static getSchema(
    schema: ResourceSchema,
    globalSchema: GlobalSchema,
    resource: Resource,
    adminUser: User
  ): ResourceSchema {
    let type = schema.type;

    if (schema.type === 'object' && schema.objectType) {
      console.debug(
        'gettign schema for object ot objectType',
        schema.objectType
      );
      type =
        (resource && resource._cls) ||
        (_.isArray(schema.objectType) && schema.objectType.length === 1
          ? schema.objectType[0]
          : schema.objectType); // TODO if multiple
    }

    if (globalSchema && globalSchema[type]) {
      return this.getAndResolveSchema(
        schema,
        globalSchema,
        resource,
        adminUser
      );
    }

    return schema;
  }

  /**
   * Resolve any schema + get object's schema if needed (from global schema)
   * @param schema
   * @param globalSchema
   * @param value
   * @returns {*}
   */
  static getAndResolveSchema(schema, globalSchema, value, adminUser) {
    let type = schema.type;

    // For resource property schema
    if (schema.type === 'object' && schema.objectType) {
      console.debug(
        'gettign schema 2 for object or objectType',
        schema.objectType
      );
      type =
        (value && value._cls) ||
        (_.isArray(schema.objectType) && schema.objectType.length === 1
          ? schema.objectType[0]
          : schema.objectType); // TODO if multiple
    }

    // For resource schema
    if (globalSchema && globalSchema[type]) {
      let subSchema = this.resolveSchema(globalSchema[type], value, adminUser);
      if (!subSchema.type) subSchema.type = 'object'; // TEMP // TODO correct db
      return { _r_: true, ...schema, ...subSchema };
    }

    return {
      _r_: true,
      ...this.resolveSchema(schema, value, adminUser),
    };
  }

  // Only "simple" types for the moment (list and obj handled specifically)
  static TypeClassForType = {
    int: Int,
    float: Number,
    string: Str,
    enum: Enum,
    richtext: Str,
  };

  static getErrors(value, schema, globalSchema) {
    schema = this.getSchema(schema, globalSchema, value);

    if (!schema.type || schema.type === 'object') {
      if (!schema._r_)
        console.warn('getErrors: schema not resolved', schema, value);

      if (_.isEmpty(value)) return schema.required ? ['Objet requis'] : null;

      return _(schema.propertiesList)
        .map((p) => this.getErrors(value[p.key], p, globalSchema))
        .flatten()
        .filter()
        .value();
    } else if (schema.type === 'list' && schema.itemSchema) {
      return (
        List.validate(value, schema).error ||
        _(value)
          .map((v) => this.getErrors(v, schema.itemSchema, globalSchema))
          .flatten()
          .filter()
          .value()
      );
    } else if (schema.type in this.TypeClassForType) {
      return [this.TypeClassForType[schema.type].validate(value, schema).error];
    }
    return null;
  }

  // convert values and remove invalid object keys
  static validate(rawValue, schema, globalSchema) {
    schema = this.getSchema(schema, globalSchema, rawValue);

    if (rawValue && rawValue.f$) {
      // special case
      // TODO check schema.canBeFunction
      console.debug(
        'validate functional value for ',
        schema.type,
        schema.label
      );
      return Func.validate(rawValue, schema); // this schema is for another type!
    }

    let allErrors = [];
    let newValue = rawValue;

    if (!schema.type || schema.type === 'object') {
      if (!schema._r_)
        console.warn('validate: schema not resolved', schema, newValue);

      if (_.isEmpty(newValue) && !schema.required)
        return { value: null, error: allErrors };

      _(schema.propertiesList).forEach((p) => {
        let { value, error } = this.validate(newValue[p.key], p, globalSchema);
        newValue[p.key] = value;
        if (_.isNil(value) && _.isNil(p.default)) delete newValue[p.key];
        error && allErrors.concat(error);
      });
    } else if (schema.type === 'list') {
      if (_.isEmpty(newValue) && !schema.required)
        return { value: null, error: allErrors };

      // TODO remove null values ?

      if (!_.isNil(newValue) && !_.isArray(newValue)) newValue = [newValue]; // patch for migrating data (ex. contentType)

      let { value, error } = List.validate(newValue, schema);
      error && allErrors.concat(error);

      newValue = _.map(value, (v) => {
        let { value, error } = this.validate(
          v,
          schema.itemSchema || { type: schema.itemType },
          globalSchema
        );
        error && allErrors.concat(error);
        return value;
      });
    } else if (schema.type in this.TypeClassForType) {
      return this.TypeClassForType[schema.type].validate(newValue, schema);
    }
    return { value: newValue, error: allErrors };
  }

  /**
   * Create a new object, with all default values, from its schema
   *
   * @param schema
   * @returns {*}
   */
  static newType(schema) {
    // TODO Schema.getSchema
    let obj = null;
    if (schema.type === 'object' || !schema.type) {
      obj = _.assign(
        schema._id ? { _cls: schema._id } : {},
        _.fromPairs(
          _.map(schema.propertiesList, (p) => [p.key, Schema.newType(p)])
        )
      );
    }

    if (schema.type === 'list') obj = [];

    if (schema.default !== undefined) {
      obj = schema.default;
    }

    return obj;
  }

  /**
   * Get references (object_id, object_ref, object?) to a certain type (cls).
   * Does not resolve schemas (obviously we don't have values...).
   *
   * Returns: {
   *     objCls:      Type of the object pointing to the given type
   *     objLabel:    Label of the object
   *     propSchema:  schema of the foreign key property
   *     isList:      is it a list or a single link
   *     itemType:    if it's a list, type of the list's items
   * }
   *
   * @param schema
   * @param type
   * @param listsOnly
   */
  static getBackRefs(globalSchema, type, listsOnly = false) {
    return _.reduce(
      globalSchema,
      (result, value) => [
        ...result,
        ..._(value.propertiesList || [])
          .concat(
            // handle sub-objects:
            _(value.propertiesList || [])
              .filter(
                (p) =>
                  p.type === 'object' &&
                  p.objectType &&
                  globalSchema[p.objectType] &&
                  globalSchema[p.objectType].propertiesList
              )
              .map((p) =>
                globalSchema[p.objectType].propertiesList.map((subP) => ({
                  ...subP,
                  key: p.key + '.' + subP.key,
                  label: subP.label + ' (dans ' + p.label + ')',
                }))
              )
              .flatten()
              .value()
          )
          .filter(
            (p) =>
              (!listsOnly || p.type === 'list') &&
              // single value
              //(allowByValue && p.type === type) ||

              // list of values:
              //(allowByValue && p.itemSchema && p.itemSchema.type === type) ||
              (equalOrIncludes(p.itemType, type) || // this is also by value... - so? leave it?
                // single ref or id:
                //      type: "object_ref / object_id",
                //      objectType: "<class>" / ["<class1>", "<class2>"...]
                equalOrIncludes(p.objectType, type) ||
                // list of ref or id
                //      type: "list",
                //      itemSchema: {
                //          "type": "object_ref / object_id",
                //          "objectType": "<class>" / ["<class1>", "<class2>"...]
                //      }
                (p.itemSchema &&
                  equalOrIncludes(p.itemSchema.objectType, type)) ||
                (p.propertyRef &&
                  equalOrIncludes(p.propertyRef.prop.objectType, type)))
          )
          .map((p) => ({
            objCls: value._id,
            objLabel: value.label,
            propSchema: p,
            isList: p.type === 'list',
            refType:
              p.type === 'list'
                ? (p.itemSchema && p.itemSchema.type) || p.itemType
                : p.type,
          }))
          .value(),
      ],
      []
    );
  }

  /**
   * Get enum value's label from object's property and value.
   * If not found, return defaultValue if set, otherwise return value.
   *
   * @param objectSchema
   * @param propKey
   * @param value
   * @param defaultValue
   * @returns {*}
   */
  static getEnumLabel(objectSchema, propKey, value, defaultValue) {
    let propSchema =
      objectSchema && _.find(objectSchema.propertiesList, { key: propKey });
    let enumValue = propSchema && _.find(propSchema.values, { value });
    return (
      (enumValue && enumValue.label) ||
      (defaultValue === undefined ? value : defaultValue)
    );
  }
}
