const ObjectIdPropertySchema = [
  {
    key: 'objectType',
    label: "Type d'objet",
    type: 'enum',
    multiple: true,
    required: true,
    group: 'Général',
    dataSource: {
      isGlobal: true,
      resource: 'schema',
      valuePath: '_id',
      labelPath: 'label',
    },
  },
];

export default ObjectIdPropertySchema;
