// @flow
import * as React from 'react';
import useSWR from 'swr';

import type { Params } from '../types';

import { useGlobal } from 'src/hooks';

import GenericStatsTable from './GenericStatsTable';

type Props = {|
  params: Params,
|};

export default function SummaryStats({ params }: Props): React.Node {
  const { siteId } = useGlobal();

  const { data: stats, error } = useSWR([
    `${siteId}/statistics/sales/summary`,
    params,
  ]);

  return <GenericStatsTable stats={stats} error={error} params={params} />;
}
