const EntityAuthors = {
  _id: 'contributors', // TODO rename this
  label: 'Auteurs',
  frontComponent: 'EntityAuthors',
  icon: 'users',
  optionsSchema: [
    { key: 'label', label: 'Libellé', type: 'string', default: 'Auteur' },
    {
      key: 'labelMultiple',
      label: 'Libellé si plusieurs auteurs',
      type: 'string',
      default: 'Auteurs',
    },
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'auteur",
      type: 'bool',
      default: true,
    },
    {
      key: 'displayOccupation',
      label: "Afficher l'occupation",
      type: 'bool',
      default: false,
    },
  ],
  forEntity: true,
};

export default EntityAuthors;
