// @flow
import { Promise as ES6Promise } from 'es6-promise';
import _ from 'lodash';
import request from 'superagent';

import type { APIMethod, QueryParams, APIChannel, BodyParams } from 'src/types';

import config from './config';
import { buildQueryString } from 'src/helpers/misc';

type Path = string; // Resource path, e.g. "/Content/ct0165"

const API_URL = `${config.API_URL}/admin`;

const requestMap = {};

export default class API {
  static _request<T>(
    method: APIMethod,
    path: Path,
    params?: QueryParams,
    body?: BodyParams,
    channel?: APIChannel,
    apiUrl: string = API_URL
  ): Promise<T> {
    const url = `${apiUrl}/${path}${
      params ? '?' + buildQueryString(params) : ''
    }`;

    return new ES6Promise((resolve, reject) => {
      if (channel) API.abort(channel);

      let chain = request[method](url, body).withCredentials();

      // if (method === 'get') chain.query(params);

      chain.end((err, response) => {
        //console.debug("RQ returned>", response);
        if (channel) delete requestMap[channel];

        if (err) {
          // TODO err.response ? response ??
          const responseError = err?.response?.body?.error;

          reject({
            code: responseError?.code,
            message: responseError?.message,
          });
        } else if (!response.body || !('result' in response.body)) {
          reject(response.body && response.body.error);
        } else {
          let b = response.body;
          if (_.isObject(b.result)) {
            if (_.isInteger(b.count)) b.result.count = b.count; // TODO _count, _offset... instead
            if (params && params.offset) b.result.offset = params.offset;
            if (params && params.limit) b.result.limit = params.limit;
          }
          resolve(b.result);
        }
      });

      if (channel) requestMap[channel] = chain.xhr || chain.req;
    });
  }

  static abort(channel: APIChannel): boolean {
    if (requestMap[channel]) {
      requestMap[channel].abort();
      delete requestMap[channel];
      return true;
    }
    return false;
  }

  static get<T>(
    path: Path,
    params?: QueryParams,
    channel?: APIChannel
  ): Promise<T> {
    return API._request(
      'get',
      path,
      { ...params, __admin: true },
      null,
      channel
    );
  }

  static post<T>(
    path: Path,
    body: BodyParams,
    channel?: APIChannel,
    params?: QueryParams
  ): Promise<T> {
    return API._request(
      'post',
      path,
      { ...params, __admin: true },
      body,
      channel
    );
  }

  static patch<T>(
    path: Path,
    body: BodyParams,
    channel?: APIChannel,
    params?: QueryParams
  ): Promise<T> {
    return API._request(
      'patch',
      path,
      { ...params, __admin: true },
      body,
      channel
    );
  }

  static put<T>(
    path: Path,
    body: BodyParams,
    channel?: APIChannel,
    params?: QueryParams
  ): Promise<T> {
    return API._request(
      'put',
      path,
      { ...params, __admin: true },
      body,
      channel
    );
  }

  static del<T>(
    path: Path,
    params?: QueryParams,
    channel?: APIChannel
  ): Promise<T> {
    return API._request('del', path, params, { __admin: true }, channel);
  }
}
