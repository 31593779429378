// @flow
import * as React from 'react';

import type { Purchase } from 'src/types/models';
import type { Props } from '../../';

import { useGlobal, useResourceContext } from 'src/hooks';

import { Label, Text, Icon, Field } from 'src/components';

export default function GiftCode({ onChange }: Props): React.Node {
  const { site } = useGlobal();
  const { resource: purchase } = useResourceContext<Purchase>();

  const { token, paymentId, productId, userId } = purchase;

  const searchParams = {
    instanceId: site.id,
    token,
    paymentId,
    productId,
  };

  const url = new URL(site.frontUrl);
  url.pathname = 'frontApi/giftCard';
  Object.keys(searchParams).forEach((key) => {
    url.searchParams.append(key, searchParams[key]);
  });

  return (
    <Field>
      <Label>
        <span>Code de l'achat / Code cadeau </span>

        {userId && (
          <span>
            (
            <a href={url.href} target="_blank" rel="noreferrer">
              <Icon name="download" />
              carte cadeau
            </a>
            )
          </span>
        )}
      </Label>
      <Text>{token}</Text>
    </Field>
  );
}
