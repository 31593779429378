// @flow
import * as React from 'react';

import type { CustomAction } from './';

import API from 'src/helpers/api/API';
import { useGlobal, useSchemaContext } from 'src/hooks';

import { NavLink } from 'react-router-dom';

const HOME_PAGE_DEFAULTS = {
  _id: 'home',
  label: 'Accueil',
  route: '/home',
  _isHome: true,
};

function CreateHomePageAction(): React.Node {
  const { siteId } = useGlobal();
  const { path } = useSchemaContext();
  const [homePageMissing, setHomePageMissing] = React.useState(false);

  React.useEffect(() => {
    const checkHomePageExists = async () => {
      const homePage = await API.get(`${siteId}/data/Page/home`);
      setHomePageMissing(!homePage);
    };

    checkHomePageExists();
  }, []);

  if (!homePageMissing) return null;

  return (
    <NavLink
      to={{
        pathname: `${path}/new`,
        search:
          '?init=' + encodeURIComponent(JSON.stringify(HOME_PAGE_DEFAULTS)),
      }}
      className="Admin-secondary-button"
    >
      + Créer la home
    </NavLink>
  );
}

const listMainActions: Array<CustomAction> = [
  {
    id: 'createHomePage',
    content: CreateHomePageAction,
  },
];

export default listMainActions;
