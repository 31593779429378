const Title = {
  _id: 'Title',
  label: 'Titre',
  icon: 'text-height',
  frontComponent: 'Title',
  optionsSchema: [
    { key: 'content', label: 'Texte', type: 'string', required: true },
    { key: 'lateralSeparator', label: 'Séparateur après titre', type: 'bool' },
  ],
  forPage: true,
};

export default Title;
