// @flow
import * as React from 'react';
import _ from 'lodash';

import type { Trailer } from './Cell';

import StatsGraph from './StatsGraph';
import EntityView from './EntityView';

export type Props = {|
  pl_trailer: Trailer,
  pl_full: Trailer,
  trailer: Trailer,
  full: Trailer,
  minutes: { [key: string]: number },
|};

export default function EntityFullStats({
  pl_trailer,
  pl_full,
  trailer,
  full,
  minutes,
}: Props): React.Node {
  return (
    <div className="EntityStatsView-wrapper">
      <EntityView
        pl_trailer={pl_trailer}
        pl_full={pl_full}
        full={full}
        trailer={trailer}
      />

      <StatsGraph
        chartType="AreaChart"
        columns={[
          {
            type: 'number',
            label: 'minute',
          },
          {
            type: 'number',
            label: 'audience',
          },
        ]}
        rows={_.map(minutes, (v, m) => [m, v / 60])}
        title="Audience au cours de la lecture (en minutes) - diffusions complètes"
        hAxisOptions={{
          title: 'minute',
          minValue: 0,
        }}
        height="400px"
      />
    </div>
  );
}
