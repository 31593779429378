const VoteButtons = {
  _id: 'vote',
  label: 'Boutons +1/-1',
  icon: 'filled-thumbs-up',
  frontComponent: 'VoteButtons',
  optionsSchema: [],
  forPage: true, // !
};

export default VoteButtons;
