// @flow
import type { SectionName, HardcodedSectionRoute } from 'src/types/routing';

// Statistics tab
import PlayStatsPage from './sectionSpecificPages/data/PlayStatsPage';
import SalesStatsPage from './sectionSpecificPages/data/SalesStatsPage';
import ChartsStatsPage from './sectionSpecificPages/data/ChartsStatsPage';

// Encoding tab
import VideoEncodingPage from './sectionSpecificPages/encoding/VideoEncodingPage';

// Site tab
// import ExternalServicesTab from './sectionSpecificPages/site/ExternalServicesTab';
import ImageTool from './sectionSpecificPages/admin/ImageTool';

// SuperAdmin tab
import CompareSitesPage from './sectionSpecificPages/superAdmin/CompareSitesPage';
import SelectInstanceAndToolsPage from './sectionSpecificPages/superAdmin/SelectInstanceAndToolsPage';
import SiteHierarchyPage from './sectionSpecificPages/superAdmin/SiteHierarchyPage';
import ComponentFinder from './sectionSpecificPages/superAdmin/ComponentFinder';

const superAdminRoutes: Array<HardcodedSectionRoute> = [
  {
    pathname: 'tools',
    component: SelectInstanceAndToolsPage,
    label: 'Outils',
  },
  {
    pathname: 'site-hierarchy',
    component: SiteHierarchyPage,
    label: 'Fil de fer',
  },
  {
    pathname: 'compare-data',
    component: CompareSitesPage,
    label: 'Comparer les sites',
  },
  {
    pathname: 'component-finder',
    component: ComponentFinder,
    label: 'Recherche de composants',
  },
];

const siteRoutes: Array<HardcodedSectionRoute> = [
  // {
  //   pathname: 'external-services',
  //   component: ExternalServicesTab,
  //   label: 'Services externes',
  // },
];

const videoEncodingRoutes: Array<HardcodedSectionRoute> = [
  {
    pathname: 'encoding',
    component: VideoEncodingPage,
    query: {
      filterBy: { encodingStatus: { $in: ['encoding', 'pending'] } },
      refresh: true,
    },
    label: "En cours d'encodage",
  },
  {
    pathname: 'error',
    component: VideoEncodingPage,
    query: { filterBy: { encodingStatus: 'error' } },
    label: 'En erreur',
  },
  {
    pathname: 'all',
    component: VideoEncodingPage,
    label: 'Toutes',
  },
];

const toolsRoutes = [
  {
    pathname: 'images',
    component: ImageTool,
    label: 'Images',
  },
];

const dataRoutes: Array<HardcodedSectionRoute> = [
  {
    pathname: 'views',
    component: PlayStatsPage,
    label: 'Statistiques de vues',
  },
  {
    pathname: 'sales',
    component: SalesStatsPage,
    label: "Statistiques d'achat",
    exact: false,
  },
  {
    pathname: 'charts',
    component: ChartsStatsPage,
    label: 'Graphiques',
    exact: false,
  },
];

const sectionHardcodedRoutes: {
  [key: SectionName]: Array<HardcodedSectionRoute>,
} = {
  site: siteRoutes,
  contents: [],
  encoding: videoEncodingRoutes,
  data: dataRoutes,
  users: [],
  payments: [],
  tools: toolsRoutes,
  superadmin: superAdminRoutes,
};

export default sectionHardcodedRoutes;
