const EntityDesc = {
  _id: 'desc',
  label: 'Description',
  icon: 'book-reader',
  frontComponent: 'EntityDesc',
  optionsSchema: [
    {
      key: 'truncateLength',
      label: 'Longueur maximale du texte (en nbre de caractères)',
      type: 'int',
      min: 0,
    },
  ],

  forEntity: true,
};

export default EntityDesc;
