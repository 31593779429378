// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from 'src/components/types';

import { Icon, Button } from 'src/components';

type Props = {|
  children: React.Node,
  additionalClassName?: string,
  style?: Style,
|};

export default function Card({
  children,
  additionalClassName,
  style,
}: Props): React.Node {
  return (
    <div className={classnames('card', additionalClassName)} style={style}>
      {children}
    </div>
  );
}

type HeaderAction = {|
  icon: string,
  onClick?: () => *,
  to?: string,
  target?: string,
  disabled?: boolean,
|};
type CardHeaderProps = {
  title: string,
  action?: HeaderAction,
  actions?: Array<HeaderAction>,
  children?: React.Node,
  additionalClassName?: string,
  style?: Style,
};
export function CardHeader({
  children,
  title,
  action,
  actions,
  additionalClassName,
  style,
}: CardHeaderProps): React.Node {
  return (
    <div
      className={classnames('card-header', additionalClassName)}
      style={style}
    >
      {!!title && <p className="card-header-title">{title}</p>}

      {action && (
        <button className="card-header-icon" onClick={action.onClick}>
          <Icon name={action.icon} />
        </button>
      )}

      {actions && (
        <div className="card-header-icon">
          {actions.map((action) => (
            <Button
              disabled={action.disabled}
              onClick={action.onClick}
              to={action.to}
              key={action.icon}
              size="small"
              style={{ marginLeft: 10 }}
              target={action.target}
            >
              <Icon name={action.icon} />
            </Button>
          ))}
        </div>
      )}

      {children}
    </div>
  );
}

type CardImageProps = {
  children: React.Node,
  additionalClassName?: string,
  style?: Style,
};
export function CardImage({
  children,
  additionalClassName,
  style,
}: CardImageProps): React.Node {
  return (
    <div
      className={classnames('card-image', additionalClassName)}
      style={style}
    >
      {children}
    </div>
  );
}

type CardContentProps = {
  ...Props,
};
export function CardContent({
  children,
  additionalClassName,
  style,
}: CardContentProps): React.Node {
  return (
    <div
      className={classnames('card-content', additionalClassName)}
      style={style}
    >
      {children}
    </div>
  );
}

type CardFooterProps = {
  children: React.Node | Array<React.Node>,
  additionalClassName?: string,
  style?: Style,
};
export function CardFooter({
  children,
  additionalClassName,
  style,
}: CardFooterProps): React.Node {
  return (
    <footer
      className={classnames('card-footer', additionalClassName)}
      style={style}
    >
      {Array.isArray(children)
        ? children.map((child, i) => (
            <p key={i} className="card-footer-item">
              {child}
            </p>
          ))
        : children}
    </footer>
  );
}
