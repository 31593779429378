import * as React from 'react';

import { getCustomPropertyRenderer } from './customPropertyRenderers';
import { getGenericPropertyRenderer } from './genericPropertyRenderers';

import { ErrorBoundary } from 'src/components';

export default function GenericPropertyRenderer({
  mode = 'cell',
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { key } = propertySchema;
  const { _id: resourceSchemaId } = resourceSchema;

  const PropertyRenderer =
    getCustomPropertyRenderer(resourceSchemaId, key) ||
    getGenericPropertyRenderer(propertySchema);

  if (!PropertyRenderer) return 'No renderer';

  return (
    <ErrorBoundary>
      <PropertyRenderer
        mode={mode}
        value={value}
        propertySchema={propertySchema}
        resource={resource}
        resourceSchema={resourceSchema}
      />
    </ErrorBoundary>
  );
}
