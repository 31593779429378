import CategoriesRenderer from './CategoriesRenderer';
import DisplayConditionRenderer from './DisplayConditionRenderer';
import NamespaceChainRenderer from './NamespaceChainRenderer';
import PublishStatusRenderer from './PublishStatusRenderer';
import RightsRenderer from './RightsRenderer';
import NamespaceRenderer from './NamespaceRenderer';

const CustomRenderers = {
  'Namespace.path': NamespaceChainRenderer,
};

export const getCustomPropertyRenderer = (schemaId, propertyKey) => {
  if (CustomRenderers[`${schemaId}.${propertyKey}`])
    return CustomRenderers[`${schemaId}.${propertyKey}`];

  switch (propertyKey) {
    case 'categories':
      return CategoriesRenderer;
    case 'rightsNeeded':
    case 'rightsGiven':
      return RightsRenderer;
    case 'displayConditions':
      return DisplayConditionRenderer;
    case 'namespace':
      return NamespaceRenderer;
    case 'publishStatus':
    case 'status':
      return PublishStatusRenderer;
    default:
      return null;
  }
};
