const DIFFUSION_LIST_ORDER_FIELDS = [
  {
    key: 'contentsOrder',
    label: "Ordre d'affichage des contenus",
    description: 'Attention: Incompatible avec le chargement au scroll',
    type: 'enum',
    values: [
      // TODO import from Front / ENTITY_COMPONENTS_MAP
      { value: 'list-order', label: "A la suite, dans l'ordre ci-dessus" },
      { value: 'sort-field', label: 'Re-trier tous les contenus par:' },
    ],
    required: true,
    default: 'list-order',
    mode: 'radio',
  },
  {
    label: 'Trier les contenu par',
    key: 'globalSortBy',
    type: 'enum',
    values: [
      // TODO import from Front / ENTITY_COMPONENTS_MAP
      { value: 'publishTs', label: 'Date de publication' },
      { value: 'eventTs', label: 'Date du contenu' },
      { value: '_stats.viewCount', label: 'Nombre de vues' },
    ],
    required: true,
    condition: { f$: "obj.contentsOrder !== 'list-order'" },
  },
  {
    key: 'sortOrder',
    label: 'Sens du tri',
    type: 'enum',
    required: true,
    condition: { f$: "obj.contentsOrder !== 'list-order'" },
    default: -1,
    values: [
      {
        value: 1,
        label: 'Croissant',
      },
      {
        value: -1,
        label: 'Décroissant',
      },
    ],
  },
];

export default DIFFUSION_LIST_ORDER_FIELDS;
