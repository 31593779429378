import * as React from 'react';
import truncateHtml from 'truncate-html';

export default function RichTextRenderer({
  mode,
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (mode !== 'cell') return null;
  if (!value) return null;

  const html = value.html || value;
  if (!html) return null;

  return (
    <div
      className="quote-html"
      dangerouslySetInnerHTML={{
        __html: truncateHtml(html, 100) || '',
      }}
    />
  );
}
