/* eslint-disable no-new-func */

import React from 'react';

import InstanceStore from 'src/stores/InstanceStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import store from 'store';

import CollectionTable from '../../../../Collection/ListTab';
import { Button } from 'src/components';
import { getSchemaFetchPath } from 'src/helpers/url';

export default class ExecuteOnCollectionTab extends ComponentBase {
  store = InstanceStore;

  didMount() {
    this.observeProp(
      'schema',
      (schema) =>
        console.log(
          'did mou or schem',
          schema._id,
          store.get(schema._id + '_list_execute_request')
        ) ||
        this.setState({
          request: store.get(schema._id + '_list_execute_request') || '{\n\n}',
          command:
            store.get(schema._id + '_list_execute_command') ||
            '{\n  $set: {\n\n  }\n}',
        })
    );
  }

  execute = () => {
    if (!this.checkErrors()) {
      let { request, command, instanceName } = this.state;
      let { schema } = this.props;

      this.setState({ result: null, execErrors: null });

      API.post(
        getSchemaFetchPath(instanceName, schema, {
          customDataPath: 'tools/execute',
        }),
        {
          request: Function('return (' + request + ')')(),
          command: Function('return (' + command + ')')(),
          limit: 1000, // TODO pagination
        }
      )
        .then((result) => this.setState({ result }))
        .catch((execErrors) => this.setState({ execErrors }));
    }
  };

  search = () => {
    if (!this.checkErrors()) {
      let { request, instanceName } = this.state;
      let { schema } = this.props;

      this.setState({ result: null, execErrors: null });

      API.post(
        getSchemaFetchPath(instanceName, schema, {
          customDataPath: 'execute',
        }),
        {
          request: Function('return (' + request + ')')(),
        }
      )
        .then((result) => this.setState({ searchResults: result }))
        .catch((execErrors) => this.setState({ execErrors }));
    }
  };

  onSet(param, value) {
    this.setState(
      {
        [param]: value,
      },
      () => {
        this.setState({ errors: this.checkErrors() });
      }
    );

    store.set(this.props.schema._id + '_list_execute_' + param, value);
  }

  checkErrors() {
    let { request, command } = this.state;

    try {
      request && Function('return (' + request + ')');
    } catch (e) {
      return 'Erreur dans la requête :\n' + e.toString();
    }

    try {
      command && Function('return (' + command + ')');
    } catch (e) {
      return 'Erreur dans la commande :\n' + e.toString();
    }

    return false;
  }

  render() {
    let { schema, resourcePathname } = this.props;
    let { result, errors, execErrors, searchResults } = this.state;

    return (
      <div className="compact-form">
        <div className="FormBase-title">
          <h3>Exécuter</h3>
        </div>
        <div>
          <textarea
            style={{ width: '95%' }}
            rows="10"
            value={this.state.request}
            onChange={(e) => this.onSet('request', e.target.value)}
          />
        </div>

        <textarea
          style={{ width: '95%' }}
          rows="10"
          value={this.state.command}
          onChange={(e) => this.onSet('command', e.target.value)}
        />

        {errors && <pre style={{ color: 'red' }}>{errors}</pre>}

        <Button onClick={this.execute} style={{ marginRight: 10 }}>
          Exécuter
        </Button>
        <Button onClick={this.search}>Chercher</Button>

        {result && (
          <pre style={{ color: 'green' }}>
            {JSON.stringify(result, null, 4)}
          </pre>
        )}

        {execErrors && (
          <pre style={{ color: 'darkred' }}>{execErrors.message}</pre>
        )}

        {searchResults && (
          <CollectionTable
            type={schema._id}
            data={searchResults}
            schema={schema}
            resourcePathname={resourcePathname}
          />
        )}
      </div>
    );
  }
}
