import * as React from 'react';

import { APIContext } from 'src/pages/contexts';

export default function ImageRenderer({
  mode,
  value: imagePath,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const { getImageUrl } = React.useContext(APIContext);

  return <img src={getImageUrl(imagePath)} alt={resource.label} />;
}
