// @flow
import * as React from 'react';
import moment from 'moment';

import { type Datasets } from 'src/components/stats/types';

import { Chart } from 'src/components';

type Props = {|
  timeSeries: Datasets,
  startDate: moment,
  endDate: moment,
  period: string,
  title: string,
|};

export default function TournamentStatsPage({
  timeSeries,
  startDate,
  endDate,
  period,
  title,
}: Props): React.Node {
  return (
    <div className="chart-statistics-page">
      <Chart
        startDate={startDate.toDate()}
        endDate={endDate.toDate()}
        period={period}
        periodTitle="jour"
        title={title}
        datasets={timeSeries}
        loading={false}
        withFooter={false}
      />
    </div>
  );
}
