import React from 'react';
import _ from 'lodash';

import ComponentBase from 'src/utils/ComponentBase';
import { Tooltipped } from 'src/utils/Tooltipped';

import { Button } from './';

import 'rc-tooltip/assets/bootstrap_white.css';

/**
 props:
 - value
 - options = array of {label, value [, description]}
 - onChange
 */
export default class DropDown extends ComponentBase {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      index: null,
    };
    this.dropDownEl = null;
    this.searchTxt = '';
  }

  fixPosition() {
    if (this.dropDownEl) {
      let screenW = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );

      let rect = this.dropDownEl.getBoundingClientRect();

      if (rect.right > screenW && rect.x - rect.width > 0) {
        this.dropDownEl.className = this.dropDownEl.className
          .replace(' DropDown--left', '')
          .concat(' DropDown--right');
      } else if (rect.left < 0) {
        this.dropDownEl.className = this.dropDownEl.className
          .replace(' DropDown--right', '')
          .concat(' DropDown--left');
      }
    }
  }

  didUpdate() {
    this.fixPosition();
  }

  didMount() {
    this.fixPosition();
    this.observeProp('options', (options) => {
      this.setState({ index: null });
      this.setIndexFromValue();
      this.searchTxt = '';
    });

    this.observeProp('value', () => {
      this.setIndexFromValue();
      this.searchTxt = '';
    });
  }

  setIndexFromValue() {
    let { options, value } = this.props;
    if (options && !_.isNil(value)) {
      this.setState({
        index: _.findIndex(options, (o) => o && o.value === value),
      });
    }
  }

  onSelectValue = (value) => {
    this.onClose();
    this.props.onChange(value);
  };

  onOpen = () => {
    this.setState({ open: true });
    // method 1 - disabled - window.addEventListener("click", this.onClose, false);
  };

  onClose = () => {
    this.setState({ open: false });
    // method 1 - disabled - window.removeEventListener("click", this.onClose);
  };

  moveIndex(inc) {
    let { index } = this.state;
    let count = _.get(this.props, 'options.length');
    this.setState({
      index: index === null ? 0 : index + inc + (count % count),
    });
    this.searchTxt = '';
  }

  onKeyDown = (e) => {
    let { index } = this.state;
    if (e.key === 'ArrowDown') {
      this.moveIndex(1);
    } else if (e.key === 'ArrowUp') {
      this.moveIndex(-1);
    } else if (e.key === 'Enter') {
      this.props.onChange(this.props.options[index].value);
      this.onClose();
    } else if (e.key.length === 1) {
      // good enough for now
      this.searchTxt += e.key.toLowerCase();

      let i = _.findIndex(
        this.props.options,
        (o) =>
          o &&
          o.value &&
          o.value.toString().toLowerCase().startsWith(this.searchTxt)
      );
      if (i) this.setState({ index: i });

      this.setTimer(() => (this.searchTxt = ''), 2000);
    } else {
      return;
    }
    e.preventDefault();
  };

  onFocus = (e) => {
    console.debug('onFocus');
    this.onOpen();
  };

  onBlur = (e) => {
    console.debug('onBlur');
    this.onClose();
    // onEndEdit
  };

  /* // method 1 - disabled
    willUnmount() {
        window.removeEventListener("click", this.onClose);
    }*/

  render() {
    let { options, value, className, tooltip, size, style, color } = this.props;
    let { open } = this.state;

    // eslint-disable-next-line eqeqeq
    let currentOption = _.find(options, (o) => o && o.value == value);
    let currentLabel = (currentOption && currentOption.label) || value;

    return (
      <Tooltipped tooltip={tooltip}>
        <span className={'DropDown' + (open ? ' DropDown--open' : '')}>
          {open && (
            <div
              className="DropDown-overlay"
              onClick={
                this.onClose // method 2
              }
            />
          )}

          <Button
            tabIndex={0}
            onClick={!open ? this.onOpen : this.onClose}
            size={size}
            style={style}
            color={color}
          >
            {currentLabel}
          </Button>

          {open && (
            <span className={'DropDown-drop' + (className || '')}>
              <div
                className="DropDown-list"
                ref={(el) => (this.dropDownEl = el)}
              >
                {options &&
                  options.map((item, i) => (
                    <div
                      className={
                        'DropDown-list-item' +
                        (i === this.state.index ? ' current' : '')
                      }
                      key={item.value + '_' + i}
                      // because onMouseDown is executed before onBlur (but not onClick)
                      //onMouseDown={e => (e.button===0) && stopPropagation(this.onSelectValue, item.value)(e)}
                      onMouseDown={(e) =>
                        e.button === 0 && this.onSelectValue(item.value)
                      }
                      // ? onTouchStart={stopPropagation(this.onSelectValue, item.value)}
                    >
                      {item.label} {item.description}
                    </div>
                  ))}
              </div>
            </span>
          )}
        </span>
      </Tooltipped>
    );
  }
}
