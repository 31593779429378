// @flow
import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { values, sortBy } from 'lodash';

import type { ResourceType } from 'src/types/models';
import type { Section, Filter } from 'src/types/routing';

import sectionHardcodedRoutes from '../../SectionRouter/hardcodedRoutes';
import SECTIONS from 'src/pages/constants/sections';
import { useGlobal } from 'src/hooks';
import { toPath } from 'src/helpers/string';
import { fetchCountByCollection, buildSchemaRouteItems } from './helpers';

import { Tag } from 'src/components';
import SidebarItem from './Item';

export type CountByCollection = { [type: ResourceType]: number };
export type RouteItem = {|
  pathname: string,
  label: string,
  exact?: boolean,
  count?: number,
  query?: Object,
  filters?: Array<Filter>,
|};

export default function Sidebar(): React.Node {
  const { globalSchema, siteId } = useGlobal();
  const location = useLocation();
  const pathSectionPart = location.pathname.split('/')[2];
  const currentSection = values(SECTIONS).find((section: Section) =>
    section.pathname.includes(pathSectionPart)
  );
  const [
    countByCollection,
    setCountByCollection,
  ] = React.useState<CountByCollection>({});

  // Fetch counts for all displayed items
  React.useEffect(() => {
    const loadCollections = async () => {
      const count: CountByCollection = await fetchCountByCollection(siteId);
      setCountByCollection(count);
    };

    loadCollections();
  }, [siteId]);

  // If user landed to unknown section
  if (!currentSection) {
    return <Redirect to={`/${siteId}`} />;
  }

  const hardcodedRoutes = ((sectionHardcodedRoutes[currentSection.id] ||
    []: any): Array<RouteItem>);

  const displayedSchemaRoutes = buildSchemaRouteItems(currentSection, {
    globalSchema,
    siteId,
    countByCollection,
  });
  const routes = sortBy(
    [...hardcodedRoutes, ...displayedSchemaRoutes],
    (route) => currentSection.order[route.pathname]
  );

  // If user landed on the root path of the section, redirect to first item
  if (location.pathname === toPath(`/${siteId}${currentSection.pathname}`)) {
    const firstRoute = routes[0];
    return firstRoute ? (
      <Redirect
        to={toPath(
          `/${siteId}${currentSection.pathname}/${firstRoute.pathname}`
        )}
      />
    ) : null;
  }

  const { sidebarShortcuts: shortcuts } = currentSection;

  return (
    <nav className="menu">
      <ul className="menu-list">
        {shortcuts && shortcuts.length > 0 && (
          <>
            <p className="menu-label">Raccourcis</p>
            {shortcuts.map((shortcut) => (
              <SidebarItem
                pathname={toPath(
                  `/${siteId}${currentSection.pathname}/${shortcut.pathname}`
                )}
                title={shortcut.label}
                key={shortcut.label}
              >
                {shortcut.label}
              </SidebarItem>
            ))}
            <p className="menu-label">General</p>
          </>
        )}

        {routes.map((route, i) => {
          return (
            <SidebarItem
              pathname={toPath(
                `/${siteId}${currentSection.pathname}/${route.pathname}`
              )}
              query={route.query}
              title={route.label}
              key={route.label}
              filters={route.filters}
            >
              {route.label}
              {(!!route.count || route.count === 0) && (
                <Tag style={{ marginLeft: 10 }} isRounded>
                  {route.count}
                </Tag>
              )}
            </SidebarItem>
          );
        })}
      </ul>
    </nav>
  );
}
