// @flow
import * as React from 'react';

import { headerHeightState } from 'src/recoil/atoms';
import { withRecoilState } from 'src/utils/HOC';

type OwnProps = {|
  children: React.Node,
|};

type Props = {|
  ...OwnProps,
  headerHeight: number,
|};

function MainContainer({ children, headerHeight }: Props) {
  return (
    <main
      className="app-container"
      style={{ height: `calc(100vh - ${headerHeight}px)` }}
    >
      {children}
    </main>
  );
}

export default (withRecoilState(
  'headerHeight',
  headerHeightState
)(MainContainer): React.ComponentType<OwnProps>);
