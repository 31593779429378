const EntityVideo = {
  _id: 'contentVideo',
  label: 'Video du contenu',
  icon: 'play-circle',
  frontComponent: 'ContentVideo',
  optionsSchema: [
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      values: [
        { label: '16/9', value: 16 / 9 },
        { label: '4/3', value: 4 / 3 },
        { label: 'cinemascope', value: 2.35 / 1 },
      ],
    },
    {
      key: 'pip',
      label: 'Player toujours visible (mini-player sur le scroll)',
      type: 'bool',
      default: false,
    },
    {
      key: 'pipPosition',
      label: 'Position du mini-player',
      type: 'enum',
      values: [
        { label: 'haut gauche', value: 'top-left' },
        { label: 'haut droit', value: 'top-right' },
        { label: 'bas gauche', value: 'bottom-left' },
        { label: 'bas droit', value: 'bottom-right' },
      ],
      condition: { f$: 'obj.pip' },
    },
    {
      key: 'pipTransparency',
      label: 'Transparence du mini-player',
      type: 'int',
      min: 0,
      max: 100,
      mode: 'slider', // TODO
      condition: { f$: 'obj.pip' },
    },
    {
      key: 'pipSize',
      label: 'Taille du mini-player (TODO)',
      type: 'enum',
      values: [
        { label: 'petit', value: 'small' },
        { label: 'grand', value: 'big' },
      ],
      condition: { f$: 'obj.pip' },
    },
    {
      key: 'autoplay',
      label: 'Démarrer la lecture automatiquement (si possible)',
      type: 'bool',
      default: false,
    },
    {
      key: 'registerButtonInterval',
      label: "Intervalle d'affichage du bouton s'abonner (en s)",
      type: 'int',
      min: 0,
    },
    {
      key: 'registerButtonDuration',
      label: "Durée d'affichage du bouton s'abonner (en s)",
      type: 'int',
      min: 0,
      required: true,
      condition: { f$: 'obj.registerButtonInterval' },
    },
    {
      key: 'registerButtonLabel',
      label: "Libellé du bouton s'abonner (en s)",
      type: 'string',
      required: true,
      condition: { f$: 'obj.registerButtonInterval' },
    },
    {
      key: 'displayImageIfNoVideo',
      label: "Afficher l'image du contenu à la place si pas de vidéo",
      type: 'bool',
      default: true,
    },
    {
      key: 'autoplayNextEpisode',
      label: "Passer automatiquement à l'épisode suivant (séries uniquement)",
      type: 'bool',
      default: false,
    },
    {
      key: 'skipOpeningLabel',
      label: 'Afficher le bouton pour passer le générique avec ce libellé',
      type: 'string',
    },
  ],
  forEntity: true,
};

export default EntityVideo;
