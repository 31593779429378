// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { BulmaColor, BulmaSize, Style } from 'src/components/types';

import { bulmaColorClassName, bulmaSizeClassName } from 'src/helpers/style';

export type InputColor = BulmaColor;
export type InputType = 'text' | 'password' | 'email' | 'tel' | 'number';
export type InputSize = BulmaSize;
export type InputState = 'normal' | 'hovered' | 'focused';

// Though passed through default props, we have to make them maybe type
export type StylingProps = {|
  color?: InputColor,
  size?: InputSize,
  state?: InputState,
  isRounded?: boolean,

  additionalClassName?: string,
  style?: Style,
|};

export type Props<T> = {|
  value: ?T,
  disabled?: boolean,
  onChange?: (?T) => any,
  placeholder?: string,
  type?: InputType,
  autoFocus?: boolean,
  name?: string,
  innerRef?: any,
  isLoading?: boolean,
  readOnly?: boolean,
  id?: string,
  min?: number,
  max?: number,
  step?: number,

  onEnterKeyPress?: () => any,
  onEscapeKeyPress?: () => any,

  ...StylingProps,
  onBlur?: () => any,
  onFocus?: (e: SyntheticInputEvent<HTMLInputElement>) => any,
|};

export default function Input<T>({
  color,
  size,
  state,
  additionalClassName,
  onEnterKeyPress,
  onEscapeKeyPress,
  onChange,
  innerRef,
  isRounded,
  isLoading,
  readOnly,
  min,
  max,

  ...otherProps
}: Props<T>): React.Node {
  const className = classnames(
    inputClassName({
      state,
      color,
      size,
      isRounded,
      additionalClassName,
    }),
    {
      'is-loading': isLoading,
    }
  );

  return (
    <input
      {...otherProps}
      className={className}
      minLength={min}
      maxLength={max}
      onKeyDown={(e) => {
        onEnterKeyPress && e.keyCode === 13 && onEnterKeyPress();
        onEscapeKeyPress && e.keyCode === 27 && onEscapeKeyPress();
      }}
      ref={innerRef}
      onChange={(e) => onChange && onChange(e.target.value)}
      readOnly={readOnly}
    />
  );
}

Input.defaultProps = {
  disabled: false,
  type: 'text',
  size: 'normal',
  state: 'normal',
  isRounded: false,
  isLoading: false,
  readOnly: false,
};

type InputClassNameParam = {|
  color?: InputColor,
  size?: InputSize,
  state?: InputState,
  additionalClassName?: string,
  isRounded?: boolean,
|};
export const inputClassName = ({
  color,
  size,
  state,
  additionalClassName,
  isRounded,
}: InputClassNameParam): string => {
  const stateClassName = classnames({
    'is-hovered': state === 'hovered',
    'is-focused': state === 'focused',
  });

  return classnames(
    'input',
    additionalClassName,
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    stateClassName,
    {
      'is-rounded': isRounded,
    }
  );
};
