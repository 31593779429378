// @flow
import * as React from 'react';

import type { ResourcePropertySchema, Resource } from 'src/types';

import invariant from 'src/helpers/invariant';

import PropertiesListEditor from './PropertiesListEditor';
import PropertyEditor from '../../../PropertyEditor';

type Props = {|
  resource: Resource,
  propertySchemas: Array<ResourcePropertySchema>,
  isSidebar?: boolean,
  onPropertyChange: (
    propertySchema: ResourcePropertySchema,
    newPropertyValue: any
  ) => any,
  disabled?: boolean,
|};

export default function SchemaEditor({
  resource: schema,
  propertySchemas,
  onPropertyChange,
  disabled,
}: Props): React.Node {
  const simpleProperties = propertySchemas.filter(
    (propertySchema) => propertySchema.key !== 'propertiesList'
  );

  const propertiesListSchema = propertySchemas.find(
    (propertySchema) => propertySchema.key === 'propertiesList'
  );
  invariant(
    !!propertiesListSchema,
    'property propertiesList is always defined in a schema'
  );

  return (
    <>
      {simpleProperties.map((propertySchema) => (
        <PropertyEditor
          key={propertySchema.key}
          propertySchema={propertySchema}
          value={schema[propertySchema.key]}
          onChange={onPropertyChange}
          disabled={disabled}
        />
      ))}

      <PropertiesListEditor
        value={schema[propertiesListSchema.key]}
        schema={propertiesListSchema}
        onChange={onPropertyChange}
        disabled={disabled}
      />
    </>
  );
}
