import * as React from 'react';

import { Tag } from 'src/components';

export default function ListRenderer({
  mode = 'cell',
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!value) return null;

  const list = Array.isArray(value) ? value : [value];

  return list
    .filter((item) => !!item)
    .map((item) => <Tag>{JSON.stringify(item)}</Tag>);
}
