// @flow
import * as React from 'react';

import { useResourceContext } from 'src/hooks';

import getCustomShowComponent from './resourceSpecific/show';

import ResourceEditor from 'src/pages/components/resource/ResourceEditor';

export default function ShowTabContent(): React.Node {
  const { resource } = useResourceContext();
  const CustomShowComponent = getCustomShowComponent(resource._cls);

  if (CustomShowComponent) return <CustomShowComponent />;

  return <ResourceEditor disabled />;
}
