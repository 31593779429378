const EntityProperty = {
  _id: 'field',
  label: 'Champ du contenu',
  icon: 'file-image',
  frontComponent: 'EntityProperty',
  forEntity: true,
  optionsSchema: [
    {
      key: 'propertyRef',
      label: 'Afficher :',
      type: 'object',
      objectType: 'ObjectPropertyReference',
      default: true,
    },
    { key: 'prefix', label: 'Préfix', type: 'string' },
    { key: 'suffix', label: 'Suffixe', type: 'string' },
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'objet",
      type: 'bool',
      default: true,
    },
  ],
};

export default EntityProperty;
