// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';

import { Counter } from 'src/components';

type Props = {|
  propertySchema: ResourcePropertySchema,
  value: string,
|};

export default function StringCounter({
  propertySchema,
  value,
}: Props): React.Node {
  const {
    charactersCounter,
    wordsCounter,
    expectedCharactersCount,
    expectedWordsCount,
  } = propertySchema;

  if (!charactersCounter && !wordsCounter) return null;

  const charactersCount = value?.length ?? 0;
  const wordsCount = value?.split(/\s+/).length ?? 0;

  return (
    <div className="string-length-counter">
      {charactersCounter && (
        <Counter
          title="caractères"
          count={charactersCount}
          target={expectedCharactersCount}
        />
      )}

      {wordsCounter && (
        <Counter title="mots" count={wordsCount} target={expectedWordsCount} />
      )}
    </div>
  );
}
