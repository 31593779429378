import config from 'src/helpers/api/config';

export const isDevelopment = () => config.PUF_ENV === 'dev';
export const isStaging = () => config.PUF_ENV === 'staging';
export const isPreProd = () => config.PUF_ENV === 'preprod';
export const isProd = () => config.PUF_ENV === 'prod';

export const debugLog = (...args) => {
  if (isDevelopment()) {
    console.log(...args);
  }
};
