// @flow
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';

import { useOutsideClickAlerter } from 'src/hooks';
import {
  inputClassName,
  type StylingProps as InputStylingProps,
} from './Input';

type TimeProps = {|
  showTimeSelect?: boolean,
  showTimeSelectOnly?: boolean,
  showFullFormat?: boolean,
  timeCaption?: string,
  timeIntervals?: number,
  showMonthDropdown?: boolean,
  showYearDropdown?: boolean,
|};

export type SharedWithTimePickerProps = {|
  value: ?Date,
  onChange: (?Date) => any,
  disabled?: boolean,
  placeholder?: string,
  autoFocus?: boolean,
  openToDate?: Date,
  withPortal?: boolean,
  onBlur?: () => any,
  name?: string,
  onChangeRaw?: (Event) => any,
  ...InputStylingProps,
|};

export type Props = {|
  ...SharedWithTimePickerProps,
  minDate?: ?Date,
  maxDate?: ?Date,
  ...TimeProps,
  ...InputStylingProps,
  allowTyping: boolean,
  readOnly?: boolean,
|};

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'dd/MM/yyyy';
const FULL_FORMAT = 'dd/MM/yyyy HH:mm:ss'; // if used, you can't allow users to manual type the date

/**
 * @deprecated Bad UX (difficult to type in) - please use InputTimestamp / OldDatePicker instead.
 */
export default function DatePicker({
  value,
  minDate,
  maxDate,
  onBlur,
  autoFocus,
  placeholder,
  onChange,
  withPortal,
  showTimeSelect,
  showTimeSelectOnly,
  showFullFormat,
  timeCaption,
  timeIntervals,
  disabled,
  openToDate,
  name,
  style,
  onChangeRaw,
  showMonthDropdown,
  showYearDropdown,
  allowTyping,
  readOnly,
  ...otherProps
}: Props): React.Node {
  const wrapperRef = React.useRef<null | HTMLElement>(null);
  const timeFormat = TIME_FORMAT;
  const dateFormat = showTimeSelectOnly
    ? TIME_FORMAT
    : showFullFormat
    ? FULL_FORMAT
    : DATE_FORMAT;

  useOutsideClickAlerter(wrapperRef, () => {
    !!onBlur && onBlur();
  });

  return (
    <div
      className="react-datepicker-wrapper"
      ref={(node) => (wrapperRef.current = node)}
      style={style}
    >
      <ReactDatePicker
        name={name || 'date'}
        className={inputClassName(otherProps)}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholderText={placeholder || 'Selectionnez une date'}
        minDate={minDate}
        maxDate={maxDate}
        selected={value}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        calendarClassName={
          !showTimeSelectOnly && showTimeSelect ? 'with-time-select' : ''
        }
        showTimeSelectOnly={showTimeSelectOnly}
        dateFormat={dateFormat}
        timeCaption={timeCaption}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        withPortal={withPortal}
        portalId={withPortal ? 'datepicker-portal' : undefined}
        openToDate={openToDate}
        autoComplete="off"
        onChangeRaw={allowTyping ? onChangeRaw : (e) => e.preventDefault()}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        readOnly={readOnly}
      />
    </div>
  );
}

DatePicker.defaultProps = {
  showTimeSelect: false,
  showTimeSelectOnly: false,
  allowTyping: true,
};
