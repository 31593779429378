// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { BulmaColor, BulmaShadesOfGrey, BulmaSize, Style } from './types';

type Props = {|
  children: React.Node,
  color?: BulmaColor | BulmaShadesOfGrey,
  size?: BulmaSize,
  isRounded?: boolean,
  onDelete?: () => any,
  light?: boolean,
  additionalClassName?: string,
  style?: Style,
  id?: string,
|};

export default function Tag({
  children,
  color,
  size,
  light,
  isRounded,
  onDelete,
  additionalClassName,
  style,
  id,
}: Props): React.Node {
  const colorClassName = classnames({
    'is-link': color === 'link',
    'is-primary': color === 'primary',
    'is-info': color === 'info',
    'is-success': color === 'success',
    'is-warning': color === 'warning',
    'is-danger': color === 'danger',
    'is-black': color === 'black',
    'is-white': color === 'white',
    'is-light': light,
    'is-dark': color === 'dark',
  });

  // Small size is not supported for tags
  const sizeClassName = classnames({
    'is-normal': size === 'normal',
    'is-medium': size === 'medium',
    'is-large': size === 'large',
  });

  const className = classnames(
    'tag',
    additionalClassName,
    colorClassName,
    sizeClassName,
    {
      'is-rounded': !!isRounded,
      'is-delete': !!onDelete,
    }
  );

  if (!!onDelete) {
    return (
      <a onClick={onDelete} className={className} style={style} id={id}>
        {children}
      </a>
    );
  }

  return (
    <span onClick={onDelete} className={className} style={style} id={id}>
      {children}
    </span>
  );
}

Tag.defaultProps = {
  size: 'normal',
  light: false,
};

type TagListProps = {|
  children: React.Node,
  hasAddons?: boolean,
  additionalClassName?: string,
  style?: Style,
|};

export function TagList({
  children,
  hasAddons,
  additionalClassName,
  style,
}: TagListProps): React.Node {
  const className = classnames('tags', additionalClassName, {
    'has-addons': !!hasAddons,
  });

  return <div className={className} style={style}></div>;
}

TagList.defaultProps = {
  hasAddons: false,
};
