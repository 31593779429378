import React from 'react';

import { Tag } from 'src/components';

export default function CategoryRenderer({
  mode,
  value: categories,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!categories || categories.length === 0) return null;

  return (
    <div className="categories-renderer">
      {categories.map((category) => (
        <Tag
          color="dark"
          style={{ marginRight: 5, marginBottom: 3 }}
          key={category}
        >
          {category}
        </Tag>
      ))}
    </div>
  );
}
