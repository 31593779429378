import React from 'react';
import { DiffLines } from 'react-diff-components';
import _ from 'lodash';

import config from 'src/helpers/api/config';
import { useGlobal, useResourceContext } from 'src/hooks';
import API from 'src/helpers/api/API';

import {
  Modal,
  Select,
  Field,
  Control,
  Label,
  Flex,
  Button,
  Notification,
} from 'src/components';

const getAuthorizedCopyEnvironments = () => {
  switch (config.PUF_ENV) {
    case 'dev':
      return ['dev', 'staging'];
    case 'staging':
      return ['staging', 'prod'];
    case 'prod':
      return ['staging', 'prod'];
    default:
      return [];
  }
};

const sortObjectKeys = (obj) => _(obj).toPairs().sortBy(0).fromPairs().value();

const environmentLabel = {
  dev: 'Local (développement)',
  staging: 'Staging',
  prod: 'Production',
  preprod: 'Pré-production',
};

export default function CopyResourceModal({ open, onClose }) {
  const { siteId, sites } = useGlobal();
  const { resource, apiPath } = useResourceContext();

  const authorizedCopyEnvironments = getAuthorizedCopyEnvironments();

  const [targetEnv, setTargetEnv] = React.useState(
    authorizedCopyEnvironments[0]
  );
  const [targetSiteId, setTargetSiteId] = React.useState(siteId);
  const [targetResource, setTargetResource] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [remoteResourceLink, setRemoteResourceLink] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [warning, setWarning] = React.useState(null);

  const fetchTargetResource = React.useCallback(async () => {
    setError(null);
    setTargetResource(null);
    setWarning(null);
    setSuccess(false);

    try {
      // For now the route is called "compare" as it will probably evolve to something
      // More elaborated later
      const url = `${apiPath}/compare`;
      const fetchedResource = await API.get(url, {
        targetEnv,
        targetSiteId,
        resourceId: resource._id,
      });

      if (!fetchedResource) {
        setWarning("La ressource n'a pas été trouvée, elle sera donc créée");
        return;
      }

      setTargetResource(fetchedResource);
    } catch (e) {
      console.error(e);

      if (e.message === 'Request failed with status code 404') {
        setWarning("La ressource n'a pas été trouvée, elle sera donc créée");
        return;
      }

      setError(e.message);
    }
  }, [targetEnv, targetSiteId, resource._id]);

  React.useEffect(() => {
    const mustFetch =
      targetEnv &&
      targetSiteId &&
      (targetEnv !== config.PUF_ENV || siteId !== targetSiteId);

    if (!mustFetch) return;

    fetchTargetResource();
  }, [targetEnv, targetSiteId, siteId]);

  const copyToTargetEnv = React.useCallback(
    async (resource, targetResource) => {
      setSuccess(false);
      setError(null);

      try {
        const url = `${apiPath}/copyTo`;
        const { resource: fetchedResource, link } = await API.post(url, {
          targetEnv,
          targetSiteId,
          resourceId: resource._id,
        });

        setTargetResource(fetchedResource);
        setRemoteResourceLink(link);
        setSuccess(true);
      } catch (e) {
        console.error(e);
        setError(e.message);
      }
    },
    [targetEnv, targetSiteId, onClose]
  );

  const canSubmit =
    targetEnv &&
    targetSiteId &&
    (targetEnv !== config.PUF_ENV || siteId !== targetSiteId) &&
    resource &&
    (targetResource || warning);

  return (
    <Modal
      modal={false}
      open={open}
      onClose={onClose}
      title="Copier la resource vers :"
    >
      <Field isHorizontal>
        <Label>Environnement de destination</Label>
        <Control style={{ minWidth: 200 }}>
          <Select
            value={
              targetEnv
                ? {
                    value: targetEnv,
                    label: environmentLabel[targetEnv],
                  }
                : null
            }
            options={authorizedCopyEnvironments.map((env) => ({
              value: env,
              label: environmentLabel[env],
            }))}
            disabled={authorizedCopyEnvironments.length < 2}
            onChange={(option) => setTargetEnv(option.value)}
            placeholder="Sélectionner l'environnement de destination"
          />
        </Control>
      </Field>

      <Field isHorizontal>
        <Label>Site de destination</Label>
        <Control style={{ minWidth: 200 }}>
          <Select
            value={
              targetSiteId
                ? {
                    value: targetSiteId,
                    label: targetSiteId,
                  }
                : null
            }
            options={sites.map((site) => ({
              value: site._id,
              label: site._id,
            }))}
            disabled={sites.length < 2}
            onChange={(option) => setTargetSiteId(option.value)}
            placeholder="Sélectionner le site de destination"
          />
        </Control>
      </Field>

      {!!targetResource && (
        <DiffLines
          from={JSON.stringify(sortObjectKeys(targetResource), null, 4) || ''}
          to={JSON.stringify(sortObjectKeys(resource), null, 4) || ''}
          type="chars"
        />
      )}

      {success && remoteResourceLink && (
        <Notification color="success" style={{ margin: '10px 0' }}>
          La ressource a bien été sauvegardée sur l'environnement "{targetEnv}",
          vous pouvez la consultez à cette adresse{' '}
          <a href={remoteResourceLink} target="_blank" rel="noreferrer">
            {remoteResourceLink}
          </a>
        </Notification>
      )}

      {error && (
        <Notification color="danger" style={{ margin: '10px 0' }}>
          {error}
        </Notification>
      )}

      {warning && (
        <Notification color="info" style={{ margin: '10px 0' }}>
          {warning}
        </Notification>
      )}

      <Flex style={{ justifyContent: 'flex-end', marginTop: 10 }}>
        {!success ? (
          <>
            <Button style={{ marginRight: 10 }} onClick={onClose}>
              Annuler
            </Button>

            <Button
              color={success ? undefined : 'success'}
              onClick={() =>
                canSubmit && copyToTargetEnv(resource, targetResource)
              }
              disabled={!canSubmit}
            >
              {success ? 'Fermer' : 'Valider'}
            </Button>
          </>
        ) : (
          <Button onClick={onClose}>Fermer</Button>
        )}
      </Flex>
    </Modal>
  );
}
