// @flow
// Comes from https://wikiki.github.io/form/checkradio/
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import type {
  CheckboxColor,
  CheckboxType,
  CheckboxSize,
  CheckboxDirection,
} from 'src/components/types';

import classnames from 'classnames';
import { bulmaColorClassName, bulmaSizeClassName } from 'src/helpers/style';

export type Props = {|
  isChecked: boolean,
  onChange?: (boolean) => any,
  disabled?: boolean,
  id?: string,
  label?: string,

  color?: CheckboxColor,
  size?: CheckboxSize,
  type?: CheckboxType,
  direction?: CheckboxDirection,

  additionalClassName?: string,
  style?: {},
|};

function Checkbox({
  isChecked,
  onChange,
  disabled,
  id,
  label,
  size,
  color,
  additionalClassName,
  style,
  direction,
}: Props): React.Node {
  const checkboxId = React.useMemo(uuidv4);

  const className = classnames(
    'is-checkradio',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    additionalClassName,
    {
      'is-rtl': direction === 'right-to-left',
    }
  );

  return (
    <>
      <input
        type="checkbox"
        className={className}
        checked={isChecked ? 'checked' : ''}
        id={id || checkboxId}
        disabled={disabled}
        onChange={() => onChange && onChange(!isChecked)}
      />
      <label className="checkbox" htmlFor={id || checkboxId}>
        {label || ' '}
      </label>
    </>
  );
}

Checkbox.defaultProps = {
  type: 'checkbox',
  size: 'normal',
  direction: 'left-to-right',
};

export default Checkbox;
