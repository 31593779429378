const HARDCODED_SCHEMAS = {
  DiffusionList: {
    _id: 'DiffusionList',
    label: 'Contenus à afficher',
    type: 'list',
    itemType: ['ContentSchedule', 'ContentRequester'],
  },
  Font: {
    _id: 'Font',
    label: 'Police de caractères',
    type: 'object',
    propertiesList: [
      {
        key: 'isImported',
        type: 'bool',
        label: 'Importer une font Google',
        // if key not provided, creates a qstring '... should have a unique "key" prop' error in Tooltip
        description: (
          <span>
            Voir ici{' '}
            <a
              key="x"
              href="https://fonts.google.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://fonts.google.com/
            </a>
          </span>
        ),
        column: true,
        required: true,
        default: false,
      },
      {
        required: true,
        key: 'fontFamily',
        type: (obj) => (obj && obj.isImported ? 'string' : 'enum'),
        label: 'Police',
        description:
          'URL du CSS associé, ex pour Google Fonts: "\'Roboto\', sans-serif"',
        convert: (obj) =>
          obj &&
          obj.isImported &&
          ((value) => {
            let r = /font-family\s*:\s*(.*);?/gi.exec(value);
            return r ? r[1] : value;
          }),
        values: (obj) =>
          obj &&
          !obj.isImported && [
            {
              value: 'Arial, Helvetica',
              label: 'Arial',
            },
            {
              value: 'Times New Roman, Times',
              label: 'Times',
            },
            {
              value: 'Courier New, Courier',
              label: 'Courier',
            },
            {
              value: 'Palatino',
              label: 'Palatino',
            },
            {
              value: 'Garamond',
              label: 'Garamond',
            },
            {
              value: 'Bookman',
              label: 'Bookman',
            },
            {
              value: 'Avant Garde',
              label: 'Avant Garde',
            },
            {
              value: 'Verdana',
              label: 'Verdana',
            },
            {
              value: 'Georgia',
              label: 'Georgia',
            },
            {
              value: 'Trebuchet MS',
              label: 'Trebuchet MS',
            },
            {
              value: 'Arial Black',
              label: 'Arial Black',
            },
            {
              value: 'Impact',
              label: 'Impact',
            },
          ],
      },
      {
        key: 'cssUrl',
        type: 'string',
        label: 'URL',

        column: true,
        condition: (obj) => obj && obj.isImported,
        convert: (obj) =>
          obj &&
          obj.isImported &&
          ((value) => {
            let r = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([ \w.,;@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi.exec(
              value
            );
            return r ? r[0] : value;
          }),
      },
    ],
  },
};

export default HARDCODED_SCHEMAS;
