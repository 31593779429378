// @flow
// https://fontawesome.com/icons?d=gallery&s=regular,solid&m=free

import type { Divider } from '../types';

const DIVIDERS: Array<Divider> = [
  {
    minSize: 0.5,
    cols: [6, 6],
  },
  {
    minSize: 0.5,
    cols: [8, 4],
  },
  {
    minSize: 0.5,
    cols: [4, 8],
  },
  {
    minSize: 0.5,
    cols: [3, 9],
  },
  {
    minSize: 0.5,
    cols: [9, 3],
  },
  {
    minSize: 1,
    cols: [3, 6, 3],
  },
  {
    minSize: 0.75,
    cols: [4, 4, 4],
  },
  {
    minSize: 0.75,
    cols: [3, 3, 3, 3],
  },
  {
    minSize: 1,
    cols: [2, 10],
  },
  {
    minSize: 1,
    cols: [10, 2],
  },
  {
    minSize: 1,
    cols: [2, 8, 2],
  },
  {
    minSize: 1,
    cols: [1, 10, 1],
  },
];

export default DIVIDERS;
