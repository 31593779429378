// @flow
import type { Tab } from '../';

import UserSubscriptionTab from './UserSubscriptionTab';

const userCustomTabs: Array<Tab> = [
  {
    to: 'stripe-subscriptions',
    icon: 'money-check-alt',
    label: 'Abo. Stripe',
    content: UserSubscriptionTab,
  },
];

export default userCustomTabs;
