const ContentDuration = {
  _id: 'contentDuration',
  frontComponent: 'ContentDuration',
  label: 'Durée du contenu',
  icon: 'stopwatch-20',
  optionsSchema: [
    {
      key: 'format',
      label: 'Format',
      type: 'enum',
      values: [
        { value: 'min', label: 'minutes (ex 84)' },
        { value: 'ms', label: "HH'MM (ex. 84'53)" },
        { value: 'hms', label: 'HH:MM:SS (ex. 01:24:53)' },
        { value: 'hm', label: 'HhMM (ex. 1h24)' },
      ],
      required: true, // unusual
    },
    { key: 'suffix', label: 'Suffixe', type: 'string' },
  ],
  forEntity: true,
  hasEntities: false,
};

export default ContentDuration;
