// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style, BulmaColor, BulmaSize } from '../types';

type Props = {|
  children: React.Node,
  color?: BulmaColor | 'dark',
  size?: BulmaSize,
  additionalClassName?: string,
  style?: Style,
|};

export default function Message({
  children,
  color,
  size,
  additionalClassName,
  style,
}: Props): React.Node {
  const sizeClassName = !!size ? `is-${size}` : '';
  const colorClassName = !!color ? `is-${color}` : '';
  const className = classnames(
    'message',
    colorClassName,
    sizeClassName,
    additionalClassName
  );

  return (
    <article className={className} style={style}>
      {children}
    </article>
  );
}

type MessageHeaderProps = {|
  children: React.Node,
  onClose?: () => any,
  additionalClassName?: string,
  style?: Style,
|};

export function MessageHeader({
  children,
  onClose,
  additionalClassName,
  style,
}: MessageHeaderProps): React.Node {
  const className = classnames('message-header', additionalClassName);

  return (
    <div className={className} style={style}>
      {children}
      {!!onClose && (
        <button
          className="delete"
          aria-label="delete"
          onClose={onClose}
        ></button>
      )}
    </div>
  );
}

type MessageBodyProps = {|
  children: React.Node,
  additionalClassName?: string,
  style?: Style,
|};

export function MessageBody({
  children,
  additionalClassName,
  style,
}: MessageBodyProps): React.Node {
  const className = classnames('message-body', additionalClassName);

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}
