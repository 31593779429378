import * as React from 'react';

import { asArray } from 'src/helpers/misc';

export default function ObjectPropertySchemaRenderer({
  mode,
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  const typeOrTypes =
    resource.objectType ||
    resource.itemType ||
    (resource.itemSchema &&
      (resource.itemSchema.objectType || resource.itemSchema.type));

  return (
    <div className="object-property-schema-renderer">
      <span className="property" title={resource.type}>
        <span className={'type ' + resource.type} />
      </span>
      <span className="FormSection-header-title" key="1">
        {resource.key} -{' '}
      </span>
      <span className="pre" key="2">
        {asArray(typeOrTypes).join('|')}
      </span>
      <span className="FormSection-header-title" key="3">
        <i>"{resource.label}"</i>
      </span>
    </div>
  );
}
