import { MODE_PROPERTY } from '../constants';

const ResumeSeriesPlayback = {
  _id: 'resumeSeriesPlayback',
  frontComponent: 'ResumeSeriesPlayback',
  label: 'Episode en cours',
  icon: 'play',
  optionsSchema: [
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        // TODO import from Front / ENTITY_COMPONENTS_MAP
        { value: 'content', label: 'Épisode en cours' },
        { value: 'series', label: 'Série' },
        { value: 'button', label: 'Bouton' },
      ],
      default: 'content',
    },
    {
      ...MODE_PROPERTY,
      label: "Mode d'affichage du contenu",
      key: 'contentMode',
      condition: { f$: 'obj.mode === "content"' },
    },
    {
      ...MODE_PROPERTY,
      label: "Mode d'affichage de la série",
      key: 'seriesMode',
      condition: { f$: 'obj.mode === "series"' },
    },
    {
      key: 'resumeLabel',
      label: "Libellé si en cours d'épisode",
      description: "utiliser [[n]] à la place du numéro d'épisode",
      type: 'string',
      default: "Reprendre l'épisode [[n]]",
      condition: { f$: 'obj.mode === "button"' },
    },
    {
      key: 'nextLabel',
      label: 'Libellé si prochain épisode',
      type: 'string',
      description: "utiliser [[n]] à la place du numéro d'épisode",
      default: "Lire l'épisode [[n]]",
      condition: { f$: 'obj.mode === "button"' },
    },
    {
      key: 'displayIfStart',
      label: "Afficher l'épisode 1 si pas d'épisode en cours",
      type: 'bool',
      default: true,
      condition: { f$: 'obj.mode === "button"' },
    },
    {
      key: 'startLabel',
      label: 'Libellé si aucun épisode en cours',
      type: 'string',
      description: "utiliser [[n]] à la place du numéro d'épisode",
      default: 'Regarder la série',
      condition: { f$: 'obj.mode === "button" && obj.displayIfStart' },
    },
  ],
  forPage: true,
  hasEntities: false,
};

export default ResumeSeriesPlayback;
