// @flow
import * as React from 'react';

import type { ResourceContextType } from 'src/pages/contexts/ResourceContext';
import type { Resource } from 'src/types/models';

import { ResourceContext } from 'src/pages/contexts/ResourceContext';
import invariant from 'src/helpers/invariant';

// RT <> ResourceType
export default function useResourceContext<
  RT: Resource
>(): ResourceContextType<RT> {
  const resourceContext = React.useContext(ResourceContext);

  invariant(!!resourceContext, 'Resource context should exist');

  return ((resourceContext: any): ResourceContextType<RT>);
}
