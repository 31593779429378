// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { ResourcePropertySchema } from 'src/types/models';

import { Text, Field, Label, Control } from 'src/components';
import EnumEditor from 'src/pages/components/resource/PropertyEditor/EnumEditor';
import NumberEditor from 'src/pages/components/resource/PropertyEditor/NumberEditor';
import StringEditor from 'src/pages/components/resource/PropertyEditor/StringEditor';
import BooleanEditor from 'src/pages/components/resource/PropertyEditor/BooleanEditor';
import DateEditor from 'src/pages/components/resource/PropertyEditor/DateEditor';

type Props = {|
  filterBy: any,
  propertySchema: ResourcePropertySchema,
  value: any,
|};

export default function Filter({
  propertySchema,
  filterBy,
  value,
}: Props): React.Node {
  // Schema must be resolved
  if (!propertySchema) return null;
  const { type } = propertySchema;
  let editor;

  switch (type) {
    case 'enum':
      editor = (
        <EnumEditor
          propertySchema={propertySchema}
          value={value}
          onChange={(values) =>
            filterBy(propertySchema.key, propertySchema.type, values)
          }
          portal
        />
      );
      break;
    case 'timestamp':
      editor = (
        <>
          <Field>
            <Label>de</Label>

            <Control>
              <DateEditor
                propertySchema={propertySchema}
                value={value?.min || null}
                max={value?.max || null}
                onChange={(newValue) =>
                  filterBy(propertySchema.key, propertySchema.type, {
                    min: newValue,
                    max: value?.max,
                  })
                }
                withPortal
              />
            </Control>
          </Field>

          <Field>
            <Label>à</Label>

            <Control>
              <DateEditor
                propertySchema={propertySchema}
                value={value?.max || null}
                min={value?.min || null}
                onChange={(newValue) =>
                  filterBy(propertySchema.key, propertySchema.type, {
                    min: value?.min,
                    max: newValue,
                  })
                }
                withPortal
              />
            </Control>
          </Field>
        </>
      );
      break;
    case 'int':
    case 'float':
      editor = (
        <NumberEditor
          propertySchema={propertySchema}
          value={value}
          onChange={(newValue) =>
            filterBy(propertySchema.key, propertySchema.type, newValue)
          }
        />
      );
      break;
    case 'string':
      editor = (
        <StringEditor
          propertySchema={propertySchema}
          value={value?.search || ''}
          onChange={(newValue) =>
            filterBy(propertySchema.key, propertySchema.type, {
              search: newValue,
            })
          }
          placeholder="Recherche..."
        />
      );
      break;
    case 'boolean':
      editor = (
        <BooleanEditor
          propertySchema={propertySchema}
          value={value}
          onChange={(newValue) =>
            filterBy(propertySchema.key, propertySchema.type, newValue)
          }
        />
      );
      break;
    default:
      return null;
  }

  if (!editor) return null;

  return (
    <div className={classnames('resource-table-filter', `type-${type}`)}>
      <Text element="h5" isTitle size={6}>
        Filter :
      </Text>
      {editor}
    </div>
  );
}
