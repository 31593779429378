import _ from 'lodash';
import Reflux from 'reflux';

/* Component lifecycle (http://busypeoples.github.io/post/react-component-lifecycle/)

INIT
constructor(p)                       can set this.state
getDerivedStateFromProps(p, s)       static, return new state
render()
componentDidMount()                  can call setState, DOM ready

=> use didMount()

UPDATE
getDerivedStateFromProps(p, s)       static, return new state
shouldComponentUpdate(next p, s)     return true/false, do NOT change state
render()
getSnapshotBeforeUpdate(prev p, s)   access DOM before re-render. Return value passed to componentDidUpdate
componentDidUpdate(prev p, s, snap)  can call setState, but beware of loops !

=> use didUpdate()

DESTROY
componentWillUnmount()

=> use willUnmount()

do NOT use:
componentWillMount()
componentWillReceiveProps()
componentWillUpdate()
*/

export default class ComponentBase extends Reflux.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.onScrollLoad) {
      let scrollDiv = document.getElementById(
        'componentBasePageScrollContainer'
      );
      this.listenToEvent('scroll', this.checkScroll.bind(this), scrollDiv);
    }

    this.didMount && this.didMount();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.didUpdate && this.didUpdate(prevProps, prevState, snapshot);

    // Fire observers
    // use isEqual instead of === for deep comparison and special cases like NaN
    this._propObservers &&
      this._propObservers.forEach((observer) => {
        if (prevProps && observer.prop) {
          let oldVal = _.get(prevProps, observer.prop);
          let newVal = _.get(this.props, observer.prop);
          if (!_.isEqual(oldVal, newVal)) observer.cb(newVal, oldVal);
        } else if (prevProps && observer.props) {
          let oldVals = _.pick(prevProps, observer.props);
          let newVals = _.pick(this.props, observer.props);
          if (!_.isEqual(oldVals, newVals)) observer.cb(newVals, oldVals);
        } else if (prevState && observer.state) {
          let oldVal = _.get(prevState, observer.state);
          let newVal = _.get(this.state, observer.state);
          if (!_.isEqual(oldVal, newVal)) observer.cb(newVal, oldVal);
        }
      });
  }

  /**
   * Wait for value / value change of a property.
   * Fired once immediately on function call (if prop not null), then on componentDidUpdate.
   * Can call setState in it (if called in a function where it's allowed).
   *
   * @param propPath      property name or path, eg. "bidule" or "params.truc"
   * @param callback      function(new value, old value, isFirst) - note: this.props contains new props
   * @param fireIfNull    if true, will fire callback immediately even if prop is null/undefined. Otherwise will wait for prop value to change.
   * @param currentProps  props to use on first call, otherwise will use this.props. Usefull if called in constructor.
   */
  observeProp(propPath, callback, fireIfNull = false, currentProps = null) {
    // Call it now if we have the value
    let val = _.get(this.props, propPath);
    if (!_.isNil(val) || fireIfNull) {
      callback(val, null, true);
    }

    this._propObservers = this._propObservers || [];
    this._propObservers.push({ prop: propPath, cb: callback });
  }

  /**
   * Wait for value / value change of properties.
   * Fired once immediately on function call, then on componentDidUpdate if any property changes.
   * Can call setState in it (if called in a function where it's allowed).
   *
   * @param propPath    property name or path, eg. "bidule" or "params.truc"
   * @param callback    function(new observed values {prop: value...}, old observed values, isFirst) - note: this.props contains all new props (not only observed values)
   * @param currentProps  props to use on first call, otherwise will use this.props. Usefull if called in constructor.
   */
  observeProps(propPaths, callback, currentProps = null) {
    // Call it now
    let vals = _.pick(currentProps || this.props, propPaths);
    callback(vals, {}, true);

    this._propObservers = this._propObservers || [];
    this._propObservers.push({ props: propPaths, cb: callback });
  }

  /**
   * Wait for value / value change of a state value.
   * Fired once immediately on function call (if prop not null), then on componentDidUpdate.
   * Warning if you call setState in it (avoid infinite loop)
   *
   * @param statePath     state name or path, eg. "bidule" or "params.truc"
   * @param callback      function(new value, old value, isFirst) - note: this.props contains new props
   * @param fireIfNull    if true, will fire callback immediately even if prop is null/undefined. Otherwise will wait for prop value to change.
   * @param currentProps  props to use on first call, otherwise will use this.props. Usefull if called in constructor.
   */
  observeState(statePath, callback, fireIfNull = false, currentProps = null) {
    // Call it now if we have the value
    let val = _.get(this.state, statePath);
    if (!_.isNil(val) || fireIfNull) {
      callback(val, null, true);
    }

    this._propObservers = this._propObservers || [];
    this._propObservers.push({ state: statePath, cb: callback });
  }

  /**
   * Execute a visible redirection (using router)
   * @param path new location
   */
  /*
    redirect(path) {
        if (this.context && this.context.router) {
            const route = this.context.router.route.location.pathname;
            if (route !== path) {
                console.debug(this.constructor && this.constructor.name, "redirect to", path);
                this.context.router.history.push(path);
            }
        } else {
            console.warn("No context router - component mounted:", this._isMounted, this); // TODO isMounted obsolete
            window.location = path;
        }
    }
    */

  /**
   *  Call this on componentDidMount - replaces window.addEventListener
   */
  listenToEvent(event, callback, element = null, useCaptureOrOptions = false) {
    if (!element) element = window;
    this._unsubscribeFunctions = this._unsubscribeFunctions || [];

    let events = event.split(' ');
    for (let i = 0; i < events.length; i++)
      if (events[i]) {
        element.addEventListener(events[i], callback, useCaptureOrOptions);
        this._unsubscribeFunctions.push(() =>
          element.removeEventListener(events[i], callback)
        );
      }
  }

  /**
   *  replaces window.setTimeout / setInterval
   */
  setTimer(callback, delay, repeat = false) {
    let timerId = (!repeat ? window.setTimeout : window.setInterval)(
      callback,
      delay
    );

    this._unsubscribeFunctions = this._unsubscribeFunctions || [];
    let unsubFunc = () =>
      (!repeat ? window.clearTimeout : window.clearInterval)(timerId);
    this._unsubscribeFunctions.push(unsubFunc);
    return unsubFunc;
  }

  /**
   * You may implement this.onScrollLoad to use scroll-loading.
   * (this.onScrollLoad must be set before componentdidMount).
   * You may set the ref "componentBasePageScrollContainer" on the div doing the main scrolling (default: window)
   */
  /*
    checkScroll() {
        let scrollY, contentHeight, pageHeight;
        let scrollDiv = document.getElementById('componentBasePageScrollContainer'); // beurk

        if (scrollDiv) { // The page content is in a div with overflow
            scrollY       = scrollDiv.scrollTop;
            contentHeight = scrollDiv.scrollHeight;
            pageHeight    = scrollDiv.clientHeight;
        }
        else { // The page itself is scrolling
            scrollY       = window.scrollY || window.pageYOffset;
            contentHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
            pageHeight    = window.innerHeight;
        }

        if ((pageHeight + scrollY) >= contentHeight - 10) {
            this.onScrollLoad && this.onScrollLoad();
        }
    }
*/

  componentWillUnmount() {
    // Unsubscribe from stores subscribed with listenToStore()
    if (this._unsubscribeFunctions)
      this._unsubscribeFunctions.forEach((u) => u());
    this._unsubscribeFunctions = [];

    this.willUnmount && this.willUnmount();
    super.componentWillUnmount();
  }
}

// Add router context to use this.context.router.push(), .replace() etc.
// TODO check this...
/*ComponentBase.contextTypes = {
    router: PropTypes.object.isRequired,
};*/
