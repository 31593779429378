import * as React from 'react';
import classnames from 'classnames';

import { useGlobal } from 'src/hooks';
import { FieldContext } from './contexts';

import {
  Label,
  Field,
  Tag,
  ErrorBoundary,
  Button,
  Control,
  QuestionMarkTooltip,
  RichTextContent,
  InformationBubble,
  Icon,
} from 'src/components';

export default function PropertyField({
  propertySchema,
  disabled,
  children,
}): React.Node {
  const { user } = useGlobal();
  const {
    key,
    required,
    label,
    description,
    toggleable,
    type,
    hint,
  } = propertySchema;
  const [toggled, toggle] = React.useState(false);
  const editorDisplayed = !toggleable || toggled;
  const [fieldState, _setFieldState] = React.useState({
    focused: false,
    displayHint: false,
  });
  const { focused, displayHint } = fieldState;
  const setFieldState = React.useCallback(
    (shape) =>
      _setFieldState((previousState) => ({
        ...previousState,
        ...shape,
      })),
    [_setFieldState]
  );

  return (
    <FieldContext.Provider
      value={{
        ...fieldState,
        setFieldState,
        onFocus: () => setFieldState({ focused: true }),
        onBlur: () => setFieldState({ focused: false }),
      }}
    >
      <Field
        additionalClassName={classnames(
          'resource-property',
          `type-${type}`,
          `${key}-property-key`,
          {
            focused,
          }
        )}
        key={key}
      >
        <Label style={{ justifyContent: 'space-between' }}>
          <div style={{ display: 'inline-block' }}>
            {label}
            {required ? ' *' : ''}

            {user.isDeveloper && key && (
              <Tag color="info" light style={{ marginLeft: 5 }}>
                {key}
              </Tag>
            )}
            {disabled && (
              <Tag color="warning" light style={{ marginLeft: 5 }}>
                Non éditable
              </Tag>
            )}

            {description && <QuestionMarkTooltip content={description} />}
          </div>

          {toggleable && (
            <Button isText size="small" onClick={() => toggle(!toggled)}>
              {toggled ? 'Replier' : 'Déplier'}
            </Button>
          )}
        </Label>

        <Control>
          {editorDisplayed && <ErrorBoundary>{children}</ErrorBoundary>}
        </Control>

        {(focused || displayHint) && hint?.html && (
          <InformationBubble
            size="small"
            style={{ marginTop: 5 }}
            onHide={() => setFieldState({ displayHint: false })}
          >
            <RichTextContent richtext={hint} />
          </InformationBubble>
        )}

        {!displayHint && !focused && hint?.html && (
          <Button
            isText
            size="small"
            onClick={() => setFieldState({ displayHint: true })}
          >
            <Icon name="lightbulb" style={{ marginRight: 5 }} />
            Afficher les astuces
          </Button>
        )}
      </Field>
    </FieldContext.Provider>
  );
}
