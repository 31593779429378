// @flow
import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import _ from 'lodash';
type Options = {|
  delay: number,
|};

type Returned = [any, (any) => any];

export default function useDelayedUpdate(
  value: any,
  setValue: (any) => any,
  options?: Options
): Returned {
  const { delay = 500 } = options || { delay: 500 };
  const [intermediateValue, setIntermediateValue] = React.useState(value);

  useDebouncedEffect(
    () => {
      if (!_.isEqual(value, intermediateValue)) {
        setValue(intermediateValue);
      }
    },
    delay,
    [intermediateValue]
  );

  return [intermediateValue, setIntermediateValue];
}
