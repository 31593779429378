import Reflux from 'reflux';
import * as clipboard from 'clipboard-polyfill';

export var ClipboardActions = Reflux.createActions([
  'set',
  'read',
  'pasteValue',
]);

export default class ClipboardStore extends Reflux.Store {
  constructor() {
    super();

    this.state = {
      clipboard: null,
    };

    this.listenables = ClipboardActions;

    document.addEventListener('paste', (event) => {
      console.debug('Clipboard paste event', event);
      this.read((v) => ClipboardActions.pasteValue(v));
    });
  }

  set(value) {
    console.debug('Copy to clipboard', value);
    this.setState({ clipboard: value });

    try {
      let dt = new clipboard.DT();
      dt.setData('application/json', JSON.stringify(value));
      dt.setData('text/plain', JSON.stringify(value, null, 4));
      clipboard.write(dt).then(null, console.error);
    } catch (e) {
      console.warn("Can't copy to clipboard: Exception", e);
    }
  }

  read(callback) {
    try {
      clipboard.readText().then(
        (txt) => {
          try {
            let value = JSON.parse(txt);
            if (value && value._cls) {
              this.setState({ clipboard: value });
              callback && callback(value);
            }
          } catch (ex) {}
        },
        (err) => {}
      );
    } catch (e) {
      console.warn("Can't read clipboard: Exception", e);
    }
  }

  pasteValue(value) {}
}

ClipboardStore.id = 'ClipboardStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state
