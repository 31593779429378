const EntityMainContentType = {
  _id: 'EntityMainContentType',
  label: 'Catégorie principale',
  icon: 'tag',
  frontComponent: 'EntityMainContentType',
  forEntity: true,
  optionsSchema: [],
};

export default EntityMainContentType;
