// @flow
import * as React from 'react';

import type { ResourceType, UserRight } from 'src/types/models';

import RESOURCE_TYPES from 'src/pages/constants/resources';

import pageCustomTabs from './page';
import videoCustomTabs from './video';
import userCustomTabs from './user';
import tournamentCustomTabs from './tournament';

export type Tab = {|
  to: string,
  icon: string,
  label: React.Node,
  content: React.ComponentType<{}>,
  right?: UserRight,
  exact?: boolean,
|};

export default function getResourceTypeCustomTabs(
  resourceType: ResourceType
): Array<Tab> {
  switch (resourceType) {
    case RESOURCE_TYPES.Page.singular:
      return pageCustomTabs;
    case RESOURCE_TYPES.Video.singular:
      return videoCustomTabs;
    case RESOURCE_TYPES.User.singular:
      return userCustomTabs;
    case RESOURCE_TYPES.Tournament.singular:
      return tournamentCustomTabs;
    default:
      return [];
  }
}
