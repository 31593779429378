import { CharactersCounterProperties } from './shared';

const RichTextPropertySchema = [
  {
    key: 'disallowedTags',
    label: 'Tags HTML interdits',
    type: 'enum',
    multiple: true,
    required: true,
    group: 'Général',
    values: [
      {
        value: 'h1',
        label: 'h1',
      },
      {
        value: 'h2',
        label: 'h2',
      },
      {
        value: 'h3',
        label: 'h3',
      },
      {
        value: 'h4',
        label: 'h4',
      },
      {
        value: 'h5',
        label: 'h5',
      },
      {
        value: 'h6',
        label: 'h6',
      },
      {
        value: 'p',
        label: 'p',
      },
    ],
  },
  ...CharactersCounterProperties,
];

export default RichTextPropertySchema;
