// @flow

import * as React from 'react';
import moment from 'moment';

import { Flex, DatePicker, Field, Control, Label } from 'src/components';

type Props = {
  startDate: moment,
  setStartDate: (moment) => void,
  endDate: moment,
  setEndDate: (moment) => void,
};

function Header({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Props): React.Node {
  return (
    <Flex
      style={{
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      }}
    >
      <Flex>
        <Field style={{ marginRight: 10 }}>
          <Label>Date de début :</Label>
          <Control>
            <DatePicker
              value={startDate.toDate()}
              onChange={(date) => setStartDate(moment(date))}
              maxDate={endDate.toDate()}
            />
          </Control>
        </Field>
        <Field style={{ marginRight: 10 }}>
          <Label>Date de début :</Label>
          <Control>
            <DatePicker
              value={endDate.toDate()}
              onChange={(date) => setEndDate(moment(date))}
              minDate={startDate.toDate()}
            />
          </Control>
        </Field>
      </Flex>
    </Flex>
  );
}

export default Header;
