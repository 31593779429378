import moment from 'moment';
import 'moment/locale/fr';

import {
  registerLocale,
  setDefaultLocale as setDatePickerDefaultLocale,
} from 'react-datepicker';

import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

let currentLocale = null;
export default function setLocale(locale) {
  if (currentLocale === locale) return;

  currentLocale = locale;

  moment.locale(locale);
  setDatePickerDefaultLocale(locale);

  const { documentElement: htmlElement } = document;
  if (htmlElement) {
    htmlElement.lang = locale;
  }
}

export const getCurrentLocale = () => currentLocale;
