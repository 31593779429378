const CartCheckout = {
  _id: 'cartCheckout',
  frontComponent: 'CartCheckout',
  label: 'Paiement du panier',
  icon: 'shopping-cart',
  optionsSchema: [
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        { value: 'default', label: 'Par défaut' },
        { value: 'simple', label: '1 seule colonne' },
        { value: 'conference', label: 'Conférences (tickets)' },
        { value: 'training', label: 'Stage (places)' },
        { value: 'events', label: 'Autre spectacles (places)' },
      ],
      required: false,
    },
    { key: 'label', label: 'Titre', type: 'string' },
    {
      key: 'buttonLabel',
      label: 'Libellé du bouton payer (doit contenir [[price]])',
      type: 'string',
      condition: { f$: 'obj.mode==="training"' },
      default: 'Réserver ma place maintenant pour [[price]] !',
    },
    {
      key: 'itemLabel',
      label: "Libellé de la quantité (ex : 'place(s)')",
      type: 'string',
    },
    {
      key: 'hideRecap',
      label: 'Ne pas afficher le récapitulatif',
      type: 'bool',
      default: false,
    },
    {
      key: 'allowChangeQuantity',
      label: 'Pouvoir modifier la quantité',
      type: 'bool',
      default: false,
    },
    {
      key: 'hideQuantity',
      label: 'Ne pas afficher la quantité si = 1',
      type: 'bool',
      condition: { f$: '!obj.allowChangeQuantity' },
    },
    {
      key: 'paidText',
      label: 'Remerciement après paiement',
      type: 'richtext',
      condition: { f$: 'obj.mode==="training"' },
    },
    {
      key: 'checkIntroText',
      label: 'Texte pour la demande chèque',
      type: 'richtext',
      condition: { f$: 'obj.mode==="training"' },
    },
    {
      key: 'checkButtonLabel',
      label: "Libellé du bouton demande 'chèque'",
      type: 'string',
      condition: { f$: 'obj.mode==="training"' },
      default: 'Valider',
    },
    {
      key: 'checkSentText',
      label: "Remerciement après demande 'chèque'",
      type: 'richtext',
      condition: { f$: 'obj.mode==="training"' },
    },
    {
      key: 'callIntroText',
      label: 'Texte pour être rappelé',
      type: 'richtext',
      condition: { f$: 'obj.mode==="training"' },
    },
    {
      key: 'callButtonLabel',
      label: 'Libellé du bouton être rappelé',
      type: 'string',
      condition: { f$: 'obj.mode==="training"' },
      default: 'Rappelez-moi',
    },
    {
      key: 'callSentText',
      label: "Remerciement après demande 'etre rappelé'",
      type: 'richtext',
      condition: { f$: 'obj.mode==="training"' },
      default: 'Rappelez-moi',
    },
    {
      key: 'backPageId',
      label: "Page cible pour le bouton 'ajouter d'autres offres'",
      description: 'par défaut, revient à la page précédente',
      type: 'object_id',
      objectType: 'Page',
      condition: { f$: '!obj.textAfter' },
    },
    {
      key: 'addMoreLabel',
      label: "Libellé 'ajouter d'autres offres'",
      type: 'string',
    },
    //{key: "autoBack", label: "Revenir à d'autres ofres si vide'", type: "bool"},
    {
      key: 'allowDonation',
      label: "Autoriser l'ajout d'un don",
      type: 'bool',
      default: false,
    },
    {
      key: 'maxDonation',
      label: 'Don max.',
      type: 'float',
      min: 0,
      max: 1000000,
      condition: { f$: 'obj.allowDonation' },
    },
    {
      key: 'donationLabel',
      label: 'Libellé don',
      type: 'string',
      condition: { f$: 'obj.allowDonation' },
    },
    {
      key: 'thankYouLabel',
      label: 'Texte de remerciement',
      type: 'richtext',
    },
    {
      key: 'allowDiscount',
      label: 'Afficher le champ code promo',
      type: 'bool',
      default: false,
    },
  ],
  forPage: true,
};

export default CartCheckout;
