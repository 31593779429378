// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { BulmaColor, BulmaSize, Style } from './types';

// TODO: check how children property is handled

type Props = {|
  children?: React.Node,
  value: number,
  maxValue: number,
  color?: BulmaColor,
  size?: BulmaSize,
  additionalClassName?: string,
  style?: Style,
|};

export default function ProgressBar({
  children,
  value,
  maxValue,
  color,
  size,
  additionalClassName,
  style,
}: Props): React.Node {
  const colorClassName = classnames({
    'is-link': color === 'link',
    'is-primary': color === 'primary',
    'is-info': color === 'primary',
    'is-success': color === 'success',
    'is-warning': color === 'warning',
    'is-danger': color === 'danger',
  });

  const sizeClassName = classnames({
    'is-small': size === 'small',
    'is-normal': size === 'normal',
    'is-medium': size === 'medium',
    'is-large': size === 'large',
  });

  const className = classnames(
    'progress',
    colorClassName,
    sizeClassName,
    additionalClassName
  );

  const progress = (
    <progress className={className} value={value} max={maxValue} style={style}>
      {value}%
    </progress>
  );

  if (children) {
    return (
      <div className="progress-bar-wrapper">
        <div className="progress-bar-label">{children}</div>
        {progress}
      </div>
    );
  }

  return progress;
}

ProgressBar.defaultProps = {
  size: 'normal',
  maxValue: 100,
};
