// @flow

import * as React from 'react';
import _ from 'lodash';
import Tooltip from 'rc-tooltip';
import classnames from 'classnames';

import type { BulmaColor, BulmaSize, Style } from './types';

import { Link } from 'react-router-dom';

type StyleProps = {|
  color?: BulmaColor | 'light' | 'dark' | 'black' | 'ghost' | 'text',
  size?: BulmaSize | 'tiny',

  isFullWidth?: boolean,
  isRounded?: boolean,
  isOutlined?: boolean,
  isInverted?: boolean,
  isText?: boolean,
  isExpanded?: boolean,
  isLight?: boolean,
|};

type Props = {|
  ...StyleProps,

  isLoading?: boolean,
  isHovered?: boolean,
  isFocused?: boolean,
  isActive?: boolean,
  isStatic?: boolean,

  element?: 'a' | 'span' | 'button',
  type?: 'submit' | 'button',
  href?: string,
  rel?: string,
  to?: string,
  className?: string,
  onClick?: () => any,
  title?: string,
  badge?: React.Node,
  tooltip?: string,
  tooltipHtml?: string,
  children: React.Node,
  disabled?: boolean,
  additionalClassName?: string,
  style?: Style,
  id?: string,
  target?: string,
|};

export default function Button(props: Props): React.Node {
  const {
    color,
    element = 'button',
    type,
    href,
    className,
    additionalClassName,
    disabled,
    onClick,
    title,
    tooltip,
    tooltipHtml,
    children,
    isActive,
    badge,
    isLight,
    to,
    target,
    ...otherProps
  } = props;
  let button;

  if (!!to) {
    button = (
      <Link
        to={to}
        className={getClassName(props)}
        title={title}
        disabled={disabled}
        target={target}
      >
        {children}
        {!!badge && <span className="badge">{badge}</span>}
      </Link>
    );
  } else {
    const Element = href ? 'a' : element;

    button = (
      <Element
        {..._.omit(otherProps, [
          'tooltipHtml',
          'isFullWidth',
          'active',
          'isText',
        ])}
        className={getClassName(props)}
        onClick={onClick}
        href={href}
        title={title || undefined}
        type={element === 'button' ? type || 'button' : undefined}
        disabled={disabled}
        target={href ? target : undefined}
      >
        {children}

        {!!badge && <span className="badge">{badge}</span>}
      </Element>
    );
  }

  const tooltipContent =
    (tooltipHtml && (
      <span dangerouslySetInnerHTML={{ __html: tooltipHtml }} />
    )) ||
    tooltip ||
    title;

  return !!tooltipContent ? (
    <Tooltip
      overlay={tooltipContent}
      placement="top"
      trigger={['hover', 'click']}
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
}

const getClassName = (props: Props) => {
  const {
    size,
    color,

    isHovered,
    isFocused,
    isActive,
    isStatic,
    disabled,
    isLoading,

    isLight,
    isFullWidth,
    isOutlined,
    isRounded,
    isInverted,
    isExpanded,
    isText,
    additionalClassName,
  } = props;

  const colorClassName = classnames(color && `is-${color}`);

  const stateClassName = classnames({
    'is-hovered': !!isHovered,
    'is-focused': !!isFocused,
    'is-active': !!isActive,
    'is-loading': !!isLoading,
    'is-static': !!isStatic,
    'is-disabled': !!disabled,
    'is-light': !!isLight,
  });

  const sizeClassName = classnames(size && `is-${size}`);

  return classnames(
    'button',
    additionalClassName,
    colorClassName,
    stateClassName,
    sizeClassName,
    {
      'is-outlined': !!isOutlined,
      'is-inverted': !!isInverted,
      'is-rounded': !!isRounded,
      'is-text': !!isText,
      'is-expanded': !!isExpanded,
      'is-fullwidth': !!isFullWidth,
    }
  );
};
