// @flow
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import invariant from './invariant';

export function getDisplayName(
  WrappedComponent: React.ComponentType<*>
): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function createBodyPortal(node: React.Node): React.Node {
  invariant(document.body, '`document.body` should be defined');
  const { body } = document;
  return ReactDOM.createPortal(node, body);
}
