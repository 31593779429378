// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from './types';

type Props = {|
  children: React.Node,
  bordered?: boolean,
  striped?: boolean,
  narrow?: boolean,
  hoverable?: boolean,
  fullwidth?: boolean,
  scrollable?: boolean,
  style?: Style,
  additionalClassName?: string,
|};

export default function Table({
  bordered,
  striped,
  narrow,
  hoverable,
  fullwidth,
  scrollable,
  children,
  additionalClassName,
  style,
}: Props): React.Node {
  const className = classnames('table', additionalClassName, {
    'is-bordered': bordered,
    'is-striped': striped,
    'is-narrow': narrow,
    'is-hoverable': hoverable,
    'is-fullwidth': fullwidth,
  });

  const table = (
    <table className={className} style={style}>
      {children}
    </table>
  );

  if (scrollable) {
    return <div className="table-container">{table}</div>;
  }

  return table;
}

Table.defaultProps = {
  bordered: true,
  striped: false,
  narrow: false,
  hoverable: false,
  fullwidth: true,
  scrollable: true,
};

type THeadProps = {|
  children: React.Node,
|};

export function THead({ children }: THeadProps): React.Node {
  return <thead>{children}</thead>;
}

type TFootProps = {|
  children: React.Node,
|};

export function TFoot({ children }: TFootProps): React.Node {
  return <tfoot>{children}</tfoot>;
}

type TBodyProps = {|
  children: React.Node,
|};

export function TBody({ children }: TBodyProps): React.Node {
  return <tbody>{children}</tbody>;
}

type TrProps = {|
  children: React.Node,
  isSelected?: boolean,
  additionalClassName?: string,
|};

export function Tr({
  children,
  isSelected,
  additionalClassName,
}: TrProps): React.Node {
  const className = classnames(additionalClassName || '', {
    'is-selected': !!isSelected,
  });

  return <tr className={className}>{children}</tr>;
}

Tr.defaultProps = {
  isSelected: false,
};

type ThProps = {|
  children?: React.Node,
  additionalClassName?: string,
  style?: Object,
|};

export function Th({
  children,
  additionalClassName,
  style,
}: ThProps): React.Node {
  return (
    <th className={additionalClassName} style={style}>
      {children}
    </th>
  );
}

type TdProps = {|
  children: React.Node,
  additionalClassName?: string,
  style?: Object,
|};

export function Td({
  children,
  additionalClassName,
  style,
}: TdProps): React.Node {
  return (
    <td className={additionalClassName} style={style}>
      {children}
    </td>
  );
}
