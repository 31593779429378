import React from 'react';

import { Tag } from 'src/components';

export default function RightsRenderer({
  mode,
  value: rights,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!rights || rights.length === 0) return <Tag>Gratuit</Tag>;

  return (
    <div className="rights-renderer">
      {rights.map((right) => (
        <Tag
          color="dark"
          style={{ marginRight: 5, marginBottom: 3 }}
          key={right}
        >
          {right}
        </Tag>
      ))}
    </div>
  );
}
