// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { BulmaColor, BulmaSize } from 'src/components/types';

import { bulmaColorClassName, bulmaSizeClassName } from 'src/helpers/style';

import TextareaAutosize from 'react-autosize-textarea';

export type TextareaState =
  | 'normal'
  | 'active'
  | 'hovered'
  | 'focused'
  | 'danger';

export type Props = {|
  value: ?string,
  disabled?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  rows?: number,
  hasFixedSize?: boolean,
  autoFocus?: boolean,
  maxLength?: number,

  color?: BulmaColor,
  size?: BulmaSize,
  state?: TextareaState,

  onChange: (string) => any,
  onEnterKeyPress?: () => void | Promise<*>,
  onEscapeKeyPress?: () => void,
  onBlur?: () => any,
  onFocus?: () => any,

  additionalClassName?: string,
  style?: {},
|};

function Textarea({
  hasFixedSize,
  value,

  onChange,
  onEnterKeyPress,
  onEscapeKeyPress,
  onBlur,
  onFocus,

  color,
  size,
  state,
  additionalClassName,
  ...otherProps
}: Props): React.Node {
  const stateClassName = classnames({
    'is-hovered': state === 'hovered',
    'is-focused': state === 'focused',
    'is-active': state === 'active',
    'is-danger': state === 'danger',
  });

  const className = classnames(
    'textarea',
    additionalClassName,
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    stateClassName,
    {
      'has-fixed-size': hasFixedSize,
    }
  );

  return (
    <TextareaAutosize
      className={className}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={(e) => {
        onEnterKeyPress && e.keyCode === 13 && !e.shiftKey && onEnterKeyPress();
        onEscapeKeyPress && e.keyCode === 27 && onEscapeKeyPress();
      }}
      onFocus={(e) => {
        e.target.setSelectionRange(
          e.target.value.length,
          e.target.value.length
        );
        onFocus && onFocus();
      }}
      onBlur={onBlur}
      value={value || ''}
      {...otherProps}
    />
  );
}

Textarea.defaultProps = {
  disabled: false,
  readOnly: false,
  size: 'normal',
  state: 'normal',
  rows: 2,
};

export default Textarea;
