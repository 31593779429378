// @flow
import _ from 'lodash';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import type {
  OnClickCol,
  OnRemoveContent,
  OnContentUp,
  OnContentDown,
  SelectInnerLayout,
  OnAddNewContent,
  OnAddComponent,
  AddNewBlock,
  OnMoveComponent,
  OnDivideCol,
  OnRemoveCol,
  OnClickComponentOptions,
  OnChangeComponent,
  OnChangeCol,
  OnChangeColFlex,
  OnChangeColAlign,
  OnChangeColFlexOrder,
  OnChangeColMarginBottom,
  OnSetFullwidth,
  OnSetGutters,
  Row,
  ComponentsContextType,
} from './types';

import { InstanceStore } from 'src/stores';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import { deepMap } from 'src/helpers/misc';
import { COMPONENTS } from './constants/simpleComponentsDefinition';
import { makeSpacingClass } from 'src/helpers/style';

import ComponentOptionsModal from './components/ComponentOptionsModal';
import {
  Text,
  Columns as BulmaColumns,
  Column as BulmaColumn,
  Button,
  Icon,
} from 'src/components';
import ComponentPickerModal from './components/ComponentPickerModal';
import LayoutPickerModal from './components/LayoutPickerModal';
import Toolbox from './Toolbox';
import Content from './Content';

const defaultContextValue: ComponentsContextType = {
  simple: [],
  complex: [],
};
export const ComponentsContext = (React.createContext<ComponentsContextType>(
  defaultContextValue
): React$Context<ComponentsContextType>);

type Props = {|
  value: Array<Row>,
  onChange: (Array<Row>) => void,
  disabled?: boolean,
|};

class LayoutEditor extends ComponentBase {
  constructor(props: Props) {
    super(props);

    this.state = {
      availableComponents: COMPONENTS,

      componentOptionsModalOpen: false,
      componentPickerModalOpen: false,
      layoutPickerModalOpen: false,
      toolboxToggled: true,
    };

    this.store = InstanceStore;
  }

  didMount = () => {
    this.observeProp(
      'schema.forPage',
      (forPage) =>
        this.setState({
          availableComponents: COMPONENTS, // wrong for object pages -> disabled -- _.filter(COMPONENTS, c => !forPage || c.forPage)
        }),
      true
    );

    this.observeProp('value', (value) => {
      const newValue = deepMap(value, (obj) => {
        if (obj && obj.component)
          return { ...obj, ...this._getCompDataforLayout(obj.component) };
        return obj;
      });

      if (!_.isEqual(value, newValue)) {
        this.props.onChange(newValue);
      }
    });

    API.get(this.state.instanceName + '/data/ComplexComponent', {
      limit: 0,
    }).then(({ data: complexComponents }) =>
      this.setState({ complexComponents })
    );
  };

  render() {
    const { value }: Props = this.props;

    const {
      instanceName,
      availableComponents,
      complexComponents,
      componentOptionsPath,
      componentOptionsModalOpen,
      componentPickerModalOpen,
      layoutPickerModalOpen,
      itemPickedPath,
      actualSize,
      toolboxToggled,
    } = this.state;

    const itemClickedContent = _.get(value, itemPickedPath) || null;
    const site = _.includes(instanceName, 'onfray') ? 'onfray' : 'demo';
    const filteredAvailableComponents = _.filter(
      availableComponents,
      (c) => !c.site || c.site === site
    );

    const componentHandlers = {
      onClickCol: this.onClickCol,
      onRemoveContent: this.onRemoveContent,
      onContentUp: this.onContentUp,
      onContentDown: this.onContentDown,
      selectInnerLayout: this.selectInnerLayout,
      onAddNewContent: this.onAddNewContent,
      onAddComponent: this.onAddComponent,
      onMoveComponent: this.onMoveComponent,
      onDivideCol: this.onDivideCol,
      onRemoveCol: this.onRemoveCol,
      onClickComponentOptions: this.onClickComponentOptions,
      onChangeCol: this.onChangeCol,
      onChangeColFlex: this.onChangeColFlex,
      onChangeColAlign: this.onChangeColAlign,
      onChangeColFlexOrder: this.onChangeColFlexOrder,
      onChangeColMarginBottom: this.onChangeColMarginBottom,
      onSetFullwidth: this.onSetFullwidth,
      onSetGutters: this.onSetGutters,
      onEditRowOptions: this.onEditRowOptions,
      onEditColumnOptions: this.onEditColumnOptions,
    };

    if (!value) return null;

    return (
      <ComponentsContext.Provider
        value={{
          simple: filteredAvailableComponents,
          complex: complexComponents || [],
        }}
      >
        <div className="layout-property-editor" style={{ marginBottom: 40 }}>
          <Text
            element="h2"
            size={3}
            additionalClassName={makeSpacingClass([['margin', 5, 'bottom']])}
          >
            Composition de la page
          </Text>

          <BulmaColumns>
            {toolboxToggled && (
              <BulmaColumn
                size={3}
                style={{
                  maxHeight: 'calc(100vh - 100px)',
                  overflow: 'scroll',
                }}
                additionalClassName="toolbox-wrapper"
              >
                <Toolbox />
              </BulmaColumn>
            )}

            <BulmaColumn
              size={toolboxToggled ? 9 : 12}
              style={{
                maxHeight: 'calc(100vh - 100px)',
                overflow: 'scroll',
              }}
            >
              <Text
                element="h3"
                size={4}
                additionalClassName={makeSpacingClass([
                  ['margin', 4, 'bottom'],
                ])}
              >
                Mise en page
                <Button
                  size="small"
                  isText
                  additionalClassName="toggle-toolbox-button"
                  onClick={() =>
                    this.setState({ toolboxToggled: !toolboxToggled })
                  }
                  style={{ marginLeft: 10 }}
                >
                  <Icon
                    name={toolboxToggled ? 'arrow-left' : 'arrow-right'}
                    style={{ marginRight: 5 }}
                  />
                  {toolboxToggled ? 'Cacher la toolbar' : 'Afficher la toolbar'}
                </Button>
              </Text>

              <Content
                rows={value}
                handlers={componentHandlers}
                addNewBlock={this.addNewBlock}
              />
            </BulmaColumn>
          </BulmaColumns>

          {componentOptionsModalOpen && (
            <ComponentOptionsModal
              open
              onClose={() =>
                this.setState({ componentOptionsModalOpen: false })
              }
              component={this.getComponent(`${componentOptionsPath}.component`)}
              onChange={(newValue) =>
                this.onChangeComponent(componentOptionsPath, newValue)
              }
              layout={value}
            />
          )}

          <ComponentPickerModal
            open={componentPickerModalOpen}
            onClose={() => this.setState({ componentPickerModalOpen: false })}
            itemClickedContent={itemClickedContent}
            onPick={(component) =>
              this.onAddComponent(itemPickedPath, component)
            }
          />

          <LayoutPickerModal
            open={layoutPickerModalOpen}
            onClose={() => this.setState({ layoutPickerModalOpen: false })}
            actualSize={actualSize}
            onSelect={this.selectInnerLayout}
          />
        </div>
      </ComponentsContext.Provider>
    );
  }

  _getCompDataforLayout = (component) => {
    return component._cls === 'ComplexComponent'
      ? {
          component: {
            _id: component._id,
            _cls: component._cls,
            label: component.label,
            _isRef: true,
            options: component.options || {},
          },
        }
      : {
          component: {
            _id: component._id,
            label: component.label,
            frontComponent: component.frontComponent,
            options: component.options || {},
          },
        };
  };

  onEditRowOptions = (path, options) => {
    this.operateOnContent(path, (row, contents, index) => {
      row.options = options;
    });
  };

  onEditColumnOptions = (path, options) => {
    this.operateOnContent(path, (column, contents, index) => {
      column.options = options;
    });
  };

  // A new layout block division has been selected inside our lightbox
  selectInnerLayout: SelectInnerLayout = (layoutColsWitdh) => {
    const { itemPickedPath } = this.state;

    this.onDivideCol(itemPickedPath, {
      cols: layoutColsWitdh,
    });

    this.setState({
      itemPickerModalOpen: false,
      itemPickedPath: null,
    });
  };

  onClickCol: OnClickCol = (path, actualSize) => {
    !!actualSize
      ? this.setState({
          itemPickedPath: path,
          layoutPickerModalOpen: true,
          actualSize,
        })
      : this.setState({
          componentPickerModalOpen: true,
          itemPickedPath: path,
          actualSize,
        });
  };

  onClickComponentOptions: OnClickComponentOptions = (path) => {
    this.setState({
      componentOptionsModalOpen: true,
      componentOptionsPath: path,
    });
  };

  onRemoveContent: OnRemoveContent = (path) => {
    this.operateOnContent(path, (content, contents, index) => {
      contents.splice(index, 1);

      if (
        contents.length === 0 &&
        content.cols &&
        content.cols[0] &&
        content.cols[0].contents.length > 0
      ) {
        content.cols[0].contents.forEach((subContent) =>
          contents.push(subContent)
        );
      }
    });
  };

  onContentUp: OnContentUp = (path) => {
    this.operateOnContent(path, (content, contents, index) => {
      contents[index] = contents[index - 1];
      contents[index - 1] = content;
    });
  };

  onContentDown: OnContentDown = (path) => {
    this.operateOnContent(path, (content, contents, index) => {
      contents[index] = contents[index + 1];
      contents[index + 1] = content;
    });
  };

  onSetFullwidth: OnSetFullwidth = (path, fullWidth) => {
    this.operateOnContent(path, (content, contents, index) => {
      content.fullWidth = fullWidth;
    });
  };

  onSetGutters: OnSetGutters = (path, gutters) => {
    this.operateOnContent(path, (content, contents, index) => {
      content.gutters = gutters;
    });
  };

  operateOnContent = (path, callback, commit = true) => {
    const { onChange, value: valueProp } = this.props;

    const newValue = _.cloneDeep(valueProp) || [];

    const pathSplit = _.toPath(path);
    const contentsPath = pathSplit.slice(0, -1);
    // $FlowIgnore
    const contentIndex = ~~_.last(pathSplit);
    const contents =
      contentsPath.length > 0 ? _.get(newValue, contentsPath) : newValue;
    const content = contents[contentIndex];

    callback(content, contents, contentIndex);

    commit && onChange(newValue);
    return newValue;
  };

  onChangeComponent: OnChangeComponent = (path, value) => {
    console.debug('onChangeComponent', path, value);

    this.operateOnContent(path, (content, contents, index) => {
      contents[index].component.options = value;
    });
  };

  onDivideCol: OnDivideCol = (path, divider) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];
    const col = _.get(newValue, path);

    const newCols = divider.cols.map((size) => ({
      size,
      contents: [],
      noMarginBottom: true,
    }));

    // If it contains something (components), put in first sub-col
    if (col.contents && col.contents.length > 0) {
      console.debug('move contents to first child col', col.contents);
      newCols[0].contents = col.contents;
      col.contents = [];
    }

    if (col.size === 12) {
      console.debug('replace full width col with cols', newCols);
      const parentColsPath = _.toPath(path).slice(0, -1);
      _.set(newValue, parentColsPath, newCols);
    } else {
      console.debug('put chidren cols in col contents', newCols);
      if (!col.contents) col.contents = [];
      col.contents.push({
        cols: newCols,
      });
    }

    onChange(newValue);
  };

  onRemoveCol: OnRemoveCol = (path) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];

    const pathSplit = _.toPath(path);
    const colsPath = pathSplit.slice(0, -1);

    // $FlowIgnore
    const index = ~~_.last(pathSplit);
    const cols = _.get(newValue, colsPath);

    const oldCol = cols[index];
    cols.splice(index, 1);

    if (cols.length > 0) {
      const indexToAdjust = index - 1 >= 0 ? index - 1 : 0;
      console.debug(
        'adjust size of col',
        indexToAdjust,
        cols[indexToAdjust].size,
        '+',
        oldCol.size
      );
      cols[indexToAdjust].size += oldCol.size;
    } else if (
      oldCol.cols &&
      oldCol.cols[0] &&
      oldCol.cols[0].contents.length > 0
    ) {
      console.debug('last col, move content up');
      oldCol.cols[0].contents.forEach((x) => cols.push(x));
    } else {
      const parentPath = pathSplit.slice(0, -3);
      const parent = _.isEmpty(parentPath)
        ? newValue
        : _.get(newValue, parentPath);
      const indexToDelete = pathSplit[pathSplit.length - 3];
      console.debug(
        'last col, empty, delete parent',
        parentPath,
        '[',
        indexToDelete,
        ']'
      );
      parent.splice(indexToDelete, 1);
    }

    onChange(newValue);
  };

  onChangeCol: OnChangeCol = (path, newColumn) => {
    const { value, onChange } = this.props;
    const clonedValue = _.cloneDeep(value) || [];
    const newValue = _.set(clonedValue, path, newColumn);

    onChange(newValue);
  };

  onChangeColFlex: OnChangeColFlex = (path, flex) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];
    const col = _.get(newValue, path);
    col.flex = flex;

    if (flex !== 'row' && ['both', 'spaced'].includes(col.align))
      col.align = null;

    onChange(newValue);
  };

  onChangeColAlign: OnChangeColAlign = (path, align) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];
    const col = _.get(newValue, path);
    col.align = align;
    onChange(newValue);
  };

  onChangeColMarginBottom: OnChangeColMarginBottom = (path, noMarginBottom) => {
    // c/c onChangeColFlex
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];
    const col = _.get(newValue, path);
    col.noMarginBottom = noMarginBottom;
    onChange(newValue);
  };

  onChangeColFlexOrder: OnChangeColFlexOrder = (path, flexOrder) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];

    const pathSplit = _.toPath(path);
    const colsPath = pathSplit.slice(0, -1);

    // $FlowIgnore
    const index = ~~_.last(pathSplit);
    const cols = _.get(newValue, colsPath);

    // Fill all orders
    cols.forEach((c, n) => {
      if (!c.flexOrder)
        for (let i = 1; i <= cols.length; i++) {
          if (!_.find(cols, { flexOrder: i })) {
            console.debug('yes');
            c.flexOrder = i;
            break;
          }
        }
    });

    // switch if necessary
    const oldFlexOrder = cols[index].flexOrder;
    cols.forEach((c) => {
      if (c.flexOrder === flexOrder) c.flexOrder = oldFlexOrder;
    });

    cols[index].flexOrder = flexOrder;

    onChange(newValue);
  };

  addNewBlock: AddNewBlock = () => {
    const { value, onChange } = this.props;

    const newValue = _.cloneDeep(value) || [];
    const rowPath = `[${newValue.length}]`;
    newValue.push({
      cols: [
        {
          size: 12,
          contents: [],
          uuid: uuidv4(),
          path: `${rowPath}.cols[0]`,
          noMarginBottom: true,
        },
      ],
      gutters: true, // by default
      uuid: uuidv4(),
      path: `[${newValue.length}]`,
    });

    onChange(newValue);
  };

  onAddNewContent: OnAddNewContent = (path) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];
    let col = _.get(newValue, path);

    if (!col.contents) col.contents = [];

    if (
      col.contents.length === 0 ||
      (col.contents.length > 0 && col.contents[0].component)
    ) {
      if (col.contents.length === 0)
        console.debug('empty col, add a first col 12');
      else
        console.debug(
          'components col, create new col 12 and copy content',
          col.contents
        );

      col = {
        size: col.size, // keep current size
        contents: [
          // copy current col as first child col
          {
            cols: [
              {
                ...col, // contents, flex, ...
                size: 12, // reset size
                noMarginBottom: true,
              },
            ],
          },
        ],
      };

      // replace col in newValue
      _.set(newValue, path, col);
    }

    // Now create new row
    col.contents.push({
      cols: [
        {
          size: 12,
          contents: [],
          noMarginBottom: true,
        },
      ],
    });

    onChange(newValue);
  };

  onMoveComponent: OnMoveComponent = (srcPath, dstPath, component) => {
    const { onChange } = this.props;

    const tempValue = this.operateOnContent(
      srcPath,
      (content, contents, index) => {
        contents.splice(index, 1);
        if (
          contents.length === 0 &&
          content.cols &&
          content.cols[0] &&
          content.cols[0].contents.length > 0
        ) {
          content.cols[0].contents.forEach((subContent) =>
            contents.push(subContent)
          );
        }
      },
      false
    );

    const newValue = tempValue;
    const col = _.get(newValue, dstPath);

    if (!col.contents) col.contents = [];

    col.contents.push(this._getCompDataforLayout(component));

    onChange(newValue);
  };

  onAddComponent: OnAddComponent = (path, component) => {
    const { value, onChange } = this.props;
    const newValue = _.cloneDeep(value) || [];
    const col = _.get(newValue, path);

    if (!col.contents) col.contents = [];

    col.contents.push(this._getCompDataforLayout(component));

    onChange(newValue);
  };

  getComponent = (path) => {
    const { value } = this.props;
    let component = _.get(value, path);

    if (component) {
      const components = [
        ...this.state.availableComponents,
        ...(this.state.complexComponents || []),
      ];
      const fullComponent = _.find(components, { _id: component._id });
      component = { ...component, ...fullComponent };
    }

    return component;
  };
}

export default (withRouter(LayoutEditor): React.ComponentType<Props>);
