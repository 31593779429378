const DiscountOrGiftInput = {
  _id: 'DiscountOrGiftInput',
  frontComponent: 'DiscountOrGiftInput',
  label: 'Saisie du code promo (ou cadeau)',
  description:
    "Si l'utilisateur rentre un code cadeau, il sera redirigé sur la page /redeem-code",
  icon: 'percentage',
  optionsSchema: [
    {
      key: 'label',
      label: 'Titre du composant',
      description: 'Sera affiché sur le site',
      type: 'string',
      default: 'Avez-vous un code promo ?',
    },
    {
      key: 'placeholder',
      label: 'Texte de description dans le champ de saisie (placeholder)',
      type: 'string',
      default: 'Votre code promo',
    },
    {
      key: 'buttonLabel',
      label: 'Texte du bouton',
      type: 'string',
    },
  ],
  forPage: true,
  forEntity: false,
};

export default DiscountOrGiftInput;
