const Image = {
  _id: 'simpleImage',
  frontComponent: 'SimpleImage',
  label: 'Image',
  icon: 'image',
  optionsSchema: [
    { key: 'image', label: 'Image', type: 'file' }, // TODO formats
    { key: 'baseline', label: 'Légende', type: 'string' },
    {
      key: 'priority',
      label: 'Prioritaire',
      type: 'bool',
      condition: { f$: 'site.featureFlag_pufComponentV2' },
    },
    {
      key: 'pageId',
      label: 'Lien vers une page',
      type: 'object_id',
      objectType: 'Page',
      condition: { f$: '!obj.link && !site.featureFlag_pufComponentV2' },
    },
    {
      key: 'link',
      label: 'Lien libre (URL)',
      type: 'string',
      condition: { f$: '!obj.pageId && !site.featureFlag_pufComponentV2' },
    },
    {
      key: 'to',
      label: 'Lien',
      type: 'Link',
      condition: { f$: 'site.featureFlag_pufComponentV2' },
    },
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      values: [
        { label: '16/9', value: 16 / 9 },
        { label: '4/3', value: 4 / 3 },
        { label: 'carré', value: 1 / 1 },
        { label: 'portrait', value: 3 / 4 },
        { label: 'cinemascope', value: 2.35 / 1 },
        { label: '5/1', value: 5 / 1 },
        { label: '6/1', value: 6 / 1 },
      ],
    },
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        // TODO import from Front / ENTITY_COMPONENTS_MAP
        { value: null, label: 'Standard' },
        { value: 'textCenter', label: 'Légende centrée' },
        { value: 'textBottom', label: 'Légende en-dessous' },
      ],
      required: true,
    },
    { key: 'width', label: 'Largeur en pixels', type: 'int', min: 0 },
    { key: 'height', label: 'Hauteur en pixels', type: 'int', min: 0 },
    {
      key: 'percent',
      label: "Taille d'affichage en %",
      type: 'int',
      min: 0,
    },
  ],
  forPage: true,
};

export default Image;
