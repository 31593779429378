// @flow
// https://bulma.io/documentation/helpers/spacing-helpers/

import type { BulmaColor, BulmaSize } from 'src/components/types';
type Color = BulmaColor | 'black' | 'white' | 'light';

type Number = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type Direction = 'top' | 'bottom' | 'left' | 'right' | 'x' | 'y';
type SpacingType = 'margin' | 'padding';

type GenerateSpacingClass = (
  SpacingType,
  Number,
  direction?: Direction
) => string;

type SpacingTuple = [SpacingType, Number, Direction];
type MakeSpacingClass = (Array<SpacingTuple>) => string;

const directionToClassMapping = {
  top: 't',
  bottom: 'b',
  left: 'l',
  right: 'r',
  x: 'x',
  y: 'y',
};

export const generateSpacingClass: GenerateSpacingClass = (
  spacingType = 'margin',
  number,
  direction
) => {
  const prefix = spacingType === 'margin' ? 'm' : 'p';
  return `${prefix}${
    !!direction ? directionToClassMapping[direction] : ''
  }-${number}`;
};

export const makeSpacingClass: MakeSpacingClass = (spacings) => {
  return spacings.map((spacing) => generateSpacingClass(...spacing)).join(' ');
};

export const bulmaColorClassName = (color?: Color): string => {
  if (!color) return '';

  switch (color) {
    case 'primary':
      return 'is-primary';
    case 'info':
      return 'is-info';
    case 'danger':
      return 'is-danger';
    case 'warning':
      return 'is-warning';
    case 'success':
      return 'is-success';
    case 'black':
      return 'is-black';
    case 'white':
      return 'is-white';
    default:
      return '';
  }
};

export const bulmaSizeClassName = (size?: BulmaSize): string => {
  if (!size) return '';

  switch (size) {
    case 'small':
      return 'is-small';
    case 'normal':
      return '';
    case 'medium':
      return 'is-medium';
    case 'large':
      return 'is-large';
    default:
      return '';
  }
};
