// @flow
import * as React from 'react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import { JsonEditor as JsonEditorReact } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import type { Resource } from 'src/types/models';

type Props = {|
  resource: Resource,
  onChange: (Resource) => void,
  disabled?: boolean,
|};

// https://github.com/josdejong/jsoneditor/blob/master/docs/api.md#configuration-options

export default function JsonEditor({
  resource,
  onChange,
  disabled,
}: Props): React.Node {
  return (
    <JsonEditorReact
      value={resource}
      ace={ace}
      theme="ace/theme/github"
      allowedModes={['tree', 'form', 'code']}
      mode="code"
      onChange={onChange}
      disabled={disabled}
    />
  );
}
