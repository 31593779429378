// @flow
import * as React from 'react';

import { useGlobal, useResourceContext, useNotification } from 'src/hooks';
import API from 'src/helpers/api/API';

import { Button } from 'src/components';

type Props = {
  style?: Object,
};

function TestEmailAction({ style }: Props): React.Node {
  const { user: admin } = useGlobal();
  const {
    resource: { _id: emailTemplateId },
    apiPath,
  } = useResourceContext();
  const [sending, setSending] = React.useState(false);
  const { force: forceNotification } = useNotification();

  const sendTest = React.useCallback(async () => {
    setSending(true);
    try {
      await API.get(`${apiPath}/${emailTemplateId}/test`);
      forceNotification({
        type: 'success',
        text: `L'email de test a été envoyé à votre adresse ${admin.login}`,
      });
    } finally {
      setSending(false);
    }
  }, [emailTemplateId, forceNotification]);

  return (
    <Button onClick={sendTest} style={style} disabled={sending}>
      {sending ? 'Envoi...' : 'Test'}
    </Button>
  );
}

export default TestEmailAction;
