// @flow
// File upload based on https://github.com/SoAanyip/React-FileUpload

import * as React from 'react';
import Dropzone from 'react-dropzone';
import request from 'superagent';
import prettyBytes from 'pretty-bytes';

import type { APIContextType } from 'src/pages/contexts';

import invariant from 'src/helpers/invariant';
import API from 'src/helpers/api/API.js';
import { withContext } from 'src/utils/HOC';
import { APIContext } from 'src/pages/contexts';

import {
  ProgressBar,
  Icon,
  Centered,
  Flex,
  Image,
  Card,
  CardHeader,
  CardImage,
  CardFooter,
  Tag,
  Input,
  Field,
  Button,
  Label,
  Control,
} from 'src/components';

type File = {|
  name: string,
  value: string,
  url?: string,
  preview?: string,
|};

type OwnProps = {|
  value: ?string,
  onChange: (?string) => *,
  disabled?: boolean,
  user?: any,
  multiple?: boolean,
  maxFiles?: number,
  accept?: string,
|};

type Props = {|
  ...OwnProps,
  ...APIContextType,
|};

type State = {|
  uploading: boolean,
  progress: number,
  progressSize: number,
  sizeColor?: 'success' | 'warning' | 'danger' | null,
  humanSize?: number,
  typedValue: string,
|};

type GetFormattedField = () => Array<File>;
type OnDrop = (files: Array<File>) => void;

const MAX_WIDTH = 250;

class DropZone extends React.Component<Props, State> {
  state: State = {
    uploading: false,
    progress: 0,
    progressSize: 0,
    typedValue: this.props.value || '',
  };

  getFormattedFiles: GetFormattedField = () => {
    const { value, getImageUrl } = this.props;

    return !!value
      ? [
          {
            name: value.startsWith('data:') ? '(inline image data)' : value,
            value,
            url: value.startsWith('data:') ? value : getImageUrl(value),
          },
        ]
      : [];
  };

  onDrop: OnDrop = (files) => {
    const { apiImageUploadUrl } = this.props;
    const req = request.post(apiImageUploadUrl).withCredentials();
    req.timeout(3600 * 1000);

    this.setState({ progress: 0, progressSize: 0, uploading: true });

    req.on('progress', (e) =>
      this.setState({ progress: e.percent, progressSize: e.loaded })
    );

    files.forEach((file) => {
      req.attach('file', file);
    });

    req.end(async (error, res) => {
      this.setState({ progress: 0, progressSize: 0, uploading: false });

      if (error) {
        console.error('upload error', error);
      } else {
        files.forEach((file) => {
          if (res.body && res.body.result.indexOf(file.name) !== -1) {
            file.url = res.body.result;
          }
        });

        // TODO multiple files
        this.props.onChange(res.body.result);
        this.setState({ typedValue: res.body.result });
      }

      await this.getSizes();
    });
  };

  async getSizes() {
    const { value: imagePath, siteId } = this.props;

    if (!imagePath) return;

    const { size, humanSize } = await API.get(`${siteId}/images/info`, {
      imagePath,
    });

    let sizeColor;
    switch (true) {
      case size <= 20000: // 20Kb
        sizeColor = 'success';
        break;
      case size <= 50000: // 50Kb
        sizeColor = 'warning';
        break;
      default:
        sizeColor = 'danger';
    }

    this.setState({
      sizeColor,
      humanSize,
    });
  }

  async componentDidMount() {
    await this.getSizes();
  }

  render(): React.Node {
    const {
      onChange,
      disabled,
      value,
      user,
      multiple = false,
      maxFiles = 1,
      accept,
    } = this.props;
    const {
      uploading,
      progress,
      progressSize,
      sizeColor,
      humanSize,
      typedValue,
    } = this.state;
    const files = this.getFormattedFiles();
    const uploadingFiles = files.filter(({ preview }) => !!preview);
    const uploadedFiles = files.filter(({ url }) => !!url);

    return (
      <>
        <Dropzone
          onDrop={this.onDrop}
          className="input"
          disabled={disabled}
          multiple={multiple}
          maxFiles={maxFiles}
          accept={accept}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="box" {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Cliquez ou déposez vos fichers ici</p>

              {uploading && (
                <Centered>
                  <Icon name="spinner" size="3x" pulse />
                </Centered>
              )}

              {uploadingFiles && (
                <Flex>
                  {uploadingFiles.map(({ preview, name }) => {
                    invariant(!!preview);

                    return (
                      <Card key={preview} style={{ maxWidth: MAX_WIDTH }}>
                        <CardHeader title={name} />
                        <CardImage>
                          <Image
                            src={preview}
                            alt="preview"
                            width={MAX_WIDTH}
                          />
                        </CardImage>
                      </Card>
                    );
                  })}
                </Flex>
              )}

              {uploading && (
                <ProgressBar value={progress}>
                  {prettyBytes(progressSize)}
                </ProgressBar>
              )}
            </div>
          )}
        </Dropzone>

        {uploadedFiles.length > 0 && (
          <Flex>
            {files
              .filter(({ url }) => !!url)
              .map(({ url, name, preview }) => {
                invariant(url, 'Url should exist after upload');
                const src = url;

                return (
                  <Card key={url} style={{ maxWidth: MAX_WIDTH }}>
                    <CardHeader
                      title={name}
                      action={{ icon: 'times', onClick: () => onChange(null) }}
                    />

                    <CardImage>
                      <Image src={src} alt="preview" />
                    </CardImage>

                    {humanSize && sizeColor && (
                      <CardFooter
                        style={{ justifyContent: 'center', padding: 10 }}
                      >
                        <Tag color={sizeColor}>Taille : {humanSize}</Tag>
                      </CardFooter>
                    )}
                  </Card>
                );
              })}
          </Flex>
        )}

        {/* Added for dev only, to directly type an image path */}
        {value && user?.isDeveloper && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onChange(typedValue);
            }}
            style={{
              marginTop: 10,
              padding: 15,
              background: '#edf6fc',
              borderRadius: 4,
            }}
          >
            <Field>
              <Label tooltip="Disponible pour les devs uniquement">
                Entrer un path d'image manuellement si nécessaire
              </Label>

              <Control style={{ display: 'flex' }}>
                <Input
                  value={typedValue}
                  onChange={(newValue) =>
                    this.setState({ typedValue: newValue || '' })
                  }
                  placeholder="Entrer un path d'image manuellement"
                  style={{ marginRight: 10 }}
                />
                <Button type="submit">Appliquer</Button>
              </Control>
            </Field>
          </form>
        )}
      </>
    );
  }
}

export default (withContext(
  APIContext,
  DropZone
): React.ComponentType<OwnProps>);
