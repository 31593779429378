const UserAccountButtons = {
  _id: 'userButtonsCustom',
  frontComponent: 'UserButtonsCustom',
  label: 'Boutons compte utilisateur',
  icon: 'user',
  optionsSchema: [
    {
      key: 'buttons',
      label: 'Boutons',
      type: 'list',
      itemSchema: {
        _id: 'UserButtonOptions',
        type: 'object',
        propertiesList: [
          {
            key: 'buttonType',
            label: 'Fonction',
            type: 'enum',
            values: [
              { value: 'login', label: 'Connexion' },
              { value: 'logout', label: 'Déconnexion' },
              { value: 'register', label: 'Inscription' },
              { value: 'account', label: 'Mon Compte' },
              { value: 'search', label: 'Recherche' },
              { value: 'gift', label: 'Offrir' },
              { value: 'redeemGift', label: "J'ai un code cadeau" },
              {
                value: 'account/invoices',
                label: 'Mon Compte > Mes factures',
              },
              { value: 'account/profile', label: 'Mon Compte > Mon profil' },
              {
                value: 'account/subscriptions',
                label: 'Mon Compte > Mon abonnement',
              },
              {
                value: 'account/paymentMeans',
                label: 'Mon Compte > Mes moyens de paiement',
              },
              {
                value: 'account/purchases',
                label: 'Mon Compte > Mes achats',
              },
              { value: 'resubscribe', label: 'Réabonnement' },
            ],
            required: true,
          },
          { key: 'label', label: 'Libellé', type: 'string' },
          {
            key: 'icon',
            label: 'Icône',
            type: 'object',
            objectType: ['FrontIcon'],
            default: null,
            toggleable: true,
          },
          {
            key: 'buttonStyle',
            label: 'Style',
            type: 'enum',
            values: [
              { value: '', label: 'Normal' },
              { value: 'highlight', label: 'Mis en évidence' },
              { value: 'standard-button', label: 'Bouton classique' },
              {
                value: 'standard-button highlight',
                label: 'Bouton mis en évidence',
              },
            ],
            required: false,
            condition: { f$: "obj.buttonType !== 'search'" },
          },
        ],
      },
      default: 'Se connecter',
    },
  ],
  forPage: true,
};

export default UserAccountButtons;
