// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import classnames from 'classnames';
import { kebabCase } from 'lodash';
import { SWRConfig } from 'swr';

import config from './helpers/api/config';
import { ENVIRONMENT_COLOR_SET } from './constants/style';
import { assert } from './helpers/invariant';

import devIcon from './images/icon_puf_dev.png';
import stagingIcon from './images/icon_puf_staging.png';
import prodIcon from './images/icon_puf_prod.png';

import AppRouter from './pages';
import ModalContext from './pages/contexts/ModalContext';

const ICONS = {
  dev: devIcon,
  staging: stagingIcon,
  prod: prodIcon,
};

export default function App(): React.Node {
  const [sidebarToggled, setSidebarToggled] = React.useState(false);

  React.useEffect(() => {
    const environmentColorSet = ENVIRONMENT_COLOR_SET[config.PUF_ENV];

    const documentElement = assert(
      document.documentElement,
      'Document always exists at this point'
    );

    Object.keys(environmentColorSet).map((CSSVariableName) =>
      documentElement.style.setProperty(
        `--environment-${kebabCase(CSSVariableName)}`,
        environmentColorSet[CSSVariableName]
      )
    );
  }, []);

  return (
    <SWRConfig
      value={{
        fetcher: SWRFetcher,
      }}
    >
      <BrowserRouter>
        <RecoilRoot>
          <ModalContext.Provider
            value={{
              depth: 0,
            }}
          >
            <div
              className={classnames('app', config.PUF_ENV, {
                'sidebar-toggled': sidebarToggled,
              })}
            >
              <Helmet>
                <title>PUF Admin {config.PUF_ENV}</title>
                <link
                  rel="icon"
                  type="image/png"
                  href={ICONS[config.PUF_ENV]}
                />
              </Helmet>

              <span onClick={setSidebarToggled} className="ToggleMenuButton">
                <span>Menu</span>
              </span>

              <AppRouter />

              <div id="modal-portal" />
              <div id="dnd-portal" />
              <div id="select-portal" />
              <div id="datepicker-portal" />
            </div>
          </ModalContext.Provider>
        </RecoilRoot>
      </BrowserRouter>
    </SWRConfig>
  );
}

const SWRFetcher = async (url, params) => {
  try {
    const response = await fetch(
      `${config.API_URL}/admin/${url}?${new URLSearchParams(
        params
      ).toString()}`,
      {
        credentials: 'include',
      }
    );
    const { result, error } = await response.json();

    if (error) throw new Error(error.reason);

    return result;
  } catch (e) {
    return e;
  }
};
