// @flow
import * as React from 'react';

import type { User } from 'src/types/models';
import type { Tab } from '../';

import { Icon } from 'src/components';
import ExecuteTab from './ExecuteTab';
import ExportTab from './ExportTab';

const genericTabs: Array<Tab> = [
  {
    to: 'export',
    label: (
      <span>
        <Icon name="file-export" /> Export
      </span>
    ),
    // $FlowIgnore
    content: ExportTab,
  },
  {
    to: 'execute',
    label: (
      <span>
        <Icon name="wrench" color="danger" /> Exécuter
      </span>
    ),
    content: ExecuteTab,
    right: 'superAdmin',
    condition: (user: User) => user.isDeveloper,
  },
];

export default genericTabs;
