// @flow
import * as React from 'react';

import Header from './Header';
import Sidebar from './Sidebar';
import MainContainer from './MainContainer';

type Props = {|
  children: React.Node,
  withSidebar: boolean,
|};

export default function AppLayout({
  children,
  withSidebar,
}: Props): React.Node {
  return (
    <div className="app">
      <Header />

      <MainContainer>
        {withSidebar && (
          <div className="app-navigation">
            <Sidebar />
          </div>
        )}

        <div className="app-content">{children}</div>
      </MainContainer>
    </div>
  );
}
