// @flow
import * as React from 'react';
import { map, assign } from 'lodash';
import classnames from 'classnames';

import { useOnKeyDown } from 'src/hooks';
import ModalContext from 'src/pages/contexts/ModalContext';

import { Flex, Text } from 'src/components';
import GlobalModalElement from './GlobalModalElement';

type ButtonText = string;

type Props = {|
  buttons?: { [buttonKey: string]: ButtonText },
  modal: boolean,
  onClose: (buttonText?: string) => any,
  modalClassName?: string,
  open: boolean,
  contentClassName?: string,
  children: React.Node,
  title?: string,
  size?: 'full' | 'two-third' | 'half',
|};

export default function Modal({
  buttons: buttonsFromProps,
  modal,
  onClose,
  modalClassName: modalClassNameFromProps,
  open,
  contentClassName: contentClassNameFromProps,
  children,
  title,
  size = 'two-third',
}: Props): React.Node {
  const buttons = assign(
    {},
    buttonsFromProps || { cancel: 'Annuler', ok: 'Ok' }
  );

  const modalContext = React.useContext(ModalContext);
  const modalClassName = classnames(
    'Modal',
    `depth-${modalContext.depth}`,
    modalClassNameFromProps,
    {
      'Modal--is-modal': modal,
    }
  );
  const contentClassName = classnames(
    'Modal-inner-wrapper',
    contentClassNameFromProps
  );
  const closeModal = React.useCallback((key) => onClose && onClose(key), [
    onClose,
  ]);

  useOnKeyDown(
    {
      onEscapeKeyDown: () => {
        if (open) {
          modal ? closeModal('cancel') : closeModal();
        }
      },
    },
    [closeModal]
  );

  return (
    <GlobalModalElement>
      <div className={modalClassName}>
        {open && (
          <div className="Modal-wrapper" onClick={() => closeModal('cancel')}>
            <div
              className={classnames(
                'wrapper',
                'flex-wrapper',
                'Modal-inner',
                `Modal-inner--${size}`
              )}
            >
              <div
                className={contentClassName}
                onClick={(e) => e.stopPropagation()}
              >
                {!modal && onClose && (
                  <span className="Modal-close-modal" onClick={closeModal}>
                    ✖
                  </span>
                )}

                <div className="Modal-content">
                  {title && (
                    <Text
                      isTitle
                      size={4}
                      additionalClassName="Modal-content-title"
                      element="h4"
                    >
                      {title}
                    </Text>
                  )}

                  <ModalContext.Provider
                    value={{
                      depth: modalContext.depth + 1,
                    }}
                  >
                    {children}
                  </ModalContext.Provider>
                </div>

                {modal && (
                  <Flex
                    style={{ justifyContent: 'flex-end' }}
                    additionalClassName="Modal-dialog"
                  >
                    {map(
                      buttons,
                      (label: string, key: string) =>
                        label && (
                          <span
                            className="Modal-dialog-button"
                            onClick={() => closeModal(key)}
                            key={'modal-button-' + key}
                          >
                            {label}
                          </span>
                        )
                    )}
                  </Flex>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </GlobalModalElement>
  );
}
