// @flow
import _ from 'lodash';
import * as React from 'react';

import { makeSpacingClass } from 'src/helpers/style';

import { Text, Icon } from 'src/components';
import PropertyRenderer from 'src/pages/components/resource/PropertyRenderer';
// import { getRenderer } from 'src/pages/SectionRouter/components/crud/CustomRenderers';
import DisplayConditionRenderer from 'src/pages/components/resource/PropertyRenderer/new/customPropertyRenderers/DisplayConditionRenderer';

type Props = {|
  component: any,
|};

const RENDER_OPTIONS = {
  image: { h: 60 },
};

export default function LayoutComponent({ component }: Props): React.Node {
  const {
    options = {},
    label: componentLabel,
    icon,
    optionsSchema,
    _cls: componentClass,
  } = component;
  const listWithLabel =
    !!options && !!options.menuItems
      ? options.menuItems.filter((item) => !!item)
      : [];
  const { mode } = options || {};
  const hasName = optionsSchema?.some((option) => option.key === 'name');

  return (
    <>
      <Text
        element="h5"
        size={6}
        additionalClassName={makeSpacingClass([['margin', 2, 'bottom']])}
      >
        {icon && <Icon name={icon} />} {componentLabel}
        {componentClass !== 'ComplexComponent' && hasName && (
          <>
            {' - '}
            <Text weight="bold" color={options.name ? 'info' : 'danger'}>
              {options.name || 'Nom manquant'}{' '}
            </Text>
          </>
        )}
        {mode && <span className="LayoutComponent-mode">(mode: {mode})</span>}
      </Text>

      {optionsSchema &&
        optionsSchema.map((propertySchema) => {
          const { key } = propertySchema;

          if (!['image', 'body', 'list'].includes(key)) return null;

          return (
            <PropertyRenderer
              propertySchema={propertySchema}
              propertyValue={options[key]}
              options={RENDER_OPTIONS[key] || {}}
              key={key}
            />
          );
        })}

      {!!listWithLabel && (
        <ul style={{ fontSize: '0.75em', margin: 0 }}>
          {listWithLabel.slice(0, 3).map((item, i) => (
            <li key={i}>{item.label || item.buttonType}</li>
          ))}
          {listWithLabel.length > 3 && '...'}
        </ul>
      )}

      {!!options && !!options.propertyRef && (
        <div>
          <small>
            {_.get(
              options,
              'propertyRef.prop.label',
              _.get(options, 'propertyRef.prop.ley')
            )}
          </small>
        </div>
      )}

      {!!options &&
        !!options.displayConditions &&
        !_.isEmpty(options.displayConditions) && (
          <div>
            <small>
              <u>Conditions&nbsp;: </u>

              {options.displayConditions.map((condition, i) => (
                <span key={i} className="comma-separated">
                  <DisplayConditionRenderer value={condition} mode="layout" />
                </span>
              ))}
            </small>
          </div>
        )}
    </>
  );
}
