// @flow
import * as React from 'react';
import _ from 'lodash';

import { OldLabel, OldFormElement } from 'src/components';

type InputType = 'text' | 'password' | 'email' | 'tel';
// type InputState = 'normal' | 'hovered' | 'focused';

type Props = {|
  value: string,
  onChange: (value: string, isMajor: boolean) => any,
  label: string,
  id?: string,
  multiline?: boolean,
  required?: boolean,
  type?: InputType,
  initialValue?: string,
  handleFocus?: (SyntheticFocusEvent<*>, boolean) => any,
  error?: string,
  onBlur?: () => any,
  onFocus?: () => any,
  className?: string,
  maxChar?: number,
  disabled?: boolean,
  tooltip?: string,
  name?: string,
  placeholder?: string,
|};

type DefaultProps = {|
  multiline: boolean,
  required: boolean,
  type: 'text',
  disabled: boolean,
|};

type State = {|
  hasFocus: boolean,
  stillEditing: boolean,
|};

export default class OldInput extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    multiline: false,
    required: false,
    type: 'text',
    disabled: false,
  };

  state: State = {
    hasFocus: false,
    stillEditing: false,
  };

  handleFocus(e: SyntheticFocusEvent<*>, hasFocus: boolean) {
    this.setState({ hasFocus: hasFocus });

    this.props.handleFocus && this.props.handleFocus(e, hasFocus);
    if (hasFocus && this.props.onFocus) this.props.onFocus();
    if (!hasFocus && this.props.onBlur) this.props.onBlur();

    if (!hasFocus) {
      this.setState({ stillEditing: false });
    }
  }

  onChangeEvent(e: SyntheticInputEvent<*>) {
    this.onChange(e.target.value, !this.state.stillEditing);
    this.setState({ stillEditing: true });
  }

  onChange(fieldValue: string, isMajorChange: boolean) {
    this.props.onChange(fieldValue, isMajorChange);
  }

  render(): React.Node {
    const {
      id,
      type,
      value,
      name,
      error,
      maxChar,
      placeholder,
      multiline,
      label,
      required,
      tooltip,
      disabled,
    } = this.props;
    const { hasFocus } = this.state;
    const Element = multiline ? 'textarea' : 'input';

    return (
      <OldFormElement
        disabled={disabled}
        hasFocus={hasFocus}
        elementClass="FormElement-input-text-element"
        error={error}
      >
        <div className="flex-wrapper flex-column FormElement-inner">
          <OldLabel label={label} required={required} tooltip={tooltip} />

          <Element
            id={id}
            type={type}
            placeholder={placeholder}
            name={name} // for auto-complete
            value={_.isNil(value) ? '' : value}
            className={
              error
                ? 'FormElement-input-text FormElement-input-text--error'
                : 'FormElement-input-text'
            }
            onChange={(e) => this.onChangeEvent(e)}
            onFocus={(e) => this.handleFocus(e, true)}
            onBlur={(e) => this.handleFocus(e, false)}
            rows="5"
          />
        </div>

        {maxChar && (
          <div className="FormElement-count-char">
            {(value && value.length) || '0'} / {maxChar}
          </div>
        )}

        {error && <div className="FormElement-error-message">{error}</div>}
      </OldFormElement>
    );
  }
}
