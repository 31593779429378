// @flow
import * as React from 'react';

import type { ResourceSchema, Resource } from 'src/types/models';

import { useStore } from 'src/hooks';
import { urlize } from 'src/helpers/url';

import { Field, Control, Label, Input } from 'src/components';

type Props = {|
  schema: ResourceSchema,
  resource: Resource,
|};

export default function ContentPublicLink({ resource }: Props): React.Node {
  const { instance } = useStore('InstanceStore');

  if (!resource?._id) return null;

  const labelPart = (resource.label ? '/' + urlize(resource.label) : '').slice(
    0,
    75
  );

  const publicUrl = new URL(instance.frontUrl);
  publicUrl.pathname = `/o/${encodeURIComponent(
    resource._cls
  )}/${encodeURIComponent(resource._id)}${labelPart}`;

  return (
    <Field additionalClassName="public-link">
      <Label>Lien public :</Label>

      <Control>
        <Input
          type="text"
          value={publicUrl.href}
          onFocus={(e) => e.target.select()}
          readOnly={true}
        />
      </Control>
    </Field>
  );
}
