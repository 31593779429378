// @flow

function toArray(obj: Object): Array<*> {
  var array = [];
  // iterate backwards ensuring that length is an UInt32
  for (var i = obj.length >>> 0; i--; ) {
    array[i] = obj[i];
  }
  return array;
}

const dataTransferInProtectedMode: Object = {};

const utils = {
  toArray: toArray,
  dataTransferInProtectedMode,
};

export default utils;
