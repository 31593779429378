// @flow
import * as React from 'react';

import { keys, values } from 'lodash';
import { getDisplayName } from 'src/helpers/react';

import {
  constraintToViewPortMapping,
  type ViewPortConstraint,
} from './ViewPort';

type ComponentPerViewPortConditions = {
  [ViewPortConstraint]: React.AbstractComponent<*>,
};

export default function viewPortAware(
  componentPerViewPortConditions: ComponentPerViewPortConditions
): React.AbstractComponent<*> {
  class ViewPortAwareWrapper extends React.Component<any> {
    render() {
      const viewPortConditions = ((keys(
        componentPerViewPortConditions
      ): any): Array<ViewPortConstraint>);

      return (
        <React.Fragment>
          {viewPortConditions.map((viewPortConstraint: ViewPortConstraint) => {
            const ViewPortComponent =
              constraintToViewPortMapping[viewPortConstraint];
            const MatchingComponent =
              componentPerViewPortConditions[viewPortConstraint];

            return (
              <ViewPortComponent key={viewPortConstraint}>
                <MatchingComponent {...this.props} />
              </ViewPortComponent>
            );
          })}
        </React.Fragment>
      );
    }
  }

  const baseComponentName = getDisplayName(
    values(componentPerViewPortConditions)[0]
  );

  ViewPortAwareWrapper.displayName = `ViewPortAware(${baseComponentName})`;

  return ViewPortAwareWrapper;
}
