// @flow
// DatePicker using https://github.com/Hacker0x01/react-datepicker/  https://reactdatepicker.com/

import * as React from 'react';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

import { OldLabel, OldFormElement } from 'src/components';

type Props = {|
  value: ?number,
  onChange: (?number) => any,
  label?: string,
  required?: boolean,
  tooltip?: string,
|};

type DefaultProps = {|
  required: boolean,
|};

type State = {|
  error: ?string,
  textValue: ?string,
  realValue: ?number,
  modified: boolean,
|};

export default class OldDatePicker extends React.Component<Props, State> {
  static defaultProps: DefaultProps = {
    required: false,
  };

  state: State = {
    error: null,
    textValue: null,
    realValue: null,
    modified: false,
  };

  componentWillMount() {
    this.onSetValue(this.props.value);
  }

  componentWillReceiveProps(nextProps: Props) {
    this.onSetValue(nextProps.value);
  }

  onSetValue(value: ?number) {
    this.setState({
      error: null,
      textValue: (value && moment.unix(value).format('DD/MM/YYYY HH:mm')) || '',
      realValue: value,
      modified: false,
    });
  }

  onBlur() {
    const { onChange } = this.props;
    const { modified, realValue } = this.state;
    modified && onChange && onChange(realValue);
  }

  setArbitraryDate(dateString: string) {
    const { onChange } = this.props;

    if (!onChange) return;

    if (dateString === 'today') {
      onChange(moment().startOf('day').unix());
    } else if (dateString === 'tomorrow') {
      onChange(moment().startOf('day').add(1, 'days').unix());
    } else if (dateString === 'now+1') {
      onChange(moment().add(1, 'hours').unix());
    } else if (dateString === 'now') {
      onChange(moment().unix());
    }
  }

  onChangeText: (e: SyntheticInputEvent<*>) => void = (e) => {
    const textValue = e.target.value;

    if (!textValue) {
      this.setState({
        error: null,
        textValue: textValue,
        realValue: null,
        modified: true,
      });
    } else {
      var date = textValue && moment(textValue, 'L LT');

      if (date.isValid()) {
        //this.props.onChangeValue(date.unix());

        this.setState({
          error: null,
          textValue: textValue,
          realValue: date.unix(),
          modified: true,
        });
      } else {
        this.setState({
          error: 'Invalid date',
          textValue: textValue,
          realValue: null,
          modified: false,
        });
      }
    }
  };

  render(): React.Node {
    const { value, label, tooltip, required } = this.props;
    const { error, modified, realValue, textValue } = this.state;

    let explainedDate = '';
    const displayedValue = modified ? realValue : value;

    if (!error && displayedValue && value) {
      let d = moment.unix(value).calendar(null, { sameElse: 'L LT' });
      if (d !== textValue) {
        explainedDate = d;
      }
    }

    return (
      <OldFormElement elementClass="FormElement-input-date-element">
        <div className="flex-wrapper flex-column FormElement-inner">
          <OldLabel label={label} tooltip={tooltip} required={required} />

          <div className="FormElement-date-picker">
            <input
              type="text"
              onChange={this.onChangeText}
              value={textValue}
              className="FormElement-input-text date"
              onBlur={this.onBlur.bind(this)}
            />
            <span>
              <span
                className="FormElement-tags-list-item"
                onClick={() => this.setArbitraryDate('now')}
              >
                maintenant
              </span>
              <span
                className="FormElement-tags-list-item"
                onClick={() => this.setArbitraryDate('now+1')}
              >
                +1h
              </span>
              <span
                className="FormElement-tags-list-item"
                onClick={() => this.setArbitraryDate('today')}
              >
                aujourd'hui
              </span>
              <span
                className="FormElement-tags-list-item"
                onClick={() => this.setArbitraryDate('tomorrow')}
              >
                demain
              </span>
              <span
                className="FormElement-tags-list-item"
                onClick={() => this.props.onChange(null)}
              >
                aucune
              </span>
            </span>
            <div>
              <i>{explainedDate}</i>
            </div>

            {error && <span className="error">{error}</span>}
          </div>
        </div>
      </OldFormElement>
    );
  }
}
