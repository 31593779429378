// @flow

import * as React from 'react';

import type { ResourceSchema } from 'src/types/models';

import { assert } from 'src/helpers/invariant';
import RESOURCE_TYPES from '../constants/resources';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { useGlobal } from 'src/hooks';
import { getResourcePath } from 'src/helpers/url';

import { Route, Redirect, Switch, type ContextRouter } from 'react-router-dom';
import ResourceCollection from './Collection';
import ResourceShow from './Show';
import ResourceNew from './New';

type Props = {|
  schema: ResourceSchema,
  ...ContextRouter,
|};

export default function ResourceRouter({ schema, match }: Props): React.Node {
  const { siteId } = useGlobal();
  const filters = schema._id ? RESOURCE_TYPES[schema._id].filters : undefined;
  const schemaPath = getResourcePath(siteId, schema);
  const schemaContextValue = {
    schema,
    path: schemaPath,
  };

  if (schema.isSingleton) {
    return (
      <SchemaContext.Provider value={schemaContextValue}>
        <Switch>
          <Route
            sensitive
            exact
            path={`${schemaPath}/new`}
            key={`${schemaPath}-new`}
            render={(routeProps) => <ResourceNew {...routeProps} />}
          />

          <Route
            sensitive
            path={`${schemaPath}/:id`}
            key={`${schemaPath}-show`}
            render={(routeProps) => {
              const id = assert(
                routeProps.match.params.id,
                'Id should be defined in match.params'
              );

              return <ResourceShow {...routeProps} id={id} />;
            }}
          />

          <Route>
            <Redirect to={`${schemaPath}/${schema.singletonId}`} />
          </Route>
        </Switch>
      </SchemaContext.Provider>
    );
  }

  return (
    <SchemaContext.Provider value={schemaContextValue}>
      <Switch>
        <Route
          sensitive
          path={`${schemaPath}/collection`}
          key={`${schemaPath}/collection`}
          render={(routeProps) => <ResourceCollection {...routeProps} />}
        />

        {!!filters &&
          filters.map((filter) => (
            <Route
              sensitive
              path={`${schemaPath}/${filter.pathname}/collection`}
              key={`${schemaPath}?filter=${filter.pathname}`}
              exact
              render={(routeProps) => (
                <ResourceCollection {...routeProps} query={filter.query} />
              )}
            />
          ))}

        <Route
          sensitive
          exact
          path={`${schemaPath}/new`}
          key={`${schemaPath}-new`}
          render={(routeProps) => <ResourceNew {...routeProps} />}
        />

        <Route
          sensitive
          path={`${schemaPath}/:id`}
          key={`${schemaPath}-show`}
          render={(routeProps) => {
            const id = assert(
              routeProps.match.params.id,
              'Id should be defined in match.params'
            );

            return <ResourceShow {...routeProps} id={id} />;
          }}
        />

        <Route>
          <Redirect to={`${schemaPath}/collection`} />
        </Route>
      </Switch>
    </SchemaContext.Provider>
  );
}
