// @flow
import * as React from 'react';
import { differenceBy } from 'lodash';
import useFuse from 'use-fuse';
import { DiffLines } from 'react-diff-components';
import _ from 'lodash';

import type { GlobalSchema, ResourceSchema } from 'src/types/models';

import config from 'src/helpers/api/config';

import {
  Button,
  Input,
  Field,
  Control,
  Icon,
  Text,
  Columns,
  Column,
} from 'src/components';

type Props = {|
  source: GlobalSchema,
  target: GlobalSchema,
  copyToSource: (string) => void,
  copyToTarget: (string) => void,
|};

// see: https://fusejs.io/
const fuseOptions = {
  keys: ['_id', 'label'],
  threshold: 0.2,
  minMatchCharLength: 3,
};

export default function ResourceSchemaMigrator({
  source,
  target,
  copyToSource,
  copyToTarget,
}: Props): React.Node {
  const [search, setSearch] = React.useState('');
  const formattedSource = ((Object.values(source): any): Array<ResourceSchema>);
  const formattedTarget = ((Object.values(target): any): Array<ResourceSchema>);

  const notExistingInSource = React.useMemo(
    () => differenceBy(formattedTarget, formattedSource, '_id'),
    [source, target]
  );
  const notExistingInTarget = React.useMemo(
    () => differenceBy(formattedSource, formattedTarget, '_id'),
    [source, target]
  );

  const fusedSource = useFuse(formattedSource, search, fuseOptions);
  // const fusedTarget = useFuse(formattedTarget, search, fuseOptions);
  const fusedNotExistingInSource = useFuse(
    notExistingInSource,
    search,
    fuseOptions
  );
  const fusedNotExistingInTarget = useFuse(
    notExistingInTarget,
    search,
    fuseOptions
  );

  const filteredSource: Array<ResourceSchema> = !!search
    ? fusedSource
    : formattedSource;
  // const filteredTarget = !!search ? fusedTarget : formattedTarget;
  const filteredNotExistingInSource = !!search
    ? fusedNotExistingInSource
    : notExistingInSource;
  const filteredNotExistingInTarget = !!search
    ? fusedNotExistingInTarget
    : notExistingInTarget;

  return (
    <>
      <Columns>
        <Column>
          <Text element="h3" size={5} isTitle color="primary">
            Schéma de l'environnement "source" (courant)
          </Text>
        </Column>
        <Column>
          <Text element="h3" size={5} isTitle color="primary">
            Schéma de l'environnement "target" (à comparer)
          </Text>
        </Column>
      </Columns>

      <Field>
        <Control style={{ maxWidth: 300 }}>
          <Input
            value={search}
            onChange={setSearch}
            placeholder="Recherche schéma via _id et label"
          />
        </Control>
      </Field>

      {filteredSource.map((sourceSchema: ResourceSchema) => {
        const key = sourceSchema._id;
        const targetSchema = target[sourceSchema._id];

        if (!targetSchema) return null;

        if (_.isEqual(sourceSchema, targetSchema))
          return (
            <div key={key}>
              ✅ {sourceSchema.label} ({key})
            </div>
          );

        return (
          <div key={key} className="flex-wrapper row">
            <div className="col one-half-col code-diff">
              <b>{key}</b> ({config.PUF_ENV})
              <Button
                onClick={() => copyToTarget(key)}
                additionalClassName="staging"
              >
                Copier vers staging
                <Icon name="arrow-right" style={{ marginLeft: 5 }} />
              </Button>
              <DiffLines
                //hideRemoved
                from={JSON.stringify(targetSchema, null, 4) || ''}
                to={JSON.stringify(sourceSchema, null, 4) || ''}
                type="chars"
              />
            </div>
            <div className="col one-half-col code-diff">
              <b>{key}</b> (serveur distant)
              <Button
                onClick={() => copyToSource(key)}
                additionalClassName={config.PUF_ENV}
              >
                <Icon name="arrow-left" style={{ marginRight: 5 }} />
                Copier vers {config.PUF_ENV} (Environnement actuel)
              </Button>
              <DiffLines
                to={JSON.stringify(targetSchema, null, 4) || ''}
                from={JSON.stringify(sourceSchema, null, 4) || ''}
                type="chars"
              />
            </div>
          </div>
        );
      })}

      {filteredNotExistingInSource.map((schema: ResourceSchema) => {
        const key = schema._id;

        return (
          <div key={key}>
            <div key={key} className="flex-wrapper row">
              <div className="col one-half-col">
                <b>{key}</b> ({config.PUF_ENV})
                <Button
                  onClick={() => copyToTarget(key)}
                  additionalClassName={config.PUF_ENV}
                >
                  Copier vers staging (devserver)
                </Button>
              </div>
            </div>
          </div>
        );
      })}

      {filteredNotExistingInTarget.map((schema: ResourceSchema) => {
        const key = schema._id;

        return (
          <div key={key}>
            <div key={key} className="flex-wrapper row">
              <div className="col one-half-col">
                <b>{key}</b> (serveur distant)
                <Button
                  onClick={() => copyToSource(key)}
                  additionalClassName={config.PUF_ENV}
                >
                  Copier vers {config.PUF_ENV}
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
