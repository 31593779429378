// @flow
import moment from 'moment';

import type { ResourceType } from 'src/types/models';
import type { Filter } from 'src/types/routing';

export type ResourceDescription = {|
  singular: ResourceType,
  plural: string,
  pluralLabel?: string,
  filters?: Array<Filter>,
|};

const RESOURCE_TYPES: { [key: ResourceType]: ResourceDescription } = {
  AbowebActionLog: {
    singular: 'AbowebActionLog',
    plural: 'AbowebActionLogs',
    pluralLabel: 'Logs de la synchro aboweb',
  },
  AccountingExportItem: {
    singular: 'AccountingExportItem',
    plural: 'AccountingExportItems',
    pluralLabel: 'Prestations pour export comptable',
  },
  Address: {
    singular: 'Address',
    plural: 'Addresses',
    pluralLabel: 'Adresses postales',
  },
  AdminUser: {
    singular: 'AdminUser',
    plural: 'AdminUsers',
    pluralLabel: 'Administrateurs',
  },
  Answer: {
    singular: 'Answer',
    plural: 'Answers',
    pluralLabel: 'Réponses',
  },
  Arrondissement: {
    singular: 'Arrondissement',
    plural: 'Arrondissements',
    pluralLabel: 'Arrondissements',
  },
  Author: {
    singular: 'Author',
    plural: 'Authors',
    pluralLabel: 'Auteurs / intervenants',
  },
  Bookmark: {
    singular: 'Bookmark',
    plural: 'Bookmarks',
    pluralLabel: 'Liens externes',
  },
  Channel: {
    singular: 'Channel',
    plural: 'Channels',
    pluralLabel: 'Canals de diffusions des contenus',
  },
  ComplexComponent: {
    singular: 'ComplexComponent',
    plural: 'ComplexComponents',
    pluralLabel: 'Composants personnalisés',
    filters: [
      {
        label: 'Composants standards',
        pathname: 'standard',
        query: {
          filterBy: {
            objectType: { $eq: [] },
          },
        },
      },
      {
        label: 'Composants pour contenu',
        pathname: 'for-content',
        query: {
          filterBy: {
            objectType: { $exists: true, $type: 'array', $ne: [] },
          },
        },
      },
    ],
  },
  ComponentDataRequester: {
    singular: 'ComponentDataRequester',
    plural: 'ComponentDataRequesters',
    pluralLabel: 'Contenus du composant',
  },
  ContentReport: {
    singular: 'ContentReport',
    plural: 'ContentReports',
    pluralLabel: 'Signalements utilisateur',
  },
  ContentRequester: {
    singular: 'ContentRequester',
    plural: 'ContentRequesters',
    pluralLabel: 'Listes de contenus',
  },
  ContentSchedule: {
    singular: 'ContentSchedule',
    plural: 'ContentSchedules',
    pluralLabel: 'Programmations de contenu',
  },
  Category: {
    singular: 'Category',
    plural: 'Categories',
    pluralLabel: 'Catégories',
  },
  Country: {
    singular: 'Country',
    plural: 'Countries',
    pluralLabel: 'Pays',
  },
  CrowdFundingCampaign: {
    singular: 'CrowdFundingCampaign',
    plural: 'CrowdFundingCampaigns',
    pluralLabel: 'Campagnes de dons',
  },
  CrowdFundingCampaignDonation: {
    singular: 'CrowdFundingCampaignDonation',
    plural: 'CrowdFundingCampaignDonations',
    pluralLabel: 'Dons de campagne',
  },
  DiffusionList: {
    singular: 'DiffusionList',
    plural: 'DiffusionLists',
    pluralLabel: 'Contenus à afficher',
  },
  Document: {
    singular: 'Document',
    plural: 'Documents',
    pluralLabel: 'Documents (plans conf etc.)',
  },
  EmailTemplate: {
    singular: 'EmailTemplate',
    plural: 'EmailTemplates',
    pluralLabel: 'Modèles de mail',
  },
  EncodingProfile: {
    singular: 'EncodingProfile',
    plural: 'EncodingProfiles',
    pluralLabel: "Profils d'encodage",
  },
  Event: {
    singular: 'Event',
    plural: 'Events',
    pluralLabel: 'Produits / Evènements',
  },
  Export: {
    singular: 'Export',
    plural: 'Exports',
    pluralLabel: 'Exports',
  },
  Font: {
    singular: 'Font',
    plural: 'Fonts',
    pluralLabel: 'Polices de caractères',
  },
  Footer: {
    singular: 'Footer',
    plural: 'Footers',
    pluralLabel: 'Pied de page du site (footer)',
  },
  FormField: {
    singular: 'FormField',
    plural: 'FormFields',
    pluralLabel: 'Champs de formulaire',
  },
  FrontendRedirection: {
    singular: 'FrontendRedirection',
    plural: 'FrontendRedirections',
    pluralLabel: 'Redirections',
  },
  Header: {
    singular: 'Header',
    plural: 'Headers',
    pluralLabel: 'Numéro de la revue',
  },
  Link: {
    singular: 'Link',
    plural: 'Links',
    pluralLabel: 'Liens',
  },
  MagazineArticle: {
    singular: 'MagazineArticle',
    plural: 'MagazineArticles',
    pluralLabel: 'Articles de revue',
  },
  MagellanOptions: {
    singular: 'MagellanOptions',
    plural: 'MagellanOptions',
    pluralLabel: 'Options Magellan',
  },
  MagazineIssue: {
    singular: 'MagazineIssue',
    plural: 'MagazineIssues',
    pluralLabel: 'Numéros de la revue',
  },
  MasterGiftCode: {
    singular: 'MasterGiftCode',
    plural: 'MasterGiftCodes',
    pluralLabel: 'Codes cadeau maître',
  },
  Menu: {
    singular: 'Menu',
    plural: 'Menus',
    pluralLabel: 'Menus',
  },
  MenuItem: {
    singular: 'MenuItem',
    plural: 'MenuItems',
    pluralLabel: 'Eléments du menu',
  },
  Namespace: {
    singular: 'Namespace',
    plural: 'Namespaces',
    pluralLabel: 'Espaces de nommage',
  },
  NativeDiscount: {
    singular: 'NativeDiscount',
    plural: 'NativeDiscounts',
    pluralLabel: 'Promotions',
  },
  News: {
    singular: 'News',
    plural: 'News',
    pluralLabel: 'Actualités/Agendas',
  },
  Newsletter: {
    singular: 'Newsletter',
    plural: 'Newsletters',
    pluralLabel: 'Newsletters',
  },
  NewspaperConfiguration: {
    singular: 'NewspaperConfiguration',
    plural: 'NewspaperConfigurations',
    pluralLabel: 'Configuration presse',
  },
  Page: {
    singular: 'Page',
    plural: 'Pages',
    pluralLabel: 'Pages',

    filters: [
      {
        label: 'Page standards',
        pathname: 'standard',
        query: {
          filterBy: {
            objectType: { $eq: [] },
          },
        },
      },
      {
        label: 'Page contenu',
        pathname: 'for-content',
        query: {
          filterBy: {
            objectType: { $exists: true, $type: 'array', $ne: [] },
          },
        },
      },
    ],
  },
  Payment: {
    singular: 'Payment',
    plural: 'Payments',
    pluralLabel: 'Paiements / Commandes',
  },
  PaywallConfiguration: {
    singular: 'PaywallConfiguration',
    plural: 'PaywallConfigurations',
    pluralLabel: 'Configuration du paywall',
  },
  PlatformConfig: {
    singular: 'PlatformConfig',
    plural: 'PlatformConfigs',
    pluralLabel: 'Configurations globale de la plateforme PUF',
  },
  PopUp: {
    singular: 'PopUp',
    plural: 'PopUps',
  },
  Purchase: {
    singular: 'Purchase',
    plural: 'Purchases',
    pluralLabel: 'Achats',

    filters: [
      {
        label: 'Achats site',
        pathname: 'direct',
        query: { filterBy: { 'subInfo.renewedPurchaseId': null } },
      },
      {
        label: '↻ Renouvellements',
        pathname: 'renewals',
        query: { filterBy: { 'subInfo.renewedPurchaseId': { $ne: null } } },
      },
      {
        label: '✔ Renouvelés',
        pathname: 'renewed',
        query: { filterBy: { 'subInfo.renewedTs': { $ne: null } } },
      },
      {
        label: '🗓 Renouvelables',
        pathname: 'to-renew',
        query: {
          filterBy: {
            status: 'ok',
            'product._cls': 'SubscriptionFormula',
            endTs: { $ne: null },
            dontRenew: { $ne: true },
            'subInfo.renewedTs': null,
            'subInfo.renewError': null,
          },
        },
      },
      {
        label: "⚡ A renouveler (aujourd'hui)",
        pathname: 'to-renew-now',
        query: {
          filterBy: {
            status: 'ok',
            'product._cls': 'SubscriptionFormula',
            endTs: { $ne: null, $lte: moment().unix() + 24 * 3600 },
            dontRenew: { $ne: true },
            'subInfo.renewedTs': null,
            'subInfo.renewError': null,
          },
        },
      },
      {
        label: '⚠️ Erreur de renouvellement',
        pathname: 'renew-error',
        query: {
          filterBy: { 'subInfo.renewError': { $ne: null } },
        },
      },
      {
        label: '↪ ⚠️ plus de CB',
        pathname: 'renew-error-no-pm',
        query: {
          filterBy: {
            'subInfo.renewError':
              "L'utilisateur n'a plus de moyen de paiement valide",
          },
        },
      },
      {
        label: '⛔ A ne pas renouveler',
        pathname: 'dont-renew',
        query: { filterBy: { status: 'ok', dontRenew: true } },
      },
      {
        label: '✔️ Abonnements actifs',
        pathname: 'active-sub',
        query: {
          filterBy: {
            status: 'ok',
            beginTs: { $not: { $gt: moment().unix() } }, // = null or lower than
            endTs: { $not: { $lt: moment().unix() } }, // = null or greater than
          },
        },
      },
      {
        label: '❌ Abonnements désactivés ou en erreur',
        pathname: 'not-ok',
        query: { filterBy: { status: { $ne: 'ok' } } },
      },
      {
        label: '🎁 Cadeaux',
        pathname: 'gift',
        query: { filterBy: { isGift: true } },
      },
      {
        label: '✨ Générés',
        pathname: 'generated',
        query: { filterBy: { _generatedTs: { $ne: null } } },
      },
    ],
  },
  Question: {
    singular: 'Question',
    plural: 'Questions',
    pluralLabel: 'Questions',
  },
  QuestionCategory: {
    singular: 'QuestionCategory',
    plural: 'QuestionCategories',
    pluralLabel: 'Thématiques de question',
  },
  Quizz: {
    singular: 'Quizz',
    plural: 'Quizzes',
    pluralLabel: 'Quizzes',
  },
  RecoRequester: {
    singular: 'RecoRequester',
    plural: 'RecoRequesters',
    pluralLabel: 'Listes de recommandations contextuelles',
  },
  RequestFilter: {
    singular: 'RequestFilter',
    plural: 'RequestFilters',
    pluralLabel: 'Filtres',
  },
  RequestSort: {
    singular: 'RequestSort',
    plural: 'RequestSorts',
    pluralLabel: 'Tris',
  },
  ResubRule: {
    singular: 'ResubRule',
    plural: 'ResubRules',
    pluralLabel: 'Règles de réabonnement',
  },
  ResubRuleItem: {
    singular: 'ResubRuleItem',
    plural: 'ResubRuleItems',
    pluralLabel: 'Offres de réabonnement',
  },
  RightOwner: {
    singular: 'RightOwner',
    plural: 'RightOwners',
    pluralLabel: 'Affiliés',
  },
  Rights: {
    singular: 'Rights',
    plural: 'Rights',
    pluralLabel: "Droits d'accès",
  },
  RSSConfiguration: {
    singular: 'RSSConfiguration',
    plural: 'RSSConfigurations',
    pluralLabel: 'Configurations RSS',
  },
  Series: {
    singular: 'Series',
    plural: 'Series',
    pluralLabel: 'Séries',
  },
  schema: {
    singular: 'schema',
    plural: 'schemas',
    pluralLabel: 'Schemas',
  },

  Site: {
    singular: 'Site',
    plural: 'Sites',
    pluralLabel: 'Sites',
  },
  SiteOptions: {
    singular: 'SiteOptions',
    plural: 'SiteOptions',
    pluralLabel: 'Options du site',
  },
  SitePaymentsConfig: {
    singular: 'SitePaymentsConfig',
    plural: 'SitePaymentsConfigs',
    pluralLabel: 'Configurations des paiements',
  },
  StripeIntent: {
    singular: 'StripeIntent',
    plural: 'StripeIntents',
    pluralLabel: '"Stripe Intents"',
  },
  Styles: {
    singular: 'Styles',
    plural: 'Styles',
    pluralLabel: 'Couleurs et style du site',
  },
  SubInfo: {
    singular: 'SubInfo',
    plural: 'SubInfos',
    pluralLabel: 'Infos Abonnement',
  },
  SubOptions: {
    singular: 'SubOptions',
    plural: 'SubOptions',
    pluralLabel: "Options de l'abonnement",
  },
  SubscriptionFormula: {
    singular: 'SubscriptionFormula',
    plural: 'SubscriptionFormulas',
    pluralLabel: "Formules d'abonnement",
  },
  SubscriptionFormulaGroup: {
    singular: 'SubscriptionFormulaGroup',
    plural: 'SubscriptionFormulaGroups',
    pluralLabel: "Groupes d'abonnements",
  },
  Survey: {
    singular: 'Survey',
    plural: 'Surveys',
    pluralLabel: 'Sondages',
  },
  Tag: {
    singular: 'Tag',
    plural: 'Tags',
    pluralLabel: 'Tags',
  },
  TextContent: {
    singular: 'TextContent',
    plural: 'TextContents',
    pluralLabel: 'Contenus texte',
  },
  Tournament: {
    singular: 'Tournament',
    plural: 'Tournaments',
    pluralLabel: 'Compétitions',
  },
  TournamentParticipant: {
    singular: 'TournamentParticipant',
    plural: 'TournamentParticipants',
    pluralLabel: 'Participants au compétition',
  },
  User: {
    singular: 'User',
    plural: 'Users',
    pluralLabel: 'Utilisateurs',
  },
  UserComment: {
    singular: 'UserComment',
    plural: 'UserComments',
    pluralLabel: 'Commentaires',
  },
  UserContribution: {
    singular: 'UserContribution',
    plural: 'UserContributions',
    pluralLabel: 'Contributions utilisateur',
  },
  UserProfileTemplate: {
    singular: 'UserProfileTemplate',
    plural: 'UserProfileTemplates',
    pluralLabel: 'Profils utilisateur',
  },
  Video: {
    singular: 'Video',
    plural: 'Videos',
    pluralLabel: 'Fichiers vidéo',
  },
  VideoContent: {
    singular: 'VideoContent',
    plural: 'VideoContents',
    pluralLabel: 'Contenus vidéo',
  },
  VideoServer: {
    singular: 'VideoServer',
    plural: 'VideoServers',
    pluralLabel: 'Serveurs de streaming vidéo',
  },
  playStats: {
    singular: 'playStats',
    plural: 'playStats',
    pluralLabel: 'Stats de lecture vidéo brute',
  },
};

export default RESOURCE_TYPES;
