// @flow
import * as React from 'react';
import Responsive from 'react-responsive';

/*
  We follow Bulma viewports lexicon:
  https://bulma.io/documentation/overview/responsiveness/#breakpoints
*/

const mobileTabletBreakpoint = 768;
const tabletDesktopBreakpoint = 1024;

const mobileMax = mobileTabletBreakpoint;
const tabletMax = tabletDesktopBreakpoint;

export type ViewPortConstraint =
  | 'mobile'
  | 'tablet'
  | 'desktop'
  | 'tabletOnly'
  | 'tabletAndBelow';

export const MobileViewPort = (props: any): React.Node => (
  <Responsive {...props} maxWidth={mobileMax} />
);

export const TabletViewPort = (props: any): React.Node => (
  <Responsive {...props} minWidth={mobileMax + 1} />
);

export const TabletAndBelowViewPort = (props: any): React.Node => (
  <Responsive {...props} maxWidth={tabletMax} />
);

export const TabletOnlyViewPort = (props: any): React.Node => (
  <Responsive {...props} minWidth={mobileMax + 1} maxWidth={tabletMax} />
);

export const DesktopViewPort = (props: any): React.Node => (
  <Responsive {...props} minWidth={tabletMax + 1} />
);

type ConstraintToViewPortMapping = {
  [ViewPortConstraint]: React.ComponentType<any>,
};

export const constraintToViewPortMapping: ConstraintToViewPortMapping = {
  mobile: MobileViewPort,
  tablet: TabletViewPort,
  desktop: DesktopViewPort,
  tabletOnly: TabletOnlyViewPort,
  tabletAndBelow: TabletAndBelowViewPort,
};
