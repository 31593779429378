// @flow
import * as React from 'react';

import type { ResourcePropertySchema, Resource } from 'src/types';
import type { OnPropertyValueChange } from '../../';

import { useGlobal } from 'src/hooks';
import invariant from 'src/helpers/invariant';
import { useResourceReferencesContext } from 'src/hooks';

import ResourceSelector from '../../ResourceSelector';
import { Modal, Flex, Button } from 'src/components';
import Item from './Item';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: Array<Resource>,
  disabled?: boolean,
|};

export default function ResourceListPropertyEditor({
  propertySchema,
  onChange: onChangeProp,
  value = [],
  disabled,
}: Props): React.Node {
  const { globalSchema } = useGlobal();
  const { itemSchema } = propertySchema;
  const { references } = useResourceReferencesContext();
  invariant(!!itemSchema, 'Item schema is always defined at this point');
  const { objectType, type } = itemSchema;
  invariant(!!objectType, 'Item schema should always bear an objectType');
  const subResourceTypes = Array.isArray(objectType)
    ? objectType
    : [objectType];

  const resourceSchemas = subResourceTypes.map((type) => globalSchema[type]);
  const [modalOpen, setModalOpen] = React.useState(false);

  invariant(
    type !== 'object_id' || subResourceTypes.length === 1,
    'Cannot have multiple resource types when selecting id'
  );

  const onChange = React.useCallback(
    (newPropertyValue: any) => {
      const newReferences = [
        ...(references[propertySchema.key] || []),
        newPropertyValue,
      ];

      if (type === 'object_id') {
        return onChangeProp(
          newPropertyValue.map((ref) => ref._id),
          newReferences // references
        );
      }

      onChangeProp(
        newPropertyValue,
        newReferences // references
      );
    },
    [onChangeProp]
  );

  const selectedResourceRefs =
    type === 'object_ref'
      ? value
      : value.map((id) => ({ _cls: subResourceTypes[0], _id: id }));

  const onRemove = React.useCallback(
    (resourceId) => {
      const newValueIds =
        type === 'object_id'
          ? value.filter((id) => id !== resourceId)
          : value.filter((ref) => ref._id !== resourceId).map((ref) => ref._id);
      const newReferences = (
        references[propertySchema.key] || []
      ).filter((ref) => newValueIds.includes(ref._id));

      if (type === 'object_id') {
        return onChangeProp(
          value.filter((id) => id !== resourceId),
          newReferences // dont edit the references
        );
      }

      onChangeProp(
        value.filter((ref) => ref._id !== resourceId),
        newReferences
      );
    },
    [value, onChangeProp, references]
  );

  return (
    <div className="resource-list-editor">
      <Flex verticalAlign additionalClassName="items">
        {selectedResourceRefs.map((resourceRef) => {
          return (
            <Item
              key={resourceRef._id}
              resource={resourceRef}
              style={{ marginRight: 10 }}
              disabled={disabled}
              reference={(references[propertySchema.key] || []).find(
                (ref) => ref._id === resourceRef._id
              )}
              onRemove={() => onRemove(resourceRef._id)}
            />
          );
        })}

        <Modal open={modalOpen} onClose={() => setModalOpen(false)} modal>
          <ResourceSelector
            // $FlowIgnore
            value={selectedResourceRefs}
            onChange={onChange}
            multi
            resourceSchemas={resourceSchemas}
          />
        </Modal>
      </Flex>

      <div className="action">
        <Button
          disabled={disabled}
          onClick={() => !disabled && setModalOpen(true)}
        >
          Editer la liste
        </Button>
      </div>
    </div>
  );
}
