export const DISPLAY_USER_TYPE_VALUES = [
  {
    value: 'anonymous',
    label: 'Non connecté (anonyme)',
  },
  {
    value: 'freemium',
    label: "Freemium (utilisateur connecté, mais pas d'abonnement)",
  },
  {
    value: 'premium',
    label: 'Premium (utilisateur abonné payant)',
  },
  {
    value: 'registered',
    label: 'Connecté (premium / freemium)',
  },
  {
    value: 'not_premium',
    label: 'Non abonné (anonyme / freemium)',
  },
];

export const CSS_PROPERTIES = [
  {
    _cls: 'ObjectPropertySchema',
    key: 'htmlID',
    label: 'ID HTML',
    type: 'string',
    required: false,
  },
  {
    _cls: 'ObjectPropertySchema',
    key: 'className',
    label: 'Classe CSS',
    type: 'string',
    required: false,
  },
  {
    _cls: 'ObjectPropertySchema',
    key: 'cssClass',
    label: 'Classe CSS obsolète',
    type: 'string',
    required: false,
    condition: {
      f$: '!site.featureFlag_pufComponentV2',
    },
  },
  {
    _cls: 'ObjectPropertySchema',
    required: false,
    readOnly: false,
    createOnly: false,
    unique: false,
    sortable: false,
    filterable: false,
    searchable: false,
    key: 'Element',
    label: 'Élément HTML',
    type: 'enum',
    values: [
      {
        value: 'h1',
        label: 'h1',
      },
      {
        value: 'h2',
        label: 'h2',
      },
      {
        value: 'h3',
        label: 'h3',
      },
      {
        value: 'h4',
        label: 'h4',
      },
      {
        value: 'h5',
        label: 'h5',
      },
      {
        value: 'h6',
        label: 'h6',
      },
      {
        value: 'p',
        label: 'p',
      },
      {
        value: 'div',
        label: 'div',
      },
      {
        value: 'span',
        label: 'span',
      },
      {
        value: 'section',
        label: 'section',
      },
      {
        value: 'article',
        label: 'article',
      },
      {
        value: 'default',
        label: 'Par défaut',
      },
    ],
  },
  {
    _cls: 'ObjectPropertySchema',
    required: false,
    readOnly: false,
    createOnly: false,
    unique: false,
    sortable: false,
    filterable: false,
    searchable: false,
    key: 'styleProperties',
    label: 'Propriétés CSS',
    type: 'list',
    itemType: ['FrontStyleProperty'],
    condition: {
      f$: 'site.featureFlag_pufComponentV2',
    },
  },
];

export const DISPLAY_PROPERTIES = [
  ...CSS_PROPERTIES,
  {
    _cls: 'ObjectPropertySchema',
    required: false,
    readOnly: false,
    createOnly: false,
    unique: false,
    sortable: false,
    filterable: false,
    searchable: false,
    key: 'displayConditions',
    label: "Conditions d'affichage",
    type: 'list',
    itemType: ['DisplayCondition'],
  },
];

export const REQUESTER_SCHEMA = {
  type: 'object',

  propertiesList: [
    {
      key: 'label',
      label: 'Nom de la liste',
      type: 'string',
    },
    {
      key: 'list',
      type: 'DiffusionList',
    },
    ...DISPLAY_PROPERTIES,
  ],
};
