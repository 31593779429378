// @flow
import * as React from 'react';

import { useGlobal } from 'src/hooks';
import { getSiteIdFromPath } from 'src/helpers/url';
import SECTIONS from 'src/pages/constants/sections';
import { toPath } from 'src/helpers/string';
import { APIContext } from './contexts';
import config from 'src/helpers/api/config';

import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import AppLayout from './AppLayout';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import SectionRouter from './SectionRouter';
import NotificationCenter from './NotificationCenter';

export default function AppRouter(): React.Node {
  const global = useGlobal();
  const { schemaLoaded, siteId, user, userLoaded } = global;
  const location = useLocation();
  const history = useHistory();
  const siteIdInPath = getSiteIdFromPath();
  const [loaded, setLoaded] = React.useState(false);
  const [apiContextState, setApiContextState] = React.useState({
    apiUrl: '',
    apiImageBaseUrl: '',
    apiImageShowUrl: '',
    apiImageUploadUrl: '',
    getImageUrl: () => '',
    apiUserContributionShowUrl: '',
    getUserContributionUrl: () => '',
    siteId: '',
  });

  React.useEffect(() => {
    if (!!siteId && siteIdInPath !== siteId) {
      history.push(
        toPath(
          `/${siteId}/${location.pathname.split('/').slice(2).join('/')}${
            location.search
          }`
        )
      );
    }
  }, [siteIdInPath, siteId]);

  React.useEffect(() => {
    window.logGlobal = () => console.log(global);
  }, [global]);

  React.useEffect(() => {
    if (userLoaded && siteId && schemaLoaded) setLoaded(true);
    if (!userLoaded || !siteId || !schemaLoaded) setLoaded(false);
  }, [userLoaded, siteId, schemaLoaded]);

  React.useEffect(() => {
    setApiContextState({
      apiUrl: `${config.API_URL}/admin/${siteId}`,
      apiImageBaseUrl: `${config.API_URL}/admin/${siteId}/images`,
      apiImageUploadUrl: `${config.API_URL}/admin/${siteId}/images/upload`,
      apiImageShowUrl: `${config.API_URL}/admin/${siteId}/images/show`,
      getImageUrl: (path) =>
        `${config.API_URL}/admin/${siteId}/images/show${path}`,
      apiUserContributionShowUrl: `${config.API_URL}/admin/${siteId}/user-contributions/show`,
      getUserContributionUrl: (userId, fileName) =>
        `${config.API_URL}/admin/${siteId}/user-contributions/show/${fileName}?userId="${userId}"`,
      siteId,
    });
  }, [siteId]);

  if (userLoaded && !user) return <LoginPage />;
  if (!loaded || !userLoaded) return null;

  const displayHomePage = [
    toPath(`/${siteId}`),
    toPath(`/${siteId}/`),
  ].includes(location.pathname);

  return (
    <APIContext.Provider value={apiContextState}>
      <AppLayout withSidebar={!displayHomePage}>
        <Switch>
          <Route exact path={toPath(`/${siteId}`)} component={HomePage} />

          {Object.keys(SECTIONS).map((sectionId, i) => {
            const section = SECTIONS[sectionId];

            return (
              <Route
                key={sectionId}
                sensitive
                path={toPath(`/${siteId}${section.pathname}`)}
                render={(routerProps) => (
                  <SectionRouter id={sectionId} {...routerProps} />
                )}
              />
            );
          })}

          <Route>
            <Redirect to={toPath(`/${siteId}`)} />
          </Route>
        </Switch>

        <NotificationCenter />
      </AppLayout>
    </APIContext.Provider>
  );
}
