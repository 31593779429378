// @flow
import * as React from 'react';

import type { Query } from 'src/types/routing';

import { useGlobal, useSchemaContext } from 'src/hooks';

import { type ContextRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Icon, Tabs } from 'src/components';
import Header from './Header';
import ListTab from './ListTab';
import SchemaListTab from 'src/pages/SectionRouter/sectionSpecificPages/superAdmin/SchemaListTab';
import getResourceTabs, { type Tab } from './resourceSpecific/tabs';

type Props = {|
  ...ContextRouter,
  query?: Query,
|};

export default function ResourceCollection({
  match,
  query,
}: Props): React.Node {
  const { schema, path } = useSchemaContext();
  const { user } = useGlobal();

  const tabs: Array<Tab> = React.useMemo(() => {
    return [
      {
        to: '',
        label: (
          <span>
            <Icon name="table" /> Liste
          </span>
        ),
        exact: true,
        content: schema._id === 'schema' ? SchemaListTab : ListTab,
      },
      ...getResourceTabs(schema._id),
    ]
      .filter(
        (tab) =>
          (!tab.right || user[tab.right]) &&
          (!tab.condition || tab.condition(user))
      )
      .map((tab) => ({
        ...tab,
        to: `${match.path}/${tab.to}`,
      }));
  }, [match.path, schema._id]);

  return (
    <div className="resource-collection">
      <Header>
        {/* $FlowIgnore */}
        <Tabs items={tabs} isBoxed />
      </Header>

      <Switch>
        {tabs.map((tab) => (
          <Route
            path={tab.to}
            exact
            key={tab.to}
            render={(routeProps) => (
              // $FlowIgnore
              <tab.content
                {...routeProps}
                basePath={path}
                schema={schema}
                query={query}
              />
            )}
          />
        ))}

        <Route>
          <Redirect to={match.path} />
        </Route>
      </Switch>
    </div>
  );
}
