// Tag selection component with simple props

import _ from 'lodash';
import React from 'react';

import InstanceStore from 'src/stores/InstanceStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';

import TagsInput from 'react-tagsinput';
import { OldLabel, Icon, AutoSuggestInput } from 'src/components';

export default class TagSelection extends ComponentBase {
  stores = [InstanceStore];

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      hasFocus: false,
      tagsById: {},
    };
  }

  didMount() {
    let { tagsById } = this.state;
    // Load everything // TODO better in the future
    API.get(this.state.instanceName + '/data/Tag').then(({ data: tags }) =>
      this.setState({ tagsById: _.keyBy(tags, '_id') })
    );

    this.observeProp('value', (value) => {
      if (_.find(value, (t) => t._id && !tagsById[t._id])) {
        API.get(this.state.instanceName + '/data/Tag').then(({ data: tags }) =>
          this.setState({ tagsById: _.keyBy(tags, '_id') })
        );
      }
    });
  }

  handleFocus(e, hasFocus) {
    this.setState({ hasFocus: hasFocus });
  }

  /**
   * Callback when tags change
   * @param tags new tag array
   * @param changed array of the tags that have changed
   * @param changedIndexes array of the indexes that have changed
   */
  onChange = (tags, changed, changedIndexes) => {
    let { tagsById } = this.state;

    function compareTags(tag1, tag2) {
      tag1 = (tag1._id && tagsById[tag1._id]) || tag1;
      tag2 = (tag2._id && tagsById[tag2._id]) || tag2;
      return (
        (tag1._id && tag1._id === tag2._id) ||
        (tag1.text &&
          tag2.text &&
          tag1.text.trim().toLowerCase() === tag2.text.trim().toLowerCase())
      );
    }

    let completedTags = tags.map(
      (t) => _.find(tagsById, (ct) => compareTags(t, ct)) || t
    );
    let uniqueTags = _.uniqWith(completedTags, (a, b) => compareTags(a, b));

    this.props.onChange(uniqueTags);
  };

  render() {
    let { value, disabled } = this.props;
    let { tagsById, error } = this.state;

    let formElementClass =
      'FormElement FormElement-input-tags-selection-element';
    formElementClass += disabled ? ' FormElement--disabled' : '';
    formElementClass += error ? ' FormElement--error' : '';
    formElementClass += this.state.hasFocus ? ' FormElement--focus' : '';

    let autocompleteInputProps = {
      placeholder: this.props.placeholder || 'Ajouter un tag',
    };

    function renderAutocompleteInput({ addTag, ...props }) {
      return (
        <AutoSuggestInput
          inputProps={props}
          addTag={addTag}
          type="Tag"
          tagsById={_.pickBy(tagsById, (t) => !_.find(value, { _id: t._id }))}
        />
      );
    }

    return (
      <div
        className={formElementClass}
        onFocus={(e) => this.handleFocus(e, true)}
        onBlur={(e) => this.handleFocus(e, false)}
        tabIndex="0"
      >
        <div className="flex-wrapper flex-column FormElement-inner">
          <OldLabel {...this.props} />

          <div className="FormElement-tags-selection">
            <TagsInput
              value={value || []}
              tagDisplayProp="text"
              onChange={this.onChange}
              inputProps={autocompleteInputProps}
              onlyUnique={true} //bug: does not trim tags - https://github.com/olahol/react-tagsinput/issues/191
              renderInput={renderAutocompleteInput}
              renderTag={(props) => {
                let {
                  tag,
                  key,
                  disabled,
                  onRemove,
                  className,
                  classNameRemove,
                  getTagDisplayValue,
                  ...other
                } = props;

                let tagStateClassName = tag._id
                  ? tag.text
                    ? 'new existing'
                    : 'old'
                  : 'new to-create';
                return (
                  <span
                    key={key}
                    {...other}
                    className={className + ' ' + tagStateClassName}
                  >
                    {tag.text ||
                      tag.label ||
                      _.get(tagsById, tag._id + '.text', '[error]')}
                    {!disabled && (
                      <a
                        className={classNameRemove}
                        onClick={(e) => onRemove(key)}
                      >
                        <Icon name="times-circle" />
                      </a>
                    )}
                  </span>
                );
              }}
            />
          </div>
        </div>

        {error && <div className="FormElement-error-message">{error}</div>}
      </div>
    );
  }
}
