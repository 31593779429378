import React from 'react';
import classnames from 'classnames';

import { APIContext } from 'src/pages/contexts';

export default function RichTextContent({
  richtext,
  element = 'div',
  additionalClassName,
}) {
  const { getImageUrl } = React.useContext(APIContext);

  if (!richtext?.html) return null;

  const { html } = richtext;

  if (html === '<p><br></p>') return null;

  const patchedHtml = html.replace(
    /(<img[^>]*)\bdata-url="([^"]+)"/g,
    (match, start, url) => {
      return start.replace(/src="[^"]*"/, '') + ` src="${getImageUrl(url)}"`;
    }
  );

  const Tag = element;

  return (
    <Tag
      className={classnames('RichTextContent', additionalClassName)}
      dangerouslySetInnerHTML={{ __html: patchedHtml }}
    />
  );
}
