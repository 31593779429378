// @flow
import type {
  InstanceStoreProps,
  SchemaStoreProps,
  UserStoreProps,
} from 'src/types/stores';
import type {
  User,
  GlobalSchema,
  Instance,
  SiteConfig,
} from 'src/types/models';

import useStore from './useStore';

type Returned = {|
  user: User,
  globalSchema: GlobalSchema,
  siteId: string,
  site: Instance,
  sites: Array<Instance>,
  siteConfig: SiteConfig,
  userLoaded: boolean,
  schemaLoaded: boolean,
|};

export default function useGlobal(): Returned {
  const { user, userLoaded } = useStore<UserStoreProps>('UserStore');
  const { globalSchema, schemaLoaded } = useStore<SchemaStoreProps>(
    'SchemaStore'
  );
  const {
    instanceName: siteId,
    instance: site,
    instanceList: sites,
    config: siteConfig,
  } = useStore<InstanceStoreProps>('InstanceStore');

  return {
    user,
    globalSchema,
    siteId,
    site,
    sites,
    siteConfig,
    userLoaded,
    schemaLoaded,
  };
}
