// @flow

import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  children: React.Node,
  elementClass?: string,
  disabled?: boolean,
  hasFocus?: boolean,
  error?: string,
|};

function OldFormElement({
  error,
  disabled,
  elementClass,
  hasFocus,
  children,
}: Props): React.Node {
  const className = classnames('FormElement', elementClass, {
    'FormElement--disabled': !!disabled,
    'FormElement--error': !!error,
    'FormElement--focus': !!hasFocus,
  });

  return <div className={className}>{children}</div>;
}

OldFormElement.defaultProps = {
  disabled: false,
  hasFocus: false,
};

export default OldFormElement;
