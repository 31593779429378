import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { difference, merge } from 'lodash';

import API from 'src/helpers/api/API';
import { listComponentsInSite } from 'src/helpers/models/pageLayout';
import { COMPONENTS } from 'src/pages/components/resource/PropertyEditor/LayoutEditor/constants/simpleComponentsDefinition';
import { useGlobal } from 'src/hooks';

import { Notification, Tabs } from 'src/components';
import UnusedComponents from './UnusedComponents';
import UnusedModes from './UnusedModes';
import UsedAndNotDefinedComponents from './UsedAndNotDefinedComponents';
import ComponentUsage from './ComponentUsage';

async function listComponentsInSites(sites, sitePages, siteComplexComponents) {
  const pageComponentDict = {};
  const ccComponentDict = {};

  sitePages.forEach(async (pages, i) => {
    const site = sites[i];
    const complexComponents = {};
    siteComplexComponents[i].forEach((component) => {
      complexComponents[component._id] = component;
    });

    listComponentsInSite(site, pages, pageComponentDict, ccComponentDict, {
      complexComponents,
    });
  });

  return { pageComponentDict, complexComponentsComponentDict: ccComponentDict };
}

const TAB = {
  ANOMALIES: 'anomalies',
  USAGE: 'usage',
};

export default function ComponentFinder() {
  const { sites } = useGlobal();
  const [tab, setTab] = React.useState(TAB.USAGE);
  const [initialized, setInitialized] = React.useState(false);
  const [pageComponents, setPageComponents] = React.useState({});
  const [ccComponents, setCCComponents] = React.useState({});
  const definedComponents = React.useRef({});

  /* 
    Fetch all pages from all sites and compute components
    components.current is of the following shape :
      { 
        [componentId: string]: {
          count: number,
          sites: { [siteId: string]: number },
          sitePages: { [siteId: string]: Array<pageIds> },
          modes: { [mode: string]: number }
        }
      }
  */
  useEffectOnce(() => {
    const fetchComponents = async () => {
      const sitePages = await Promise.all(
        sites.map(async (site) => (await API.get(`${site._id}/data/Page`)).data)
      );
      const siteComplexComponents = await Promise.all(
        sites.map(
          async (site) =>
            (await API.get(`${site._id}/data/ComplexComponent`)).data
        )
      );

      const {
        pageComponentDict,
        complexComponentsComponentDict,
      } = await listComponentsInSites(sites, sitePages, siteComplexComponents);

      setPageComponents(pageComponentDict);
      setCCComponents(complexComponentsComponentDict);
      setInitialized(true);
    };

    fetchComponents();
  });

  // Get defined components and their modes from simpleComponentsDefintion
  useEffectOnce(() => {
    COMPONENTS.forEach((component) => {
      definedComponents.current[component._id] =
        definedComponents[component._id] || {};
      definedComponents.current[component._id].modes =
        component.optionsSchema
          ?.find((option) => option.key === 'mode')
          ?.values?.map((value) => value.value) || [];
      definedComponents.current[component._id].component = component;
    });
  });

  if (!initialized) return 'loading...';

  const components = merge({}, pageComponents, ccComponents);

  // console.log('FETCHED COMPONENTS', components);
  // console.log('DEFINED COMPONENTS', definedComponents.current);
  const definedComponentIds = Object.keys(definedComponents.current);
  const usedComponentIds = Object.keys(components);
  const unusedComponentIds = difference(definedComponentIds, usedComponentIds);
  const usedAndNotDefinedComponentIds = difference(
    usedComponentIds,
    definedComponentIds
  );
  const unusedModes = {};

  // Get unused modes
  usedComponentIds
    .filter((componentId) => definedComponents.current[componentId])
    .forEach((componentId) => {
      const simpleComponent = definedComponents.current[componentId];
      const componentUnusedModes = difference(
        simpleComponent.modes,
        Object.keys(components[componentId].modes)
      );
      if (componentUnusedModes.length > 0)
        unusedModes[componentId] = componentUnusedModes;
    });
  // console.log('UNUSED COMPONENTS', unusedComponentIds);
  // console.log('UNUSED MODES', unusedModes);
  // console.log('USED AND NOT DEFINED', usedAndNotDefinedComponentIds);

  return (
    <div className="component-finder">
      <Notification color="warning">
        Attention: cette page est couteuse en terme d'appel API et de calcul. Ne
        faîtes pas des aller-retours dessus :)
      </Notification>

      <Tabs
        items={[
          {
            label: 'Usage des composants',
            active: tab === TAB.USAGE,
            onClick: () => setTab(TAB.USAGE),
          },
          {
            label: 'Anomalies',
            active: tab === TAB.ANOMALIES,
            onClick: () => setTab(TAB.ANOMALIES),
          },
        ]}
      />

      {tab === TAB.ANOMALIES && (
        <>
          {unusedComponentIds.length > 0 && (
            <UnusedComponents
              componentIds={unusedComponentIds}
              definedComponents={definedComponents.current}
            />
          )}

          {Object.keys(unusedModes).length > 0 && (
            <UnusedModes modes={unusedModes} />
          )}

          {usedAndNotDefinedComponentIds.length > 0 && (
            <UsedAndNotDefinedComponents
              componentIds={usedAndNotDefinedComponentIds}
            />
          )}
        </>
      )}

      {tab === TAB.USAGE && <ComponentUsage components={components} />}
    </div>
  );
}
