const EntityCommentsCount = {
  _id: 'EntityCommentsCount',
  label: 'Nombre de commentaires',
  icon: 'comment',
  frontComponent: 'EntityCommentsCount',
  forEntity: true,
  optionsSchema: [],
};

export default EntityCommentsCount;
