const EntityMainTag = {
  _id: 'EntityMainTag',
  label: 'Tag principale',
  icon: 'tag',
  frontComponent: 'EntityMainTag',
  forEntity: true,
  optionsSchema: [],
};

export default EntityMainTag;
