const Video = {
  _id: 'video',
  label: 'Vidéo seule',
  description: (
    <span>
      <h3>Vidéo sans contenu associé</h3>
      <div>
        Affiche un player vidéo jouant une vidéo choisie soit dans la liste des
        Video du site, soit externe (flux ou Youtube).
      </div>
      <div>Usage typique : vidéo d'intro."</div>
    </span>
  ),
  icon: 'video',
  frontComponent: 'Video',
  forPage: true,
  optionsSchema: [
    {
      key: 'srcType',
      label: 'Type de source',
      type: 'enum',
      required: true,
      values: [
        { value: 'internal', label: 'Vidéo du site (objet Video)' },
        { value: 'youtube', label: 'Youtube' },
      ],
    },
    {
      key: 'video',
      label: 'Vidéo',
      type: 'object_ref',
      objectType: 'Video',
      required: true,
      condition: { f$: 'obj.srcType==="internal"' },
    },
    {
      key: 'streamUrl',
      label: 'URL du flux',
      type: 'string',
      condition: { f$: 'obj.srcType==="url"' },
    },
    {
      key: 'youtubeUrl',
      label: 'URL Youtube',
      type: 'string',
      condition: { f$: 'obj.srcType==="youtube"' },
    },
    {
      key: 'title',
      label: 'Titre à afficher',
      type: 'string',
    },
    {
      key: 'isPriorityPlayback',
      label: 'Lecture exclusive',
      description: 'met en pause les autres lectures de la page',
      type: 'bool',
    },
  ],
};

export default Video;
