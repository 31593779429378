import BoolRenderer from './BoolRenderer';
import UserFileRenderer from './UserFileRenderer';
import ImageRenderer from './ImageRenderer';
import ListRenderer from './ListRenderer';
import EnumRenderer from './EnumRenderer';
import ObjectPropertySchemaRenderer from './ObjectPropertySchemaRenderer';
import RichTextRenderer from './RichTextRenderer';
import TimestampRenderer from './TimestampRenderer';
import ObjectIdRenderer from './ObjectIdRenderer';
import ObjectRefRenderer from './ObjectRefRenderer';

function GenericRenderer({ value }) {
  return value ? JSON.stringify(value) : null;
}

export const getGenericPropertyRenderer = (propertySchema) => {
  const { type } = propertySchema;

  switch (type) {
    case 'timestamp':
      return TimestampRenderer;
    case 'image':
      return ImageRenderer;
    case 'file': {
      const { isUserFile } = propertySchema;

      // Currently, the file and image property type are the same
      if (isUserFile) return UserFileRenderer;
      return ImageRenderer;
    }
    case 'bool':
      return BoolRenderer;
    case 'richtext':
      return RichTextRenderer;
    case 'ObjectPropertySchema':
      return ObjectPropertySchemaRenderer;
    case 'list':
      return ListRenderer;
    case 'enum':
      return EnumRenderer;
    case 'object_id':
      return ObjectIdRenderer;
    case 'object_ref':
      return ObjectRefRenderer;

    case 'string':
    case 'slug':
    case 'mongooseRef':
    case 'int':
    case 'float':
      return GenericRenderer;
    default:
      return null;
  }
};
