import _ from 'lodash';
import React from 'react';

import { useResourceContext } from 'src/hooks';

const BASE_VIDEO_URL = 'http://video1.wpvideoserver.com/puf/';

const DownloadLink = (props) => (
  <li>
    <a
      download // will not work (not same domain)
      target="_blank"
      href={props.url}
      rel="noopener noreferrer"
    >
      {props.res}p
    </a>
  </li>
);

export default function VideoStreamsTab() {
  const { resource: video } = useResourceContext();

  if (!video) return null;

  return (
    <div className="FormBase--primary">
      <h2>Extrait</h2>
      <ul>
        {video.trailer &&
          _.sortBy(video.trailer.res).map((res) => (
            <DownloadLink
              key={res}
              res={res}
              url={
                BASE_VIDEO_URL +
                'prog_trailer/' +
                res +
                'p/' +
                video.trailer.mp4
              }
            />
          ))}
      </ul>

      <h2>Vidéo complète</h2>
      <ul>
        {video.full &&
          _.sortBy(video.full.res).map((res) => (
            <DownloadLink
              key={res}
              res={res}
              url={
                BASE_VIDEO_URL + 'prog_full/' + res + 'p/' + video.trailer.mp4
              }
            />
          ))}
      </ul>
    </div>
  );
}
