// @flow
import * as React from 'react';

import classnames from 'classnames';

import type { Style } from 'src/components/types';

type Props = {|
  isGapLess?: boolean,
  isMultiline?: boolean,
  isMobile?: boolean,
  style?: Style,
  children: React.Node,
  isjustifiedCenterContent?: boolean,
  additionalClassName?: string,
  contentVerticallyAligned?: boolean,
|};

export default function Columns({
  isGapLess,
  isMultiline,
  isMobile,
  style = {},
  children,
  isjustifiedCenterContent,
  contentVerticallyAligned,
  additionalClassName,
}: Props): React.Node {
  let className = classnames('columns', additionalClassName, {
    'is-gapless': isGapLess,
    'is-multiline': isMultiline,
    'is-mobile': isMobile,
  });

  let mergedStyle = {};
  if (isjustifiedCenterContent)
    mergedStyle = { ...mergedStyle, justifyContent: 'center' };
  if (contentVerticallyAligned)
    mergedStyle = { ...mergedStyle, alignItems: 'center' };
  mergedStyle = { ...mergedStyle, ...style };

  return (
    <div style={mergedStyle} className={className}>
      {children}
    </div>
  );
}
