// @flow
import * as React from 'react';
import moment from 'moment';
import { uniq } from 'lodash';
// $FlowIgnore
import { Line } from 'react-chartjs-2';

import type { Datasets } from './types';

import { LoadingOverlay, Text } from 'src/components';

const GRAPH_COLORS = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
];

type Props = {|
  startDate: ?Date,
  endDate: ?Date,
  loading: boolean,
  datasets: Datasets,
  title: string,
  periodTitle: string,
  period: string,
  withFooter?: boolean,
|};

export default function Chart({
  startDate,
  endDate,
  datasets,
  title,
  periodTitle,
  period,
  loading,
  withFooter = true,
}: Props): React.Node {
  const prepareChart = React.useCallback(() => {
    const chartOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: `${title} par ${periodTitle}, de ${moment(startDate).format(
            'L'
          )} à ${moment(endDate).format('L')}`,
        },
      },
      scales: {
        x: {
          // type: 'time',
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
          },
        },
      },
    };

    let labels = [];
    const filteredData = {};

    // $FlowIgnore
    Object.keys(datasets).forEach((datasetName) => {
      filteredData[datasetName] = datasets[datasetName].data.map(
        (dataPoint) => {
          labels.push(dataPoint.label);
          return dataPoint.value;
        }
      );
    });
    labels = uniq(labels);

    return {
      labels,
      datasets: filteredData,
      options: chartOptions,
    };
  }, [datasets, period, endDate, startDate]);

  const {
    labels,
    datasets: formattedDatasets,
    options: chartOptions,
  } = prepareChart();

  const chartData = {
    labels,
    datasets: Object.keys(datasets).map((dataKey, i) => ({
      label: datasets[dataKey].title,
      data: formattedDatasets[dataKey],
      borderColor: GRAPH_COLORS[i],
    })),
  };

  return (
    <div className="chart" style={{ position: 'relative' }}>
      <Line options={chartOptions} data={chartData} />

      {/* Chart footer */}
      {withFooter && (
        <p style={{ marginTop: 30 }}>
          <Text weight="bold" isTitle size={5} element="h5">
            Notes additionnelles:
          </Text>

          <ul className="bullet-list">
            {Object.keys(datasets).map((dataKey, i) => {
              const { title, description } = datasets[dataKey];

              return (
                <li key={dataKey} style={{ marginBottom: 10 }}>
                  <Text weight="bold" style={{ color: GRAPH_COLORS[i] }}>
                    {title} :{' '}
                  </Text>
                  <br />
                  {description}
                </li>
              );
            })}
          </ul>
        </p>
      )}

      <LoadingOverlay show={loading} />
    </div>
  );
}
