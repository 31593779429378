// @flow
import * as React from 'react';

import type { CustomItemAction } from '..';
import type {
  Resource,
  ResourceSchema,
  ResourcePropertySchema,
} from 'src/types/models';

import { useSchemaContext, useGlobal } from 'src/hooks';
import ResourceAPIConnector from 'src/helpers/api/ResourceAPIConnector';
import PropertyRenderer from 'src/pages/components/resource/PropertyRenderer/new';
import { ArchivedContext } from '../contexts';

import { NavLink } from 'react-router-dom';
import { Field, Button, Icon, Tr, Td, CheckBox } from 'src/components';

type Props = {|
  item: Resource,
  getActions?: (ResourceSchema) => Array<CustomItemAction>,
  isSelected: boolean,
  onSelect?: (Resource) => any,
  openInANewTab: boolean,
  columnProperties: Array<ResourcePropertySchema>,
  canDelete: boolean,
  refetch: () => Promise<any>,
|};

function ResourceRow({
  item,
  openInANewTab,
  getActions,
  columnProperties,
  isSelected,
  onSelect,
  canDelete,
  refetch,
}: Props) {
  const { schema, path } = useSchemaContext();
  const actions = getActions ? getActions(schema) : [];
  const archived = React.useContext(ArchivedContext);

  const actionsTD = archived ? (
    <Td>
      <RestoreItemButton schema={schema} id={item._id} refetch={refetch} />
    </Td>
  ) : (
    <Td>
      <div style={{ display: 'flex' }}>
        {schema.recordsAreEditableInAdmin ? (
          <NavLink
            title="Editer"
            to={`${path}/${item._id}/edit`}
            style={{ marginRight: 5 }}
            target={openInANewTab ? '_blank' : undefined}
          >
            <Button size="small">
              <Icon name="edit" size="sm" />
            </Button>
          </NavLink>
        ) : (
          <NavLink
            title="Voir"
            to={`${path}/${item._id}`}
            style={{ marginRight: 5 }}
            target={openInANewTab ? '_blank' : undefined}
          >
            <Button size="small">
              <Icon name="eye" size="sm" />
            </Button>
          </NavLink>
        )}

        {canDelete && (
          <DeleteItemButton schema={schema} id={item._id} refetch={refetch} />
        )}

        {actions.map((ItemCustomActionComponent, i) => (
          // $FlowIgnore
          <ItemCustomActionComponent
            key={i}
            schema={schema}
            // $FlowIgnore
            resource={item}
            basePath={path}
            refetch={refetch}
          />
        ))}
      </div>
    </Td>
  );

  return (
    <Tr key={item._id}>
      {!!onSelect && (
        <Td>
          <Field>
            <CheckBox
              isChecked={!!isSelected}
              onChange={() => onSelect(item)}
            />
          </Field>
        </Td>
      )}

      {actionsTD}

      {schema &&
        columnProperties.map((propertySchema) => {
          return (
            <Td key={propertySchema.key}>
              <ItemProperty
                schema={schema}
                propertySchema={propertySchema}
                resource={item}
              />
            </Td>
          );
        })}
    </Tr>
  );
}

export default (React.memo(ResourceRow): React.ComponentType<Props>);

type DeleteItemButtonProps = {|
  schema: ResourceSchema,
  id: string,
  refetch: () => Promise<any>,
|};

function DeleteItemButton({ schema, id, refetch }: DeleteItemButtonProps) {
  const { siteId } = useGlobal();
  const connector = new ResourceAPIConnector(siteId, schema, id);

  const deleteItem = React.useCallback(async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ?')) {
      await connector.destroy();
      await refetch();
    }
  }, [id]);

  return (
    <Button
      title="Supprimer"
      size="small"
      isLight
      color="danger"
      style={{ marginRight: 5 }}
      onClick={deleteItem}
    >
      <Icon name="trash" />
    </Button>
  );
}

function RestoreItemButton({ schema, id, refetch }: DeleteItemButtonProps) {
  const { siteId } = useGlobal();
  const archived = React.useContext(ArchivedContext);
  const connector = new ResourceAPIConnector(siteId, schema, id);

  const restoreItem = React.useCallback(async () => {
    if (window.confirm('Êtes-vous sûr de vouloir restaurer ?')) {
      await connector.restore({ archived });
      await refetch();
    }
  }, [id]);

  return (
    <Button
      title="Restaurer"
      size="small"
      isLight
      color="success"
      onClick={restoreItem}
    >
      <Icon name="trash-restore" />
    </Button>
  );
}

function ItemProperty({ propertySchema, schema, resource }) {
  if (!propertySchema?.key) return null;

  const value = resource[propertySchema.key];

  return (
    <PropertyRenderer
      mode="cell"
      value={value}
      propertySchema={propertySchema}
      resource={resource}
      resourceSchema={schema}
    />
  );
}
