// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  children: React.Node,
  horizontally?: boolean,
  vertically?: boolean,
  style?: Object,
  fullyExpanded?: boolean,
  additionalClassName?: string,
|};

export default function Centered({
  horizontally,
  vertically,
  style,
  fullyExpanded,
  children,
  additionalClassName,
}: Props): React.Node {
  return (
    <div
      className={classnames('centered', additionalClassName, {
        horizontally: !!horizontally,
        vertically: !!vertically,
        'fully-expanded': !!fullyExpanded,
      })}
      style={style}
    >
      {children}
    </div>
  );
}

Centered.defaultProps = {
  vertically: true,
  horizontally: true,
  fullyExpanded: false,
};
