// @flow

import * as React from 'react';

import { Table, THead, TBody, Tr, Th, Td } from 'src/components';

type UserResult = {|
  userId: string,
  score: string,
  rank: string,
|};

type Props = {|
  top20Rankings: Array<UserResult>,
|};

export default function RankingTable({ top20Rankings }: Props): React.Node {
  return (
    <Table striped hoverable fullwidth>
      <THead>
        <Tr>
          <Th>user ID</Th>
          <Th>Score</Th>
          <Th>Rank</Th>
        </Tr>
      </THead>

      <TBody>
        {top20Rankings.map((userResult, i) => {
          return (
            <Tr>
              <Td>{userResult.userId}</Td>
              <Td>{userResult.score}</Td>
              <Td>{userResult.rank}</Td>
            </Tr>
          );
        })}
      </TBody>
    </Table>
  );
}
