import * as React from 'react';
import moment from 'moment';

export default function TimestampRenderer({
  mode,
  value: timestamp,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (mode !== 'cell') return null;
  if (!timestamp) return null;

  return (
    <span title={moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')}>
      {moment.unix(timestamp).format('LLL')}
    </span>
  );
}
