// @flow
// Basic text input component with simple props
import * as React from 'react';
import ContentEditable from 'react-sane-contenteditable';

type Value = string;
type Props = {|
  value: Value,
  onChange: (value: Value, isMajor: boolean) => any,
  disabled?: boolean,
|};

type DefaultProps = {|
  disabled: boolean,
|};

type State = {|
  hasFocus: boolean,
  stillEditing: boolean,
|};

export default class InputContentEditable extends React.Component<
  Props,
  State
> {
  static defaultProps: DefaultProps = {
    disabled: false,
  };

  state: State = {
    hasFocus: false,
    stillEditing: false,
  };

  handleFocus(hasFocus: boolean) {
    this.setState({ hasFocus });

    if (!hasFocus) {
      this.setState({ stillEditing: false });
    }
  }

  onChange: (value: Value) => void = (value) => {
    this.props.onChange(value, !this.state.stillEditing);
    this.setState({ stillEditing: true });
  };

  render(): React.Node {
    const { value, disabled } = this.props;

    return (
      <span>
        <ContentEditable
          tagName="span"
          content={(value || '').toString()} // TODO raw str conversion
          onChange={(e, value) => this.onChange(value)}
          onFocus={() => this.handleFocus(true)}
          onBlur={() => this.handleFocus(false)}
          editable={!disabled}
        />
      </span>
    );
  }
}
