// @flow
import * as React from 'react';
import ReactDOM from 'react-dom';

// Displays children under the #modal-portal node in the DOM

type Props = {|
  children: React.Node,
|};

export default class GlobalModalElement extends React.Component<Props> {
  el: HTMLDivElement = document.createElement('div');

  componentDidMount() {
    const modalRoot: ?HTMLElement = document.getElementById('modal-portal');
    if (!modalRoot) throw new Error('Missing node #modal-portal');
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    const modalRoot: ?HTMLElement = document.getElementById('modal-portal');
    if (!modalRoot) throw new Error('Missing node #modal-portal');
    modalRoot.removeChild(this.el);
  }

  render(): React.Node {
    // https://reactjs.org/docs/portals.html

    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
