// @flow
import * as React from 'react';
import moment from 'moment';
import { pick } from 'lodash';

import type { ActionProps } from './';

import { useGlobal } from 'src/hooks';
import API from 'src/helpers/api/API';

import { Button } from 'src/components';

export default function ModerateActions({
  resource,
  schema,
  refetch,
}: ActionProps): React.Node {
  const { siteId } = useGlobal();

  const setStatus = React.useCallback(
    async (status) => {
      if (
        resource.status !== status &&
        !resource.publishTs &&
        status === 'published'
      ) {
        resource.publishTs = moment().unix();
      }

      resource.status = status;

      await API.put(
        `${siteId}/data/${schema._id}/${resource._id}`,
        pick(resource, ['publishTs', 'status'])
      );

      await refetch();
    },
    [schema, resource, refetch]
  );

  return (
    <React.Fragment>
      <Button
        title="Accepter"
        onClick={() => setStatus('published')}
        style={{ marginRight: 5 }}
        size="small"
      >
        ✅
      </Button>
      <Button
        title="Refuser"
        onClick={() => setStatus('rejected')}
        style={{ marginRight: 5 }}
        size="small"
      >
        ⛔
      </Button>
      <Button
        title="Archiver"
        onClick={() => setStatus('archived')}
        style={{ marginRight: 5 }}
        size="small"
      >
        <b>Ⓐ</b>
      </Button>
    </React.Fragment>
  );
}
