// @flow
import * as React from 'react';
// https://github.com/AbhyudayaSharma/react-git-info#readme
import GitInfo from 'react-git-info/macro';
import moment from 'moment';

import type { Environment } from 'src/types/environment';

import config from 'src/helpers/api/config';

import { Button, Modal, Text } from 'src/components';

const getColor = (
  environement: Environment
): 'success' | 'info' | 'danger' | 'light' => {
  switch (environement) {
    case 'dev':
      return 'success';
    case 'staging':
      return 'info';
    case 'prod':
      return 'danger';
    default:
      return 'light';
  }
};

export default function VersionTag(): React.Node {
  const [modalOpen, setModalOpen] = React.useState(false);
  const { branch, tags, commit } = GitInfo();
  const environement = !!config.PUF_ENV
    ? config.PUF_ENV.toUpperCase()
    : 'UNKNOWN';

  React.useEffect(() => {
    function onKeyDown(e: SyntheticKeyboardEvent<HTMLButtonElement>) {
      if (e.altKey && e.keyCode === 69) {
        setModalOpen(true);
        e.stopPropagation();
        e.preventDefault();
      }
    }

    window.addEventListener('keydown', onKeyDown);

    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <>
      <Button
        color={getColor(config.PUF_ENV)}
        id="environment-tag"
        size="tiny"
        onClick={() => setModalOpen(true)}
      >
        Environment: {environement}
      </Button>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        modal={false}
        title="Environment information"
      >
        <Text element="h3" style={{ marginBottom: 20 }}>
          <Text style={{ marginRight: 30 }} color="grey">
            Environment :
          </Text>
          <Text weight="bold">{environement}</Text>
        </Text>

        <Text element="h3" style={{ marginBottom: 20 }}>
          <Text style={{ marginRight: 60 }} color="grey">
            Branche :
          </Text>
          <Text weight="bold">{branch}</Text>
        </Text>

        <Text element="h3" style={{ marginBottom: 20 }}>
          <Text style={{ marginRight: 90 }} color="grey">
            Tag :
          </Text>
          <Text
            weight="bold"
            transformation={tags.length > 0 ? undefined : 'italic'}
            color={tags.length > 0 ? undefined : 'grey'}
          >
            {tags.length > 0 ? tags[0] : 'Pas de tag'}
          </Text>
        </Text>

        <Text element="h3" style={{ marginBottom: 20 }}>
          <Text style={{ marginRight: 62 }} color="grey">
            Commit :
          </Text>
          <Text weight="bold">{commit.shortHash}</Text>
        </Text>

        <Text element="h3">
          <Text style={{ marginRight: 82 }} color="grey">
            Date :
          </Text>
          <Text weight="bold">{moment(commit.date).format('LLL')}</Text>
        </Text>

        <Text
          element="h5"
          style={{ marginTop: 30 }}
          size={7}
          transformation="italic"
          align="right"
        >
          Appuyer sur "Alt + e" pour ouvrir cette popup
        </Text>
      </Modal>
    </>
  );
}
