// @flow
import * as React from 'react';
import { omit } from 'lodash';

import type { Style } from '../types';

import utils from './utils';

export type DragType = 'component' | 'divider' | 'tomove';

type Props = {|
  type: DragType,
  wrapperComponent?: Object,
  enabled: boolean,
  draggableId?: string,
  data: any,
  className?: string,
  onDragEnd?: (SyntheticDragEvent<*>) => any,
  onDragStart?: (SyntheticDragEvent<*>) => any,
  children: React.Node,
  style?: Style,
|};

type DefaultProps = {|
  enabled: boolean,
|};

export default class Draggable extends React.Component<Props> {
  static defaultProps: DefaultProps = {
    enabled: true,
  };

  render(): React.Node {
    let { type, style } = this.props;

    if (type && type.toLowerCase() !== type)
      console.error(
        "For some reason you can't use upper case type on Draggable:",
        type
      );

    let Tag = 'div';
    let props = { ...this.props }; // copy

    if (this.props.wrapperComponent) {
      Tag = this.props.wrapperComponent.type;
      props = Object.assign(props, this.props.wrapperComponent.props);
      delete props.wrapperComponent;
    }

    const newProps = omit(
      {
        ...props,
        draggable: 'true',
        onDragEnd: this.onDragEnd.bind(this),
        onDragStart: this.onDragStart.bind(this),
      },
      'enabled'
    );

    return (
      <Tag {...newProps} data="json" style={style} className="draggable">
        {props.children}
      </Tag>
    );
  }

  onDragStart(e: SyntheticDragEvent<*>) {
    if (typeof this.props.onDragStart === 'function') this.props.onDragStart(e);
    let props = { ...this.props }; // copy
    if (this.props.wrapperComponent)
      props = Object.assign(props, this.props.wrapperComponent.props);

    e.dataTransfer.setData(props.type, JSON.stringify(props.data));
    utils.dataTransferInProtectedMode[props.type] = props.data;
  }

  onDragEnd(e: SyntheticDragEvent<*>) {
    // Not called if dropped!
    if (typeof this.props.onDragEnd === 'function') this.props.onDragEnd(e);
    utils.dataTransferInProtectedMode = {}; // so this is not sufficient... another bug of this lib
  }
}
