// @flow
import type { ColumnExportDescription } from 'src/types/models/exports';

export const USER_EXPORT_COLUMNS_EDIIS: Array<ColumnExportDescription> = [
  { prop: 'login' },
  { prop: '_id' },
  { prop: 'title' },
  { prop: 'firstName' },
  { prop: 'lastName' },
  { prop: 'nickname' },
  { prop: 'birthDateTs' },
  { prop: 'tel' },
  { prop: 'address.title' },
  { prop: 'address.firstName' },
  { prop: 'address.lastName' },
  { prop: 'address.company' },
  { prop: 'address.line1' },
  { prop: 'address.line2' },
  { prop: 'address.line3' },
  { prop: 'address.postalCode' },
  { prop: 'address.city' },
  { prop: 'address.country' },
  { prop: 'freeAccessUntil' },
  { prop: 'useAnswerNotif' },
  { prop: 'receiveNewsletter' },
  { prop: 'receiveSpam' },
  { prop: 'stripeCustomerId' },
  { prop: 'createTs' },
  { prop: 'denyContribution' },
  { prop: 'importTs' },
  { prop: 'abowebUserId' },
  { prop: 'getresponseId' },
  { prop: 'passHash', label: 'Hash mot de passe' },
];

export const PAYMENT_EXPORT_COLUMNS_EDIIS: Array<ColumnExportDescription> = [
  { prop: '_id' },
  { prop: 'createTs' },
  { prop: 'status' },
  { prop: 'donation' },
  { prop: 'shippingCost' },
  { prop: 'price' },
  { prop: 'netPrice' },
  { prop: 'stripeFee' },
  { prop: 'refund' },
  { prop: 'abowebOrderId' },
  { prop: 'userId._id' },
  { prop: 'userId.login' },
  { prop: 'shippingAddress.title' },
  { prop: 'shippingAddress.firstName' },
  { prop: 'shippingAddress.lastName' },
  { prop: 'shippingAddress.company' },
  { prop: 'shippingAddress.line1' },
  { prop: 'shippingAddress.line2' },
  { prop: 'shippingAddress.line3' },
  { prop: 'shippingAddress.postalCode' },
  { prop: 'shippingAddress.city' },
  { prop: 'shippingAddress.country' },
  { prop: 'billingAddress.title' },
  { prop: 'billingAddress.firstName' },
  { prop: 'billingAddress.lastName' },
  { prop: 'billingAddress.company' },
  { prop: 'billingAddress.line1' },
  { prop: 'billingAddress.line2' },
  { prop: 'billingAddress.line3' },
  { prop: 'billingAddress.postalCode' },
  { prop: 'billingAddress.city' },
  { prop: 'billingAddress.country' },
  { prop: 'intent.id' },
];

export const PURCHASE_EXPORT_COLUMNS_EDIIS: Array<ColumnExportDescription> = [
  { prop: 'status' },
  { prop: 'createTs' },
  { prop: 'isGift' },
  { prop: 'user._id' },
  { prop: 'user.login' },
  { prop: 'fromUserId._id' },
  { prop: 'fromUserId.login' },
  { prop: 'product._id' },
  { prop: 'product.label' },
  { prop: 'userPrice' },
  { prop: 'shippingCost' },
  { prop: 'paymentId._id' },
  { prop: 'dontRenew' },
];

export const EVENT_EXPORT_COLUMNS_EDIIS: Array<ColumnExportDescription> = [
  { prop: '_id' },
  { prop: 'label' },
  { prop: 'cartLabel' },
  { prop: 'eventTs' },
  { prop: 'price' },
  { prop: 'maxTickets' },
  { prop: 'requiresShipping' },
  { prop: 'foreignShippingCost' },
  { prop: 'disableDonation' },
  { prop: 'requireJustif' },
  { prop: 'isSub' },
  { prop: 'abowebTarifCodeFr' },
  { prop: 'abowebShippingTarifCode' },
  { prop: '_abowebFormulaCode' },
];

export const FORMULA_EXPORT_COLUMNS_EDIIS: Array<ColumnExportDescription> = [
  { prop: '_id' },
  { prop: 'label' },
  { prop: 'stripePlanName' },
  { prop: 'price' },
  { prop: 'isObsolete' },
  { prop: 'maxTickets' },
  { prop: 'requiresShipping' },
  { prop: 'foreignShippingCost' },
  { prop: 'disableDonation' },
  { prop: 'isSub' },
  { prop: 'displayFirstIssue' },
  { prop: 'abowebTarifCodeFr' },
  { prop: 'abowebTarifCodeInt' },
  { prop: 'resubOnly' },
  { prop: 'noRecurrence' },
];

export const PURCHASE_NEW_EXPORT_COLUMNS_EDIIS: Array<ColumnExportDescription> = [
  { prop: '_id', label: 'no commande' },
  { prop: 'createTs', label: 'date création' },
  { label: 'ori abo', f$: '"?"' },
  { prop: 'abowebDetails.pns', label: '1er num a servir' },
  { prop: 'abowebDetails.servir', label: 'durée' },
  { prop: 'product.price', label: 'prix unit ttc' },
  { prop: 'user.login', label: 'mail' },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).company',
    label: 'adresse société',
  },
  //{ prop: 'paymentId.shippingAddress.title', label: '' },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).firstName',
    label: 'adresse nom',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).lastName',
    label: 'adresse prénom',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).normalized[0]',
    label: 'adresse ligne 1',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).normalized[1]',
    label: 'adresse ligne 2',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).normalized[2]',
    label: 'adresse ligne 3',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).normalized[3]',
    label: 'adresse ligne 4',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).postalCode',
    label: 'code postal',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).city',
    label: 'ville',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).country',
    label: 'pays',
  },
  { prop: 'user.tel', label: 'téléphone' },
  { label: 'code famille', f$: '"?"' },
  {
    f$:
      'obj.shippingCost && ' +
      '  obj.product.abowebTarifCodeInt || ' +
      '  obj.product.abowebTarifCodeFr',
    label: 'code tarif formule',
  },
  { prop: 'paymentId.price', label: 'facture mt ttc' },
  { label: 'no facture', f$: '"?"' },
  { label: 'cqlf fact', f$: '"?"' },
  {
    f$:
      'obj.paymentId.intent.charges.data[0].payment_method_details.type === "card" ?' +
      '  "CB ABM" : ' +
      '  obj.paymentId.intent.charges.data[0].payment_method_details.type',
    label: 'mode de règlement',
  }, // format

  { prop: 'createTs', label: 'date regl' },
  { prop: 'paymentId.userId', label: 'client payeur' },
  { prop: 'user._id', label: 'client livré' },
  { label: 'famille formule', f$: '"?"' },
  { prop: 'user.stripeCustomerId', label: 'id stripe' },
  {
    f$:
      'obj.paymentId.intent.charges.data[0].payment_method_details.card.exp_month + ' +
      "'/' + obj.paymentId.intent.charges.data[0].payment_method_details.card.exp_year",
    label: 'CB Expirée',
  },
  { prop: 'user.passHash', label: 'Hash mot de passe' },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).normalized[4]',
    label: 'adresse ligne 5',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).normalized[5]',
    label: 'adresse ligne 6',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).line1',
    label: 'adresse brute ligne 1',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).line2',
    label: 'adresse brute ligne 2',
  },
  {
    f$:
      '(obj.paymentId.shippingAddress ? obj.paymentId.shippingAddress : obj.paymentId.billingAddress).line3',
    label: 'adresse brute ligne 3',
  },
];
