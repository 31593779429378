const EntityTags = {
  _id: 'tags',
  label: 'Tags',
  icon: 'tags',
  frontComponent: 'EntityTags',
  optionsSchema: [
    {
      key: 'label',
      label: 'Libellé',
      type: 'string',
      default: 'Thèmes abordés',
    },
    {
      key: 'labelIfMultiple',
      label: 'Libellé si plusieurs',
      type: 'string',
      default: 'Thèmes abordés',
    },

    { key: 'tagCount', label: 'Nombre max. de tags', type: 'int' },
  ],
  forEntity: true,
};

export default EntityTags;
