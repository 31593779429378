// @flow
import * as React from 'react';
import { pickBy } from 'lodash';

import { useHistory } from 'react-router-dom';
import { useResourceContext } from 'src/hooks';

import ResourceEditor from 'src/pages/components/resource/ResourceEditor';

export default function EditTabContent(): React.Node {
  const history = useHistory();
  const { resource, path, schema, dirty } = useResourceContext();

  const leaveWithoutSaving = () =>
    window.confirm(
      'Êtes-vous sûr de vouloir quitter sans enregistrer vos modifications ?'
    );

  const duplicate = React.useCallback(() => {
    const filteredResource = pickBy(resource, (propertyValue, propertyName) => {
      if (propertyName === '_id') return false;

      const schemaProperty = schema.propertiesList.find(
        (propertiesListItem) => propertiesListItem.key === propertyName
      );

      if (!schemaProperty) return false;

      if (schemaProperty.notDuplicable) return false;

      return true;
    });

    if (!dirty || leaveWithoutSaving())
      history.push({
        pathname: `${path}/new`,
        state: { resource: filteredResource },
      });
  }, [history, resource, dirty]);

  const close = React.useCallback(() => {
    if (!dirty || leaveWithoutSaving())
      history.push({
        pathname: path,
      });
  }, [history, path, dirty]);

  return <ResourceEditor onClose={close} onDuplicate={duplicate} />;
}
