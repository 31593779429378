const EntityBody = {
  _id: 'body',
  label: 'Texte complet',
  icon: 'file-alt',
  frontComponent: 'EntityBody',
  optionsSchema: [
    {
      key: 'displayRegisterLink',
      label: "Afficher le lien 's'abonner'",
      description:
        "Afficher le lien 's'abonner' / 'se connecter' si texte payant et user freemium",
      type: 'bool',
      default: true,
      required: true,
    },
  ],
  forEntity: true,
};

export default EntityBody;
