// @flow
import * as React from 'react';

import { useGlobal } from 'src/hooks';

import { NavLink } from 'react-router-dom';
import InstanceSelect from './InstanceSelect';
import InstanceLogo from './InstanceLogo';
import { viewPortAware } from 'src/components';

// $FlowIgnore
const ResponsiveInstanceSelect = React.memo(
  viewPortAware({
    tabletAndBelow: () => (
      <InstanceSelect>
        <InstanceLogo />
      </InstanceSelect>
    ),
    desktop: Desktop,
  }),
  () => true
);

export default ResponsiveInstanceSelect;

function Desktop() {
  const { siteId } = useGlobal();

  return (
    <>
      <div className="navbar-brand">
        <NavLink
          exact
          to={`/${siteId}`}
          activeClassName="is-active"
          className="navbar-item"
          style={{ minWidth: 52 }}
        >
          <InstanceLogo />
        </NavLink>
      </div>

      <InstanceSelect />
    </>
  );
}
