import Reflux from 'reflux';
import API from 'src/helpers/api/API';
import _ from 'lodash';

import HARDCODED_SCHEMAS from 'src/schema/hardcodedSchemas';

export var SchemaActions = Reflux.createActions([
  'load',
  'set',
  //"setSpecific",
]);

export default class SchemaStore extends Reflux.Store {
  constructor() {
    super();

    this.state = {
      schemaLoadError: null,
      globalSchema: {},
      schemaLoaded: false,
    };

    this.listenables = SchemaActions;

    this.load();
  }

  load() {
    API.get('global/data/schema').then(
      // TODO instance
      ({ data }) => this.set(data),
      (error) => this.setState({ globalSchema: {}, schemaLoadError: error })
    );
  }

  set(data) {
    let globalSchema = { ...HARDCODED_SCHEMAS, ..._.keyBy(data, '_id') };

    this.setState({
      globalSchema,
      schemaLoadError: null,
      schemaLoaded: true,
    });
  }

  static getGlobalSchema() {
    let schemaStore = Reflux.getGlobalState().schemaStore;
    this.instanceName = schemaStore && schemaStore.globalSchema;
  }
}

SchemaStore.id = 'SchemaStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state
