const SocialButtons = {
  _id: 'socialButtons',
  frontComponent: 'SocialButtons',
  label: 'Boutons sociaux',
  icon: 'thumbs-up',
  optionsSchema: [
    { key: 'twitterUrl', label: 'URL du Twitter', type: 'string' },
    { key: 'facebookUrl', label: 'URL de la page Facebook', type: 'string' },
    { key: 'youtubeUrl', label: 'URL de la chaîne Youtube', type: 'string' },
    { key: 'instagramUrl', label: 'URL Instagram', type: 'string' },
    { key: 'linkedinUrl', label: 'URL LinkedIn', type: 'string' },
  ],
  forPage: true,
};

export default SocialButtons;
