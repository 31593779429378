// @flow
import * as React from 'react';

// React Google chart doc -> https://react-google-charts.com/
import { Chart } from 'react-google-charts';

type ChartType = 'AreaChart' | 'ColumnChart';

type Column = {
  type: 'number',
  label: string,
};

type Row = [string, number];

type HAxisOptions = {|
  title?: string,
  minValue?: number,
  maxValue?: number,
|};

type VAxisOptions = {|
  title?: string,
  minValue?: number,
  maxValue?: number,
|};

type Options = {|
  animation: any,
  series: Object,
  yellowFrom: number,
  yellowTo: number,
  redFrom: number,
  redTo: number,
  minorTicks: number,
|};

type Props = {|
  chartType: ChartType,
  columns: Array<Column>,
  rows: Array<Row>,
  title?: string,
  hAxisOptions?: HAxisOptions,
  vAxisOptions?: VAxisOptions,
  options?: Options,
  height?: string,
|};

export default function StatsGraph({
  chartType,
  columns,
  rows,
  title,
  hAxisOptions,
  vAxisOptions,
  options,
  height,
}: Props): React.Node {
  return (
    <Chart
      chartType={chartType || 'ColumnChart'}
      columns={columns}
      rows={rows}
      options={{
        title,
        explorer: {
          actions: ['dragToZoom', 'rightClickToReset'],
          axis: 'horizontal',
          keepInBounds: true,
        },
        animation: {
          duration: 500,
          easing: 'inAndOut',
        },
        hAxis: hAxisOptions,
        vAxis: vAxisOptions,
        bar: { groupWidth: '85%' },
        chartArea: { height: '85%', top: '5%' },
        legend: 'none',
        ...options,
      }}
      width="100%"
      height={height || '500px'}
      legend_toggle
    />
  );
}
