import { MODE_PROPERTY } from '../constants';

const TagList = {
  _id: 'tagList',
  label: 'Liste de tags',
  frontComponent: 'TagList',
  useRequester: true,
  optionsSchema: [
    {
      key: 'name',
      label: "Nom interne de l'emplacement",
      description:
        "Pour identifier cette liste dans l'admin lors de la planification des contenus. Non affiché sur le site.",
      type: 'string',
    },
    {
      key: 'label',
      label: 'Titre de la liste',
      description: 'Sera affiché sur le site',
      type: 'string',
    },
    {
      key: 'labelIfMultiple',
      label: 'Titre de la liste si plusieurs',
      type: 'string',
    },

    MODE_PROPERTY,
    {
      key: 'itemCount',
      label: 'Nombre de tags max au total',
      type: 'int',
    },
    {
      key: 'list',
      type: 'DiffusionList',
      itemType: 'Tag', // TODO
    },
  ],
  forPage: true,
  hasEntities: true,
};

export default TagList;
