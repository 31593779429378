import React from 'react';

import { UserActions } from 'src/stores/UserStore';
import ComponentBase from 'src/utils/ComponentBase';
import { getSiteIdFromPath } from 'src/helpers/url';

import { OldInput } from 'src/components';

export default class LoginPage extends ComponentBase {
  static defaultProps = {};

  bindToState = (stateName) => ({
    value: this.state[stateName],
    onChange: (value) => this.setState({ [stateName]: value }),
  });

  onSubmit = () => {
    let { login, password } = this.state;

    let hostPrefix = window.location.hostname.split('_')[0];
    const siteId = getSiteIdFromPath();

    UserActions.login({ login, password, hostPrefix }, siteId, (error) =>
      this.setState({ error })
    );
  };

  render() {
    return (
      <>
        <div className="LoginPage">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.onSubmit();
            }}
          >
            <h1>Administration Plateforme UfullVideo</h1>

            <OldInput id="email" label="Login" {...this.bindToState('login')} />
            <OldInput
              id="password"
              label="Mot de passe"
              type="password"
              {...this.bindToState('password')}
            />
            <button
              className="FormBase-submit-button"
              id="submit-button"
              type="submit"
            >
              Login
            </button>
            {this.state.error && (
              <span className="error">{this.state.error.message}</span>
            )}
          </form>
        </div>
      </>
    );
  }
}
