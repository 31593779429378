import * as React from 'react';
import classnames from 'classnames';

// flow-ignore
import { default as ReactSelect } from 'react-select';
// flow-ignore
import ReactCreatableSelect from 'react-select/creatable';

type Option<T> = {
  [string]: any,
  value: T,
  label: string,
};

export type GroupedOption<T> = {|
  label: string,
  options: Array<Option<T>>,
|};

type ActionTypes =
  | 'clear'
  | 'create-option'
  | 'deselect-option'
  | 'pop-value'
  | 'remove-value'
  | 'select-option'
  | 'set-value';
export type Action = { action: ActionTypes };

type Props<T> = {|
  value: Option<T> | Array<Option<T>> | null,
  options: Array<Option<T>> | Array<GroupedOption<T>>,
  onChange: (
    value: Option<T> | Array<Option<T>> | null | void,
    action: Action
  ) => any,
  onInputChange?: (value: string) => string,
  isClearable?: boolean,
  placeholder?: string,
  styles?: {},
  isSearchable?: boolean,
  disabled?: boolean,
  captureMenuScroll?: boolean,
  closeMenuOnScroll?: boolean,
  menuPortalTarget?: any,
  maxMenuHeight?: number,
  isMulti?: boolean,
  autoFocus?: boolean,
  onBlur?: () => *,
  isCreatable?: boolean,
  formatCreateLabel?: (value: string) => string,
  isLoading?: boolean,
  loadingMessage?: () => string,
  inModal?: boolean,
  noOptionsMessage?: string,
  isDanger?: boolean,
|};

type State = {|
  isLoading: boolean,
|};

export default class Select<T> extends React.Component<Props<T>, State> {
  state: State = {
    isLoading: false,
  };

  handleChange: (
    option: Option<T> | Array<Option<T>> | null | void,
    action: Action
  ) => void = (option, action) => {
    const { onChange } = this.props;

    if (!option) return onChange(null, { action: 'clear' });

    onChange(option, action);
  };

  render(): React.Node {
    const {
      isClearable,
      isCreatable,
      styles,
      inModal,
      noOptionsMessage,
      isDanger,
      disabled,
      ...otherProps
    } = this.props;

    const ComponentType = isCreatable ? ReactCreatableSelect : ReactSelect;
    const className = classnames('Select', isDanger && 'is-danger');
    const classNamePrefix = classnames({
      SelectInModalSub: inModal,
      SelectSub: !inModal,
    });

    return (
      <ComponentType
        {...otherProps}
        isClearable={isClearable}
        isDisabled={disabled}
        disabled={disabled}
        escapeClearsValue={isClearable}
        backspaceRemovesValue={false}
        className={className}
        classNamePrefix={classNamePrefix}
        onChange={this.handleChange}
        noOptionsMessage={({ inputValue }: { inputValue: string }) =>
          noOptionsMessage || `Pas de résultat pour ${inputValue}`
        }
        styles={{
          // flow-ignore
          ...styles,
        }}
      />
    );
  }
}
