// @flow
import * as React from 'react';

import type { ResourceType } from 'src/types/models';

import RESOURCE_TYPES from 'src/pages/constants/resources';

import userCustomActions from './user';
import emailTemplatesCustomActions from './emailTemplates';

export type CustomActionProp = {
  style?: Object,
};

export default function getResourceCustomActions(
  resourceType: ResourceType
): Array<React.AbstractComponent<CustomActionProp>> {
  switch (resourceType) {
    case RESOURCE_TYPES.User.singular:
      return userCustomActions;
    case RESOURCE_TYPES.EmailTemplate.singular:
      return emailTemplatesCustomActions;
    default:
      return [];
  }
}
