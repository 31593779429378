// @flow
import type { SectionName, Section } from 'src/types/routing';

import buildSectionItemOrder from './sectionItemsOrder';

const SECTIONS: { [key: SectionName]: Section } = {
  site: {
    id: 'site',
    label: 'Site',
    rights: 'siteConfig',
    pathname: '/website-configuration',
    defaultPathname: '/website-configuration/pages',
    icon: 'browser',
    order: buildSectionItemOrder('site'),
    sidebarShortcuts: [
      {
        pathname: 'complex-components/header/edit',
        label: 'Header',
      },
    ],
  },
  contents: {
    id: 'contents',
    label: 'Contenus',
    rights: 'contents',
    pathname: '/contents',
    defaultPathname: '/contents/text-contents',
    icon: 'layer-group',
    order: buildSectionItemOrder('contents'),
  },
  products: {
    id: 'products',
    label: 'Produits',
    rights: 'products',
    pathname: '/products',
    defaultPathname: '/products/subscription-formulas',
    icon: 'shopping-cart',
    order: buildSectionItemOrder('products'),
  },
  encoding: {
    id: 'encoding',
    label: 'Encodage',
    rights: 'contents',
    pathname: '/video-encoding',
    icon: 'play',
    order: buildSectionItemOrder('encoding'),
  },
  data: {
    id: 'data',
    label: 'Data',
    rights: 'hasDataSectionAccess',
    pathname: '/data',
    icon: 'chart-bar',
    order: buildSectionItemOrder('data'),
  },
  users: {
    id: 'users',
    label: 'Utilisateurs',
    rights: 'users',
    pathname: '/users',
    icon: 'users',
    order: buildSectionItemOrder('users'),
  },
  payments: {
    id: 'payments',
    label: 'Paiements',
    rights: 'users',
    pathname: '/payments',
    icon: 'dollar-sign',
    order: buildSectionItemOrder('payments'),
  },
  admin: {
    id: 'admin',
    label: 'Admin',
    rights: 'admin',
    pathname: '/admin',
    icon: 'user-shield',
    order: buildSectionItemOrder('admin'),
  },
  tools: {
    id: 'tools',
    label: 'Tools',
    rights: 'hasToolsAccess',
    pathname: '/tools',
    icon: 'cogs',
    order: buildSectionItemOrder('tools'),
  },
  superadmin: {
    id: 'superadmin',
    label: 'Super admin',
    rights: 'superAdmin',
    pathname: '/super-admin',
    icon: 'shield-alt',
    order: buildSectionItemOrder('superadmin'),
  },
};

export default SECTIONS;
