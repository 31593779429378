// @flow
import * as React from 'react';
import classnames from 'classnames';
import 'rc-tooltip/assets/bootstrap_white.css';

import { QuestionMarkTooltip } from 'src/components';
type Props = {|
  children: React.Node,
  required?: boolean,
  tooltip?: string,
  onClick?: (any) => any,
  additionalClassName?: string,
  style?: Object,
|};

export default function Label({
  children,
  required,
  tooltip,
  onClick,
  additionalClassName,
  style,
}: Props): React.Node {
  if (!children) return null;

  return (
    <label
      onClick={onClick}
      className={classnames('label', additionalClassName)}
      style={style}
    >
      {children}
      {required ? '*' : ''}
      {tooltip && <QuestionMarkTooltip content={tooltip} />}
    </label>
  );
}
