const AnswerQuestionAuthor = {
  _id: 'answerQuestionAuthor',
  frontComponent: 'AnswerQuestionAuthor',
  label: 'Auteur question',
  icon: 'user',
  optionsSchema: [
    { key: 'prefix', label: 'Préfixe', type: 'string' },
    {
      key: 'suffix',
      label: 'Suffixe',
      type: 'string',
      default: ' demande :',
    },
  ],
  forPage: true,
};

export default AnswerQuestionAuthor;
