// @flow
import * as React from 'react';

import type {
  ResourceType,
  ResourceSchema,
  UserRight,
  User,
} from 'src/types/models';
import type { Match } from 'react-router-dom';
import type { Query } from 'src/types/routing';

import RESOURCE_TYPES from 'src/pages/constants/resources';

import genericTabs from './generic';
import accountingExportItemCustomTabs from './accountingExportItem';
import videoServerCustomTabs from './videoServer';
import userCustomTabs from './user';
import resubRuleCustomTabs from './resubRule';

export type GenericProps = {|
  basePath: string,
  match: Match,
  schema: ResourceSchema,
  query?: Query,
|};

export type Tab = {|
  to: string,
  label: React.Node,
  content: React.ComponentType<GenericProps>,
  right?: UserRight,
  exact?: boolean,
  condition?: (User) => boolean,
|};

function getCustomTabs(resourceType: ResourceType): Array<Tab> {
  switch (resourceType) {
    case RESOURCE_TYPES.AccountingExportItem.singular:
      return accountingExportItemCustomTabs;
    case RESOURCE_TYPES.VideoServer.singular:
      return videoServerCustomTabs;
    case RESOURCE_TYPES.User.singular:
      return userCustomTabs;
    case RESOURCE_TYPES.Payment.singular:
      return resubRuleCustomTabs;
    default:
      return [];
  }
}

function getGenericTabs(resourceType: ResourceType): Array<Tab> {
  switch (resourceType) {
    case RESOURCE_TYPES.Page.singular:
      return [];
    case 'schema':
      return [];
    default:
      return genericTabs;
  }
}

export default function getResourceTypeTabs(
  resourceType: ResourceType
): Array<Tab> {
  return [...getGenericTabs(resourceType), ...getCustomTabs(resourceType)];
}
