import { MODE_PROPERTY } from '../constants';
import DIFFUSION_LIST_ORDER_FIELDS from './DiffusionList';

const SingleEntitySchema = {
  _id: 'singleEntity',
  label: 'Contenu seul',
  icon: 'square',
  frontComponent: 'SingleEntity',
  forPage: true,
  hasEntities: true,
  optionsSchema: [
    {
      key: 'name',
      label: "Nom interne de l'emplacement",
      description:
        "Pour identifier cette liste dans l'admin lors de la planification des contenus. Non affiché sur le site.",
      type: 'string',
    },
    MODE_PROPERTY,
    {
      key: 'list',
      type: 'DiffusionList',
      // TODO max: 1
    },
    ...DIFFUSION_LIST_ORDER_FIELDS,
  ],
};

export default SingleEntitySchema;
