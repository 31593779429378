// @flow
import * as React from 'react';
import truncate from 'truncate-html';

import type { ResourcePropertySchema } from 'src/types/models';

import DynImg from 'src/utils/DynImg';

type Props = {|
  propertySchema: ResourcePropertySchema,
  propertyValue: any,
  options: any,
|};

function PropertyRenderer({
  propertySchema,
  propertyValue,
  options = {},
}: Props): React.Node {
  const { type, key } = propertySchema;

  switch (type) {
    case 'richtext':
      if (!propertyValue?.html) return null;

      return (
        <span
          className="quote-html"
          dangerouslySetInnerHTML={{
            __html: truncate(propertyValue?.html, 100) || '',
          }}
        />
      );
    case 'file':
      if (key !== 'image') return null;
      return <DynImg src={propertyValue} {...options} />;

    case 'DiffusionList':
      if (!propertyValue || propertyValue.length === 0) return null;

      return (
        <ul className="diffusion-list-renderer">
          {propertyValue.map((item) => {
            if (item._cls === 'ContentSchedule') {
              return (
                <li key={JSON.stringify(item)}>
                  {item.content?.label || 'A remplir'}
                </li>
              );
            }

            if (item._cls === 'ContentRequester') {
              return (
                <li key={JSON.stringify(item)}>
                  Liste de {item.type} - {item.limit} affichés, triés par{' '}
                  {item.sortBy} / {item.sortOrder}
                </li>
              );
            }

            return null;
          })}
        </ul>
      );

    default:
      return null;
  }
}

export default PropertyRenderer;
