// @flow
import * as React from 'react';
import { pick } from 'lodash';

import type { ResourcePropertySchema, ResourceRef } from 'src/types';
import type { OnPropertyValueChange } from './';

import { getResourcePath } from 'src/helpers/url';
import { useGlobal } from 'src/hooks';
import { getResourceLabel } from 'src/helpers/models/schema';
import { useResourceReferencesContext } from 'src/hooks';

import { Button, Icon, Card, CardHeader, Flex, Modal } from 'src/components';

import ResourceSelector from './ResourceSelector';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: ?ResourceRef,
  disabled?: boolean,
|};

export default function ObjectRefEditor({
  value: resourceRef,
  propertySchema,
  onChange,
  disabled,
}: Props): React.Node {
  const { siteId, globalSchema } = useGlobal();
  const { references } = useResourceReferencesContext();
  const reference = references[propertySchema.key];

  const objectTypes = (Array.isArray(propertySchema.objectType)
    ? propertySchema.objectType
    : [propertySchema.objectType]
  ).filter((type) => !!type);

  //$FlowIgnore
  const resourceSchemas = pick(globalSchema, objectTypes);
  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Flex additionalClassName="object-ref-editor">
        {!!resourceRef && resourceSchemas[resourceRef._cls] && (
          <Card
            style={{ marginRight: 10 }}
            additionalClassName="object-ref-card"
          >
            <CardHeader
              title={getResourceLabel(
                reference || resourceRef,
                resourceSchemas[resourceRef._cls]
              )}
              actions={[
                {
                  icon: 'eye',
                  to: getResourcePath(
                    siteId,
                    resourceSchemas[resourceRef._cls],
                    resourceRef._id
                  ),
                  target: '_blank',
                },
              ]}
            />
          </Card>
        )}

        {!disabled && (
          <>
            <Button
              onClick={() => setModalOpen(true)}
              style={{ marginRight: 10 }}
            >
              <Icon name={!!resourceRef ? 'edit' : 'plus'} />
            </Button>

            {!!resourceRef && (
              <Button
                onClick={() => onChange(null)}
                style={{ marginRight: 10 }}
                color="danger"
              >
                <Icon name="times" />
              </Button>
            )}
          </>
        )}
      </Flex>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} modal>
        <ResourceSelector
          value={resourceRef ? [resourceRef] : []}
          onChange={(resource) => {
            onChange(resource);
          }}
          resourceSchemas={Object.keys(resourceSchemas).map(
            (schemaId) => resourceSchemas[schemaId]
          )}
        />
      </Modal>
    </>
  );
}
