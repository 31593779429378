// @flow
/* eslint-disable  no-new-func */
import { isNil } from 'lodash';

import type {
  ResourcePropertySchema,
  GlobalSchema,
  Resource,
  User,
  Site,
} from 'src/types';

const NON_EDITABLE_FIELDS = []; // ['_id']; // Disabled for now, waiting for slug

type FilterPropertyOptions = {|
  resource: Resource,
  user: User,
  site: Site,
|};

export const filterProperty = (
  propertySchema: ResourcePropertySchema,
  { resource, user, site, ...context }: FilterPropertyOptions
): any => {
  const isResourcePersisted = !!resource._id;
  const {
    key,
    createOnly,
    readOnly,
    condition,
    notDisplayableInAdmin,
  } = propertySchema;
  const propertyValue = resource[key];

  let editable = true;

  if (notDisplayableInAdmin) return false;

  if (isResourcePersisted && createOnly) editable = false;

  if (readOnly) editable = false;

  if (!editable && isNil(propertyValue)) return false;

  if (NON_EDITABLE_FIELDS.includes(key)) return false;

  if (!!condition) {
    const filterFunction =
      typeof condition === 'function'
        ? condition
        : // $FlowIgnore
          Function(
            '{ obj, adminUser, site, context }',
            'return !obj ? null : (' + condition.f$ + ')'
          );

    if (!filterFunction({ obj: resource, adminUser: user, site, context }))
      return false;
  }

  return true;
};

type TransformPropertyOptions = {|
  resource: Resource,
  globalSchema: GlobalSchema,
|};

export const transformProperty = (
  propertySchema: ResourcePropertySchema,
  { globalSchema, resource }: TransformPropertyOptions
): ResourcePropertySchema => {
  let formattedPropertySchema = propertySchema;

  if (propertySchema.type in globalSchema) {
    // $FlowIgnore
    formattedPropertySchema = {
      // $FlowIgnore
      ...globalSchema[propertySchema.type],
      key: propertySchema.key,
      objectType: propertySchema.type,
    };
  }

  if (typeof formattedPropertySchema.values === 'function') {
    formattedPropertySchema = {
      ...formattedPropertySchema,
      values: formattedPropertySchema.values(resource),
    };
  }

  if (typeof formattedPropertySchema.type === 'function') {
    formattedPropertySchema = {
      ...formattedPropertySchema,
      type: formattedPropertySchema.type(resource),
    };
  }

  if (typeof formattedPropertySchema.convert === 'function') {
    formattedPropertySchema = {
      ...formattedPropertySchema,
      convert: formattedPropertySchema.convert(resource),
    };
  }

  // $FlowIgnore
  return formattedPropertySchema;
};
