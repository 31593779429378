import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import InstanceStore from 'src/stores/InstanceStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import { toHumanReadable as dateToHumanReadable } from 'src/helpers/dates';
import config from 'src/helpers/api/config';
import { buildQueryString } from 'src/helpers/misc';

import { Button, Switch, OldDatePicker } from 'src/components';

export default class AccountingExportTab extends ComponentBase {
  store = InstanceStore;
  state = {
    accountingFormat: true,
  };

  didMount() {
    let { accountingFormat, instanceName } = this.state;
    API.get(`${instanceName}/stripePayout`, {
      accountingFormat,
    }).then((payouts) => this.setState({ payouts }));
  }

  render() {
    let { begin, end, payouts, instanceName } = this.state;

    return (
      <div className="AccountingExportTab FormBase--primary">
        <h1>
          Export comptable - Réconciliation transactions / virements Stripe
        </h1>
        <Switch
          value={this.state.accountingFormat}
          onChange={(v) => this.setState({ accountingFormat: v })}
          label="Utiliser le format comptable : (123) au lieu de -123"
        />

        <h2>Par date de transaction</h2>
        <p>
          <i>
            Attention : il s'agit des dates de paiements/remboursements et non
            des dates de virements :
          </i>
        </p>
        <div>
          <OldDatePicker
            label="de :"
            value={begin || ''}
            onChange={(v) => this.setState({ begin: v })}
          />

          <OldDatePicker
            label="à :"
            value={end || ''}
            onChange={(v) => this.setState({ end: v })}
          />
        </div>
        <a
          className="FormBase-button---"
          target="_blank"
          rel="noopener noreferrer"
          href={`${
            config.API_URL
          }/admin/${instanceName}/custom-exports/stripePayout?${buildQueryString(
            {
              begin,
              end,
            }
          )}`}
        >
          <Button>Exporter tous les paiements dans ces dates</Button>
        </a>

        <h2>Par virement</h2>
        <ul>
          {(!payouts && <div>Chargement des virements Stripe...</div>) ||
            _.map(payouts, (payout) => (
              <li key={payout.id}>
                <a
                  className="FormBase-button---"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${
                    config.API_URL
                  }/admin/${instanceName}/custom-exports/stripePayout/${encodeURIComponent(
                    payout.id
                  )}?outputFileName="${encodeURIComponent(
                    'export_comptable_' +
                      moment.unix(payout.arrival_date).format('YYYY-MM-DD') +
                      '_' +
                      ~~(payout.amount / 100) +
                      'EUR.csv'
                  )}
                    "`}
                >
                  Virement du {dateToHumanReadable({ ts: payout.arrival_date })}
                  {' - '}
                  {(payout.status === 'paid' && (
                    <span className="success">Payé</span>
                  )) ||
                    (payout.status === 'in_transit' && (
                      <span className="warning">En transit</span>
                    )) ||
                    (payout.status === 'in_transit' && (
                      <span>{payout.status}</span>
                    ))}
                </a>
              </li>
            ))}
        </ul>
      </div>
    );
  }
}
