import React from 'react';
import _ from 'lodash';

import InstanceStore from 'src/stores/InstanceStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import { bindInput } from 'src/helpers/misc';

import {
  Button,
  Switch,
  OldDatePicker,
  OldInput,
  OldSelect,
  Icon,
} from 'src/components';

export default class GetResponseSynchroTab extends ComponentBase {
  store = InstanceStore;
  state = {
    results: null,
    syncAbowebSubscriptions: false,
    maxSyncTs: null,
  };

  sync() {
    const {
      limit,
      filterType,
      beginTs,
      endTs,
      email,
      syncAbowebSubscriptions,
      maxSyncTs,
    } = this.state;

    this.setState({ results: null, loading: true, error: null });

    API.get(this.state.instanceName + '/getresponse/sync', {
      limit,
      filterType,
      beginTs,
      endTs,
      email,
      syncAbowebSubscriptions,
      maxSyncTs,
    })
      .then((results) => this.setState({ results, loading: false }))
      .catch((error) => this.setState({ error, loading: false }));
  }

  render() {
    let { results, loading, error, filterType } = this.state;

    return (
      <div className="GetresponseSynchroTab FormBase--primary">
        <h1>Synchronisation des contacts vers GetResponse</h1>

        <OldSelect
          label="Par"
          {...bindInput(this, 'filterType')}
          options={[
            { value: 'create_date', label: 'Dates de création' },
            { value: 'customer_email', label: 'E-mail de contact' },
            { value: 'all', label: 'Tous' },
          ]}
        />

        {(filterType === 'max_count' && (
          <>
            <OldInput
              label="Nombre à synchroniser (vide = tous) - par ordre de dernière modification"
              {...bindInput(this, 'limit')}
            />
          </>
        )) ||
          (filterType === 'customer_email' && (
            <OldInput label="E-mail" {...bindInput(this, 'email')} />
          )) ||
          (filterType === 'create_date' && (
            <>
              <OldDatePicker label="de" {...bindInput(this, 'beginTs')} />

              <OldDatePicker label="à" {...bindInput(this, 'endTs')} />
            </>
          ))}

        <Switch
          label="Synchroniser l'état des abonnements Aboweb (long)"
          {...bindInput(this, 'syncAbowebSubscriptions')}
          tooltip={
            'Renseigne les champs de type "aboweb_...", ex. "aboweb_etat_dernier_abo_web", "aboweb_dns_dernier_abo_papier", "aboweb_formule_active", etc.'
          }
        />

        <OldDatePicker
          label="Exclure les utilisateurs ayant été synchronisés après le"
          {...bindInput(this, 'maxSyncTs')}
        />

        <Button onClick={() => this.sync()} disabled={!filterType}>
          Synchroniser
        </Button>

        {(loading && (
          <div className="center-content">
            <Icon name="spinner" size="3x" pulse />
          </div>
        )) ||
          (error && (
            <div className="FormElement-error-message">
              {error.message || 'Erreur ' + error.code}
            </div>
          )) ||
          (results !== null && (
            <div>
              <div className="success">OK - {results.summary}</div>
              {_.map(results.errors, (error, j) => (
                <div key={j} className="error">
                  [{error[0]}] / {error[1]} : {error[2]}
                </div>
              ))}
            </div>
          ))}
      </div>
    );
  }
}
