// @flow
import * as React from 'react';
import useFuse from 'use-fuse';
import useDebouncedEffect from 'use-debounced-effect';

import type { GenericProps } from 'src/pages/ResourceRouter/Collection/resourceSpecific/tabs';
import type { ResourceSchema } from 'src/types/models';

import { useSchemaContext, useGlobal } from 'src/hooks';

import {
  Table,
  THead,
  TBody,
  Th,
  Tr,
  Td,
  Link,
  Field,
  Control,
  Input,
} from 'src/components';

const fuseOptions = {
  keys: ['_id'],
  threshold: 0.1,
  minMatchCharLength: 3,
};

export default function SchemaListTab(props: GenericProps): React.Node {
  const { globalSchema } = useGlobal();
  const { path } = useSchemaContext();
  const [search, setSearch] = React.useState('');
  const [fuseSearch, setFuseSearch] = React.useState('');

  useDebouncedEffect(() => setFuseSearch(search), 500, [search]);

  const schemas: Array<ResourceSchema> = (Object.values(globalSchema): any);

  const fusedSchemas = useFuse(schemas, fuseSearch, fuseOptions);

  return (
    <div className="schema-list-tab">
      <Field>
        <Control>
          <Input
            value={search}
            onChange={(val) => setSearch(val || '')}
            placeholder="recherche"
          />
        </Control>
      </Field>
      <Table>
        <THead>
          <Tr>
            <Th>Nom</Th>
          </Tr>
        </THead>
        <TBody>
          {(fuseSearch.length > 0 ? fusedSchemas : schemas).map((schema) => {
            return (
              <Tr key={schema._id}>
                <Td>
                  <Link to={`${path}/${schema._id}/edit`}>{schema._id}</Link>
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
}
