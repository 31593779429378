import * as React from 'react';

import { APIContext } from 'src/pages/contexts';

export default function UserFileRenderer({ value: filePath, resource }) {
  const { getUserContributionUrl } = React.useContext(APIContext);

  const fileUrl = getUserContributionUrl(
    resource.userId,
    handleFileNameEncoded(filePath)
  );

  return (
    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
      {filePath}
    </a>
  );
}

function handleFileNameEncoded(filePath) {
  try {
    return encodeURIComponent(decodeURIComponent(escape(filePath))); // gruge UTF8 // ... qui marche pas
  } catch (e) {
    console.error(
      filePath,
      JSON.stringify(filePath),
      escape(filePath),
      e.message
    );
  }

  return encodeURIComponent(filePath);
}
