// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from 'src/components/types';

import { createBodyPortal } from 'src/helpers/react';

type Props = {|
  children: React.Node,
  popperRef: React.Ref<any>,
  open: boolean,
  isNarrow?: boolean,
  style: Style,
  additionalClassName?: string,
|};

export default function DropdownMenu({
  children,
  popperRef,
  open,
  isNarrow,
  style,
  additionalClassName,
}: Props): React.Node {
  const className = classnames('dropdown-menu', additionalClassName, {
    'is-narrow': isNarrow,
    'is-opened': open,
  });

  return createBodyPortal(
    <div ref={popperRef} style={style} className={className} role="menu">
      <div className="dropdown-content">{children}</div>
    </div>
  );
}
