// @flow
import * as React from 'react';

import type { ContentStatsProps } from 'src/types';

import { EntityFullStats } from 'src/components/stats';
import PropertyField from '../../PropertyField';

export default function ContentStats({
  value,
  propertySchema,
}: ContentStatsProps): React.Node {
  return (
    <PropertyField propertySchema={propertySchema}>
      <EntityFullStats {...value} />
    </PropertyField>
  );
}
