// @flow
import * as React from 'react';

import type { Query, Filter } from 'src/types/routing';

import { NavLink, Route } from 'react-router-dom';

type Props = {|
  pathname: string,
  children: React.Node,
  title: string,
  query?: Query,
  filters?: Array<Filter>,
|};

function SidebarItem({
  pathname,
  query,
  children,
  title,
  filters,
}: Props): React.Node {
  return (
    <li>
      <NavLink
        activeClassName="is-active"
        to={pathname}
        title={title}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {children}
      </NavLink>

      {!!filters && filters.length > 0 && (
        <Route path={pathname}>
          <ul>
            {filters.map((filter, i) => {
              return (
                <SidebarItem
                  pathname={`${pathname}/${filter.pathname}/collection`}
                  key={filter.label}
                  title={title}
                >
                  {filter.label}
                </SidebarItem>
              );
            })}
          </ul>
        </Route>
      )}
    </li>
  );
}

export default SidebarItem;
