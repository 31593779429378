/* eslint-disable  no-new-func */
import * as React from 'react';
import { map, filter, uniq } from 'lodash';

import { DISPLAY_PROPERTIES } from '../constants/componentOptionsAdditionalSchemas';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { useDelayedUpdate, useGlobal } from 'src/hooks';
import API from 'src/helpers/api/API';
import { ResourceConditionContext } from 'src/pages/contexts/ResourceContext';

import { Tabs, Modal, ErrorBoundary } from 'src/components';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

export default function ComponentOptionsModal({
  component,
  onClose,
  open,
  onChange,
  layout,
}) {
  const resourceConditionContext = React.useContext(ResourceConditionContext);
  const { optionsSchema } = component;
  const [tab, setTab] = React.useState(optionsSchema ? 'data' : 'display');
  const [hasLoadedCustomModes, setHasLoadedCustomModes] = React.useState(false);
  const [customModes, setCustomModes] = React.useState([]);
  const { siteId, site } = useGlobal();
  const [compOptions, setCompOptions] = useDelayedUpdate(
    { ...(component?.options || {}) },
    onChange
  );

  React.useEffect(() => {
    const fetchModes = async () => {
      if (!component?.hasEntities) {
        setHasLoadedCustomModes(true);
        return;
      }

      const { data: complexComponents } = await API.get(
        `${siteId}/data/ComplexComponent`,
        {
          adminFilter: {
            objectType: { $exists: true },
            mode: { $exists: true },
          },
        }
      );

      setCustomModes(filter(uniq(map(complexComponents, 'mode'))));
      setHasLoadedCustomModes(true);
    };

    fetchModes();
  }, [component]);

  if (!component) return null;

  // A bit hacky, but we need to pass the customModes at first initialization of EnumPropertyEditor otherwise
  // the additional modes to not appear in the Select
  if (!hasLoadedCustomModes) return null;

  const dataTabEnabled = !!optionsSchema;

  const currentTabContent = {
    data: dataTabEnabled && (
      <SchemaContext.Provider
        value={{
          schema: {
            type: 'object',
            propertiesList: prepareComponentOptionsSchema(optionsSchema, {
              modes: customModes,
              context: {
                layout,
                ...resourceConditionContext,
                siteId,
                site: site.site,
              },
            }),
          },
        }}
      >
        <ResourceFormEditor
          resource={compOptions}
          onChange={(newValue) =>
            setCompOptions({ ...compOptions, ...newValue })
          }
        />
      </SchemaContext.Provider>
    ),
    display: (
      <SchemaContext.Provider
        value={{
          schema: { type: 'object', propertiesList: DISPLAY_PROPERTIES },
        }}
      >
        <ResourceFormEditor
          resource={compOptions}
          onChange={(newValue) =>
            setCompOptions({ ...compOptions, ...newValue })
          }
        />
      </SchemaContext.Provider>
    ),
  }[tab];

  return (
    <Modal modal open={open} onClose={onClose} size="half">
      <div>
        <Tabs
          size="large"
          align="centered"
          items={[
            {
              label: 'Données',
              active: tab === 'data',
              onClick: () => dataTabEnabled && setTab('data'),
              icon: 'list',
              disabled: !dataTabEnabled,
            },
            {
              label: 'Affichage',
              active: tab === 'display',
              onClick: () => setTab('display'),
              icon: 'drafting-compass',
            },
          ]}
        />

        <ErrorBoundary>{currentTabContent}</ErrorBoundary>
      </div>
    </Modal>
  );
}

const prepareComponentOptionsSchema = (optionsSchema, options) => {
  const { modes = [], context = {} } = options || {};
  const schema = [];

  for (const _optionSchema of optionsSchema) {
    const optionSchema = { ..._optionSchema };
    const { key, condition } = optionSchema;

    if (key === 'mode') {
      optionSchema.values = [
        ...optionSchema.values,
        ...modes.map((mode) => ({ label: mode, value: mode })),
      ];
    }

    if (condition) {
      const filterFunction = Function(
        '{ obj, context, site }',
        'return (' + condition.f$ + ')'
      );

      if (!filterFunction({ obj: context.layout, site: context.site, context }))
        continue;
    }

    schema.push(optionSchema);
  }

  return schema;
};
