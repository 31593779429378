// @flow
import * as React from 'react';

import Icon from '../Icon';
import Text from '../Text';

type Props = {|
  iconName: string,
  title: string,
  description?: string,
|};

export default function DefaultPlaceholder({
  iconName,
  title,
  description,
}: Props): React.Node {
  return (
    <div className="default-placeholder">
      <Icon name={iconName} size="2x" />
      <Text isTitle size={5} element="h3">
        {title}
      </Text>
      {description && (
        <Text isSubtitle size={6} element="h4">
          {description}
        </Text>
      )}
    </div>
  );
}
