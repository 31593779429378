// @flow
import * as React from 'react';

import type { ResourceSchema, NativeDiscount } from 'src/types/models';

import { useStore } from 'src/hooks';

import setupSubscriptionPageIdImage from './capture-setup-subscribePageId.png';
import { Link } from 'react-router-dom';
import {
  QuestionMarkTooltip,
  Input,
  Control,
  Field,
  Label,
  InformationBubble,
} from 'src/components';

type Props = {|
  schema: ResourceSchema,
  discount: NativeDiscount,
|};

export default function DiscountSubscriptionsLink({
  discount,
}: Props): React.Node {
  const { instance, config } = useStore('InstanceStore');
  const { subscribePageId } = config.options;

  const subscribeLinkUrl = new URL(instance.frontUrl);
  subscribeLinkUrl.pathname = `/p/${subscribePageId}`;
  subscribeLinkUrl.searchParams.append(
    'token',
    encodeURIComponent(discount.code)
  );

  return (
    <Field additionalClassName="promo-cart-link" style={{ margin: '15px 0px' }}>
      <Label>
        Lien vers la page d'abonnement (avec ajout du code) :{' '}
        <QuestionMarkTooltip content="Ce lien redirige sur la page abonnement en ajoutant automatiquement le code promo" />
      </Label>

      {subscribePageId ? (
        <Control>
          <Input
            type="text"
            value={subscribeLinkUrl.href}
            onFocus={(e) => e.target.select()}
            readOnly={true}
          />
        </Control>
      ) : (
        <InformationBubble type="warning">
          <p>
            Vous devez mettre en place la page d'abonnement dans la rubrique{' '}
            <Link to="/website-configuration/options">Site > Options</Link>.
          </p>
          <img
            src={setupSubscriptionPageIdImage}
            height={200}
            style={{ marginTop: 20 }}
            alt="Screenshot montrant l'endroit à éditer"
          />
        </InformationBubble>
      )}
    </Field>
  );
}
