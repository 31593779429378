// @flow
import { useEffect } from 'react';

type TargetRef = {|
  current: null | HTMLElement,
|};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOutsideClickAlerter(
  targetRef: TargetRef | Array<TargetRef>,
  onOutsideClick: () => any
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const refs = Array.isArray(targetRef) ? targetRef : [targetRef];
      const clickedElement = event.target;

      if (!(clickedElement instanceof Node)) return;

      const eventIsContained =
        refs.filter(
          (ref) =>
            !!ref.current &&
            (ref.current.contains(clickedElement) ||
              ref.current === clickedElement)
        ).length > 0;

      if (!eventIsContained) {
        onOutsideClick();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [targetRef, onOutsideClick]);
}
