// @flow
import * as React from 'react';

import type { Resource, ResourceSchema } from 'src/types/models';
import type { Query } from 'src/types/routing';

import useResourcePaginatedData from './useResourcePaginatedData';
import { useGlobal } from 'src/hooks';
import { ArchivedContext } from './contexts';

import { Switch } from 'src/components';
import Paginator from '../../Paginator';
import Table from './Table';

export type ActionProps = {
  schema: ResourceSchema,
  resource: Resource,
  basePath: string,
  onUpdate: (Resource) => any,
};
export type CustomItemAction = React.ComponentType<ActionProps>;

type Props = {|
  getItemCustomActions?: (Resource) => Array<CustomItemAction>,
  openInANewTab?: boolean,
  query?: Query,
  onSelect?: (Resource) => void,
  selectedIds?: Array<string>,
  countPerPage?: number,
  canDelete?: boolean,
|};

export default function ResourceTable({
  openInANewTab,
  getItemCustomActions,
  query,
  onSelect,
  selectedIds,
  countPerPage: initialCountPerPage,
  canDelete,
}: Props): React.Node {
  const [archived, setArchived] = React.useState(false);
  const { user } = useGlobal();

  const {
    items,
    sortBy,
    filterBy,
    setFilterBy,
    setSortBy,
    loading,
    error,
    search,
    setSearch,
    page,
    setPage,
    countPerPage,
    setCountPerPage,
    count,
    refetch,
  } = useResourcePaginatedData({
    archived,
    query,
    countPerPage: initialCountPerPage,
  });

  return (
    <div className="resource-table">
      <Paginator
        search={search}
        setSearch={setSearch}
        page={page}
        setPage={setPage}
        countPerPage={countPerPage}
        setCountPerPage={setCountPerPage}
        loading={loading}
        count={count}
        error={error}
        additionalActions={
          user.isDeveloper && (
            <>
              <Switch
                label="Archivés"
                value={archived}
                onChange={setArchived}
              />
            </>
          )
        }
      >
        <ArchivedContext.Provider value={archived}>
          <Table
            items={items}
            sortBy={sortBy}
            filterBy={filterBy}
            setSortBy={setSortBy}
            setFilterBy={setFilterBy}
            onSelect={onSelect}
            selectedIds={selectedIds}
            getItemCustomActions={getItemCustomActions}
            openInANewTab={!!openInANewTab}
            refetch={refetch}
            canDelete={!!canDelete}
          />
        </ArchivedContext.Provider>
      </Paginator>
    </div>
  );
}
