const BooleanPropertySchema = [
  {
    key: 'default',
    label: 'Valeur par défaut',
    type: 'bool',
    required: false,
    default: false,
    group: 'Général',
  },
];

export default BooleanPropertySchema;
