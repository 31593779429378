// @flow
import * as React from 'react';
import moment from 'moment';

import {
  Field,
  Label,
  Control,
  DatePicker,
  Text,
  Flex,
  Tabs,
} from 'src/components';
import UserCustomExport from './UserCustomExport';
import PresetExports from './PresetExports';

export default function ExportCollection(): React.Node {
  const [{ beginDate, endDate }, setFilters] = React.useState({
    beginDate: moment().subtract(1, 'month').toDate(),
    endDate: moment().toDate(),
  });
  const beginTs = beginDate ? beginDate.getTime() / 1000 : null;
  const endTs = endDate ? endDate.getTime() / 1000 : null;
  const [tab, setTab] = React.useState('presets');

  return (
    <div className="export-collection-tab">
      <Text element="h1" size={4} isTitle>
        Filtrer
      </Text>

      <Flex>
        <Field style={{ marginRight: 10 }}>
          <Label>Date de début :</Label>
          <Control>
            <DatePicker
              maxDate={endDate}
              value={beginDate}
              showTimeSelect
              showFullFormat
              onChange={(newBeginDate) =>
                setFilters((previousFilters) => ({
                  ...previousFilters,
                  beginDate: newBeginDate,
                }))
              }
            />
          </Control>
        </Field>

        <Field>
          <Label>Date de fin :</Label>
          <Control>
            <DatePicker
              minDate={beginDate}
              maxDate={moment().add(1, 'day').toDate()}
              value={endDate}
              showTimeSelect
              showFullFormat
              onChange={(newEndDate) =>
                setFilters((previousFilters) => ({
                  ...previousFilters,
                  endDate: newEndDate,
                }))
              }
            />
          </Control>
        </Field>
      </Flex>

      <Tabs
        align="left"
        items={[
          {
            label: 'Presets',
            active: tab === 'presets',
            onClick: () => setTab('presets'),
            icon: 'bullseye',
          },
          {
            label: 'Custom',
            active: tab === 'custom',
            onClick: () => setTab('custom'),
            icon: 'user',
          },
        ]}
      />

      {tab === 'presets' && <PresetExports beginTs={beginTs} endTs={endTs} />}
      {tab === 'custom' && <UserCustomExport beginTs={beginTs} endTs={endTs} />}
    </div>
  );
}
