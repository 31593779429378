import _ from 'lodash';
import React from 'react';
import { DiffLines } from 'react-diff-components';
import Reflux from 'reflux';

import { toHumanReadable as dateToHumanReadable } from 'src/helpers/dates';
import InstanceStore from 'src/stores/InstanceStore';
import SchemaStore from 'src/stores/SchemaStore';
import API from 'src/helpers/api/API';
import ComponentBase from 'src/utils/ComponentBase';
import config, {
  DEVSERVER_PREPROD_API_URL,
  PRODSERVER_PROD_API_URL,
  LAFURIA_PROD_API_URL,
  STAGING_API_URL,
} from 'src/helpers/api/config';

import { Button, OldForm, Switch, OldSelect, Icon } from 'src/components';

function sortObj(obj) {
  if (typeof obj !== 'object' || obj === null) return obj;

  if (Array.isArray(obj)) return obj.map((e) => sortObj(e)).sort();

  return Object.keys(obj)
    .sort()
    .reduce((sorted, k) => {
      sorted[k] = sortObj(obj[k]);
      return sorted;
    }, {});
}

function cleanObj(obj) {
  return sortObj(_.omit(obj, ['createTs', 'updateTs']));
}

const SRVURLS = {
  prod: PRODSERVER_PROD_API_URL,
  devserver: DEVSERVER_PREPROD_API_URL,
  lafuria: LAFURIA_PROD_API_URL,
  staging: STAGING_API_URL,
};

export default class CompareSitesPage extends ComponentBase {
  stores = [InstanceStore, SchemaStore];
  state = {
    type1: 'Page',
    type2: 'Page',
    instance1: _.get(Reflux.getGlobalState(), 'instanceStore.instanceName'),
  };

  putData(server, instance, collection, id, entity) {
    const serverUrl = (server && SRVURLS[server]) || undefined;
    API._request(
      entity ? 'put' : 'delete',
      instance + '/data/' + collection + '/' + encodeURIComponent(id),
      null,
      entity,
      null,
      serverUrl // uses API_URL if undefined
    ).then(() => {
      alert(
        collection +
          '[' +
          id +
          '] \n"' +
          entity.label +
          '"\ncopié vers ' +
          (server || config.PUF_ENV) +
          ' > ' +
          instance
      );

      let data = _.clone(server ? this.state.data2 : this.state.data1);

      let index = _.findIndex(data, { _id: id });
      if (index !== -1) data[index] = entity;
      else data.push(entity);

      this.setState(server ? { data2: data } : { data1: data });
    });
  }

  load = () => {
    let { server2, instance1, instance2, type1, type2 } = this.state;

    const serverUrl = (server2 && SRVURLS[server2]) || undefined;

    this.setState({ data1: null, data2: null });

    API._request(
      'get',
      instance1 + '/data/' + type1,
      { __admin: true, sortBy: { _id: 1 } },
      null,
      null
    ).then((data1) => this.setState({ data1 }));

    API._request(
      'get',
      instance2 + '/data/' + type2,
      { __admin: true, sortBy: { _id: 1 } },
      null,
      null,
      serverUrl
    ).then((data2) => this.setState({ data2 }));
  };

  onSelectServer2 = (server2) => {
    const serverUrl = (server2 && SRVURLS[server2]) || undefined;
    const { instance1, instance2 } = this.state;

    this.setState({
      server2,
      server2Instances: null,
      instance2: null,
      server2InstancesError: null,
    });

    API._request(
      'get',
      'global/data/Site',
      { __admin: true },
      null,
      null,
      serverUrl
    )
      .then((sites) =>
        this.setState({
          server2Instances: sites.map((s) => ({
            value: s._id,
            label: s._id + ' (' + s.domain + ')',
          })),
          instance2:
            !instance2 && instance1 && _.find(sites, { _id: instance1 })
              ? instance1
              : instance2,
        })
      )
      .catch((server2InstancesError) =>
        this.setState({ server2InstancesError })
      );
  };

  render() {
    let {
      server2,
      server2Instances,
      instance1,
      instance2,
      type1,
      type2,
      data1,
      data2,
      showAll,
      server2InstancesError,
    } = this.state;

    const collOptions = [
      { label: 'Pages', value: 'Page' },
      { label: 'Composants complexes', value: 'ComplexComponent' },
      { label: 'Config', value: 'Config' },
      { label: 'Prestation comptable', value: 'AccountingExportItem' },
      { label: 'Modèles de mail', value: 'EmailTemplate' },
      { label: "Formules d'abonnement", value: 'SubscriptionFormula' },
      { label: 'Règles réabonnement', value: 'ResubRule' },
      ..._(this.state.globalSchema)
        .filter((schema) => schema.isContent || schema.section)
        .map((schema) => ({
          label: schema.label || schema._id,
          value: schema._id,
        }))
        .uniqBy('value')
        .sortBy('label')
        .value(),
    ];

    return (
      <div className="wrapper">
        <OldForm type="primary">
          <div className="flex-wrapper row">
            <div className="col one-half-col">
              <h2>
                Serveur actuel : <b>{config.PUF_ENV}</b>
              </h2>
            </div>
            <div className="col one-half-col">
              <OldSelect
                label="SERVEUR A COMPARER"
                value={server2}
                options={[
                  { value: 'prod', label: 'PROD' },
                  { value: 'devserver', label: 'DEV SERVER' },
                  { value: 'lafuria', label: 'lafuria.fr (prod)' },
                  { value: 'staging', label: 'STAGING' },
                ]}
                onChange={this.onSelectServer2}
              />
            </div>
          </div>
          <div className="flex-wrapper row">
            <div className="col one-half-col">
              <OldSelect
                label="Instance"
                value={instance1}
                options={this.state.instanceList}
                onChange={(instance1) =>
                  this.setState({ instance1 }, this.load)
                }
              />
            </div>
            <div className="col one-half-col">
              {server2 ? (
                server2Instances && (
                  <OldSelect
                    label={'Instance ' + server2}
                    value={instance2}
                    options={server2Instances}
                    onChange={(instance2) =>
                      this.setState({ instance2 }, this.load)
                    }
                  />
                )
              ) : (
                <OldSelect
                  label="Instance"
                  value={instance2}
                  options={_.reject(this.state.instanceList, {
                    value: instance1,
                  })}
                  onChange={(instance2) =>
                    this.setState({ instance2 }, this.load)
                  }
                />
              )}
              {server2InstancesError && (
                <div className="error">
                  {server2InstancesError.message} - Loggez-vous sur
                  l'administration du serveur cible et vérifiez l'autorisation
                  des cookies tiers pour ce site
                </div>
              )}
            </div>
          </div>
          <div className="flex-wrapper row">
            <div className="col one-half-col">
              {instance1 && (
                <OldSelect
                  label="A comparer"
                  value={type1}
                  options={collOptions}
                  onChange={(t1) =>
                    this.setState(
                      {
                        type1: t1,
                        type2: !type2 || type2 === type1 ? t1 : type2,
                      },
                      this.load
                    )
                  }
                />
              )}
            </div>
            <div className="col one-half-col">
              {instance2 && (
                <OldSelect
                  label="A comparer"
                  value={type2}
                  options={collOptions}
                  onChange={(t2) =>
                    this.setState(
                      {
                        type2: t2,
                        type1: !type1 || type1 === type2 ? t2 : type1,
                      },
                      this.load
                    )
                  }
                />
              )}
            </div>
          </div>

          {data1 && data2 && (
            <div>
              <div style={{ textAlign: 'center' }} onClick={this.load}>
                <Switch
                  label="Afficher tout"
                  value={showAll}
                  onChange={(showAll) => this.setState({ showAll })}
                  tooltip="Affiche toutes les données et pas seulement les différences"
                />
                <Button>
                  <Icon name="redo" />
                </Button>
              </div>
              <hr />
              {_.map(data1, (entity1) => {
                let _id = entity1._id;
                let entity2 = _.find(data2, { _id });
                let cleanEntity1 = cleanObj(entity1);
                let cleanEntity2 = cleanObj(entity2);

                return (
                  <div key={_id}>
                    {(_.isEqual(cleanEntity1, cleanEntity2) &&
                      ((showAll && (
                        <div className="flex-wrapper row">
                          <div className="col full-col code-diff">
                            <div className="Diff">
                              <pre className="Diff__text">
                                {JSON.stringify(cleanEntity1, null, 4)}
                              </pre>
                            </div>
                          </div>
                        </div>
                      )) || <div>✅ {_id}</div>)) || (
                      <div className="flex-wrapper row">
                        <div className="col one-half-col code-diff">
                          <b>{_id}</b> ({config.PUF_ENV})
                          {entity1.updateTs && (
                            <span>
                              {' '}
                              mod:{' '}
                              {dateToHumanReadable({
                                ts: entity1.updateTs,
                                format: 'LL',
                              })}
                            </span>
                          )}
                          <Button
                            onClick={() =>
                              this.putData(
                                server2,
                                instance2,
                                type2,
                                _id,
                                entity1
                              )
                            }
                          >
                            Copier vers {server2} ⮆
                          </Button>
                          <DiffLines
                            //hideRemoved
                            from={JSON.stringify(cleanEntity2, null, 4) || ''}
                            to={JSON.stringify(cleanEntity1, null, 4) || ''}
                            type="chars"
                          />
                        </div>
                        <div className="col one-half-col code-diff">
                          <b>{_id}</b> ({server2})
                          <Button
                            onClick={() =>
                              this.putData(null, instance1, type1, _id, entity2)
                            }
                            additionalClassName={config.PUF_ENV}
                          >
                            {(entity2 && '⮄ Copier vers ' + config.PUF_ENV) ||
                              '❌⮄ Supprimer sur ' + config.PUF_ENV}
                          </Button>
                          {entity2 && (
                            <DiffLines
                              //hideRemoved
                              to={JSON.stringify(cleanEntity2, null, 4) || ''}
                              from={JSON.stringify(cleanEntity1, null, 4) || ''}
                              type="chars"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

              {_.differenceBy(data2, data1, '_id').map((entity2) => (
                <div key={entity2._id}>
                  <div className="flex-wrapper row">
                    <div className="col one-half-col code-diff">
                      <Button
                        onClick={() =>
                          this.putData(
                            server2,
                            instance2,
                            type2,
                            entity2._id,
                            null
                          )
                        }
                      >
                        Supprimer sur {server2} <Icon name="times" />
                      </Button>
                    </div>
                    <div className="col one-half-col">
                      <b>{entity2._id}</b> ({server2})
                      <Button
                        onClick={() =>
                          this.putData(
                            null,
                            instance2,
                            type2,
                            entity2._id,
                            entity2
                          )
                        }
                        additionalClassName={config.PUF_ENV}
                      >
                        Copier vers {config.PUF_ENV}
                      </Button>
                      <DiffLines
                        //hideRemoved
                        to={JSON.stringify(cleanObj(entity2), null, 4) || ''}
                        from={''}
                        type="chars"
                      />
                    </div>
                  </div>
                  }
                </div>
              ))}
            </div>
          )}
        </OldForm>
      </div>
    );
  }
}
