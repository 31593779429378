import Tooltip from 'rc-tooltip';
import React from 'react';

/**
 * Wrap children in Tooltip only if needed
 *
 * @param props : tooltip : text or react elements, tooltipPlacement: left, right, ...
 * @returns {*}
 * @constructor
 */
export function Tooltipped(props) {
  return props.tooltip ? (
    <Tooltip
      placement={props.tooltipPlacement || 'top'}
      trigger={['hover']}
      overlay={props.tooltip}
    >
      {props.children}
    </Tooltip>
  ) : (
    props.children
  );
}
