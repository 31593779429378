// @flow
import * as React from 'react';

import type { Purchase, ResourcePropertySchema } from 'src/types/models';
import type { Props } from '../../';

import GiftCode from './GiftCode';

export default function getPurchaseCustomProperty(
  resource: Purchase,
  propertySchema: ResourcePropertySchema
): ?React.AbstractComponent<Props> {
  switch (propertySchema.key) {
    case 'token':
      if (resource.isGift) return GiftCode;
      return null;
    default:
      return null;
  }
}
