import React from 'react';
import _ from 'lodash';
import { Fraction } from 'fractional';
import classnames from 'classnames';

import DIVIDERS from '../constants/dividers';
import { Draggable, Tile } from 'src/components';

// Layout menu component, to divide blocks
export default function LayoutPicker({ selectInnerLayout, size }) {
  const draggable = !selectInnerLayout;
  const leftDividers = DIVIDERS.filter((divider, i) => i % 2 === 0);
  const rightDividers = DIVIDERS.filter((divider, i) => i % 2 === 1);

  return (
    <>
      <Tile isAncestor additionalClassName="layout-picker">
        {[leftDividers, rightDividers].map((dividers, i) => (
          <Tile
            isParent
            isVertical
            key={i % 2 === 0 ? 'left' : 'right'}
            style={{ padding: 5 }}
          >
            {dividers.map((divider, j) => {
              const colDivs = divider.cols.map((col, k) => (
                <div
                  className="layout-column"
                  style={{
                    width: `calc(${(100 / 12) * col}% - 10px)`,
                    margin: '0px 5px',
                  }}
                  key={JSON.stringify(divider) + '-' + k}
                >
                  {col / _.sum(divider.cols) >= 0.5 && (
                    <span>
                      {new Fraction(col, _.sum(divider.cols)).toString()}
                    </span>
                  )}
                </div>
              ));

              const layout = (
                <Tile
                  additionalClassName={classnames('box', 'is-small', 'layout', {
                    draggable,
                    enabled: size >= divider.minSize,
                    disabled: size < divider.minSize,
                  })}
                >
                  {colDivs}
                </Tile>
              );

              return draggable ? (
                <Draggable
                  type="divider"
                  data={divider}
                  key={JSON.stringify(divider)}
                >
                  {layout}
                </Draggable>
              ) : (
                <div
                  onClick={() =>
                    selectInnerLayout && selectInnerLayout(divider.cols)
                  }
                  key={JSON.stringify(divider)}
                >
                  {layout}
                </div>
              );
            })}
          </Tile>
        ))}
      </Tile>
    </>
  );
}
