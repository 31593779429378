const EntityPoster = {
  _id: 'EntityPoster',
  label: 'Poster du contenu',
  icon: 'image',
  frontComponent: 'EntityPoster',
  forEntity: true,
  optionsSchema: [
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'objet",
      type: 'bool',
      default: true,
    },
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      required: true,
      values: [
        { label: '16/9', value: 'ratio-16-9' },
        { label: '4/3', value: 'ratio-4-3' },
        { label: 'carré', value: 'ratio-1-1' },
        { label: 'portrait', value: 'ratio-3-4' },
        { label: 'cinemascope', value: 'ratio-2_35-1' },
        { label: 'bandeau', value: 'ratio-5-1' },
      ],
    },
  ],
};

export default EntityPoster;
