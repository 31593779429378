// @flow
// Fuse.js, a lightweight fusy search, see documentation -> https://fusejs.io/api

import _ from 'lodash';
import * as React from 'react';
import Fuse from 'fuse.js';

import type { Component } from '../../types';

import { ComponentsContext } from '../../';

import { Field, Control, Input, Text } from 'src/components';
import Section from './Section';

const fuseOptions = {
  keys: ['label', '_id', 'frontComponent'],
  findAllMatches: true,
  threshold: 0.4,
};

type Props = {|
  onPickComponent?: (Component) => void,
|};

type WithComponentsContextProps = {|
  ...Props,
  simpleComponents: Array<Component>,
  complexComponents: Array<Component>,
|};

type FuseOutput<T> = Array<T>;

function ComponentPicker({
  simpleComponents,
  complexComponents,
  onPickComponent,
}: WithComponentsContextProps): React.Node {
  const [search, setSearch] = React.useState('');

  const simpleComponentsFuse = React.useMemo(
    () => new Fuse(simpleComponents, fuseOptions),
    [simpleComponents]
  );
  const complexComponentsFuse = React.useMemo(
    () => new Fuse(complexComponents, fuseOptions),
    [complexComponents]
  );

  const searchFilteredBaseComponents = !!search
    ? (simpleComponentsFuse.search(search): FuseOutput<Component>).map(
        (fuseResult) => fuseResult
      )
    : simpleComponents;
  const searchFilteredComplexComponents = !!search
    ? (complexComponentsFuse.search(search): FuseOutput<Component>).map(
        (fuseResult) => fuseResult
      )
    : complexComponents;

  // const availableComponents = _.sortBy(availableComponentsFromProps, 'label');

  const simpleComponentsForPage = _.filter(
    searchFilteredBaseComponents,
    (component) =>
      !component.site && component.forPage && !component.hasEntities
  );
  const simpleComponentsForEntity = _.filter(
    searchFilteredBaseComponents,
    (component) => !component.site && component.forEntity
  );
  const simpleComponentsOfEntity = _.filter(
    searchFilteredBaseComponents,
    (component) => component.ofEntity
  );
  const simpleComponentsWithEntities = _.filter(
    searchFilteredBaseComponents,
    (component) => !component.site && component.hasEntities
  );
  const simpleComponentsRemaining = _.filter(
    searchFilteredBaseComponents,
    (component) => component.site
  );

  const complexComponentsForPage = _.filter(
    searchFilteredComplexComponents,
    (component) => !component.objectType
  );
  const complexComponentsForData = _.filter(
    searchFilteredComplexComponents,
    (component) => !!component.objectType
  );

  return (
    <div className="ComponentPicker">
      <Field>
        <Control>
          <label className="label">Recherche</label>
          <Input value={search} onChange={setSearch} size="small" />
        </Control>
      </Field>

      <hr style={{ backgroundColor: 'black' }} />

      <Text element="h3" size={4}>
        Composants de base
      </Text>

      <Section
        title="Composants de base"
        components={simpleComponentsForPage}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />
      <Section
        title="Affichage des contenus"
        components={simpleComponentsWithEntities}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />
      <Section
        title="Composants contenu"
        components={simpleComponentsOfEntity}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />
      <Section
        title="Données du contenu"
        components={simpleComponentsForEntity}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />
      <Section
        title="Spécifiques site"
        components={simpleComponentsRemaining}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />

      <hr style={{ backgroundColor: 'black' }} />

      <Text element="h3" size={4}>
        Composants personnalisés
      </Text>

      <Section
        title="Composants de page"
        components={complexComponentsForPage}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />
      <Section
        title="Données du contenu"
        components={complexComponentsForData}
        onPickComponent={onPickComponent}
        forceExpand={!!search}
      />
    </div>
  );
}

export default function ComponentPickerWithComponents({
  onPickComponent,
}: Props): React.Node {
  return (
    <ComponentsContext.Consumer>
      {({ simple, complex }) => (
        <ComponentPicker
          onPickComponent={onPickComponent}
          simpleComponents={simple}
          complexComponents={complex}
        />
      )}
    </ComponentsContext.Consumer>
  );
}
