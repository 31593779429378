import * as React from 'react';
import { times } from 'lodash';

import config from 'src/helpers/api/config';
import { useGlobal } from 'src/hooks';
import API from 'src/helpers/api/API';

import {
  Flex,
  Control,
  Select,
  Button,
  Text,
  Label,
  Field,
  InformationBubble,
} from 'src/components';

const MAXIMUM_VIDEO_LIMIT = 100;
const PROCESSED_BY_DEFAULT = 100;
const FIXED_EXPORT_OPTIONS = [
  {
    value: {
      offset: 0,
      limit: 10,
    },
    label: '10',
  },
  {
    value: {
      offset: 10,
      limit: 20,
    },
    label: '10 => 30',
  },
  {
    value: {
      offset: 30,
      limit: 70,
    },
    label: '30 => 100',
  },
];

const getOptions = (videoCount) => {
  const videoSlicesCount = Math.ceil(
    (videoCount - PROCESSED_BY_DEFAULT) / MAXIMUM_VIDEO_LIMIT
  );

  return [
    ...FIXED_EXPORT_OPTIONS,
    ...times(videoSlicesCount, (sliceIndex) => {
      const offset = (sliceIndex + 1) * MAXIMUM_VIDEO_LIMIT;
      const nextOffset = offset + MAXIMUM_VIDEO_LIMIT;

      return {
        value: {
          offset,
          limit: MAXIMUM_VIDEO_LIMIT,
        },
        label: `${offset} => ${nextOffset}`,
      };
    }),
  ];
};

export default function ExportSection() {
  const [videoCount, setVideoCount] = React.useState(0);
  const { siteId } = useGlobal();
  const [options, setOptions] = React.useState([]);
  const [option, setOption] = React.useState(options[0]);

  React.useEffect(() => {
    const fetchVideos = async () => {
      const count = await API.get(`${siteId}/data/Video/count`, {
        countOnly: true,
      });
      setVideoCount(count);
    };

    fetchVideos();
  }, []);

  React.useEffect(() => {
    if (videoCount > 0) {
      const specificOptions = getOptions(videoCount);
      setOptions(specificOptions);
      setOption(specificOptions[0]);
    }
  }, [videoCount]);

  return (
    <div className="export-section">
      <Text isTitle element="h4" size={4}>
        Export des vues
      </Text>
      <InformationBubble>
        <Text element="p">
          Le fichier contiendra une ligne pour chaque vue d'utilisateur pour les
          vidéos sélectionnées ci-dessous.
        </Text>
      </InformationBubble>

      <Flex
        verticalAlign
        style={{ justifyContent: 'space-between', marginTop: 15 }}
      >
        <Field isHorizontal style={{ alignItems: 'center' }}>
          <Label>Les</Label>
          <Control style={{ width: 130, margin: '0 10px' }}>
            <Select options={options} value={option} onChange={setOption} />
          </Control>
          <Label>plus vues</Label>
        </Field>

        {!!option && (
          <Button
            href={`${config.API_URL}/admin/${siteId}/custom-exports/play?offset=${option.value.offset}&limit=${option.value.limit}`}
            color="primary"
            style={{ marginLeft: 10 }}
          >
            Exporter
          </Button>
        )}
      </Flex>
    </div>
  );
}
