// @flow
import * as React from 'react';

import Schema from 'src/schema/Schema';
import { useResourceContext, useGlobal } from 'src/hooks';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { getResourcePath } from 'src/helpers/url';

import { Helmet } from 'react-helmet';
import { Select, Field, Control, Label } from 'src/components';
import ResourceTable from 'src/pages/components/resource/ResourceTable';

export default function LinksTabContent(): React.Node {
  const { schema, resource } = useResourceContext();
  const { globalSchema, siteId } = useGlobal();
  const [linkedResourceRefOption, setLinkedResourceRefOption] = React.useState(
    null
  );
  const { value: linkedResourceRef } = linkedResourceRefOption || {};

  const backRefs = Schema.getBackRefs(globalSchema, schema._id);
  const label = schema.label || schema._id;

  const linkedResourceSchema = linkedResourceRef
    ? globalSchema[linkedResourceRef?.objCls]
    : null;

  const query = React.useMemo(() => {
    if (!linkedResourceRef) return {};

    return {
      filterBy:
        linkedResourceRef.refType === 'object_ref'
          ? {
              [linkedResourceRef.propSchema.key + '._id']: resource._id,
            }
          : { [linkedResourceRef.propSchema.key]: resource._id },
    };
  }, [linkedResourceRef, resource]);

  const options = React.useMemo(
    () =>
      backRefs.map((ref) => ({
        label: (
          <span>
            <b key="title">{ref.objLabel}</b> dont cet objet {label} est{' '}
            <i key="schemaKey">{ref.propSchema.label || ref.propSchema.key}</i>
          </span>
        ),
        value: ref,
      })),
    [backRefs]
  );

  return (
    <div className="links-tab-content">
      <Helmet>
        <title>{'Liste ' + (schema.label || schema._id)}</title>
      </Helmet>

      <Field>
        <Label>Objets liés</Label>
        <Control>
          <Select
            value={linkedResourceRefOption}
            onChange={setLinkedResourceRefOption}
            options={options}
            required
          />
        </Control>
      </Field>

      {linkedResourceRef && linkedResourceSchema && (
        <SchemaContext.Provider
          value={{
            schema: linkedResourceSchema,
            path: getResourcePath(siteId, linkedResourceSchema),
          }}
        >
          <ResourceTable openInANewTab canDelete query={query} />
        </SchemaContext.Provider>
      )}
    </div>
  );
}
