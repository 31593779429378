// @flow
import * as React from 'react';

export type ModalContextType = {
  depth: number,
};

export default (React.createContext<ModalContextType>({
  depth: 0,
}): React.Context<ModalContextType>);
