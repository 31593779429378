// @flow

import * as React from 'react';

import classnames from 'classnames';

import { OldLabel } from 'src/components';
export type Option<T> = {|
  label: string,
  value: T,
  selected?: boolean,
|};

type Props<T: string | number> = {|
  value: ?T,
  label?: string,
  options: Array<Option<T>>,
  handleFocus?: (e: SyntheticFocusEvent<*>, hasFocus: boolean) => any,
  onChange?: (T) => any,
  onMultipleChange?: (Array<T>) => any,
  disabled?: boolean,
  multiple?: boolean,
  tooltip?: string,
  required?: boolean,
|};

type DefaultProps = {|
  multiple: boolean,
  required: boolean,
  disabled: boolean,
|};

type State = {|
  hasFocus: boolean,
|};

export default class OldSelect<T: string | number> extends React.Component<
  Props<T>,
  State
> {
  static defaultProps: DefaultProps = {
    multiple: false,
    required: false,
    disabled: false,
  };

  state: State = {
    hasFocus: false,
  };

  handleFocus(e: SyntheticFocusEvent<*>, hasFocus: boolean) {
    this.setState({ hasFocus: hasFocus });
    this.props.handleFocus && this.props.handleFocus(e, hasFocus);
  }

  onChange(e: any) {
    const { multiple, onChange, onMultipleChange } = this.props;

    if (multiple && onMultipleChange) {
      const options = e.target.options;
      const fieldValue: Array<T> = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          fieldValue.push(JSON.parse(options[i].value));
        }
      }
      onMultipleChange(fieldValue);
    } else if (onChange) {
      const fieldValue: T = JSON.parse(e.target.value);
      onChange(fieldValue);
    }
  }

  render(): React.Node {
    const { label, options, value, disabled, tooltip, required } = this.props;
    const { hasFocus } = this.state;

    const formElementClass = classnames(
      'FormElement',
      'FormElement-input-select-element',
      {
        'FormElement--disabled': disabled,
        'FormElement--focus': hasFocus,
      }
    );

    return (
      <div className={formElementClass}>
        <div className="flex-wrapper flex-column FormElement--inner">
          <OldLabel
            label={label}
            required={required}
            tooltip={tooltip}
            htmlFor="input-select"
          />

          <div className="FormElement-input-select-wrapper">
            <select
              name="FormElement-input-select"
              value={JSON.stringify(value)}
              multiple={this.props.multiple || false}
              className="FormElement-input-select"
              onChange={(e) => this.onChange(e)}
              onFocus={(e) => this.handleFocus(e, true)}
              onBlur={(e) => this.handleFocus(e, false)}
            >
              {!required && <option value="null"></option>}
              {options &&
                options.map((option, i) => (
                  <option value={JSON.stringify(option.value)} key={i}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
