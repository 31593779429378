import * as React from 'react';

import { FieldContext } from './contexts';

import { Input } from 'src/components';

function removeAccents(str) {
  return str
    ? str.normalize
      ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      : str.replace(/\W+/g, '')
    : '';
}

function urlize(str) {
  return str
    ? removeAccents(str.toLowerCase()) // then remove all non-alphanum characters:
        .replace(/^\W+|\W+$/g, '') // trim begin/end
        .replace(/\W+/g, '-') // replace others with "-"
    : '';
}

export default function SlugEditor({ value, onChange, disabled }) {
  const [inputValue, setInputValue] = React.useState(value);
  const { onFocus: onFieldFocus, onBlur: onFieldBlur } = React.useContext(
    FieldContext
  );

  return (
    <>
      <Input
        type="text"
        value={inputValue}
        onChange={(newValue) => {
          setInputValue(newValue);
          onChange(urlize(newValue));
        }}
        disabled={disabled}
        onFocus={onFieldFocus}
        onBlur={onFieldBlur}
      />
      <Input
        type="text"
        value={`Valeur enregistrée : ${value || ''}`}
        disabled
      />
    </>
  );
}
