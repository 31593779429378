import * as React from 'react';

import { Tag } from 'src/components';

export default function PublishStatusRenderer({
  mode,
  value: publishStatus,
  propertySchema,
  resource,
  resourceSchema,
}) {
  switch (publishStatus) {
    case 'archived':
      return <Tag color="danger">Archivé</Tag>;
    case 'prepub':
      return <Tag color="warning">Prépub</Tag>;
    case 'published':
      return <Tag color="success">Publié</Tag>;
    default:
      return <Tag>{publishStatus}</Tag>;
  }
}
