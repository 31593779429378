import React, { useState, useEffect, useRef } from 'react';

export default function ExternalLinkInput({
  url,
  cancelEntity,
  setEntity,
  cancelError,
  entity,
  removeEntity,
}) {
  const [urlState, setUrlState] = useState(url);
  let textInput = useRef(null);
  const setLink = (event) => {
    let url = urlState;
    setEntity({ url });
    reset();

    // Force blur to work around Firefox's NS_ERROR_FAILURE
    event.target.blur();
  };

  const reset = () => {
    setUrlState('');

    cancelEntity();
  };

  const onLinkChange = (event) => {
    event.stopPropagation();
    const url = event.target.value;

    if (url === '') {
      cancelError();
    }

    setUrlState(url);
  };

  const onLinkKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setLink(event);
    } else if (event.key === 'Escape') {
      event.preventDefault();
      reset();
    }
  };

  useEffect(() => {
    textInput && textInput.current && textInput.current.focus();
  }, [textInput]);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <input
        ref={textInput}
        type="text"
        className="toolbar__input"
        onChange={onLinkChange}
        value={urlState || ''}
        onKeyDown={onLinkKeyDown}
        placeholder={'Entrez un lien'}
      />
      <span className="toolbar__item" style={{ verticalAlign: 'bottom' }}>
        <button
          onClick={removeEntity}
          type="button"
          className="toolbar__button toolbar__input-button"
        >
          {entity !== null ? <p>unlink</p> : <p>close</p>}
        </button>
      </span>
    </div>
  );
}
