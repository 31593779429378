// @flow
import type { Tab } from '../';

import TournamentStatsTab from './StatsTab';

const userCustomTabs: Array<Tab> = [
  {
    to: 'statistics',
    icon: 'chart-line',
    label: 'Statistiques',
    content: TournamentStatsTab,
  },
];

export default userCustomTabs;
