// @flow

import * as React from 'react';

type Props = {|
  children: React.Node,
  delay: number,
|};

type State = {|
  shouldRender: boolean,
|};

export default class DelayedRenderer extends React.Component<Props, State> {
  state: State = {
    shouldRender: false,
  };

  timeoutRef: any = null;

  componentDidMount() {
    this.timeoutRef = setTimeout(
      () => this.setState({ shouldRender: true }),
      this.props.delay
    );
  }

  componentWillUnmount() {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }

  render(): React.Node {
    if (!this.state.shouldRender) return null;

    return this.props.children;
  }
}
