// @flow
import * as React from 'react';

import type { Resource, ResourceSchema } from 'src/types/models';

import { Icon, Button } from 'src/components';
import ModerateActions from './ModerateActions';

export type ActionProps = {
  schema: ResourceSchema,
  resource: Resource,
  basePath: string,
  refetch: () => Promise<any>,
};

export type CustomAction = React.ComponentType<ActionProps>;

export default function getItemActions(
  schema: ResourceSchema
): Array<CustomAction> {
  switch (schema._id) {
    case 'TextContent':
    case 'Answer':
      return [ViewStatisticsAction];
    case 'Question':
    case 'UserComment':
      return [ModerateActions];
    default:
      return [];
  }
}

function ViewStatisticsAction({ basePath, resource }: ActionProps) {
  return (
    <Button
      title="Statistiques"
      to={`${basePath}/${resource._id}/stats`}
      style={{ marginRight: 5 }}
      size="small"
      isLight
      color="info"
    >
      <Icon name="chart-bar" />
    </Button>
  );
}
