const TextBlock = {
  _id: 'textBlock',
  label: 'Bloc texte libre',
  icon: 'text-height',
  frontComponent: 'RichTextBlock',
  optionsSchema: [
    { key: 'body', label: 'Texte', type: 'richtext' },
    {
      key: 'linkPageId',
      label: 'Lien vers une page',
      type: 'object_id',
      objectType: 'Page',
      condition: { f$: '!obj.link' },
    },
    { key: 'aName', label: 'Balise', type: 'string' },
  ],
  forPage: true,
};

export default TextBlock;
