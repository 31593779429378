// @flow
import * as React from 'react';

import type { ResourcePropertySchema, Resource } from 'src/types/models';

import { useSchemaContext, useResourceReferencesContext } from 'src/hooks';

import SchemaEditor from './SchemaEditor';
import DefaultEditor from './DefaultEditor';

export type OnPropertyChange = (
  propertySchema: ResourcePropertySchema,
  newPropertyValue: any
) => any;

export type PropertiesEditorProps = {|
  resource: Resource,
  properties: Array<ResourcePropertySchema>,
  isSidebar?: boolean,
  onPropertyChange: (
    propertySchema: ResourcePropertySchema,
    newPropertyValue: any
  ) => any,
|};

type Props = {|
  resource: Resource,
  onChange: ($Shape<Resource>) => any,
  disabled?: boolean,
|};

export default function ResourceFormEditor({
  onChange,
  resource,
  disabled,
}: Props): React.Node {
  const { schema } = useSchemaContext();
  const { setReferences } = useResourceReferencesContext();

  const onPropertyChange: OnPropertyChange = React.useCallback(
    (
      propertySchema: ResourcePropertySchema,
      newPropertyValue: any,
      references: any
    ) => {
      if (isReferenceProperty(propertySchema)) {
        setReferences((previousReferences) => ({
          ...previousReferences,
          [propertySchema.key]: references,
        }));
      }
      onChange({
        [propertySchema.key]: newPropertyValue,
      });
    },
    [onChange, setReferences]
  );

  const PropertiesEditor =
    schema._id === 'schema' ? SchemaEditor : DefaultEditor;

  if (!resource) return null;

  return (
    <PropertiesEditor
      resource={resource}
      propertySchemas={schema.propertiesList}
      onPropertyChange={onPropertyChange}
      disabled={disabled}
    />
  );
}

const isReferenceProperty = (propertySchema: ResourcePropertySchema) => {
  const { type, itemSchema } = propertySchema;

  switch (type) {
    case 'object_id':
    case 'object_ref':
      return true;
    case 'list':
      return ['object_ref', 'object_id'].includes(itemSchema?.type);
    default:
      return false;
  }
};
