// @flow
import type { Tab } from '../';

import PageHierarchyTab from './PageHierarchyTab';

const pageCustomTabs: Array<Tab> = [
  {
    to: 'sitemap',
    icon: 'sitemap',
    label: 'Fil de fer',
    content: PageHierarchyTab,
  },
];

export default pageCustomTabs;
