// @flow
import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';

const INITIAL_STATE = {
  COUNT_PER_PAGE: 30,
  PAGE: 0,
  SEARCH: '',
};

// you can provided an initial state
type Options = {|
  countPerPage?: number,
  page?: number,
  search?: string,
|};

export type Returned = {|
  search: string,
  fetchSearch: string,
  setSearch: (string) => void,
  page: number,
  setPage: (number) => void,
  countPerPage: number,
  setCountPerPage: (number) => void,
  reset: () => void,
|};

export default function usePagination(options: Options): Returned {
  const {
    countPerPage: initialCountPerPage = INITIAL_STATE.COUNT_PER_PAGE,
    page: initialPage = INITIAL_STATE.PAGE,
    search: initialSearch = INITIAL_STATE.SEARCH,
  } = options || {};
  const [userSearch, setUserSearch] = React.useState(initialSearch);
  const [fetchSearch, setFetchSearch] = React.useState(initialSearch);
  const [page, setPage] = React.useState(initialPage);

  const [countPerPage, setCountPerPage] = React.useState(initialCountPerPage);

  useDebouncedEffect(() => setFetchSearch(userSearch), 500, [userSearch]);

  const reset = React.useCallback(() => {
    setPage(initialPage);
    setCountPerPage(initialCountPerPage);
    setUserSearch(initialSearch);
    setFetchSearch(initialSearch);
  }, [setPage, setCountPerPage, setUserSearch, setFetchSearch]);

  return {
    search: userSearch,
    setSearch: setUserSearch,
    fetchSearch,
    page,
    setPage,
    countPerPage,
    setCountPerPage,
    reset,
  };
}
