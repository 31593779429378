import TypePropertySchema from '../specificProperties/Type';

const ListPropertySchema = [
  {
    key: 'itemType',
    label: "Type(s) d'item",
    description:
      "Attention, à utiliser à la place d'itemSchema ? avec ? préciser",
    type: 'enum',
    multiple: true,
    condition: { f$: '!obj.itemSchema' },
    required: false, // ?
    group: 'Général',
    dataSource: {
      isGlobal: true,
      resource: 'schema',
      valuePath: '_id',
      labelPath: 'label',
    },
  },
  {
    key: 'itemSchema',
    label: "Schéma d'un item",
    type: 'object',
    group: 'Général',
    propertiesList: [
      TypePropertySchema,
      {
        key: 'propertiesList',
        label: 'Properties List',
        type: 'list',
        itemType: 'ObjectPropertySchema',
        condition: {
          f$: 'obj.type === "object"',
        },
      },

      {
        key: 'objectType',
        label: "Types d'objets référencés",
        type: 'enum',
        multiple: true,
        condition: {
          f$:
            'obj.type === "object" || obj.type === "object_ref" || obj.type === "object_id"',
        },
        required: true,
        dataSource: {
          isGlobal: true,
          resource: 'schema',
          valuePath: '_id',
          labelPath: 'label',
        },
      },
    ],
  },
];

export default ListPropertySchema;
