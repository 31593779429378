// @flow
import * as React from 'react';
import useSWR from 'swr';

import type { Params } from '../types';

import { useGlobal } from 'src/hooks';

import { Flex, Select } from 'src/components';
import GenericStatsTable from './GenericStatsTable';

type Props = {|
  params: Params,
|};

export default function PerFormulaStats({
  params: paramsProp,
}: Props): React.Node {
  const { siteId } = useGlobal();
  const [formulaId, setFormulaId] = React.useState(null);

  const { data: { data: formulas } = {} } = useSWR(
    `${siteId}/data/SubscriptionFormula`
  );

  const params = React.useMemo(
    () => ({ ...paramsProp, formulaId: formulaId ? `"${formulaId}"` : '' }),
    [formulaId, paramsProp]
  );
  const { data: stats, error } = useSWR(
    formulaId ? [`${siteId}/statistics/sales/per-formula`, params] : null
  );

  if (!formulas) return null;

  return (
    <div>
      <Flex style={{ justifyContent: 'flex-end', marginTop: 20 }}>
        <Select
          options={formulas.map((formula) => ({
            label: formula.label,
            value: formula._id,
          }))}
          onChange={(option) => setFormulaId(option.value)}
          styles={{
            container: (provided) => ({ ...provided, width: 300 }),
          }}
        />
      </Flex>

      {formulaId && (
        <GenericStatsTable stats={stats} error={error} params={params} />
      )}
    </div>
  );
}
