// TO REMOVE

import _ from 'lodash';
import React from 'react';

import { SchemaStore, InstanceStore } from 'src/stores';
import API from 'src/helpers/api/API';

import Modal from 'src/components/Modal';
import ResourceShow from 'src/pages/ResourceRouter/Show';
import ComponentBase from 'src/utils/ComponentBase';

function labelFromSchema(schema, type) {
  return _.get(schema, type + '.label', type);
}

// c/c server
function getComponentsFromPage(item, components, path) {
  // broad recursive search for components (has .options & all...)
  if (item && _.isPlainObject(item)) {
    // hasEntities not here! TODO search in ccs
    if (
      (item.options && item.options.list) /*|| item.hasEntities*/ ||
      item.frontComponent === 'SingleEntity' ||
      item._cls === 'ComplexComponent'
    ) {
      // TODO actual condition is hasEntities && mainObject
      components.push({
        component: item,
        path: path,
      });
    } // TODO if .options but no list, skip
    /*if (item.component) { // TODO actual condition is hasEntities && mainObject
            components.push({
                component: item.component,
                path     : path+".component",
            });
        }*/
    else if (item.cols) {
      // Order by flexOrder for content priority
      _(item.cols)
        .map((col, i) => ({ col, i })) // record real index
        .sortBy('col.flexOrder') // sort by fall order
        .forEach(({ col, i }) =>
          getComponentsFromPage(col, components, path + '.cols[' + i + ']')
        );
    } else {
      _.mapValues(item, (v, k) =>
        getComponentsFromPage(v, components, (path ? path + '.' : '') + k)
      );
    }
  } else if (_.isArray(item)) {
    item.map((v, i) =>
      getComponentsFromPage(v, components, path + '[' + i + ']')
    );
  }
}

class SiteComponent extends ComponentBase {
  store = InstanceStore;

  edit(ccToEdit) {
    this.setState({ ccToEdit });
  }

  render() {
    let { type, mode, ccs, id } = this.props;

    let cc =
      (id && _.find(ccs, { _id: id })) ||
      _.find(ccs, { objectType: type, mode });

    return (
      <div style={{ marginLeft: '20px' }} className="comp">
        {(cc && (
          <div className="cc">
            <div className="comp-description">
              <span className="info is-cc" title="Composant personnalisé">
                CP <i className="small">{cc.label}</i>
              </span>
              {type && (
                <span className="info type" title="type">
                  {type}
                </span>
              )}
              {mode && (
                <span className="info mode" title="mode">
                  {mode}
                </span>
              )}
              <span
                className="info identifier pop-edit"
                title="éditer le composant personnalisé"
                onClick={() => this.edit(cc._id)}
              >
                {cc._id}
              </span>
            </div>

            <Hierarchy
              {..._.pick(this.props, ['ccs', 'type', 'mode', 'globalSchema'])}
              pageOrComponent={cc}
              d={this.props.d + 1}
            />
          </div>
        )) || (
          <div className="sc comp-description">
            <span className="info is-sc" title="Composant simple (front)">
              CS
            </span>
            {type && (
              <span className="info type" title="type">
                {type}
              </span>
            )}
            {mode && (
              <span className="info mode" title="mode">
                {mode}
              </span>
            )}
          </div>
        )}

        <Modal
          modal={false}
          open={this.state.ccToEdit}
          //contentClassName={"test-component-popup"} // TODO
          title={'Edition...'}
          onClose={() => {
            this.setState({ ccToEdit: null });
          }}
        >
          <ResourceShow
            type={'ComplexComponent'}
            initialId={this.state.ccToEdit}
            onClose={(item) => {
              this.setState({ ccToEdit: null });
            }}
            onCancel={(item) => {
              this.setState({ ccToEdit: null });
            }}
            navigationType="state"
          />
        </Modal>
      </div>
    );
  }
}

class ObjectPropertyRequesterItem extends ComponentBase {
  render() {
    let { pageOrComponent, globalSchema } = this.props;
    let { objCls, _isBackRef, refObjType, label } =
      this.props.propertyRef || {};

    let type = _isBackRef ? objCls : refObjType;

    return (
      <div>
        <span className="requester-desc">
          {_isBackRef ? '⭠' : '⭢ propriété'}{' '}
        </span>
        <span>{label}</span>
        {_isBackRef ? (
          <span className="requester-desc"> à l'objet </span>
        ) : (
          <span className="requester-desc"> de l'objet </span>
        )}
        {pageOrComponent && (
          <span>
            {labelFromSchema(globalSchema, pageOrComponent.objectType)}
          </span>
        )}
        <SiteComponent {...this.props} type={type} />
      </div>
    );
  }
}

class RecoRequesterItem extends ComponentBase {
  render() {
    let { pageOrComponent, globalSchema, item } = this.props;
    let { sameType } = item;

    return (
      <div>
        ⤭ Recommandation
        {sameType &&
          ((pageOrComponent && (
            <span>
              {' '}
              de {labelFromSchema(globalSchema, pageOrComponent.objectType)}
            </span>
          )) || <span> du même type</span>)}
      </div>
    );
  }
}

class ComponentListItem extends ComponentBase {
  render() {
    let { item, globalSchema } = this.props;

    return (
      (item._cls === 'ContentRequester' && (
        <div className="ContentRequester item">
          <SiteComponent {...this.props} type={item.type} />
        </div>
      )) ||
      (item._cls === 'ContentSchedule' && item.content && (
        <div className="ContentRequester item">
          <span>1 {labelFromSchema(globalSchema, item.content._cls)} : </span>
          <span className="identifier">{item.content && item.content._id}</span>
          <SiteComponent {...this.props} type={item.content._cls} />
        </div>
      )) ||
      (item._cls === 'ObjectPropertyRequester' && item.propertyRef && (
        <div className=" ObjectPropertyRequester item">
          <ObjectPropertyRequesterItem
            {...this.props}
            propertyRef={item.propertyRef}
          />
        </div>
      )) ||
      (item._cls === 'ObjectPropertyRequester' && !item.propertyRef && (
        <div
          className=" ObjectPropertyRequester item retrocompat"
          title="Données obsolètes (mode rétro-compat)"
        >
          <ObjectPropertyRequesterItem {...this.props} propertyRef={item} />
        </div>
      )) ||
      (item._cls === 'RecoRequester' && (
        <div className="item">
          <RecoRequesterItem {...this.props} item={item} />
        </div>
      )) || (
        <div className="item">
          <u>{item._cls}</u>
        </div>
      )
    );
  }
}

export class Hierarchy extends ComponentBase {
  render() {
    if (this.props.d > 10)
      return (
        <span className="error">
          Profondeur max atteinte : probablement un problème de référence
          circulaire...
        </span>
      );

    let { pageOrComponent } = this.props;

    let componentsRef = [];
    getComponentsFromPage(
      pageOrComponent.layout || pageOrComponent.templateLayout,
      componentsRef,
      ''
    );

    return (
      <div style={{ marginLeft: '20px' }} className="layout">
        {_.map(componentsRef, ({ component, path }) => {
          const options = component.options || {};
          return (
            <div key={path} className="item">
              {(component._cls === 'ComplexComponent' && (
                <>
                  {options && (options.name || options.label) && (
                    <i> "{options.name || options.label}"</i>
                  )}

                  <SiteComponent
                    {...this.props}
                    type={pageOrComponent.objectType}
                    id={component._id}
                    mode={options.mode}
                  />
                </>
              )) || (
                <>
                  <span className="sc-label">
                    {component.label || component._id}
                  </span>

                  {/*options && options.mode &&
                          <span> <span className="info mode">{options.mode}</span></span>
                         */}

                  {/*<span>
                            <span className="info identifier">{component.frontComponent}</span>
                         </span>*/}

                  {options && (options.name || options.label) && (
                    <i> "{options.name || options.label}" </i>
                  )}

                  <div className="comp-list">
                    {(_.get(component, 'options.list') &&
                      _.map(options.list, (listItem, i) => (
                        <div key={i} className="item">
                          <ComponentListItem
                            {...this.props}
                            item={listItem}
                            mode={options.mode}
                          />
                        </div>
                      ))) || (
                      <div className="item">
                        <SiteComponent
                          {...this.props}
                          type={pageOrComponent.objectType}
                          mode={options.mode}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default class SiteHierarchyPage extends ComponentBase {
  stores = [SchemaStore, InstanceStore];

  didMount() {
    API.get(
      this.state.instanceName + '/data/Page',
      null //{filter: {objectType: null}}
    ).then((pages) => this.setState({ pages }));

    API.get(this.state.instanceName + '/data/ComplexComponent').then((ccs) =>
      this.setState({ ccs })
    );

    API.get(this.state.instanceName + '/data/Config/header').then((header) =>
      this.setState({ header })
    );

    API.get(this.state.instanceName + '/data/Config/header').then((footer) =>
      this.setState({ footer })
    );
  }

  render() {
    let { pages, ccs } = this.state;

    return (
      <div className="site-hierarchy-wrapper">
        {_.map(pages, (page) => (
          <div key={page._id} className="page">
            <h2>
              <span>{page.label}</span>
              <span className="identifier"> - /{page._id}</span>
              {page.objectType && (
                <span className="small">
                  {' '}
                  <span className="info type">{page.objectType}</span>
                </span>
              )}
            </h2>
            <Hierarchy
              pageOrComponent={page}
              ccs={ccs}
              d={1}
              globalSchema={this.state.globalSchema}
            />
          </div>
        ))}
      </div>
    );
  }
}
