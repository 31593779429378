const DataSourceSchema = {
  key: 'dataSource',
  label: 'Source de données',
  type: 'object',
  group: 'Avancé',
  propertiesList: [
    {
      key: 'isGlobal',
      type: 'bool',
      label: 'Utiliser la base globale (schema, sites...)',
    },
    {
      key: 'resource',
      label: 'Resource',
      description: 'Resource à lister dans la base',
      type: 'enum',
      required: true,
      dataSource: (obj) =>
        !obj?.dataSource?.isGlobal && {
          resource: 'schema',
          valuePath: '_id',
          labelPath: 'label',
        },
      values: (obj) =>
        !!obj?.dataSource?.isGlobal && [
          { value: 'schema', label: 'Schema' },
          { value: 'Site', label: 'Sites' },
        ],
    },
    {
      key: 'valuePath',
      label: 'Chemin de la valeur',
      description: "Ex '_id' si on veut l'ID de la resource",
      type: 'string', // TODO extract values from schema
      required: false,
      default: '_id',
      condition: { f$: 'obj.dataSource && obj.dataSource.resource' },
    },
    {
      key: 'labelPath',
      label: 'Chemin du libellé de la valeur',
      description: "Ex 'label' si on veut lister les label dans le select",
      type: 'string',
      required: false,
      default: 'label',
      condition: { f$: 'obj.dataSource && obj.dataSource.resource' },
    },
    {
      key: 'query',
      label: 'Paramètres de la requête (JSON)',
      type: 'object',
      editor: 'json',
      condition: { f$: 'obj.dataSource && obj.dataSource.resource' },
    },
  ],
};

export default DataSourceSchema;
