// @flow
import { kebabCase } from 'lodash';

import type { ResourceType } from 'src/types/models';

import RESOURCE_TYPES from 'src/pages/constants/resources';

export const toPath = (path: string): string =>
  path
    .split('/')
    .map((pathPart) => kebabCase(pathPart))
    .join('/');

export const pluralizeResourceType = (type: ResourceType): string =>
  RESOURCE_TYPES[type]?.plural || type;

export const pluralizeResourceLabel = (
  type: ResourceType,
  label: string
): string => RESOURCE_TYPES[type]?.pluralLabel || label || type;
