// @flow
import * as React from 'react';
import Tooltip from 'rc-tooltip';

import 'rc-tooltip/assets/bootstrap_white.css';

type Props = {|
  label?: string,
  required?: boolean,
  tooltip?: string,
  htmlFor?: string,
|};

export default function OldLabel({
  label,
  required,
  tooltip,
  htmlFor,
}: Props): React.Node {
  if (!label) return null;

  return (
    <label htmlFor={htmlFor || 'input-text'} className="FormElement-label">
      <span className="FormElement-label-inner">
        {label}
        {required ? '*' : ''}
      </span>

      {tooltip && (
        <div className="FormElement-tooltip">
          <Tooltip
            placement="right"
            trigger={['hover']}
            overlay={
              <span className="FormElement-tooltip-text">{tooltip}</span>
            }
          >
            <a href="#" className="FormElement-tooltip-trigger">
              ?
            </a>
          </Tooltip>
        </div>
      )}
    </label>
  );
}
