// @flow
import * as React from 'react';
import { cloneDeep, merge } from 'lodash';

import type {
  Content,
  ColumnGroup,
  Handlers,
  ColumnGroupOptions,
} from '../../../types';

import { CSS_PROPERTIES } from '../../../constants/componentOptionsAdditionalSchemas';
import SchemaContext from 'src/pages/contexts/SchemaContext';

import {
  Icon,
  Button,
  Tile,
  Modal,
  Field,
  Input,
  Control,
  Label,
  Message,
  MessageBody,
  MessageHeader,
  Text,
} from 'src/components';
import ColumnContent from './ColumnContent';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';

type Props = {|
  contents: Array<Content>,
  group: ColumnGroup,
  columnPath: string,
  actualSize: number,
  handlers: Handlers,
  rowCount: number,
  color: string,
  title: string,
  isNew: boolean,
  baseContentIndex: number,
  onDelete?: () => void,
  onSave: (ColumnGroup) => void,
|};

export default function Group({
  contents,
  group,
  columnPath,
  actualSize,
  handlers,
  rowCount,
  color,
  title,
  isNew,
  baseContentIndex,
  onDelete,
  onSave,
}: Props): React.Node {
  const [modalOpen, openModal] = React.useState(false);
  const [dirty, setDirty] = React.useState(isNew);
  const [editedGroup, setEditedGroup] = React.useState({
    options: cloneDeep(group.options),
    title: group.title,
  });

  return (
    <Message
      size="small"
      additionalClassName="column-group"
      style={{ backgroundColor: color }}
    >
      <MessageHeader style={{ justifyContent: 'space-between' }}>
        <Text isTitle element="h6" size={6} color="white">
          Groupe - {title || 'Group sans nom'}
        </Text>
        <div>
          <Button
            size="small"
            style={{ marginRight: 5 }}
            onClick={() => openModal(true)}
          >
            <Icon name="edit" size="small" />
          </Button>

          {dirty && onSave && (
            <Button
              color="success"
              size="small"
              onClick={() => {
                onSave(merge(group, editedGroup));
                setDirty(false);
              }}
              style={{ marginRight: 5 }}
            >
              <Icon name="check" size="small" />
            </Button>
          )}

          {onDelete && (
            <Button color="danger" size="small" onClick={onDelete}>
              <Icon name="times" size="small" />
            </Button>
          )}
        </div>
      </MessageHeader>

      <MessageBody style={{ padding: 5 }}>
        {contents.map((content, contentIndex) => {
          const contentPath = `${columnPath}.contents[${
            baseContentIndex + contentIndex
          }]`;

          // In the future, we could use GroupManager to allow deeper levels of grouping
          return (
            <Tile isChild key={contentIndex}>
              <ColumnContent
                content={content}
                path={contentPath}
                actualSize={actualSize}
                handlers={handlers}
                index={contentIndex}
                rowCount={contents.length}
              />
            </Tile>
          );
        })}
      </MessageBody>

      {modalOpen && (
        <OptionsModal
          group={group}
          onChange={(groupShape) => {
            setDirty(true);
            setEditedGroup({ ...editedGroup, ...groupShape });
          }}
          onClose={() => openModal(false)}
        />
      )}
    </Message>
  );
}

type OptionsModalProps = {|
  onClose: () => void,
  group: ColumnGroup,
  onChange: ($Shape<ColumnGroup>) => void,
|};

function OptionsModal({ group, onChange, onClose }: OptionsModalProps) {
  const { options, title } = group;
  const [editedOptions, setEditedOptions] = React.useState<
    $Shape<ColumnGroupOptions>
  >({ ...options });
  const [editedTitle, setEditedTitle] = React.useState(title);

  return (
    <Modal
      open
      onClose={(buttonName) => {
        buttonName === 'ok'
          ? onChange({ options: editedOptions, title: editedTitle })
          : setEditedOptions({ ...options });
        onClose();
      }}
      title="Options d'affichage du groupe"
      modalClassName="is-small"
      size="half"
      modal
    >
      <Field>
        <Label>Titre du group</Label>
        <Control>
          <Input
            value={editedTitle}
            onChange={(newTitle) => setEditedTitle(newTitle || '')}
          />
        </Control>
      </Field>

      <SchemaContext.Provider
        // $FlowIgnore
        value={{
          schema: { type: 'object', propertiesList: CSS_PROPERTIES },
        }}
      >
        <ResourceFormEditor
          resource={editedOptions}
          onChange={(newValue) =>
            setEditedOptions({ ...editedOptions, ...newValue })
          }
        />
      </SchemaContext.Provider>
    </Modal>
  );
}
