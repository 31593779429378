// @flow
import Reflux from 'reflux';
import { keyBy } from 'lodash';

import type { Site, Admin } from 'src/types/models';

import API from 'src/helpers/api/API';
import config from 'src/helpers/api/config';

export var InstanceActions: any = Reflux.createActions(['set', 'loadSiteList']);

// Note : instanceName <=> site._id

export type GetFrontUrlOptions = {|
  path?: string,
  queryParams?: Object,
|};

const getFrontUrl = (site: Site, options?: GetFrontUrlOptions) => {
  const { _id: instanceName, domain } = site;
  const { queryParams = {}, path = '' } = options || {
    queryParams: {},
    path: '',
  };

  let url;

  if (config.FRONT_URL) {
    url = new URL(config.FRONT_URL);
  } else {
    switch (config.PUF_ENV) {
      case 'dev':
        url = new URL('http://localhost:3001');
        url.searchParams.append('select_instance', instanceName);
        break;
      case 'staging':
        url = new URL(`https://${instanceName}--staging.mica.media`);
        url.searchParams.append('select_instance', instanceName);
        break;
      case 'prod':
        url = new URL(`https://${domain}`);
        break;
      default:
        throw new Error(`Unrecognized environment ${config.PUF_ENV}`);
    }
  }

  url.pathname = path;
  Object.keys(queryParams).map((key) =>
    url.searchParams.append(key, queryParams[key])
  );

  return url;
};

export default class InstanceStore extends Reflux.Store {
  constructor() {
    super();

    this.state = {
      instanceList: [],
      instanceName: null,
    };

    this.listenables = [InstanceActions];
  }

  /**
   * This will only work with an admin user session
   */
  loadSiteList(user: Admin, instanceName: string) {
    API.get('global/data/Site', { limit: 0 }).then(({ data: sites }) => {
      const userSiteList = sites.filter(
        (site) =>
          !user.siteIds ||
          user.siteIds.length === 0 ||
          user.siteIds.includes(site._id)
      );

      const prefix =
        config.PUF_ENV === 'prod'
          ? '[PROD] '
          : config.PUF_ENV === 'dev'
          ? '[dev] '
          : '';

      this.setState({
        instanceList: userSiteList.map((site) => ({
          id: site._id,
          _id: site._id,
          value: site._id,
          label: prefix + site._id + ' (' + site.domain + ')',
          site,
          frontUrl: getFrontUrl(site),
          getFrontUrl: (options?: GetFrontUrlOptions) =>
            getFrontUrl(site, options),
        })),
      });

      this.set(instanceName);
    });
  }

  async set(instanceName: string) {
    const { instanceName: currentInstanceName, instanceList } = this.state;

    if (!!instanceName && instanceName === currentInstanceName) return;

    const newCurrentInstanceName =
      instanceList.find((instance) => instance.id === instanceName)?.id ||
      instanceList[0].id;
    const newCurrentInstance =
      instanceList.find((instance) => instance.id === instanceName) ||
      instanceList[0];

    const configs = await API.get(`${newCurrentInstanceName}/data/Config`);

    this.setState({
      instance: newCurrentInstance,
      instanceName: newCurrentInstanceName,
      config: keyBy(configs, '_id'),
    });
  }
}

InstanceStore.id = 'InstanceStore'; // https://github.com/reflux/refluxjs/tree/master/docs/stores#global-state
