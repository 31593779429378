// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from '../types';

type Props = {|
  children: React.Node,
  additionalClassName?: string,
  style?: Style,
|};

export default function Box({
  children,
  style,
  additionalClassName,
}: Props): React.Node {
  const className = classnames('box', additionalClassName);

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}
