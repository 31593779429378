import * as ContainerComponents from './containers';
import * as ForPageComponents from './forPage';
import * as ForEntityComponents from './forEntity';
import * as entityComponents from './entityComponents';

export const COMPONENTS = [
  ...Object.values(ContainerComponents),
  ...Object.values(ForPageComponents),
  ...Object.values(ForEntityComponents),
  ...Object.values(entityComponents),
];
