// @flow
import * as React from 'react';

import { useGlobal } from 'src/hooks';

import GearIcon from 'src/images/gear-icon.svg';
import LayoutIcon from 'src/images/layout-icon.svg';
import LayoutBuilderIcon from 'src/images/layout-builder-icon.svg';

import { NavLink } from 'react-router-dom';

const DashboardSquare = ({ route, icon, children }): React.Node => {
  const { siteId } = useGlobal();

  return (
    <div className="col one-third-col">
      <NavLink to={`/${siteId}${route || '/'}`} className="DashboardSquare">
        {icon && <img alt="todo-alt" className="icon" src={icon} />}
        {children}
      </NavLink>
    </div>
  );
};

export default function HomePage(): React.Node {
  return (
    <div className="flex-wrapper row">
      <DashboardSquare icon={GearIcon} route="/website-configuration">
        <h2>Options du site</h2>
      </DashboardSquare>
      <DashboardSquare
        icon={LayoutBuilderIcon}
        route="/website-configuration/pages"
      >
        <h2>Configurer le layout des pages</h2>
      </DashboardSquare>
      <DashboardSquare icon={LayoutIcon} route="/contents">
        <h2>Contenus</h2>
      </DashboardSquare>
    </div>
  );
}
