import * as React from 'react';

import API from 'src/helpers/api/API';
import { useGlobal } from 'src/hooks';
import { APIContext } from 'src/pages/contexts';

import {
  Input,
  Button,
  Text,
  Field,
  Control,
  Icon,
  Column,
  Columns,
} from 'src/components';

export default function ImageTool() {
  const [search, setSearch] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const [resultCount, setResultCount] = React.useState(0);
  const { siteId } = useGlobal();

  const makeSearch = React.useCallback(async () => {
    setError(null);
    setSubmitting(true);
    try {
      const { results, count } = await API.get(`${siteId}/images/search`, {
        search,
      });
      setResults(results);
      setResultCount(count);
    } catch (e) {
      if (e.message) setError(e.message);
    } finally {
      setSubmitting(false);
    }
  }, [search]);

  const deleteImage = React.useCallback(async (imagePath) => {
    const deleted = await API.del(`${siteId}/images/delete`, {
      imagePath,
    });

    if (!deleted) return;

    setResults((previousResults) =>
      previousResults.filter((path) => path !== imagePath)
    );
    setResultCount((previousCount) => previousCount - 1);
  });

  return (
    <div className="image-tool-page">
      <Text element="h1" isTitle size={3} style={{ marginBottom: '2rem' }}>
        Éditeur d'images
      </Text>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (submitting) return;
          makeSearch();
        }}
      >
        <Field hasAddons>
          <Control>
            <Input
              value={search}
              onChange={setSearch}
              placeholder="Entrez le path de l'image que vous recherchez"
            />
          </Control>
          <Control>
            <Button type="submit" color="info" disabled={submitting}>
              Rechercher
            </Button>
          </Control>
        </Field>
      </form>

      {error && <p className="help is-danger">{error}</p>}

      <div className="results" style={{ marginTop: '1rem' }}>
        {results ? (
          results.length > 0 ? (
            <>
              {resultCount !== results.length && (
                <Text element="p" color="grey">
                  {resultCount} résulats correspondent à votre recherche, mais
                  seulement {results.length} ont été affichés. <br />
                  Affiner votre recherche
                </Text>
              )}
              <Columns isMultiline>
                {results.map((imagePath) => {
                  return (
                    <Column size={4}>
                      <Image imagePath={imagePath} onDelete={deleteImage} />
                    </Column>
                  );
                })}
              </Columns>
            </>
          ) : (
            <Text element="p" color="grey">
              Aucun résultat pour votre recherche
            </Text>
          )
        ) : (
          <Text element="p" color="grey">
            Aucune recherche en cours, entrez un nom dans le champ ci-dessus{' '}
            <Icon name="arrow-up" />
          </Text>
        )}
      </div>
    </div>
  );
}

function Image({ imagePath, onDelete: onDeleteProp }) {
  const [deleting, setDeleting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { getImageUrl } = React.useContext(APIContext);

  const onDelete = React.useCallback(async () => {
    setDeleting(true);

    try {
      await onDeleteProp(imagePath);
    } catch (e) {
      setDeleting(false);
      setError(e?.message);
    }
  }, [imagePath, onDeleteProp]);

  if (!imagePath) return null;

  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-16by9">
          <img src={getImageUrl(imagePath)} alt={imagePath} />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-6">{imagePath}</p>
            {error && <p className="help is-danger">{error}</p>}
          </div>
        </div>
      </div>

      <footer className="card-footer">
        <a
          onClick={onDelete}
          className="card-footer-item"
          href="#"
          disabled={deleting}
        >
          <Icon name="trash" />
        </a>
      </footer>
    </div>
  );
}
