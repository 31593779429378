// @flow
import * as React from 'react';
import classNames from 'classnames';

type Props = {|
  title: string,
  count: number,
  target?: number,
|};

export default function Counter({ title, count, target }: Props): React.Node {
  const [rangeDistance, setRangeDistance] = React.useState(null);

  // Effect to compute how far (in percentage) we are from expected count
  React.useEffect(() => {
    if (!target) return;

    if (Number.isInteger(count) && Number.isInteger(target)) {
      const rangeRatio = (Math.abs(target - count) / target) * 100;

      if (rangeRatio < 20) {
        setRangeDistance('excellent');
        return;
      }
      if (rangeRatio < 40) {
        setRangeDistance('ok');
        return;
      }

      setRangeDistance('bad');
      return;
    }
  }, [count, target]);

  return (
    <span
      className={classNames('tag', 'counter', {
        'is-success': rangeDistance === 'excellent',
        'is-warning': rangeDistance === 'ok',
        'is-danger': rangeDistance === 'bad',
      })}
    >
      <span className="count">{count}</span>{' '}
      {target && <span className="target">/ {target}</span>}{' '}
      <span className="counter-title"> {title}</span>
    </span>
  );
}
