// @flow
import * as React from 'react';

import type {
  ResourceSchema,
  Resource,
  NativeDiscount,
} from 'src/types/models';

import ProductCartLink from './ProductCartLink';
import ContentPublicLink from './ContentPublicLink';
import DiscountSubscriptionsLink from './DiscountSubscriptionsLink';

type Props = {|
  schema: ResourceSchema,
  resource: Resource,
  isSidebar?: boolean,
|};

export default function CustomFields({
  schema,
  resource,
  isSidebar,
}: Props): React.Node {
  const customFields = [];

  if (schema.isContent && !isSidebar) {
    customFields.push(
      <ContentPublicLink
        key="content-public-link"
        schema={schema}
        resource={resource}
      />
    );
  }

  if (schema.isProduct && !isSidebar) {
    customFields.push(
      <ProductCartLink
        key="product-cart-link"
        schema={schema}
        resource={resource}
      />
    );
  }

  switch (schema._id) {
    case 'NativeDiscount':
      customFields.push(
        <DiscountSubscriptionsLink
          key="discount-subscriptions-link"
          schema={schema}
          discount={((resource: any): NativeDiscount)}
        />
      );
      break;
    default:
  }

  return customFields;
}
