const EntityEdiisCartLink = {
  _id: 'EntityEdiisCartLink',
  label: 'Lien vers panier Ediis',
  icon: 'cart-shopping',
  frontComponent: 'EntityEdiisCartLink',
  optionsSchema: [
    {
      key: 'label',
      label: 'Text',
      type: 'string',
    },
  ],

  forEntity: true,
};

export default EntityEdiisCartLink;
