// @flow

import * as React from 'react';
import classnames from 'classnames';

type Props = {|
  label: string,
  disabled: boolean,
  children: React.Node,
  required: boolean,
|};

export default function OldMultiFormElements({
  label,
  disabled = false,
  children,
  required,
}: Props): React.Node {
  const className = classnames('FormMultiElements', {
    'FormMultiElements--disabled': disabled,
  });
  return (
    <div className={className}>
      <div className="FormElement">
        {label && (
          <span className="FormElement-label">{`${label}${
            required ? '*' : ''
          }`}</span>
        )}
        <div className="FormMultiElements-fields">{children}</div>
      </div>
    </div>
  );
}

OldMultiFormElements.defaultProps = {
  disabled: false,
  required: false,
};
