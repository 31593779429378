// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style, BulmaColor } from '../types';
import type { ColumnSize } from '../layout/grid/Column';

import { bulmaColorClassName } from 'src/helpers/style';

type Props = {|
  children?: React.Node,
  isAncestor?: boolean,
  isParent?: boolean,
  isChild?: boolean,
  isVertical?: boolean,
  color?: BulmaColor,
  size?: ColumnSize,
  additionalClassName?: string,
  style?: Style,
|};

export default function Tile({
  children,
  color,
  additionalClassName,
  style,
  isAncestor,
  isParent,
  isChild,
  isVertical,
  size,
}: Props): React.Node {
  const className = classnames(
    'tile',
    bulmaColorClassName(color),
    additionalClassName,
    {
      [`is-${size || ''}`]: !!size,
      'is-ancestor': isAncestor,
      'is-parent': isParent,
      'is-child': isChild,
      'is-vertical': isVertical,
    }
  );

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}
