// @flow
import * as React from 'react';

import type { ResourceSchema, Resource } from 'src/types/models';

import { useStore } from 'src/hooks';

import setupCartPageIdImage from './capture-setup-cartPageId.png';
import { Link } from 'react-router-dom';
import {
  QuestionMarkTooltip,
  Input,
  Control,
  Field,
  Label,
  InformationBubble,
} from 'src/components';

type Props = {|
  schema: ResourceSchema,
  resource: Resource,
|};

export default function ProductCartLink({
  schema,
  resource,
}: Props): React.Node {
  const { instance, config } = useStore('InstanceStore');
  const { cartPageId } = config.options;

  const cartLinkUrl = new URL(instance.frontUrl);
  cartLinkUrl.pathname = `/p/${cartPageId}`;

  cartLinkUrl.searchParams.append('itemCls', resource._cls);

  cartLinkUrl.searchParams.append('itemId', resource._id);

  return (
    <Field additionalClassName="product-link" style={{ margin: '15px 0px' }}>
      <Label>
        Lien vers le panier (avec ajout du produit) :{' '}
        <QuestionMarkTooltip content="Ce lien redirige sur le panier en ajoutant automatiquement le produit en question" />
      </Label>

      {cartPageId ? (
        <Control>
          <Input
            type="text"
            value={cartLinkUrl.href}
            onFocus={(e) => e.target.select()}
            readOnly={true}
          />
        </Control>
      ) : (
        <InformationBubble type="warning">
          <p>
            Vous devez mettre en place la page panier dans la rubrique{' '}
            <Link to="/website-configuration/options">Site > Options</Link>.
          </p>
          <img
            src={setupCartPageIdImage}
            height={200}
            style={{ marginTop: 20 }}
            alt="Screenshot montrant l'endroit à éditer"
          />
        </InformationBubble>
      )}
    </Field>
  );
}
