// @flow
// The different sizes are available here https://fontawesome.com/how-to-use/on-the-web/styling/sizing-icons
// The different icons are availalbe here https://fontawesome.com/icons

import * as React from 'react';
import classnames from 'classnames';

import type { BulmaColor, Style } from './types';

type Size =
  | 'xs'
  | 'sm'
  | 'lg'
  | '2x'
  | '3x'
  | '5x'
  | '7x'
  | '10x'
  | 'small'
  | 'large';

type Props = {|
  name: string,
  text?: string,
  size?: Size,
  color?: BulmaColor,
  pulse?: boolean,
  title?: string,
  additionalClassName?: string,
  style?: Style,
  type?: 'brand' | 'regular' | 'solid',
|};

const getSizeClassName = (size?: Size) => {
  if (!size) return '';

  switch (size) {
    case 'small':
      return 'fa-sm';
    case 'large':
      return 'fa-lg';
    default:
      return `fa-${size}`;
  }
};

export default function Icon({
  name,
  style,
  additionalClassName,
  size,
  color,
  pulse,
  title,
  text,
  type = 'solid',
}: Props): React.Node {
  const className = classnames('icon', additionalClassName, {
    'has-text-primary': color && color === 'primary',
    'has-text-info': color && color === 'info',
    'has-text-danger': color && color === 'danger',
    'has-text-warning': color && color === 'warning',
    'has-text-success': color && color === 'success',
  });

  const iconClassName = classnames(
    {
      fas: type === 'solid',
      fab: type === 'brand',
      far: type === 'regular',
    },
    `fa-${name}`,
    `fa-${name}`,
    getSizeClassName(size),
    {
      'fa-pulse': pulse,
    }
  );

  const icon = (
    <span className={className} style={style}>
      <i className={iconClassName} />
    </span>
  );

  if (text) {
    return (
      <span className="icon-text">
        {icon}
        <span>{text}</span>
      </span>
    );
  }

  return icon;
}

Icon.defaultProps = {
  pulse: false,
};
