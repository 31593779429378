// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { ImageSize, ImageRatio, Style } from './types';

type Props = {|
  src: string,
  alt: string,
  width?: number,
  height?: number,
  size?: ImageSize,
  ratio?: ImageRatio,
  isRounded?: boolean,
  additionalClassName?: string,
  style?: Style,
|};

export default function Image({
  src,
  alt,
  size,
  ratio,
  isRounded,
  width,
  height,
  additionalClassName,
  style,
}: Props): React.Node {
  const className = classnames(
    'image',
    additionalClassName,
    !!size && `is-${size}x${size}`,
    !!ratio && `is-${ratio}`,
    {
      'is-rounded': isRounded,
    }
  );

  return (
    <figure className={className} style={{ ...style, width, height }}>
      <img src={src} alt={alt} />
    </figure>
  );
}
