export const SIMPLE_COMPONENTS_MODES: Array<ComponentMode> = [
  // TODO import from Front / ENTITY_COMPONENTS_MAP
  { value: 'thumbnail', label: 'Thumbnail' },
  { value: 'sidebar', label: 'sidebar' },
  { value: 'large', label: 'grand' },
  { value: 'detailed', label: 'détaillé' },
  { value: 'full', label: 'Complet' },
  { value: 'wide', label: 'pleine largeur' },
  { value: 'simple', label: 'simple' },
  { value: 'image_only', label: 'image seule' },
];

export const MODE_PROPERTY = {
  key: 'mode',
  label: "Mode d'affichage",
  type: 'enum',
  values: SIMPLE_COMPONENTS_MODES,
};
