export const update = (list, index, value) => [
  ...list.slice(0, index),
  value,
  ...list.slice(index + 1),
];

export const remove = (list, index) => [
  ...list.slice(0, index),
  ...list.slice(index + 1),
];

export const swap = (list, firstIndex, secondIndex) => {
  const smallestIndex = firstIndex < secondIndex ? firstIndex : secondIndex;
  const biggestIndex = smallestIndex === firstIndex ? secondIndex : firstIndex;

  return [
    ...list.slice(0, smallestIndex),
    list[biggestIndex],
    ...list.slice(smallestIndex + 1, biggestIndex),
    list[smallestIndex],
    ...list.slice(biggestIndex + 1),
  ];
};
