/**

 src = absolute/relative image url, ex.

 @see https://github.com/lencioni/SLIR#using

 w   Maximum width
 h   Maximum height
 c   Crop ratio
 b   Background fill color (hex)
 q   Quality
 p   Progressive

 other params are <img> tag attributes

 */

import _ from 'lodash';
import * as React from 'react';

import { APIContext } from 'src/pages/contexts';

// TODO param https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit also ?

var DYNIMG_PARAMS_PIXELS = { w: 1, h: 1 };
var DYNIMG_PARAMS_OTHER = { c: 1, q: 1, bf: 1, p: 1, algo: 1, force: 1 };

export default function DynImg(props) {
  const { apiImageUrl } = React.useContext(APIContext);

  if (!props.src) return <img alt="todo-alt" className={props.className} />;

  let src = props.src;

  let params = [],
    imgAttr = {};

  _.forEach(props, (v, p) => {
    if (p in DYNIMG_PARAMS_PIXELS && v) {
      params.push(p + '=' + ~~(1 * v * (window.devicePixelRatio || 1)));
    } else if (p in DYNIMG_PARAMS_OTHER) {
      params.push(p + '=' + JSON.stringify(v));
    } else if (p !== 'src') {
      imgAttr[p] = v;
    }
  });

  return (
    <img
      alt="todo-alt"
      src={`${apiImageUrl}${src}`}
      {...imgAttr}
      className={props.className}
    />
  );
}
