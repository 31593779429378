// @flow
import * as React from 'react';

export type APIContextType = {|
  apiUrl: string,
  apiImageBaseUrl: string,
  apiImageShowUrl: string,
  getImageUrl: (string) => string,
  apiImageUploadUrl: string,
  siteId: string,
  apiUserContributionShowUrl: string,
  getUserContributionUrl: (userId: string, fileName: string) => string,
|};

export const APIContext = (React.createContext<APIContextType>({
  apiUrl: '',
  apiImageBaseUrl: '',
  apiImageShowUrl: '',
  apiImageUploadUrl: '',
  getImageUrl: () => '',
  siteId: '',
  apiUserContributionShowUrl: '',
  getUserContributionUrl: () => '',
}): React.Context<APIContextType>);
