// @flow
import * as React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import type { Style } from 'src/components/types';

import { Link } from 'src/components';

type Props = {
  children: React.Node,
  onClick?: () => any,
  to?: string,
  isDanger?: boolean,
  isWarning?: boolean,
  isSuccess?: boolean,
  isInfo?: boolean,
  isPrimary?: boolean,
  style?: Style,
  isActive?: boolean,
  disabled?: boolean,
  additionalClassName?: string,
};

export default function DropdownMenuItem({
  children,
  onClick,
  to,
  isDanger,
  isWarning,
  isSuccess,
  style,
  isInfo,
  isPrimary,
  isActive,
  disabled,
  additionalClassName,
}: Props): React.Node {
  const className = classnames('dropdown-item', additionalClassName, {
    'is-active': isActive,
    disabled,
  });

  if (to) {
    return (
      <NavLink
        tabIndex={0}
        to={to}
        className={className}
        style={style}
        disabled={disabled}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <Link
      className={className}
      onClick={() => !disabled && onClick && onClick()}
      style={style}
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && onClick && !disabled && onClick()}
      disabled={disabled}
    >
      {children}
    </Link>
  );
}
