const SendAFile = {
  _id: 'sendAFile',
  frontComponent: 'SendAFile',
  label: 'Envoyer un fichier',
  icon: 'file',
  optionsSchema: [
    {
      key: 'label',
      label: 'Titre',
      type: 'string',
    },
    {
      key: 'consentLabel',
      label: 'Texte de la checkbox',
      type: 'string',
    },
    {
      key: 'buttonLabel',
      label: 'Libellé du bouton',
      type: 'string',
    },
    {
      key: 'confirmationLabel',
      label: 'Texte à afficher après envoi',
      type: 'string',
    },
  ],
  forPage: true,
  forEntity: false,
};

export default SendAFile;
