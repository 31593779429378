// @flow
import * as React from 'react';

import type { Tab } from '../';

import { Icon } from 'src/components';
import GetResponseSynchroTab from '../shared/GetResponseSynchroTab';

const userCustomTabs: Array<Tab> = [
  {
    to: 'getresponse-synchro',
    label: (
      <span>
        <Icon name="mail-bulk" /> Synchro GetResponse
      </span>
    ),
    content: GetResponseSynchroTab,
  },
];

export default userCustomTabs;
