// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from './';

import { useGlobal } from 'src/hooks';

import { Switch, Tag } from 'src/components';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: boolean,
  disabled?: boolean,
|};

export default function BooleanEditor({
  propertySchema,
  onChange,
  disabled,
  value,
}: Props): React.Node {
  const { user } = useGlobal();
  const { label, key, required, description } = propertySchema;

  return (
    <Switch
      value={value}
      onChange={onChange}
      additionalFieldClassName="resource-property"
      label={
        <>
          <span>{label}</span>
          {user.isDeveloper && (
            <Tag color="info" light style={{ marginLeft: 10 }}>
              {key}
            </Tag>
          )}
        </>
      }
      required={required}
      tooltip={description}
      disabled={disabled}
    />
  );
}
