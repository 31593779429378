// @flow
import * as React from 'react';

import type { Style } from '../types';

import { DelayedRenderer } from '../';

const containerStyle = {
  padding: 15,
  display: 'flex',
  justifyContent: 'center',
};

const sizes = {
  small: 0.75,
  tiny: 0.5,
};

type Size = $Keys<typeof sizes>;

type CircularProgressProps = {|
  size?: Size,
  style?: Style,
  borderWidth: number,
  color: string,
|};

function CircularProgress({
  size,
  style = {},
  color,
  borderWidth,
}: CircularProgressProps): React.Node {
  let sizeInPixels = 25;

  if (size) {
    sizeInPixels = sizeInPixels * sizes[size];
  }

  return (
    <div
      className="loader"
      style={{
        display: 'inline-block',
        borderColor: color,
        borderWidth: `${borderWidth}px ${borderWidth}px 0 0`,
        height: `${sizeInPixels}px`,
        width: `${sizeInPixels}px`,
        ...style,
      }}
    />
  );
}

CircularProgress.defaultProps = {
  color: '#B8C6D0',
  borderWidth: 2,
};

export { CircularProgress };

type LoadingProps = {|
  size?: Size,
  style?: Style,
  color?: string,
  delay: number,
  delayed?: boolean,
|};

function Loading({
  size,
  style,
  color,
  delay,
  delayed,
}: LoadingProps): React.Node {
  if (!delay || !delayed)
    return (
      <div style={containerStyle}>
        <CircularProgress size={size} style={style} color={color} />
      </div>
    );

  return (
    <div style={containerStyle}>
      <DelayedRenderer delay={delay}>
        <CircularProgress size={size} style={style} color={color} />
      </DelayedRenderer>
    </div>
  );
}

Loading.defaultProps = {
  delay: 500,
};

export default Loading;
