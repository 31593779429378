const Register = {
  _id: 'register',
  frontComponent: 'Register',
  label: 'Inscription/connexion',
  icon: 'user',
  optionsSchema: [
    {
      key: 'label',
      label: 'Libellé en haut du formulaire',
      type: 'string',
    },
    {
      key: 'simpleMode',
      label: 'Mode simplifié',
      type: 'bool',
      default: false,
      required: true,
    },
    {
      key: 'modal',
      label: 'Boite de dialogue',
      type: 'bool',
      default: false,
      required: true,
    },
  ],
  forPage: true,
};

export default Register;
