// @flow
import type { Environment } from 'src/types/environment';

type EnvironmentColorSet = {|
  primaryColor: string,
  primaryColorRGB: string,
|};

export const ENVIRONMENT_COLOR_SET: {
  [key: Environment]: EnvironmentColorSet,
} = {
  dev: {
    primaryColor: '#3e9048',
    primaryColorRGB: '62, 144, 72',
  },
  staging: {
    primaryColor: '#354cd5',
    primaryColorRGB: '53, 76, 213',
  },
  prod: {
    primaryColor: '#c91313',
    primaryColorRGB: '201, 19, 19',
  },
};

export const BULMA_COLORS = {
  SUCCESS: 'hsl(153, 53%, 53%)',
  DANGER: 'hsl(348, 86%, 61%)',
  WARNING: 'hsl(44, 100%, 77%)',
  INFO: 'hsl(207, 61%, 53%)',
};
