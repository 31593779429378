// @flow
import * as React from 'react';
import _ from 'lodash';

import { buildQueryString } from 'src/helpers/misc';
import config from 'src/helpers/api/config';
import { useGlobal, useSchemaContext } from 'src/hooks';

import { Switch, Text } from 'src/components';

const EXPORTABLE_TYPES = [
  'string',
  'bool',
  'int',
  'float',
  'enum',
  'timestamp',
];
const OBJECT_TYPES = ['object', 'object_id', 'object_ref']; // WARNING: wrong prop path for object_id, but corrected by server

type Props = {|
  beginTs: ?number,
  endTs: ?number,
|};

export default function CustomExport({ beginTs, endTs }: Props): React.Node {
  const { schema } = useSchemaContext();
  const { siteId, globalSchema } = useGlobal();

  const [fields, setFields] = React.useState({});
  const queryString = buildQueryString({
    adminFilter: {
      createTs: (beginTs || endTs) && {
        min: beginTs,
        max: endTs,
      },
    },
    outputFormat: 'csv',
    // $FlowIgnore
    columns: _.map(_.pickBy(fields), (label, prop) => ({
      prop,
      label,
    })),
    __admin: true,
    depDepth: 1,
    resource: schema._id,
  });

  return (
    <div className="custom-export-tab">
      <Text element="h1" size={5} isTitle>
        Sélectionner les champs
      </Text>

      <ExportObject
        schema={schema}
        fields={fields}
        globalSchema={globalSchema}
        onChangeField={(path, value) =>
          setFields((previousFields) => ({
            ...previousFields,
            [path]: value,
          }))
        }
      />

      <a
        className="FormBase-button"
        target="_blank"
        rel="noopener noreferrer"
        href={`${config.API_URL}/admin/${siteId}/custom-exports?${queryString}`}
      >
        Exporter CSV
      </a>
    </div>
  );
}

type ExportObjectProps = {|
  prefix?: string,
  label?: string,
  schema: any,
  fullLabel?: string,
  fields: {},
  onChangeField: (string, any) => void,
  globalSchema: {},
|};

const ExportObject = ({
  prefix,
  label,
  schema,
  fullLabel,
  fields,
  onChangeField,
  globalSchema,
}: ExportObjectProps) => {
  let alwaysOpen = !prefix;
  const [open, setOpen] = React.useState(alwaysOpen);

  return (
    <div>
      {!alwaysOpen && (
        <Switch
          label={
            <span>
              {open ? '▼ ' : '▶ '}
              <span>{label}</span>
              {schema.label ? ' (' + schema.label + ')' : ''}
            </span>
          }
          value={open}
          onChange={setOpen}
        />
      )}

      <div style={{ marginLeft: '20px' }}>
        {open &&
          _.filter(
            schema.propertiesList,
            (p) => EXPORTABLE_TYPES.includes(p.type) || p.exportFormatting
          ).map((p) => {
            const path = (prefix ? prefix + '.' : '') + p.key;
            const plabel = ((fullLabel && fullLabel + '  \n') || '') + p.label;
            return (
              <Switch
                key={p.key}
                label={p.label}
                value={!!fields[path]}
                onChange={(v) => onChangeField(path, v ? plabel : false)}
              />
            );
          })}

        {open &&
          _.filter(
            schema.propertiesList,
            (p) => OBJECT_TYPES.includes(p.type) && p.objectType
          ).map((p) => {
            const path = (prefix ? prefix + '.' : '') + p.key;

            return (
              <div key={path}>
                {open &&
                  _.map(
                    _.isArray(p.objectType) ? p.objectType : [p.objectType],
                    (type) => {
                      const subSchema = globalSchema[type];
                      return (
                        subSchema && (
                          <ExportObject
                            key={type}
                            label={p.label}
                            fullLabel={
                              fullLabel ? fullLabel + '  \n' + p.label : p.label
                            }
                            schema={subSchema}
                            globalSchema={globalSchema}
                            fields={fields}
                            prefix={(prefix ? prefix + '.' : '') + p.key}
                            onChangeField={onChangeField}
                          />
                        )
                      );
                    }
                  )}
              </div>
            );
          })}
      </div>
    </div>
  );
};
