// @flow
import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';

import type {
  Column as ColumnType,
  ColumnSize,
  Handlers,
  FlexValue,
  AlignValue,
  RowAlignValue,
} from '../../types';

import NoMarginBottomIcon from 'src/images/margin-bottom-off.svg';
import WithMarginBottomIcon from 'src/images/margin-bottom-on.svg';
import { makeSpacingClass } from 'src/helpers/style';
import { ColumnSchema } from '../schemas';
import SchemaContext from 'src/pages/contexts/SchemaContext';

import {
  OldDropDown as DropDown,
  Droppable,
  Icon,
  Button,
  Flex,
  Modal,
  Text,
} from 'src/components';
import ResourceFormEditor from 'src/pages/components/resource/ResourceEditor/FormEditor';
import DIVIDERS from '../../constants/dividers';
import GroupManager from './GroupManager';

type Props = {|
  column: ColumnType,
  path: string,
  actualSize: ColumnSize,
  handlers: Handlers,
  index: number,
  columnsCount: number,
|};

type State = {|
  dragOver: boolean,
  canDrop: boolean,
  optionsModalOpened: boolean,
  dirty: boolean,
  editedColumn: ColumnType,
|};

export default class LayoutColumn extends React.Component<Props, State> {
  state: State = {
    dragOver: false,
    canDrop: false,
    optionsModalOpened: false,
    dirty: false,
    editedColumn: _.cloneDeep(_.omit(this.props.column, 'contents')),
  };

  onDrop(data: any, e: any) {
    e.stopPropagation();
    const { path, handlers } = this.props;

    if (data.component) {
      handlers.onAddComponent(path, data.component);
    } else if (data.tomove) {
      if (e.ctrlKey) {
        handlers.onAddComponent(path, data.tomove.component);
      } else {
        handlers.onMoveComponent(data.tomove.path, path, data.tomove.component);
      }
    } else if (data.divider) {
      handlers.onDivideCol(path, data.divider);
    }
  }

  canDrop(data: any): any {
    const { actualSize, path } = this.props;

    const canDrop =
      data.component ||
      (data.tomove &&
        !_.isEqual(_.toPath(data.tomove.path).slice(0, -2), _.toPath(path))) ||
      (data.divider && data.divider.minSize <= actualSize);

    return !!canDrop;
  }

  onDragEnter(e: any, canDrop: boolean) {
    this.setState({ dragOver: true, canDrop });
  }

  onDragLeave(e: any, canDrop: boolean) {
    this.setState({ dragOver: false, canDrop: true });
  }

  render(): React.Node {
    const { column, path, actualSize, handlers, columnsCount } = this.props;
    const {
      contents,
      noMarginBottom,
      flexOrder: columnFlexOrder,
      flex: columnFlex,
      align: columnAlign,
      groups = [],
    } = column;

    const minSize = _.minBy(DIVIDERS, 'minSize').minSize;
    const {
      dragOver,
      canDrop,
      optionsModalOpened,
      dirty,
      editedColumn,
    } = this.state;

    let flex = columnFlex;
    let align = columnAlign;

    // Convert old values:
    if (!!flex && /col-\w+/.test(flex)) {
      align = flex.slice(4);
      flex = null;
    } else if (flex && flex !== 'row' && flex !== 'text') {
      align = flex === 'left' ? null : flex;
      flex = 'row';
    }

    //-- select flex order menu
    let flexOrderValues = null;
    if (columnsCount > 1) {
      flexOrderValues = [
        {
          value: null,
          label: 'R',
          description: <i> - non spécifié (de gauche à droite)</i>,
        },
      ];

      for (let i = 1; i <= columnsCount; i++) {
        flexOrderValues.push({
          value: i,
          label: (
            <span className="LayoutTemplateItem-flex-order-number">{i}</span>
          ),
        });
      }
    }

    if (!contents) {
      return <div style={{ width: '100%' }}>empty col (should not happen)</div>;
    }

    const hasDeeperRow = contents.find((content) => !!content.cols);

    if (!hasDeeperRow) {
      return (
        <Droppable
          types={['component', 'divider', 'tomove']}
          onDrop={this.onDrop.bind(this)}
          canDrop={this.canDrop.bind(this)}
          onDragEnter={this.onDragEnter.bind(this)}
          onDragLeave={this.onDragLeave.bind(this)}
          style={{ width: '100%' }}
          className={classnames({
            over: dragOver,
            'prevent-dropping': dragOver && !canDrop,
          })}
        >
          <div className="layout-column">
            <Flex
              style={{ justifyContent: 'space-between', alignItems: 'center' }}
              additionalClassName={makeSpacingClass([['margin', 2, 'bottom']])}
            >
              <Text element="h4" isTitle size={6} style={{ marginBottom: 5 }}>
                Colonne
              </Text>

              <div>
                {!!minSize &&
                  actualSize >= minSize && ( // TODO better ?
                    <Button
                      title="Diviser ce bloc"
                      onClick={() => handlers.onClickCol(path, actualSize)}
                      size="small"
                      style={{ marginRight: 5 }}
                    >
                      <Icon name="cut" size="small" />
                    </Button>
                  )}

                <DropDown
                  value={flex}
                  options={flexValues}
                  onChange={(flex) => handlers.onChangeColFlex(path, flex)} // TODO
                  tooltip="Répartition des éléments"
                  size="small"
                  style={{ marginRight: 5 }}
                />

                <DropDown
                  value={align}
                  options={flex === 'row' ? alignValuesRow : alignValues}
                  onChange={(align) => handlers.onChangeColAlign(path, align)} // TODO
                  tooltip="Alignement"
                  size="small"
                  style={{ marginRight: 5 }}
                />

                {flexOrderValues && (
                  <DropDown
                    value={columnFlexOrder}
                    options={flexOrderValues}
                    onChange={(flexOrder) =>
                      handlers.onChangeColFlexOrder(path, flexOrder)
                    } // TODO
                    size="small"
                    style={{ marginRight: 5 }}
                    tooltip={
                      <div>
                        <div>Priorité de cette colonne, déterminant :</div>
                        <div>- l'ordre de placement des contenus</div>
                        <div>- l'ordre d'empilement lors du responsive"</div>
                      </div>
                    }
                  />
                )}

                <Button
                  title={
                    noMarginBottom
                      ? 'Pas de marge inférieure'
                      : 'Marge inférieure'
                  }
                  onClick={() =>
                    handlers.onChangeColMarginBottom(path, !noMarginBottom)
                  }
                  size="small"
                  style={{ marginRight: 5 }}
                >
                  <img
                    alt="todo-alt"
                    className="small-icon"
                    src={
                      noMarginBottom ? NoMarginBottomIcon : WithMarginBottomIcon
                    }
                  />
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      optionsModalOpened: true,
                    })
                  }
                  size="small"
                  style={{ marginRight: 10 }}
                >
                  <Icon name="fas fa-pen fa-sm" />
                </Button>

                <Button
                  title="Ajouter des composants"
                  onClick={() => handlers.onClickCol(path)}
                  size="small"
                  style={{ marginRight: 5 }}
                  color="success"
                >
                  <Icon name="plus" size="small" />
                </Button>

                {dirty && (
                  <Button
                    color="success"
                    size="small"
                    onClick={() => {
                      handlers.onChangeCol(path, {
                        ...column,
                        ...editedColumn,
                      });
                      this.setState({ dirty: false });
                    }}
                    style={{ marginRight: 5 }}
                  >
                    <Icon name="check" size="small" />
                  </Button>
                )}

                {!contents.length && ( // empty col
                  <Button
                    title="Supprimer le bloc"
                    onClick={() => handlers.onRemoveCol(path)}
                    size="small"
                    color="danger"
                  >
                    <Icon name="times" />
                  </Button>
                )}
              </div>
            </Flex>

            <GroupManager
              contents={contents}
              groups={groups}
              columnPath={path}
              actualSize={actualSize}
              handlers={handlers}
              column={column}
            />

            {optionsModalOpened && (
              <OptionsModal
                column={editedColumn}
                onChange={(newColumn) =>
                  this.setState({ editedColumn: newColumn, dirty: true })
                }
                onClose={() => this.setState({ optionsModalOpened: false })}
              />
            )}
          </div>
        </Droppable>
      );
    }

    return (
      <div style={{ width: '100%' }} className="layout-column">
        {/* we need at least flex order: */}
        {columnFlexOrder && (
          <Flex
            style={{ justifyContent: 'flex-start' }}
            additionalClassName={makeSpacingClass([['margin', 4, 'bottom']])}
          >
            {flexOrderValues && (
              <DropDown
                value={columnFlexOrder}
                options={flexOrderValues}
                onChange={(flexOrder) =>
                  handlers.onChangeColFlexOrder(path, flexOrder)
                } // TODO
                size="small"
                style={{ marginRight: 5 }}
                tooltip={
                  <div>
                    <div>Priorité de cette colonne, déterminant :</div>
                    <div>- l'ordre de placement des contenus</div>
                    <div>- l'ordre d'empilement lors du responsive"</div>
                  </div>
                }
              />
            )}
          </Flex>
        )}
        <GroupManager
          contents={contents}
          groups={groups}
          columnPath={path}
          actualSize={actualSize}
          handlers={handlers}
          column={column}
        />

        {optionsModalOpened && (
          <OptionsModal
            column={editedColumn}
            onChange={(newColumn) =>
              this.setState({ editedColumn: newColumn, dirty: true })
            }
            onClose={() => this.setState({ optionsModalOpened: false })}
          />
        )}
      </div>
    );
  }
}

type FlexValueItem = {|
  value: FlexValue,
  label: React.Node,
  description: string,
|};

const flexValues: Array<FlexValueItem> = [
  {
    value: 'row',
    label: <span>▪▪▪</span>,
    description: 'Eléments sur une ligne',
  },
  {
    value: null,
    label: <span>≡</span>,
    description: 'Eléments empilés',
  }, // col
  {
    value: 'text',
    label: <span>ttt</span>,
    description: 'Sous forme de texte',
  },
];

type AlignValueRowItem = {|
  value: RowAlignValue | null,
  label: React.Node,
  description: string,
|};

const alignValuesRow: Array<AlignValueRowItem> = [
  {
    value: null,
    label: <span>▪▪</span>,
    description: 'par défaut',
  },
  {
    value: 'right',
    label: <span>▪▪</span>,
    description: 'Droite',
  },
  {
    value: 'center',
    label: <span>▪▪</span>,
    description: 'Centré',
  },
  {
    value: 'both',
    label: <span>▪ ▪</span>,
    description: 'Sur toute la largeur',
  },
  {
    value: 'spaced',
    label: <span> ▪ ▪ </span>,
    description: 'Répartis',
  },
];

type AlignValueItem = {|
  value: AlignValue | null,
  label: React.Node,
  description: string,
|};

const alignValues: Array<AlignValueItem> = [
  {
    value: null,
    label: <span>--</span>,
    description: 'Par défaut',
  },
  {
    value: 'left',
    label: (
      <span>
        <Icon name="align-left" size="sm" />
      </span>
    ),
    description: 'Gauche',
  },
  {
    value: 'right',
    label: (
      <span>
        <Icon name="align-right" size="sm" />
      </span>
    ),
    description: 'Droite',
  },
  {
    value: 'center',
    label: (
      <span>
        <Icon name="align-center" size="sm" />
      </span>
    ),
    description: 'Centré',
  },
];

type OptionsModalProps = {|
  onClose: () => void,
  column: ColumnType,
  onChange: ($Shape<ColumnType>) => void,
|};

function OptionsModal({ column, onChange, onClose }: OptionsModalProps) {
  const [editedColumn, setEditedColumn] = React.useState<ColumnType>(
    _.cloneDeep(column)
  );

  return (
    <Modal
      open
      onClose={(buttonName) => {
        if (buttonName === 'ok') onChange(editedColumn);
        onClose();
      }}
      title="Options d'affichage de la ligne"
      modalClassName="is-small"
      size="half"
      modal
    >
      <SchemaContext.Provider
        // $FlowIgnore
        value={{
          schema: ColumnSchema,
        }}
      >
        <ResourceFormEditor
          resource={editedColumn}
          onChange={(newValue) =>
            setEditedColumn({ ...editedColumn, ...newValue })
          }
        />
      </SchemaContext.Provider>
    </Modal>
  );
}
