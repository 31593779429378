// @flow
import * as React from 'react';

import type { ResourceSchema, Resource } from 'src/types/models';

import invariant from 'src/helpers/invariant';
import ResourceAPIConnector, {
  type Callbacks,
} from 'src/helpers/api/ResourceAPIConnector';
import useGlobal from './useGlobal';
import useNotification from './useNotification';
import { getResourceSignature } from 'src/helpers/models/resource';

type Options = {| ...Callbacks |};

type Returned = {|
  resource: ?Resource,
  references: Object,
  loading: boolean,
  connector: ?ResourceAPIConnector,
  setReferences: (Object) => void,
|};

export default function useFetchResource(
  schema: ResourceSchema,
  id: string,
  // $FlowIgnore
  options?: Options = {}
): Returned {
  const { siteId } = useGlobal();
  const { force: forceNotification } = useNotification();

  const resourceAPIConnector = React.useRef<ResourceAPIConnector | null>(null);
  const resourceSignature = getResourceSignature(siteId, schema, id);
  const [resource, setResource] = React.useState<?$Shape<Resource>>(null);
  const [references, setReferences] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchResource = async () => {
      invariant(id, 'Cannot fetch resource with missing id');

      resourceAPIConnector.current = new ResourceAPIConnector(
        siteId,
        schema,
        id,
        {
          onSave: (newResource) => {
            forceNotification({
              type: 'success',
              text: `${schema.label} - ${id} a bien été enregistrée`,
            });

            setResource(newResource);

            if (options.onSave) options.onSave(newResource);
          },
          onDestroy: (deletedResource) => {
            forceNotification({
              type: 'success',
              text: `${schema.label} - ${id} a bien été supprimée`,
            });

            if (options.onDestroy) options.onDestroy(deletedResource);
          },
        }
      );

      try {
        const {
          resource: fetchedResource,
          references: fetchedReferences = {},
        } = await resourceAPIConnector.current.load();
        setResource(fetchedResource);
        setReferences(fetchedReferences);
      } finally {
        setLoading(false);
      }
    };

    if (id) fetchResource();
  }, [resourceSignature, id]);

  return {
    loading,
    resource,
    references,
    setReferences,
    connector: resourceAPIConnector.current,
  };
}
