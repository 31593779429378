import { MODE_PROPERTY } from '../constants';
import DIFFUSION_LIST_ORDER_FIELDS from './DiffusionList';

const ContentsGrid = {
  _id: 'gridList',
  label: 'Grille',
  icon: 'th-large',
  useRequester: true,
  optionsSchema: [
    {
      key: 'name',
      label: "Nom interne de l'emplacement",
      description:
        "Pour identifier cette liste dans l'admin lors de la planification des contenus. Non affiché sur le site.",
      type: 'string',
    },
    {
      key: 'label',
      label: 'Titre de la liste',
      description: 'Sera affiché sur le site',
      type: 'string',
    },
    {
      key: 'labelIfMultiple',
      label: 'Titre de la liste si plusieurs',
      type: 'string',
    },

    MODE_PROPERTY,

    {
      ...MODE_PROPERTY,
      key: 'alternateMode',
      label: "Mode d'affichage alternatif (1 contenu sur 2)",
    },

    {
      key: 'colCount',
      label: 'Nombre de colonnes max.',
      description:
        "Il s'agit en fait de la taille d'un colonne en 1/12ème de page. 0 = automatique",
      type: 'int',
      default: 4,
      min: 0,
      max: 12,
    },
    {
      key: 'itemCount',
      label: 'Nombre de contenus max au total',
      type: 'int',
    },
    {
      key: 'seperatorBetweenRows',
      label: 'Ajouter un séparateur entre les rows',
      type: 'bool',
    },
    {
      key: 'displayMenu',
      label: 'Afficher les filtres',
      type: 'bool',
      default: false,
    },
    {
      key: 'displaySearch',
      label: 'Afficher la recherche',
      type: 'bool',
      default: true,
      condition: { f$: 'obj.displayMenu' },
    },
    {
      key: 'enableLoadMore',
      label:
        'Autoriser le chargement additionnel de contenus (rajoute un bouton "Charger plus")',
      type: 'bool',
      default: false,
      condition: { f$: 'site.featureFlag_pufComponentV2' },
    },
    {
      key: 'menuTags',
      label: 'Boutons filtrer par thème',
      type: 'list',
      itemSchema: {
        type: 'object_ref',
        objectType: 'Tag',
      },
      condition: { f$: 'obj.displayMenu' },
    },
    {
      key: 'menuQuestionCat',
      label: 'Boutons filtrer par thématique',
      type: 'list',
      itemSchema: {
        type: 'object_ref',
        objectType: 'QuestionCategory',
      },
      condition: { f$: 'obj.displayMenu' },
    },
    {
      key: 'menuFilters',
      label: 'Boutons filtres personnalisés',
      type: 'list',
      itemType: ['RequestFilter', 'RequestSort', 'RequestFilterDropdown'],
      condition: { f$: 'obj.displayMenu' },
    },
    {
      key: 'pageId',
      label: 'Lien vers une page sur le titre de la liste',
      type: 'object_id',
      objectType: 'Page',
      condition: { f$: 'obj.label' },
    },
    {
      key: 'list',
      type: 'DiffusionList',
    },
    ...DIFFUSION_LIST_ORDER_FIELDS,
  ],
  frontComponent: 'ContentsGrid',
  forPage: true,
  hasEntities: true,
};

export default ContentsGrid;
