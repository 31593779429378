const ImmanensArticleLink = {
  _id: 'ImmanensArticleLink',
  frontComponent: 'ImmanensArticleLink',
  label: 'Lien vers article liseuse Immanens',
  icon: 'newspaper',
  forPage: true,
  forEntity: false,
  hasEntities: false,
};

export default ImmanensArticleLink;
