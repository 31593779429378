import ImageButton from './ImageButton';
import ImageBlock from './ImageBlock';

const ImagePlugin = {
  title: 'Image',
  type: 'image',
  buttonComponent: ImageButton, // React component to be rendered in the block sidebar
  blockComponent: ImageBlock, // React component for rendering the content block
  blockRenderer(data) {
    // is this used??
    return `<div class="img-wrapper float-${data.get('align')}"><img 
        data-target-content-id="${data.get('id')}"
        src="${data.get('url')}"
        alt="${data.get('caption') || ''}"
        data-url="${data.get('url')}"
        data-author="${data.get('rightsHolder') || ''}"
        width="${data.get('display') || '50%'}"
        data-align="${data.get('align') || 'center'}"
      /></div>`;
  },
};
export default ImagePlugin;
