// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from './';

import { useDelayedUpdate } from 'src/hooks';
import { FieldContext } from './contexts';

import { Input, Textarea } from 'src/components';
import StringCounter from './components/StringCounter';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: string,
  disabled?: boolean,
  placeholder?: string,
|};

export default function StringEditor({
  propertySchema,
  onChange,
  disabled,
  value,
  placeholder,
}: Props): React.Node {
  const { multiline } = propertySchema;
  const [debouncedValue, setDebouncedValue] = useDelayedUpdate(value, onChange);
  const { onFocus: onFieldFocus, onBlur: onFieldBlur } = React.useContext(
    FieldContext
  );

  return (
    <>
      {multiline ? (
        <Textarea
          value={debouncedValue || ''}
          onChange={setDebouncedValue}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
        />
      ) : (
        <Input
          type="text"
          value={debouncedValue || ''}
          onChange={setDebouncedValue}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFieldFocus}
          onBlur={onFieldBlur}
        />
      )}

      <StringCounter propertySchema={propertySchema} value={debouncedValue} />
    </>
  );
}
