// @flow
import * as React from 'react';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from '../';

import { useGlobal } from 'src/hooks';

import ResourceListPropertyEditor from './ResourceListEditor';
import ObjectListPropertyEditor from './ObjectListEditor';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: Array<any>,
  disabled?: boolean,
|};

export default function ListPropertyEditor({
  propertySchema,
  onChange,
  value,
  disabled,
}: Props): React.Node {
  const { globalSchema } = useGlobal();
  const { itemSchema } = propertySchema;
  const itemType = Array.isArray(propertySchema.itemType)
    ? propertySchema.itemType[0]
    : propertySchema.itemType;
  const itemTypeStoredInDB =
    itemType && !!globalSchema[itemType]?.hasAssociatedDBCollection;

  return itemTypeStoredInDB ||
    ['object_ref', 'object_id'].includes(itemSchema?.type) ? (
    <ResourceListPropertyEditor
      propertySchema={propertySchema}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <ObjectListPropertyEditor
      propertySchema={propertySchema}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
}
