// @flow
import * as React from 'react';
import { sortBy, isEqual } from 'lodash';

import type { Resource, ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from './';

import { BULMA_COLORS } from 'src/constants/style';
import API from 'src/helpers/api/API';
import invariant from 'src/helpers/invariant';
import { useGlobal } from 'src/hooks';
import { getSchemaFetchPath } from 'src/helpers/url';

import { Select } from 'src/components';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: string,
  disabled?: boolean,
  portal?: boolean,
|};

type Option = {
  label: string,
  value: string,
};

export default function EnumPropertyEditor({
  propertySchema,
  value: propertyValue,
  onChange,
  disabled,
  portal,
}: Props): React.Node {
  const { siteId, globalSchema } = useGlobal();
  const { values, dataSource, multiple, required } = propertySchema;
  const [options, setOptions] = React.useState<Array<Option>>([]);
  const currentValue = multiple
    ? // $FlowIgnore
      (Array.isArray(propertyValue)
        ? propertyValue
        : propertyValue
        ? [propertyValue]
        : []
      ).map((value) => options.find((option) => isEqual(option.value, value)))
    : options.find(({ value }) => isEqual(value, propertyValue)) || null;

  React.useEffect(() => {
    const fetchDataSourceOptions = async (): Promise<Array<Option>> => {
      invariant(!!dataSource);

      const {
        resource: dataSourceType,
        apiPath: customApiPath,
        query,
        labelPath,
        valuePath,
        listPath,
      } = dataSource;
      const dataSourceSchema = globalSchema[dataSourceType];

      let apiPath;

      if (customApiPath) {
        apiPath = `${siteId}/${customApiPath}`;
      } else {
        apiPath = dataSourceSchema
          ? getSchemaFetchPath(siteId, dataSourceSchema)
          : `${
              dataSourceType === 'schema' ? 'global' : siteId
            }/data/${dataSourceType}`;
      }

      type APIResult = {
        count: number,
        data: Array<Resource> | { [key: typeof listPath]: Array<Resource> },
      };

      const result = (await API.get<APIResult>(apiPath, query)).data;

      const resources: Array<Resource> =
        listPath && !Array.isArray(result) && listPath in result
          ? result[listPath]
          : // $FlowIgnore
            result;

      const rawOptions = resources.map((resource) => {
        const value = resource[valuePath];

        if (labelPath) {
          return {
            label: resource[labelPath],
            value,
          };
        } else if (!!values) {
          const matchingSchemaValue = values.find(
            (schemaValue) => schemaValue.value === value
          );

          if (!matchingSchemaValue) return null;

          const label =
            matchingSchemaValue.label || resource[valuePath] || 'aucun';

          return {
            label,
            value: matchingSchemaValue.value,
          };
        }

        return null;
      });

      const options = ((sortBy(
        rawOptions.filter((option) => !!option && option.value !== undefined),
        'label'
      ): any): Array<Option>);

      return options;
    };

    const getOptions = async () => {
      if (dataSource) {
        setOptions(await fetchDataSourceOptions());
      } else {
        if (!values || !Array.isArray(values)) {
          setOptions([]); //TODO FIX
          return;
        }
        invariant(
          !!values && Array.isArray(values),
          "propertySchema.values should be an array when property type is 'enum'"
        );
        // $FlowIgnore
        setOptions(values);
      }
    };

    getOptions();
  }, []);

  return (
    <Select
      isClearable={!required}
      disabled={disabled}
      isMulti={multiple}
      value={currentValue}
      options={options}
      menuPortalTarget={
        portal ? document.getElementById('select-portal') : undefined
      }
      onChange={(option) => {
        if (multiple) {
          onChange(
            Array.isArray(option)
              ? option.map(({ value }) => value)
              : option.value
          );
        } else {
          onChange(option?.value);
        }
      }}
      styles={{
        option: (provided, { value }) => {
          return {
            ...provided,
            ...getOptionCustomStyle(value),
          };
        },
      }}
    />
  );
}

const getOptionCustomStyle = (value: any): Object => {
  switch (value) {
    case 'prepub':
      return {
        // backgroundColor: BULMA_COLORS.WARNING,
        // color: 'white',
        color: BULMA_COLORS.WARNING,
        fontWeight: 'bold',
      };
    case 'published':
      return {
        // backgroundColor: BULMA_COLORS.SUCCESS,
        // color: 'white',
        color: BULMA_COLORS.SUCCESS,
        fontWeight: 'bold',
      };
    case 'archived':
      return {
        // backgroundColor: BULMA_COLORS.DANGER,
        // color: 'white',
        color: BULMA_COLORS.DANGER,
        fontWeight: 'bold',
      };
    default:
      return {};
  }
};
