// @flow
import * as React from 'react';
import { map } from 'lodash';

import type { Resource, ResourceSchema, ResourceRef } from 'src/types';

import { useGlobal } from 'src/hooks';
import invariant from 'src/helpers/invariant';
import SchemaContext from 'src/pages/contexts/SchemaContext';
import { getResourcePath } from 'src/helpers/url';
import { makeRef } from 'src/schema/Schema';

import { Flex, Select, Text, EmptyState } from 'src/components';
import Item from './Item';
import ResourceTable from 'src/pages/components/resource/ResourceTable';

type Props = {|
  onChange: (Array<Resource>) => any,
  value: Array<ResourceRef>,
  multi?: boolean,
  resourceSchemas: Array<ResourceSchema>,
  title?: string,
|};

export default function ResourceSelector({
  onChange: onChangeProp,
  value: selectedResources = [],
  resourceSchemas,
  multi,
  title,
}: Props): React.Node {
  const { siteId } = useGlobal();
  const [selectedSchema, setSelectedSchema] = React.useState(
    resourceSchemas.length === 1 ? resourceSchemas[0] : null
  );
  const selectedIds = React.useMemo(
    () => selectedResources.map((resource) => resource._id),
    [map(selectedResources, '_id').join('-')]
  );

  const onChange = React.useCallback(
    (selectedResource: Resource) => {
      if (multi) {
        if (selectedIds.includes(selectedResource._id)) {
          onChangeProp(
            // $FlowIgnore
            selectedResources.filter(
              (resource) => resource._id !== selectedResource._id
            )
          );
        } else {
          // $FlowIgnore
          onChangeProp([
            ...selectedResources,
            makeRef(selectedResource, { resourceSchema: selectedSchema }),
          ]);
        }
      } else {
        selectedResource?._id === selectedResources[0]?._id
          ? // $FlowIgnore
            onChangeProp(null)
          : // $FlowIgnore
            onChangeProp(
              makeRef(selectedResource, { resourceSchema: selectedSchema })
            );
      }
    },
    [selectedResources.length]
  );

  invariant(
    resourceSchemas.length > 0,
    'resourceSchemas cannot be empty in ResourceSelector'
  );

  return (
    <div className="resource-selector">
      <Text element="h4" size={3}>
        {title || 'Sélection de ressources'}
      </Text>

      <div
        className="selected-resources"
        style={{ marginTop: 20, marginBottom: 40 }}
      >
        <Text element="h4" size={5} style={{ marginBottom: 5 }}>
          Ressources sélectionnées
        </Text>

        {selectedResources.length > 0 ? (
          <Flex verticalAlign>
            {selectedResources.map((resource) => {
              return (
                <Item
                  key={resource._id}
                  // $FlowIgnore
                  resource={resource}
                  style={{ marginRight: 10 }}
                  // $FlowIgnore
                  onRemove={() => onChange(resource)}
                />
              );
            })}
          </Flex>
        ) : (
          <EmptyState
            title="Aucune resource sélectionnée pour l'instant"
            icon="search"
            style={{ padding: '30px 20px' }}
            size="normal"
          />
        )}
      </div>

      {resourceSchemas.length > 1 && (
        <Flex
          style={{
            justifyContent: 'flex-end',
            marginBottom: 20,
          }}
        >
          <Select
            placeholder="Type de ressource à afficher"
            options={resourceSchemas.map((resourceSchema) => ({
              label: resourceSchema.label,
              value: resourceSchema._id,
              schema: resourceSchema,
            }))}
            value={
              selectedSchema
                ? {
                    label: selectedSchema.label,
                    id: selectedSchema._id,
                    schema: selectedSchema,
                  }
                : null
            }
            onChange={(option) => setSelectedSchema(option?.schema)}
            menuPortalTarget={document.getElementById('select-portal')}
            styles={{
              container: (provided) => ({ ...provided, width: 300 }),
            }}
          />
        </Flex>
      )}

      {!!selectedSchema ? (
        <SchemaContext.Provider
          value={{
            schema: selectedSchema,
            path: getResourcePath(siteId, selectedSchema),
          }}
        >
          <div className="resource-table-wrapper">
            <ResourceTable
              openInANewTab
              onSelect={onChange}
              selectedIds={selectedIds}
            />
          </div>
        </SchemaContext.Provider>
      ) : (
        <EmptyState
          title="Sélectionner un type de ressource"
          icon="hand-pointer"
          style={{ padding: '80px 20px' }}
        />
      )}
    </div>
  );
}
