const Contributors = {
  _id: 'contributors_therealone',
  frontComponent: 'Contributors',
  label: 'Contributeurs',
  icon: 'user',
  optionsSchema: [
    {
      key: 'mode',
      label: "Mode d'affichage",
      type: 'enum',
      values: [
        // TODO import from Front / ENTITY_COMPONENTS_MAP
        { value: 'list', label: 'Liste' },
        { value: 'count', label: 'Nombre' },
        { value: 'bar', label: 'Barre de progression' },
      ],
      required: true, // unusual
    },
    {
      key: 'prefix',
      label: 'Préfixe',
      type: 'string',
      condition: { f$: "obj.mode === 'count'" },
    },
    {
      key: 'suffix',
      label: 'Suffixe',
      type: 'string',
      condition: { f$: "obj.mode === 'count'" },
    },
    {
      key: 'goal',
      label: 'Nombre de contributeurs espérés',
      type: 'int',
      min: 0,
      required: true,
      condition: { f$: "obj.mode === 'bar'" },
    },
  ],
  forPage: true,
  site: 'demo', //"template1 / infolibre", // TODO
};

export default Contributors;
