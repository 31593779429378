// @flow
// Fuse.js, a lightweight fusy search, see documentation -> https://fusejs.io/api

import * as React from 'react';
import classnames from 'classnames';

import type { Component } from '../../types';

import Tooltip from 'rc-tooltip';
import { Text, Button, Icon, Flex, Tag, Draggable } from 'src/components';

type Props = {|
  title: string,
  onPickComponent?: (Component) => void,
  components: Array<Component>,
  forceExpand: boolean,
|};

export default function Section({
  title,
  onPickComponent,
  components,
  forceExpand,
}: Props): React.Node {
  const [expanded, setExpanded] = React.useState(true);
  const draggable = !onPickComponent;

  if (components.length === 0) {
    return null;
  }

  return (
    <div
      className="component-picker-section"
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Flex
        verticalAlign
        style={{
          justifyContent: 'space-between',
          marginBottom: forceExpand || expanded ? 10 : 0,
          alignItems: 'center',
        }}
      >
        <Text element="h4" size={6} weight="bold">
          {title}
        </Text>

        <Button
          isText
          onClick={() => setExpanded(!expanded)}
          size="small"
          disabled={forceExpand}
          style={{ marginTop: 4 }}
        >
          <Icon name={expanded || forceExpand ? 'angle-down' : 'angle-up'} />
        </Button>
      </Flex>

      {(forceExpand || expanded) && components.length > 0 && (
        <div className="components">
          {components.map((item) => {
            const { icon, label, description, _id } = item;

            const tag = (
              <Tag
                color="dark"
                additionalClassName={classnames('component', {
                  draggable,
                })}
                style={{ marginRight: 5, marginBottom: 5 }}
              >
                <span
                  onClick={
                    !!onPickComponent ? () => onPickComponent(item) : undefined
                  }
                >
                  {icon && <Icon name={icon} style={{ marginRight: 3 }} />}
                  {label}
                </span>
              </Tag>
            );

            return (
              <Tooltip
                overlay={description || ''}
                placement="topRight"
                trigger={(description && ['hover', 'click']) || ''}
                key={_id || label}
              >
                {draggable ? (
                  <Draggable
                    type="component"
                    data={item}
                    style={{ display: 'inline-block' }}
                  >
                    {tag}
                  </Draggable>
                ) : (
                  tag
                )}
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
}
