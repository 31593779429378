// this is the default LinkInput that handles `LINK`-entities:
import ExternalLinkInput from './ExternalLinkInput';
// import InternalLinkInput from './InternalLinkInput';

const entityInputs = {
  LINK: ExternalLinkInput,
  // INTERNAL_PAGE_LINK: InternalLinkInput,
};

export default entityInputs;
