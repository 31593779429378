// Tag list (like a select multiple) component with simple props

import React, { Component } from 'react';

import { OldLabel } from 'src/components';

export default class TagList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      label: props.label || null,
      required: props.required || false,
      tagList: props.tagList || null,

      value: props.initialValue ? [props.initialValue] : [],
      error: null,
      hasFocus: false,

      hidden: props.hidden || false,
    };
  }

  resetField() {
    this.setState({ value: [this.props.initialValue] || false });
  }

  handleFocus(e, hasFocus) {
    this.setState({ hasFocus: hasFocus });
  }

  onChange(e) {
    let tagClickedValue = e.target.getAttribute('data-value');
    let value = this.state.value;

    if (value.indexOf(tagClickedValue) === -1) {
      value.push(tagClickedValue);
    } else {
      value.splice(value.indexOf(tagClickedValue), 1);
    }

    this.setState({ value: value });
  }

  render() {
    let tagList = this.state.tagList;

    let value = this.state.value;
    let error = this.state.error;
    let disabled = this.props.disabled || false;

    let formElementClass = 'FormElement FormElement-input-tags-list-element';
    formElementClass += disabled ? ' FormElement--disabled' : '';
    formElementClass += error ? ' FormElement--error' : '';
    formElementClass += this.state.hasFocus ? ' FormElement--focus' : '';

    return (
      <div
        className={formElementClass}
        onFocus={(e) => this.handleFocus(e, true)}
        onBlur={(e) => this.handleFocus(e, false)}
        tabIndex="0"
      >
        <div className="flex-wrapper flex-column FormElement-inner">
          <OldLabel {...this.props} />

          <div className="FormElement-tags-list">
            {tagList &&
              tagList.map((tag, i) => (
                <span
                  className={
                    value.indexOf(tag.value) !== -1
                      ? 'FormElement-tags-list-item FormElement-tags-list-item--active'
                      : 'FormElement-tags-list-item'
                  }
                  data-value={tag.value}
                  key={i}
                  onClick={(e) => this.onChange(e)}
                >
                  {tag.label}
                </span>
              ))}
          </div>
        </div>

        {error && <div className="FormElement-error-message">{error}</div>}
      </div>
    );
  }
}
