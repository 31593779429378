import * as React from 'react';

import { Tag } from 'src/components';

export default function ObjectIdRenderer({
  mode = 'cell',
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (!value) return null;

  const [objectType] = Array.isArray(propertySchema.objectType)
    ? propertySchema.objectType
    : [propertySchema.objectType];

  if (!objectType) return null;

  return (
    <Tag>
      {objectType} - {value}
    </Tag>
  );
}
