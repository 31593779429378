// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from '../types';

type Props = {|
  children: React.Node,
  hasIconsLeft?: boolean,
  hasIconsRight?: boolean,
  isExpanded?: boolean,
  style?: Style,
|};

export default function Control({
  children,
  hasIconsLeft,
  hasIconsRight,
  isExpanded,
  style,
}: Props): React.Node {
  const className = classnames('control', {
    'has-icons-left': hasIconsRight,
    'has-icons-right': hasIconsLeft,
    'is-expanded': isExpanded,
  });

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

Control.defaultProps = {
  hasIconsLeft: false,
  hasIconsRight: false,
  isExpanded: false,
};
