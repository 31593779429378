// @flow
import type { GlobalSchema } from 'src/types/models';
import type { Section } from 'src/types/routing';
import type { CountByCollection, RouteItem } from './';

import {
  toPath,
  pluralizeResourceType,
  pluralizeResourceLabel,
} from 'src/helpers/string';
import RESOURCE_TYPES from '../../constants/resources';
import API from 'src/helpers/api/API';
import { getSectionSchemas } from 'src/helpers/models/schema';

type buildSchemaRouteItemsOptions = {|
  globalSchema: GlobalSchema,
  siteId: string,
  countByCollection: CountByCollection,
|};
export const buildSchemaRouteItems = (
  section: Section,
  { globalSchema, siteId, countByCollection }: buildSchemaRouteItemsOptions
): Array<RouteItem> => {
  const sectionSchemas = getSectionSchemas(section.id, {
    siteId,
    globalSchema,
  });

  return sectionSchemas.map((schema) => {
    const { label, _id: type } = schema;
    const count = !schema.isSingleton
      ? countByCollection[schema._id]
      : undefined;

    return {
      pathname: `${toPath(pluralizeResourceType(type))}`,
      label: !!label
        ? pluralizeResourceLabel(type, label)
        : pluralizeResourceType(type),
      exact: true,
      filters: RESOURCE_TYPES[schema._id]?.filters || [],
      count,
    };
  });
};

export const fetchCountByCollection = (
  siteId: string
): Promise<CountByCollection> =>
  API.get(`${siteId}/collections`, {
    withCount: true,
  });
