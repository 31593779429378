// @flow
import * as React from 'react';

import type { Resource } from 'src/types/models';

import { Button, Flex, Icon } from 'src/components';

type Props = {|
  resource: Resource,
  toggleJSON: (any) => void,
  historyStackLength: number,
  historyStackIndex: number,
  setHistoryStackIndex: (newIndex: number) => any,
  isResourcePersisted: boolean,
  dirty: boolean,
  saving: boolean,
  canDelete: boolean,
  canRestoreDaft: boolean,
  jsonEditorActive: boolean,
  onRestoreDraft?: () => Promise<Resource>,
  onSave?: () => Promise<Resource>,
  onDelete?: () => any,
  onDuplicate?: () => void,
  onCancel?: () => void,
  onClose?: () => void,
|};

export default function Footer({
  resource,
  toggleJSON,
  historyStackLength,
  historyStackIndex,
  setHistoryStackIndex,
  isResourcePersisted,
  dirty,
  saving,
  canRestoreDaft,
  jsonEditorActive,
  onRestoreDraft,
  onSave,
  onDelete,
  onDuplicate,
  onCancel,
  onClose,
}: Props): React.Node {
  // return <div className="resource-form-footer">Footer</div>;

  const undoCount = historyStackIndex;
  const redoCount = historyStackLength - (historyStackIndex + 1);

  return (
    <div className="resource-form-footer">
      <Flex style={{ justifyContent: 'space-between' }}>
        <Flex>
          {!!onClose && (
            <Button
              onClick={onClose}
              style={{ marginRight: 10 }}
              title="Fermer"
            >
              <Icon name="arrow-left" />
            </Button>
          )}

          <Button
            onClick={toggleJSON}
            style={{ marginRight: 10 }}
            title="Éditer la version JSON"
            isActive={jsonEditorActive}
          >
            <Icon name="code" />
          </Button>

          {!!onRestoreDraft && (
            <Button
              onClick={onRestoreDraft}
              title="Restorer la version initiale"
              disabled={!canRestoreDaft}
            >
              <Icon name="trash-restore" />
            </Button>
          )}
        </Flex>

        <Flex verticalAlign>
          <Button
            onClick={() => setHistoryStackIndex(historyStackIndex - 1)}
            style={{ marginRight: 10, position: 'relative' }}
            disabled={undoCount === 0}
            isLight
            badge={undoCount > 0 ? `(${undoCount})` : undefined}
          >
            <Icon name="undo" style={{ marginRight: 0 }} />
          </Button>

          <Button
            onClick={() => setHistoryStackIndex(historyStackIndex + 1)}
            disabled={redoCount === 0}
            badge={redoCount > 0 ? `(${redoCount})` : undefined}
            isLight
          >
            <Icon name="redo" />
          </Button>
        </Flex>

        <Flex verticalAlign>
          {!!onDelete && (
            <Button
              onClick={onDelete}
              color="danger"
              style={{ marginRight: 10 }}
              isLight
            >
              <Icon name="trash" />
            </Button>
          )}

          {!!onDuplicate && (
            <Button onClick={onDuplicate} style={{ marginRight: 10 }}>
              <Icon name="copy" />
            </Button>
          )}

          {/* What does onCancel do versus onClose ? */}
          {onCancel && (
            <Button onClick={onCancel} style={{ marginRight: 10 }}>
              Annuler
            </Button>
          )}

          <Button
            onClick={onSave}
            disabled={saving || (isResourcePersisted && !dirty)}
            color="success"
            isLight
          >
            {isResourcePersisted ? 'Sauvegarder' : 'Créer'}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
}
