// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from 'src/components/types';

type DropdownTriggerProps = {|
  children: React.Node,
  popperRef?: React.Ref<any>,
  style?: Style,
  additionalClassName?: string,
|};

export default function DropdownTrigger({
  children,
  popperRef,
  additionalClassName,
  style,
}: DropdownTriggerProps): React.Node {
  const className = classnames('dropdown-trigger', additionalClassName);

  return (
    <div className={className} style={style} ref={popperRef}>
      {children}
    </div>
  );
}
