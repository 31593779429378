// @flow
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isString, isNumber } from 'lodash';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from '../';

import invariant from 'src/helpers/invariant';
import { useGlobal } from 'src/hooks';
import { getResourceLabel } from 'src/helpers/models/schema';
import { update, remove, swap } from 'src/helpers/array';

import { Text, Button, Icon, Centered, Flex, Tag } from 'src/components';
import PropertyEditor from '../';

const SIMPLE_TYPES = [
  'string',
  'int',
  'richtext',
  'float',
  'bool',
  'slug',
  'color',
];

const isSimpleTypeSchema = (schema) => SIMPLE_TYPES.includes(schema?.type);
const getSimpleTypeDefaultValue = (type) => {
  switch (type) {
    case 'string':
      return '';
    case 'int':
      return 0;
    case 'richtext':
      return null;
    case 'float':
      return 0;
    case 'bool':
      return 0;
    case 'slug':
      return '';
    case 'color':
      return null;
    default:
      return null;
  }
};

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: Array<Object>,
  disabled?: boolean,
|};

export default function ObjectListPropertyEditor({
  propertySchema,
  onChange,
  value: items = [],
  disabled,
}: Props): React.Node {
  const { globalSchema } = useGlobal();

  const providedItemTypes = Array.isArray(propertySchema.itemType)
    ? propertySchema.itemType
    : [propertySchema.itemType].filter((type) => !!type);

  const itemSchemas = (propertySchema.itemSchema
    ? [propertySchema.itemSchema]
    : // $FlowIgnore
      providedItemTypes.map((type) => globalSchema[type])
  ).filter((schema) => !!schema);

  invariant(
    itemSchemas.length > 0,
    'One schema is needed at least in ObjectListEditor'
  );

  const [expandedItems, setExpandedItems] = React.useState({});
  const toggleItemExpansion = React.useCallback(
    (itemId) =>
      setExpandedItems((previousState) => ({
        ...previousState,
        [itemId]: !previousState[itemId],
      })),
    []
  );

  const createNewItem = React.useCallback(
    (itemSchemaArg) => {
      const itemId = uuidv4();

      if (isSimpleTypeSchema(itemSchemaArg)) {
        onChange([...items, getSimpleTypeDefaultValue(itemSchemaArg.type)]);
        return;
      }

      const itemSchema = itemSchemaArg._id
        ? itemSchemas.find((schema) => schema._id === itemSchemaArg._id)
        : itemSchemaArg;

      invariant(!!itemSchema);

      const newItem = Object.fromEntries([
        ['_id', itemId],
        ['_cls', itemSchemaArg._id],
        ...(itemSchema.propertiesList || []).map((property) => [
          property.key,
          property.default,
        ]),
      ]);
      onChange([...items, newItem]);
      toggleItemExpansion(itemId);
    },
    [items]
  );

  const updateItem = React.useCallback(
    (itemIndex, item) => {
      onChange(update(items, itemIndex, item));
    },
    [items]
  );

  const deleteItem = React.useCallback(
    (itemIndex) => {
      onChange(remove(items, itemIndex));
    },
    [items]
  );

  const swapItems = React.useCallback(
    (firstIndex, secondIndex) => {
      onChange(swap(items, firstIndex, secondIndex));
    },
    [items]
  );

  const getItemKey = (item) => {
    if (!item) return null;
    if (item.id) return item.id;
    if (item._id) return item._id;
    if (isString(item) || isNumber(item)) return item;
    return null;
  };

  return (
    <div className="object-list-editor">
      {items.map((item, itemIndex) => {
        const itemId = makeItemId(item, itemIndex);
        const itemExpanded = !!expandedItems[itemId];
        const itemSchema =
          itemSchemas.length > 1
            ? itemSchemas.find((schema) => schema._id === item._cls)
            : itemSchemas[0];

        const itemKey = getItemKey(item) || itemIndex;

        return (
          <ObjectListPropertyEditorItem
            key={itemKey}
            index={itemIndex}
            item={item}
            itemSchema={itemSchema}
            expanded={itemExpanded}
            disabled={disabled}
            isFirstItem={itemIndex === 0}
            isLastItem={itemIndex === items.length - 1}
            toggleItemExpansion={toggleItemExpansion}
            deleteItem={deleteItem}
            swapItems={swapItems}
            updateItem={updateItem}
          />
        );
      })}

      <Centered additionalClassName="actions-wrapper">
        {itemSchemas.map((itemSchema) => (
          <Button
            onClick={() => createNewItem(itemSchema)}
            size="small"
            key={itemSchema._id}
            additionalClassName="add-button"
            disabled={disabled}
          >
            <Icon name="plus" style={{ marginRight: 3 }} />{' '}
            {`Ajout d'un ${itemSchema.label || propertySchema.label}`}
          </Button>
        ))}
      </Centered>
    </div>
  );
}

const makeItemId = (item, index: number): string =>
  item._id || item.key || item.label || index;

type ObjectListPropertyEditorItemProps = {|
  item: any,
  index: number,
  disabled?: boolean,
  itemSchema: any,
  expanded: boolean,
  isFirstItem: boolean,
  isLastItem: boolean,
  toggleItemExpansion: (itemId: string) => void,
  deleteItem: (index: number) => void,
  swapItems: (firstIndex: number, secondIndex: number) => void,
  updateItem: (index: number, newItem: any) => void,
|};

export function ObjectListPropertyEditorItem({
  item,
  index,
  expanded,
  disabled,
  isFirstItem,
  isLastItem,
  itemSchema,
  toggleItemExpansion,
  deleteItem,
  swapItems,
  updateItem,
}: ObjectListPropertyEditorItemProps): React.Node {
  const { user } = useGlobal();

  const itemPositionComponents = (
    <div className="actions">
      <Button
        color="danger"
        isLight
        size="small"
        style={{ marginRight: 10 }}
        onClick={() => !disabled && deleteItem(index)}
        disabled={disabled}
      >
        <Icon name="times" />
      </Button>
      <Button
        style={{ marginRight: 10 }}
        size="small"
        color="text"
        disabled={disabled || isFirstItem}
        onClick={() => !disabled && swapItems(index, index - 1)}
      >
        <Icon name="angle-up" />
      </Button>
      <Button
        size="small"
        color="text"
        disabled={disabled || isLastItem}
        onClick={() => !disabled && swapItems(index, index + 1)}
      >
        <Icon name="angle-down" />
      </Button>
    </div>
  );

  if (isSimpleTypeSchema(itemSchema))
    return (
      <div className="object-list-editor-item">
        <Flex style={{ justifyContent: 'space-between', marginBottom: 10 }}>
          <div />
          {itemPositionComponents}
        </Flex>

        <PropertyEditor
          // $FlowIgnore
          propertySchema={itemSchema}
          value={item}
          onChange={(schema, newItem) => updateItem(index, newItem)}
          disabled={disabled}
        />
      </div>
    );

  const itemId = makeItemId(item, index);
  const itemLabel = getResourceLabel(item, itemSchema) || JSON.stringify(item);
  invariant(!!itemSchema, 'Item schema should always exist');

  return (
    <div key={itemId} className="object-list-editor-item">
      <Flex style={{ justifyContent: 'space-between', marginBottom: 10 }}>
        <Flex verticalAlign additionalClassName="item-label-wrapper">
          <Button
            size="small"
            style={{ marginRight: 10 }}
            onClick={() => toggleItemExpansion(itemId)}
            isText
          >
            {expanded ? 'Replier' : 'Déplier'}
          </Button>
          <Text
            isTitle
            element="h5"
            size={6}
            style={{ marginBottom: 0 }}
            additionalClassName="item-label"
          >
            {user.isDeveloper &&
              item._cls === 'ObjectPropertySchema' &&
              item.key && (
                <Tag color="info" light style={{ marginRight: 5 }}>
                  {item.key}
                </Tag>
              )}

            {itemLabel}
          </Text>
        </Flex>

        {itemPositionComponents}
      </Flex>

      {expanded && (
        // $FlowIgnore
        <PropertyEditor
          // $FlowIgnore
          propertySchema={itemSchema}
          value={item}
          onChange={(schema, newItem) => updateItem(index, newItem)}
          disabled={disabled}
        />
      )}
    </div>
  );
}
