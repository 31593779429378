const EntityImage = {
  _id: 'image',
  label: 'Image du contenu',
  icon: 'file-image',
  frontComponent: 'EntityImage',
  optionsSchema: [
    {
      key: 'useImage2',
      label: "Utiliser l'image alternative (si disponible)",
      type: 'bool',
      default: false,
    },
    {
      key: 'ratioWH',
      label: 'Ratio',
      type: 'enum',
      values: [
        { label: '16/9', value: 16 / 9 },
        { label: '4/3', value: 4 / 3 },
        { label: 'carré', value: 1 },
        { label: 'portrait', value: 3 / 4 },
        { label: 'cinemascope', value: 2.35 },
        { label: 'bandeau', value: 5 },
      ],
    },
    {
      key: 'cropAlgo',
      label: 'Type de recadrage',
      type: 'enum',
      values: [
        { label: 'par défaut', value: 0 },
        { label: 'entropie', value: 1 },
        { label: "zone d'attention", value: 2 },
        { label: 'centré', value: 3 },
      ],
      default: 0,
    },
    { key: 'width', label: 'Largeur en pixels', type: 'int' },
    {
      key: 'linkToEntity',
      label: "Créer un lien vers la page de l'objet",
      type: 'bool',
      default: true,
    },
    {
      key: 'displayButtons',
      label: 'Afficher les boutons vidéo / texte',
      type: 'bool',
      default: false,
    },
  ],
  forEntity: true,
};

export default EntityImage;
