import * as React from 'react';

import type { ResourceType, InstanceName } from 'src/types/models';
import type { ExportDescription } from 'src/types/models/exports';

import {
  EVENT_EXPORT_COLUMNS_EDIIS,
  USER_EXPORT_COLUMNS_EDIIS,
  PAYMENT_EXPORT_COLUMNS_EDIIS,
  FORMULA_EXPORT_COLUMNS_EDIIS,
} from './ediis';

export const getExportSettings = (
  instanceName: InstanceName,
  resourceType: ResourceType,
  beginTs: ?number,
  endTs: ?number
): Array<ExportDescription> => {
  const BASIC_PARAMS = {
    outputFormat: 'csv',
    depDepth: 1,
    adminFilter: {
      createTs: (beginTs || endTs) && {
        min: beginTs,
        max: endTs,
      },
    },
    __admin: true,
  };

  return (
    {
      Purchase: [
        {
          label: 'Export EDIIS 2 (sans les cadeaux)',
          description: (
            <div className="description">
              <p style={{ marginBottom: 20 }}>
                Récupère tous les achats qui
                <ul>
                  <li>ont un status ok</li>
                  <li>ont un utilisateur bénéficiaire</li>
                  <li>ne sont pas des cadeaux</li>
                  <li>ont été créés entre les dates sélectionnées</li>
                  <li>ont un produit attaché</li>
                  <li>ont un payeur</li>
                </ul>
              </p>
              <p>
                {
                  "L'adresse de facturation vaut celle du paiement ou celle de l'utilisateur payeur"
                }
                {
                  "L'adresse de livraison vaut celle du paiement ou l'adresse de facturation ou celle de l'utilisateur payeur"
                }
              </p>
            </div>
          ),
          params: {
            exportId: 'PURCHASE_EDIIS_EXPORT',
            filter: {
              isGift: false,
              createTs: (beginTs || endTs) && {
                min: beginTs,
                max: endTs,
              },
            },
          },
        },

        {
          label: 'Export EDIIS 2 avec cadeaux (utilisés)',
          description: (
            <div className="description">
              <p style={{ marginBottom: 20 }}>
                Récupère tous les achats qui
                <ul>
                  <li>ont un status ok</li>
                  <li>ont un utilisateur bénéficiaire</li>
                  <li>sont des cadeaux</li>
                  <li>ont été créés entre les dates sélectionnées</li>
                  <li>ont un produit attaché</li>
                  <li>ont un payeur</li>
                </ul>
              </p>
              <p>
                {
                  "L'adresse de facturation vaut celle du paiement ou celle de l'utilisateur payeur"
                }
                {
                  "L'adresse de livraison vaut celle du paiement ou l'adresse de facturation ou celle de l'utilisateur payeur"
                }
              </p>
            </div>
          ),
          params: {
            exportId: 'PURCHASE_EDIIS_EXPORT',
            filter: {
              isGift: true,
              createTs: (beginTs || endTs) && {
                min: beginTs,
                max: endTs,
              },
            },
          },
        },

        {
          label: 'Super export',
          description: (
            <div className="description">(Description à remplir)</div>
          ),
          params: {
            exportId: 'PURCHASE_SUPER_EXPORT',
            filter: {
              isGift: true,
              createTs: (beginTs || endTs) && {
                min: beginTs,
                max: endTs,
              },
            },
          },
        },
      ],

      Payment: [
        {
          label: 'Exporter EDIIS',
          description: (
            <div className="description">(Description à remplir)</div>
          ),
          params: {
            ...BASIC_PARAMS,
            sortBy: { createTs: -1 },
            columns: PAYMENT_EXPORT_COLUMNS_EDIIS,
          },
        },
      ],

      User: [
        {
          label: 'Exporter EDIIS',
          description: (
            <div className="description">(Description à remplir)</div>
          ),
          params: {
            ...BASIC_PARAMS,
            sortBy: { createTs: -1 },
            columns: USER_EXPORT_COLUMNS_EDIIS,
          },
        },
      ],

      Event: [
        {
          label: 'Exporter EDIIS',
          description: (
            <div className="description">(Description à remplir)</div>
          ),
          params: {
            ...BASIC_PARAMS,
            sortBy: { createTs: -1 },
            columns: EVENT_EXPORT_COLUMNS_EDIIS,
          },
        },
      ],

      SubscriptionFormula: [
        {
          label: 'Exporter EDIIS',
          description: (
            <div className="description">(Description à remplir)</div>
          ),
          params: {
            ...BASIC_PARAMS,
            sortBy: { createTs: -1 },
            columns: FORMULA_EXPORT_COLUMNS_EDIIS,
          },
        },
      ],
    }[resourceType] || []
  );
};
