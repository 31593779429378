import { MODE_PROPERTY } from '../constants';
import DIFFUSION_LIST_ORDER_FIELDS from './DiffusionList';

const ContentSlider = {
  _id: 'contentsSlider',
  label: 'Liste défilante',
  icon: 'angle-double-right',
  description: 'Liste de contenus ("slider") défilant horizontalement',
  frontComponent: 'ContentsSlider',
  useRequester: true,
  optionsSchema: [
    {
      key: 'name',
      label: "Nom interne de l'emplacement",
      description:
        "Pour identifier cette liste dans l'admin lors de la planification des contenus. Non affiché sur le site.",
      type: 'string',
    },
    {
      key: 'label',
      label: 'Titre de la liste',
      description: 'Sera affiché sur le site',
      type: 'string',
    },
    {
      key: 'labelIfMultiple',
      label: 'Titre de la liste si plusieurs',
      type: 'string',
    },

    MODE_PROPERTY,
    {
      key: 'itemCount',
      label: 'Nombre de contenus max au total',
      type: 'int',
    },
    {
      key: 'showCount',
      label: 'Nombre de contenus à afficher',
      type: 'int',
    },
    {
      key: 'speed',
      label: 'Vitesse de slide',
      type: 'enum',
      default: 300,
      values: [
        { label: 'lent', value: 1000 },
        { label: 'moyen', value: 500 },
        { label: 'rapide', value: 300 },
        { label: 'très rapide', value: 150 },
      ],
    },
    {
      key: 'list',
      type: 'DiffusionList',
    },
    ...DIFFUSION_LIST_ORDER_FIELDS,
  ],
  forPage: true,
  hasEntities: true,
};

export default ContentSlider;
