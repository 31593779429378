import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

export default function StripeSubscriptionRenderer(props) {
  return (
    (props && (
      <span
        className={classnames('StripeSubscription', 'status_' + props.status, {
          current:
            props.current_period_end > moment().unix() &&
            props.current_period_start <= moment().unix(),
          test: !props.livemode,
        })}
      >
        <div className="plan-name">
          {props.plan && props.plan.nickname}
          {props.status === 'trialing' && " (période d'essai)"}
        </div>{' '}
        depuis le {moment.unix(props.start).format('LLL')}
        {props.status !== 'active' &&
          props.status !== 'trialing' &&
          `(status: ${props.status})`}
      </span>
    )) ||
    null
  );
}
