// @flow
import * as React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import type { ResourceType } from 'src/types/models';
import API from 'src/helpers/api/API';

import { getResourcePath } from 'src/helpers/url';
import { buildQueryString } from 'src/helpers/misc';
import config from 'src/helpers/api/config';
import { getExportSettings } from './specialExports';
import { useGlobal, useNotification, useSchemaContext } from 'src/hooks';

import { Text, Flex, Button, Icon } from 'src/components';

type Props = {|
  beginTs: ?number,
  endTs: ?number,
|};

export default function PresetExports({ beginTs, endTs }: Props): React.Node {
  const { siteId } = useGlobal();
  const { schema } = useSchemaContext();

  return (
    <div className="preset-exports">
      {getExportSettings(siteId, schema._id, beginTs, endTs).map(
        ({ params, label, description }, i: number) => (
          <ExportButton
            siteId={siteId}
            description={description}
            resource={schema._id}
            params={params}
            beginTs={beginTs}
            endTs={endTs}
            label={label}
            key={schema._id + '_' + i}
          />
        )
      )}
    </div>
  );
}

function ExportButton({
  siteId,
  resource,
  params,
  label,
  description,
  beginTs,
  endTs,
}: {
  siteId: string,
  resource: ResourceType,
  params: any,
  label: string,
  description: React.Node,
  beginTs: ?number,
  endTs: ?number,
}) {
  const history = useHistory();
  const { globalSchema } = useGlobal();
  const exportSchema = globalSchema.Export;

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const { exportId } = params;
  const { push: pushNotification } = useNotification();
  const useNewExportSystem = [
    'PURCHASE_SUPER_EXPORT',
    'PURCHASE_EDIIS_EXPORT',
  ].includes(exportId);

  const generateExport = React.useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const generatedDataExport = await API.post(`${siteId}/exports`, {
        beginTs,
        endTs,
        ...params,
        resource,
      });
      pushNotification({
        type: 'success',
        text: 'Votre export est prêt, vous pouvez maintenant le télécharger',
      });
      history.push(
        getResourcePath(siteId, exportSchema, generatedDataExport._id)
      );
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setError, siteId, params, resource, pushNotification]);

  return (
    <div className={classnames('export', label)}>
      <Text isTitle size={6} element="h4">
        {label}
      </Text>

      {description}

      <Flex style={{ justifyContent: 'end' }}>
        {useNewExportSystem ? (
          <Button isLoading={loading} onClick={generateExport}>
            <Icon name="download" style={{ marginRight: 5 }} />
            Exporter
          </Button>
        ) : (
          <Button
            target="_blank"
            rel="noopener noreferrer"
            href={`${
              config.API_URL
            }/admin/${siteId}/custom-exports?${buildQueryString({
              ...params,
              resource,
            })}`}
          >
            <Icon name="download" style={{ marginRight: 5 }} />
            Télécharger
          </Button>
        )}
      </Flex>

      {error && (
        <Text color="danger" element="p" style={{ marginTop: 10 }}>
          Une erreur est survenue durant votre export
          <br />
          <b>
            {error.code} : {error.message}
          </b>
        </Text>
      )}
    </div>
  );
}
