import * as React from 'react';

import { Tag } from 'src/components';

export default function BoolRenderer({
  mode,
  value,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (typeof value === 'undefined') return <Tag>Non défini</Tag>;

  return (
    <Tag color={value ? 'success' : 'danger'}>{value ? 'Oui' : 'Non'}</Tag>
  );
}
