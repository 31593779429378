import _ from 'lodash';

export default function ListRenderer({
  mode = 'cell',
  value: list,
  propertySchema,
  resource,
  resourceSchema,
}) {
  if (mode !== 'cell') return null;
  if (!list || list.length === 0) return null;

  return _.filter(
    _.map(list, (item) => item && (item.label || item.text || item._id || ''))
  ).join(', ');
}
