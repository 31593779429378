import { MODE_PROPERTY } from '../constants';

const EntityAddToCartButton = {
  _id: 'addToCartButton',
  frontComponent: 'AddToCartButton',
  label: 'Ajouter au panier',
  icon: 'cart-plus',
  optionsSchema: [
    {
      key: 'addLabel',
      label: 'Texte du bouton ajouter au panier',
      type: 'string',
    },
    {
      key: 'removeLabel',
      label: 'Texte du bouton supprimer du panier',
      type: 'string',
    },
    {
      key: 'disabledLabel',
      label: 'Texte du bouton pas/plus disponible',
      type: 'string',
    },
    {
      key: 'variablePriceLabel',
      label: 'Texte du prix variable',
      type: 'string',
    },
    {
      key: 'sliderLabel',
      label: 'Texte du slider de prix variable',
      type: 'string',
    },
    {
      key: 'pageId',
      label: 'Après ajout au panier, aller à la page',
      type: 'object_id',
      objectType: 'Page',
    },
    MODE_PROPERTY,
  ],
  forEntity: true,
};

export default EntityAddToCartButton;
