// @flow
import * as React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { Text, Button, Notification } from './';

type Props = {|
  children: React.Node,
|};

export default function ErrorBoundary({ children }: Props): React.Node {
  return (
    <ReactErrorBoundary
      onError={(error) => {
        console.error(error);
        Sentry.captureException(error);
      }}
      FallbackComponent={ErrorFallback}
    >
      {children}
    </ReactErrorBoundary>
  );
}

function ErrorFallback({ error, onReset }) {
  return (
    <Notification color="danger" light>
      <Text element="p" size={7}>
        Une erreur est survenue, vous pouvez partager un screenshot de cet écran
        sur Slack #bugs-tech
      </Text>

      <Text element="h5" size={6} style={{ marginTop: 10, marginBottom: 5 }}>
        Détails de l'erreur
      </Text>
      <pre>{error.message}</pre>

      {onReset && <Button onClick={onReset}>Try again</Button>}
    </Notification>
  );
}
