import React from 'react';
import ReactDOM from 'react-dom';
import setLocale from 'src/utils/setLocale';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import GitInfo from 'react-git-info/macro';
import truncate from 'truncate-html';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';

import config from 'src/helpers/api/config';

import 'src/styles/index.scss';

import App from './App';

function initialize() {
  setLocale('fr');

  const { commit, tags } = GitInfo();

  const release = tags.length > 0 ? tags.join(' ') : commit.shortHash;

  if (config.PUF_ENV !== 'dev') {
    Sentry.init({
      dsn:
        'https://3c6ea08f2c64416ab37774c064e76921@o572411.ingest.sentry.io/5721777',
      integrations: [new Integrations.BrowserTracing()],
      environment: config.PUF_ENV,
      release: release,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  truncate.setup({
    length: 100,
    byWords: true,
  });

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
  );
}

initialize();

// Disable Julien's logs
console.julienLog = (message) => null;

ReactDOM.render(<App />, document.getElementById('root'));

// disabled to allow easier refresh when upgrading. See https://github.com/facebook/create-react-app/issues/3372
//registerServiceWorker();
