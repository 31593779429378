// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Route, type ContextRouter } from 'react-router-dom';
import { omit } from 'lodash';

import getCustomTabs, { type Tab } from './resourceSpecific/tabs';
import {
  ResourceContext,
  ResourceReferencesContext,
} from 'src/pages/contexts/ResourceContext';
import { useGlobal, useFetchResource, useSchemaContext } from 'src/hooks';
import invariant from 'src/helpers/invariant';
import { getSchemaFetchPath } from 'src/helpers/url';

import { Redirect } from 'react-router-dom';
import { Tabs, Flex, Loader } from 'src/components';
import LinksTabContent from './LinksTab';
import EditTabContent from './EditTab';
import Header from './Header';
import ShowTabContent from './ShowTab';

type FormattedTab = {|
  ...Tab,
  content: React.Node,
|};

// Everything is accessible using the `useResourceContext` hook

export type Props = {|
  id: string,
  navigationType?: 'routing' | 'state', // Describe whether navigation should reflect in URL
  ...ContextRouter,
|};

export default function ResourceShow({
  id,
  navigationType,
  match,
  location,
  history,
}: Props): React.Node {
  const { schema, path } = useSchemaContext();
  const { user, siteId } = useGlobal();
  const {
    resource,
    references,
    loading,
    connector,
    setReferences,
  } = useFetchResource(schema, id, {
    onDestroy: () => history.push(path),
  });
  const [dirty, setDirty] = React.useState(false);

  if (loading) return <Loader delayed={false} />;

  if (!loading && (!resource || !connector)) {
    return (
      <Redirect
        to={{
          pathname: schema.isSingleton ? `${path}/new` : path,
        }}
      />
    );
  }

  if (schema.recordsAreEditableInAdmin && location.pathname === match.url) {
    return (
      <Redirect
        to={{
          pathname: `${match.url}/edit`,
          state: location.state,
        }}
      />
    );
  }

  const rawTabs: Array<Tab> = [
    schema.recordsAreEditableInAdmin
      ? {
          label: 'Édition',
          to: 'edit',
          icon: 'edit',
          content: EditTabContent,
        }
      : {
          label: 'Vue',
          to: '',
          icon: 'eye',
          exact: true,
          content: ShowTabContent,
        },
    {
      label: 'liens',
      to: 'links',
      icon: 'link',
      content: LinksTabContent,
    },
    ...getCustomTabs(schema._id),
  ].filter((tab) => !tab.right || user[tab.right]);

  const tabs: Array<FormattedTab> = rawTabs.map((tab) => ({
    ...tab,
    content: (
      <Route
        path={`${path}/${id}/${tab.to}`}
        key={tab.to}
        render={() => <tab.content />}
        exact={tab.exact}
      />
    ),
  }));

  invariant(
    !!resource && !!connector,
    'Resource and connector exist at this point'
  );

  return (
    <ResourceContext.Provider
      value={{
        resource,
        connector,
        schema,
        path,
        dirty,
        setDirty,
        apiPath: getSchemaFetchPath(siteId, schema),
      }}
    >
      <ResourceReferencesContext.Provider value={{ references, setReferences }}>
        <div className="resource-show">
          <Helmet>
            <title>{`${schema.label || schema._id}: ${id || 'new'}`}</title>
          </Helmet>

          <Header navigationType={navigationType}>
            <Flex
              style={{ justifyContent: 'flex-start' }}
              additionalClassName="tabs-container"
            >
              <Tabs
                items={tabs.map((tab) => ({
                  ...omit(tab, 'content'),
                  to: `${path}/${id}/${tab.to}`,
                }))}
                isBoxed
              />
            </Flex>
          </Header>

          {tabs.map((tab) => tab.content)}
        </div>
      </ResourceReferencesContext.Provider>
    </ResourceContext.Provider>
  );
}

ResourceShow.defaultProps = {
  navigationType: 'routing',
};
