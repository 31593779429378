// @flow
import * as React from 'react';
import { usePopper } from 'react-popper';
import classnames from 'classnames';

import type { Style } from 'src/components/types';

import { useOnKeyDown, useOutsideClickAlerter } from 'src/hooks';

import Menu from './Menu';
import Trigger from './Trigger';

export type TriggerProp = (onTriggerClick: () => any) => React.Node;

type MenuProps = {|
  isNarrow?: boolean,
  style?: Style,
  additionalClassName?: string,
|};

type Props = {|
  children: ?React.Node,
  trigger: TriggerProp,
  menuProps?: MenuProps,
  align?: 'right' | 'left',
  disabled?: boolean,
  style?: $Shape<Style>,
|};

export default function Dropdown({
  children,
  trigger,
  menuProps = {},
  align,
  disabled,
  style,
}: Props): React.Node {
  const [isOpened, setIsOpened] = React.useState(false);

  const closeDropdown = React.useCallback(() => {
    if (isOpened) setIsOpened(false);
  }, [isOpened, setIsOpened]);

  const onTriggerClick = React.useCallback(() => {
    if (disabled) return;
    setIsOpened(!isOpened);
  }, [isOpened, disabled, setIsOpened]);

  useOnKeyDown(
    {
      onEscapeKeyDown: closeDropdown,
    },
    [closeDropdown]
  );

  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const placement = align === 'left' ? 'bottom-start' : 'bottom-end';
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
    }
  );

  React.useLayoutEffect(() => {
    if (isOpened) {
      // We have to manually ask popper to update to correctly place
      // the popper element
      !!update && update();
    }
  }, [isOpened, update]);

  useOutsideClickAlerter(
    [{ current: popperElement }, { current: referenceElement }],
    () => {
      closeDropdown();
    }
  );

  // $FlowIgnore
  const menuStyle = {
    ...(!!menuProps.style ? menuProps.style : {}),
    ...styles.popper,
  };

  const className = classnames('dropdown', align && `is-${align}`, {
    'is-active': !disabled || isOpened,
    'is-disabled': disabled,
  });

  return (
    <div className={className} style={style}>
      <Trigger popperRef={setReferenceElement}>
        {trigger(onTriggerClick)}
      </Trigger>

      <Menu
        popperRef={setPopperElement}
        open={isOpened}
        {...menuProps}
        style={menuStyle}
        {...attributes.popper}
      >
        {children}
      </Menu>
    </div>
  );
}
