// @flow

import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  notificationCenterState,
  forceNotification,
  pushNotification,
} from 'src/recoil/atoms';
import { v4 as uuidv4 } from 'uuid';

type Options = {|
  type: 'success' | 'warning' | 'danger' | 'info',
  text: string,
|};

type Returned = {|
  push: (Options) => any,
  force: (Options) => any,
|};

export default function useNotification(): Returned {
  const setNotificationCenter = useSetRecoilState(notificationCenterState);

  const push = React.useCallback(({ text, type }: Options) => {
    const id = uuidv4();

    setNotificationCenter(
      pushNotification({
        id,
        type,
        text,
        time: 3000,
      })
    );
  }, []);

  const force = React.useCallback(({ type, text }) => {
    const id = uuidv4();

    setNotificationCenter(
      forceNotification({
        id,
        type,
        text,
        time: 3000,
      })
    );
  }, []);

  return { push, force };
}
