const ImmanensReader = {
  _id: 'ImmanensReader',
  frontComponent: 'ImmanensReader',
  label: 'Liseuse Immanens',
  icon: 'newspaper',
  forPage: true,
  forEntity: false,
  hasEntities: false,
};

export default ImmanensReader;
