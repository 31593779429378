// @flow
import * as React from 'react';

import { useGlobal, useResourceContext } from 'src/hooks';

import { Button, QuestionMarkTooltip } from 'src/components';

type Props = {
  style?: Object,
};

function UserLoginAsAction({ style }: Props): React.Node {
  const { user: admin, site } = useGlobal();
  const { resource: user } = useResourceContext();

  const generateLoginUrl = React.useCallback(() => {
    const password = window.prompt('Entrer votre mot de passe admin');
    const loginParams = {
      // $FlowIgnore
      login: user.login,
      password: `admin:${admin.login}:${password}`,
    };
    const loginUrl = site.getFrontUrl({
      queryParams: loginParams,
      path: '/login',
    });

    window.open(loginUrl, '_blank').focus();
  });

  return (
    <Button onClick={generateLoginUrl} style={style}>
      Login as
      <QuestionMarkTooltip content="En cliquant sur ce lien, votre mot de passe admin vous sera demandé puis vous serez redirigé sur le site, connecté en tant que l'utilisateur" />
    </Button>
  );
}

export default UserLoginAsAction;
