// @flow
import * as React from 'react';

import { useResourceContext, useGlobal } from 'src/hooks';
import { toHumanReadable } from 'src/helpers/dates';
import config from 'src/helpers/api/config';

import { Text, Tag, Flex, Button, Icon, Notification } from 'src/components';
import RowInfos from './RowInfos';

const STATUS_LABEL = {
  success: 'Succès',
  pending: 'En cours/suspendu',
  error: 'Erreur',
};
const STATUS_COLOR = {
  success: 'success',
  pending: 'warning',
  error: 'danger',
};

export default function ExportShow(): React.Node {
  const { siteId } = useGlobal();
  const { resource } = useResourceContext<any>();

  const {
    title,
    description,
    admin,
    format,
    params,
    size,
    result,
    fileName,
    status,
  } = resource;

  const { filter } = params;

  const exportDate = toHumanReadable({ ts: resource.exportTs });
  const exportPeriod = getExportPeriod(filter.createTs);

  return (
    <div className="export-show">
      <div className="highlevel-description">
        <Flex
          style={{
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 30,
          }}
        >
          <div>
            <Text isTitle element="h1" spaced>
              {title} (.{format})
            </Text>
          </div>

          <div>
            {status === 'success' && (
              <Button
                rel="noopener noreferrer"
                href={`${config.API_URL}/admin/${siteId}/exports/${resource._id}/file`}
              >
                <Icon name="download" style={{ marginRight: 5 }} />{' '}
                {size && '(' + size + ')'}
              </Button>
            )}

            <Tag
              size="large"
              color={STATUS_COLOR[status]}
              style={{ marginLeft: 10 }}
            >
              {STATUS_LABEL[status]}
            </Tag>
          </div>
        </Flex>

        <Notification color="link" style={{ marginBottom: 20 }} light>
          <p>{description}</p>
        </Notification>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ marginBottom: 15 }}>
            Date de l'export : <Text weight="bold">{exportDate}</Text>
          </Text>
          <Text style={{ marginBottom: 15 }}>
            Période concernée : <Text weight="bold">{exportPeriod}</Text>
          </Text>

          {admin && (
            <Text style={{ marginBottom: 15 }}>
              Réalisé par l'admin <Text weight="bold">{admin.login}</Text>{' '}
            </Text>
          )}

          <Text style={{ marginBottom: 0 }}>
            Nom du fichier: <Text weight="bold">{fileName}</Text>
          </Text>
        </div>
      </div>

      <hr
        style={{
          backgroundColor: '#dcdcdc',
          margin: '60px auto',
          maxWidth: '60%',
        }}
      />

      {result && <RowInfos result={result} />}
    </div>
  );
}

const getExportPeriod = (createTs) => {
  const { min, max } = createTs;
  const from = toHumanReadable({ ts: min });
  const to = toHumanReadable({ ts: max });
  return `du ${from} au ${to}`;
};
