// @flow
import * as React from 'react';

import type { Row, Handlers, AddNewBlock } from '../types';

import { Button, Icon, Tile } from 'src/components';
import LayoutRow from './Row';

type Props = {|
  rows: Array<Row>,
  handlers: Handlers,
  addNewBlock: AddNewBlock,
|};

export default function LayoutContent({
  rows,
  handlers,
  addNewBlock,
}: Props): React.Node {
  return (
    <Tile isAncestor isVertical additionalClassName="layout-editor-content">
      {rows.map((row, i) => (
        <React.Fragment key={i}>
          <LayoutRow
            row={row}
            path={`[${i}]`}
            index={i}
            rowCount={rows.length}
            handlers={handlers}
          />

          {row.options?.withSeparator && (
            <div className="layout-row-separator" />
          )}
        </React.Fragment>
      ))}

      <Button onClick={addNewBlock} style={{ marginBottom: 30, marginTop: 10 }}>
        <Icon name="plus" style={{ marginRight: 5 }} />
        Ajouter un bloc
      </Button>
    </Tile>
  );
}
