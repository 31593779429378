// @flow
import * as React from 'react';

import {
  convertToNumber,
  type ConvertToNumberOptions as Options,
} from 'src/helpers/number';

const DEFAULT_OPTIONS: Options = {
  integerOnly: false,
  min: undefined,
  max: undefined,
  decimals: 2,
};

type Returned = [?string | number, ?number, (?number | string) => void];

export default function useNumberState(
  initialValue: number,
  optionsProp: Options
): Returned {
  const options = React.useRef<Options>({
    ...DEFAULT_OPTIONS,
    ...(optionsProp || null),
  });

  const [rawNumber, setRawNumber] = React.useState(initialValue);
  const [convertedNumber, setConvertedNumber] = React.useState(initialValue);
  const previousConvertedNumber = React.useRef(convertedNumber);

  const setNumber = React.useCallback(
    (newNumber) => {
      setRawNumber(newNumber);
      setConvertedNumber(newNumber);
      previousConvertedNumber.current = newNumber;
    },
    [setRawNumber, setConvertedNumber]
  );

  React.useEffect(() => {
    if (initialValue !== convertedNumber) {
      setNumber(initialValue);
    }
  }, [initialValue, convertedNumber]);

  React.useEffect(() => {
    const { result: newConvertedNumber, valid } = convertToNumber(
      rawNumber,
      options.current
    );

    if (valid) setNumber(newConvertedNumber);
  }, [rawNumber, options.current]);

  return [rawNumber, convertedNumber, setRawNumber];
}
