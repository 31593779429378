// @flow
import * as React from 'react';
import { isUndefined, isFinite } from 'lodash';

import { useNumber } from 'src/hooks';

import { Input } from './';

type Props = {|
  onChange: (?number) => void,
  value: number,
  disabled?: boolean,
  min?: number,
  max?: number,
  onBlur?: () => any,
  onFocus?: (e: SyntheticInputEvent<HTMLInputElement>) => any,
|};

export default function FloatInput({
  onChange,
  disabled,
  value,
  min,
  max,
  onBlur,
  onFocus,
}: Props): React.Node {
  const [
    rawIntermediateValue,
    convertedValue,
    setRawIntermediateValue,
  ] = useNumber(value, {
    min,
    max,
  });

  React.useEffect(() => {
    if (value === null && isUndefined(convertedValue)) return;
    if (convertedValue !== value) onChange(convertedValue);
  }, [convertedValue]);

  return (
    <Input
      type="number"
      value={isFinite(rawIntermediateValue) ? rawIntermediateValue : ''}
      onChange={setRawIntermediateValue}
      min={min}
      max={max}
      disabled={disabled}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}
