// @flow
import * as React from 'react';
import moment from 'moment';

import type { ResourcePropertySchema } from 'src/types';
import type { OnPropertyValueChange } from './';

import { DatePicker } from 'src/components';

type Props = {|
  propertySchema: ResourcePropertySchema,
  onChange: OnPropertyValueChange,
  value: number,
  disabled?: boolean,
  min?: number,
  max?: number,
  withPortal?: boolean,
|};

export default function DateEditor({
  propertySchema,
  onChange,
  disabled,
  value,
  min,
  max,
  withPortal,
}: Props): React.Node {
  const fromUnixToDate = (date) => (!!date ? moment.unix(date).toDate() : null);

  return (
    <DatePicker
      withPortal={withPortal}
      showTimeSelect
      showFullFormat
      value={fromUnixToDate(value)}
      onChange={(newDate) =>
        onChange(!!newDate ? newDate.getTime() / 1000 : null)
      }
      disabled={disabled}
      minDate={fromUnixToDate(min)}
      maxDate={fromUnixToDate(max)}
    />
  );
}
