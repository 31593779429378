import * as React from 'react';

import { Text, Box } from 'src/components';

export default function UnusedComponents({ componentIds, definedComponents }) {
  return (
    <Box additionalClassName="unused-components">
      <Text isTitle element="h3">
        {componentIds.length} composants définis mais jamais utilisés
      </Text>

      <ul>
        {componentIds.sort().map((componentId) => {
          const component = definedComponents[componentId].component;

          if (!component) {
            console.warn(
              'Component does not exist in component definition',
              componentId
            );
            return null;
          }

          return (
            <li key={componentId}>
              <UnusedComponent component={component} />
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

const UnusedComponent = ({ component }) => (
  <>
    <b>{component._id}</b> : {component.frontComponent}
  </>
);
