// @flow
import * as React from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import moment from 'moment';

import API from 'src/helpers/api/API';
import { useGlobal } from 'src/hooks';

import { USAGE_TYPE, USAGE_TYPE_LABELS } from './constants';

import { Toolbar, Chart } from 'src/components';
import { PERIOD, PERIOD_LABELS } from 'src/components/stats/constants';

export default function ChartsStatsPage(): React.Node {
  const { siteId } = useGlobal();

  const [loading, setLoading] = React.useState(true);
  const [, setError] = React.useState(null);
  const [startDate, setStartDate] = React.useState(
    moment().subtract(1, 'month').toDate()
  );
  const [endDate, setEndDate] = React.useState(moment().toDate());
  const [selectedUsage, setSelectedUsage] = React.useState({
    value: USAGE_TYPE.SUBSCRIPTIONS_RENEWALS,
    label: USAGE_TYPE_LABELS[USAGE_TYPE.SUBSCRIPTIONS_RENEWALS],
  });
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    value: PERIOD.DAY,
    label: PERIOD_LABELS[PERIOD.DAY],
  });
  const [datasets, setDatasets] = React.useState({});

  const fetchDataset = React.useCallback(async () => {
    setLoading(true);
    try {
      let fetchedDatasets = {};
      switch (selectedUsage.value) {
        case USAGE_TYPE.SUBSCRIPTIONS_RENEWALS:
          fetchedDatasets = await API.get(
            `${siteId}/statistics/subscriptions-renewals`,
            {
              period: selectedPeriod.value,
              startDate: moment(startDate).unix(),
              endDate: moment(endDate).unix(),
            }
          );
          break;
        case USAGE_TYPE.USER_CREATION:
          fetchedDatasets = await API.get(
            `${siteId}/statistics/user-creation`,
            {
              period: selectedPeriod.value,
              startDate: moment(startDate).unix(),
              endDate: moment(endDate).unix(),
            }
          );
          break;
        default:
          break;
      }
      setDatasets(fetchedDatasets);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  });

  React.useEffect(() => {
    fetchDataset();
  }, []);

  useDebouncedEffect(
    () => {
      fetchDataset();
    },
    1000,
    [selectedUsage, selectedPeriod.value, startDate, endDate]
  );

  return (
    <div className="chart-statistics-page">
      <Toolbar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        usageTypeLabels={USAGE_TYPE_LABELS}
        usageType={USAGE_TYPE}
        setEndDate={setEndDate}
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
        selectedUsage={selectedUsage}
        setSelectedUsage={setSelectedUsage}
      />

      <Chart
        startDate={startDate}
        endDate={endDate}
        period={selectedPeriod.value}
        datasets={datasets}
        loading={loading}
        title={USAGE_TYPE_LABELS[selectedUsage.value]}
        periodTitle={PERIOD_LABELS[selectedPeriod.value]}
      />
    </div>
  );
}
