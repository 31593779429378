// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Style } from 'src/components/types';

import { PullRight } from 'src/components';

export type ColumnSize =
  | 'full'
  | 'three-quarters'
  | 'two-thirds'
  | 'half'
  | 'one-third'
  | 'one-quarter'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

type Props = {|
  isNarrow?: boolean,
  size?: ColumnSize,
  mobileSize?: ColumnSize,
  tabletSize?: ColumnSize,
  desktopSize?: ColumnSize,
  isVerticallyCentered?: boolean,
  children?: React.Node,
  contentIsPulledRight?: boolean,
  ellipsisOnLineOverflow?: boolean,
  offset?: ColumnSize,
  style?: Style,
  hideOn?: 'mobile' | 'touch' | 'tablet' | 'desktop',
  additionalClassName?: string,
|};

export default function Column({
  isNarrow,
  size,
  mobileSize,
  tabletSize,
  desktopSize,
  isVerticallyCentered,
  children,
  contentIsPulledRight,
  ellipsisOnLineOverflow,
  offset,
  style = {},
  hideOn,
  additionalClassName,
}: Props): React.Node {
  let className = classnames('column', {
    'is-narrow': isNarrow,
    [`is-${size || ''}`]: !!size,
    [`is-${mobileSize || ''}-mobile`]: !!mobileSize,
    [`is-${tabletSize || ''}-tablet`]: !!tabletSize,
    [`is-${desktopSize || ''}-desktop`]: !!desktopSize,
    [`is-offset-${offset || ''}`]: !!offset,
    [`is-hidden-${hideOn || ''}`]: !!hideOn,
    [`${additionalClassName || ''}`]: !!additionalClassName,
  });

  let mergedStyle = {};
  if (isVerticallyCentered)
    mergedStyle = { ...mergedStyle, alignSelf: 'center' };
  if (ellipsisOnLineOverflow) {
    mergedStyle = {
      ...mergedStyle,
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    };
  }
  mergedStyle = { ...mergedStyle, ...style };

  return (
    <div className={className} style={mergedStyle}>
      {contentIsPulledRight ? <PullRight>{children}</PullRight> : children}
    </div>
  );
}
