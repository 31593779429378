// @flow
import * as React from 'react';
import moment from 'moment';

import { Switch, Route, Redirect, type ContextRouter } from 'react-router-dom';
import SummaryStats from './tabs/SummaryStats';
import PerFormulaStats from './tabs/PerFormulaStats';
import Header from './Header';
import { Tabs, InformationBubble } from 'src/components';
import Footer from './Footer';

type Props = {|
  ...ContextRouter,
|};

function SalesStatsPage({ match }: Props): React.Node {
  const [startDate, setStartDate] = React.useState(
    moment().subtract(1, 'month')
  );
  const [endDate, setEndDate] = React.useState(moment());
  const params = React.useMemo(
    () => ({
      startTs: startDate.unix(),
      endTs: endDate.unix(),
    }),
    [startDate, endDate]
  );

  return (
    <div className="sales-stats-page">
      <Header
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      <InformationBubble
        type="warning"
        style={{ marginBottom: 20, maxWidth: 700 }}
        size="small"
      >
        Les statistiques affichées considèrent l'écart entre la date de début et
        la date de fin sélectionnées (1 mois par défaut).
        <ul className="bullet-list">
          <li>
            Les statistiques de la colonne de gauche considère la période
            précédente
          </li>
          <li>
            Les statistiques de la colonne de droite considère la période
            sélectionnée
          </li>
        </ul>
      </InformationBubble>

      <Tabs
        isBoxed
        items={[
          {
            label: 'Résumé des ventes',
            to: `${match.path}/summary`,
          },
          {
            label: "Par type d'abonnement",
            to: `${match.path}/per-formula`,
          },
        ]}
        style={{ marginBottom: -1 }}
      />

      <div className="tab-content">
        <Switch>
          <Route path={`${match.path}/summary`}>
            {(routeProps) => <SummaryStats params={params} />}
          </Route>
          <Route path={`${match.path}/per-formula`}>
            {(routeProps) => <PerFormulaStats params={params} />}
          </Route>

          <Redirect to={`${match.path}/summary`} />
        </Switch>
      </div>

      <Footer
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>
  );
}

export default SalesStatsPage;
