// @flow
import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import type { ContextRouter } from 'react-router-dom';
import type { Resource } from 'src/types';

import { useHistory, useLocation } from 'react-router-dom';
import {
  ResourceContext,
  ResourceReferencesContext,
} from 'src/pages/contexts/ResourceContext';
import { useGlobal, useSchemaContext } from 'src/hooks';
import ResourceAPIConnector from 'src/helpers/api/ResourceAPIConnector';
import { getResourcePath, getSchemaFetchPath } from 'src/helpers/url';

import Header from './Show/Header';
import ResourceEditor from 'src/pages/components/resource/ResourceEditor';
import { Loader } from 'src/components';

type Props = ContextRouter;

export default function ResourceNew(props: Props): React.Node {
  const history = useHistory();
  const location = useLocation();
  const { schema, path } = useSchemaContext();
  const { siteId } = useGlobal();
  const resourceAPIConnector = React.useRef<ResourceAPIConnector | null>(null);
  const [resource, setResource] = React.useState<Resource | null>(null);
  const [references, setReferences] = React.useState<Object>({});
  const [loading, setLoading] = React.useState(true);

  useEffectOnce(() => {
    const effect = async () => {
      resourceAPIConnector.current = new ResourceAPIConnector(
        siteId,
        schema,
        null,
        {
          onSave: (resource) =>
            history.push(getResourcePath(siteId, schema, resource._id)),
        }
      );

      if (
        schema.isSingleton &&
        schema.singletonId &&
        (await ResourceAPIConnector.checkExistence(
          siteId,
          schema,
          schema.singletonId
        ))
      ) {
        history.push(getResourcePath(siteId, schema, schema.singletonId));
        return;
      }

      const fetchedResource = (await resourceAPIConnector.current.load()) || {};
      if (schema.isSingleton) fetchedResource._id = schema.singletonId;

      const storedResource: Resource = ({
        ...fetchedResource,
        ...(location.state?.resource || {}),
      }: any);
      setResource(storedResource);
      setLoading(false);
    };

    effect();
  });

  const close = React.useCallback(() => {
    if (
      window.confirm(
        'Êtes-vous sûr de vouloir quitter sans enregistrer vos modifications ?'
      )
    )
      history.push({
        pathname: path,
      });
  }, [history, path]);

  if (loading) return <Loader delayed={false} />;
  if (!resource || !resourceAPIConnector.current) return null;

  return (
    <ResourceContext.Provider
      value={{
        resource,
        connector: resourceAPIConnector.current,
        schema,
        path,
        dirty: true,
        apiPath: getSchemaFetchPath(siteId, schema),
      }}
    >
      <ResourceReferencesContext.Provider value={{ references, setReferences }}>
        <div className="resource-new">
          <Header navigationType="routing" />
          <ResourceEditor onClose={close} isNew />
        </div>
      </ResourceReferencesContext.Provider>
    </ResourceContext.Provider>
  );
}
