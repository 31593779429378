// @flow
import * as React from 'react';

import { PERIOD, PERIOD_LABELS } from './constants';

import {
  Flex,
  Control,
  DatePicker,
  Field,
  Label,
  Select,
  Columns,
  Column,
} from 'src/components';

type Props = {|
  startDate: ?Date,
  setStartDate: (?Date) => any,
  endDate: ?Date,
  usageTypeLabels: {| [labelId: string]: string |},
  usageType: {| [labelId: string]: string |},
  setEndDate: (?Date) => any,
  selectedPeriod: {| label: string, value: string |},
  setSelectedPeriod: ({| label: string, value: string |}) => any,
  selectedUsage: {| label: string, value: string |},
  setSelectedUsage: ({| label: string, value: string |}) => any,
|};

export default function Toolbar({
  startDate,
  setStartDate,
  endDate,
  usageTypeLabels,
  usageType,
  setEndDate,
  selectedPeriod,
  setSelectedPeriod,
  selectedUsage,
  setSelectedUsage,
}: Props): React.Node {
  return (
    <Columns>
      <Column size={8}>
        <Flex>
          <Field style={{ marginRight: 10 }}>
            <Label>Date de début :</Label>
            <Control>
              <DatePicker
                maxDate={endDate}
                value={startDate}
                onChange={setStartDate}
              />
            </Control>
          </Field>

          <Field style={{ marginRight: 10 }}>
            <Label>Date de fin :</Label>
            <Control>
              <DatePicker
                minDate={startDate}
                value={endDate}
                onChange={setEndDate}
              />
            </Control>
          </Field>

          <Field style={{ marginRight: 10 }}>
            <Label>Période</Label>
            <Control>
              <Select
                styles={{ container: () => ({ width: 100 }) }}
                value={selectedPeriod}
                options={Object.keys(PERIOD).map((period) => ({
                  value: period,
                  label: PERIOD_LABELS[period],
                }))}
                onChange={setSelectedPeriod}
              />
            </Control>
          </Field>
        </Flex>
      </Column>

      <Column size={4}>
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Field>
            <Label>Cas d'usage</Label>
            <Control>
              <Select
                styles={{ container: () => ({ width: 300 }) }}
                value={selectedUsage}
                options={Object.keys(usageType).map((usage) => ({
                  value: usage,
                  label: usageTypeLabels[usage],
                }))}
                onChange={setSelectedUsage}
              />
            </Control>
          </Field>
        </Flex>
      </Column>
    </Columns>
  );
}
