// Tag selection component with simple props

import _ from 'lodash';
import React from 'react';

import { regexEsc } from 'src/helpers/misc'; // see https://github.com/olahol/react-tagsinput

import Autosuggest from 'react-autosuggest';
import Highlighter from 'react-highlight-words';

export default class AutosuggestInput extends React.Component {
  state = {
    suggestions: [],
  };

  onFetch = ({ value, reason }) => {
    // https://github.com/moroshko/react-autosuggest#onsuggestionsfetchrequested-required

    /// TODO search on label field only

    let startOfString = new RegExp('^' + regexEsc(value), 'i');

    this.setState({
      suggestions: _.filter(
        this.props.tagsById,
        (t) =>
          (t.text || t.label)
            .slice(0, value.length)
            .localeCompare(value, undefined, { sensitivity: 'base' }) === 0
      )
        .map((t) => ({ ...t, text: t.label || t.text })) // .text : Tag retrocompat
        .sort((objA, objB) => {
          let a = objA.text,
            b = objB.text;

          if (startOfString.test(a) && !startOfString.test(b)) return -1;
          if (startOfString.test(b) && !startOfString.test(a)) return 1;
          if (a.length < b.length) return -1;
          if (a.length > b.length) return 1;
          return a.localeCompare(b);
        }),
    });
  };

  onInputChange = (e, { newValue, method }) => {
    if (method === 'enter') {
      e.preventDefault();
    } else {
      this.props.inputProps.onChange(e);
    }
  };

  onSubmit = (e, { suggestion }) => {
    this.props.addTag(suggestion);
  };

  render() {
    return (
      <Autosuggest
        ref={this.props.inputProps.ref}
        suggestions={this.state.suggestions}
        getSuggestionValue={(s) => (s.label || s.text) + '***'}
        renderSuggestion={(suggestion, { query, isHighlighted }) => (
          <span
            className={'suggestion' + (isHighlighted ? ' highlighted' : '')}
          >
            <Highlighter
              highlightClassName="search-term"
              highlightTag="b"
              searchWords={[query]}
              autoEscape={true}
              textToHighlight={suggestion.label || suggestion.text}
            />
          </span>
        )}
        inputProps={{
          ...this.props.inputProps,
          onChange: this.onInputChange,
        }}
        onSuggestionSelected={this.onSubmit}
        onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
        onSuggestionsFetchRequested={this.onFetch}
        highlightFirstSuggestion={true}
      />
    );
  }
}
