const EntitySharingButtons = {
  _id: 'sharing',
  label: 'Boutons partager',
  icon: 'share',
  frontComponent: 'SharingButtons',
  forEntity: true,
  optionsSchema: [
    {
      key: 'iconOnly',
      label: "N'afficher que les icônes",
      type: 'bool',
      default: true,
    },
    { key: 'facebook', label: 'sur Facebook', type: 'bool', default: true },
    { key: 'twitter', label: 'sur Twitter', type: 'bool', default: true },
    { key: 'linkedin', label: 'sur LinkedIn', type: 'bool', default: false },
    {
      key: 'pinterest',
      label: 'sur Pinterest',
      type: 'bool',
      default: false,
    },
  ],
};

export default EntitySharingButtons;
