// @flow
import * as React from 'react';

import type { SchemaContextType } from 'src/pages/contexts/SchemaContext';

import SchemaContext from 'src/pages/contexts/SchemaContext';
import invariant from 'src/helpers/invariant';

export default function useSchemaContext(): SchemaContextType {
  const schemaContext = React.useContext(SchemaContext);

  invariant(!!schemaContext, 'Schema context should exist');

  return schemaContext;
}
