import { CSS_PROPERTIES } from '../constants/componentOptionsAdditionalSchemas';

export const RowSchema = {
  _id: 'LayoutRow',
  label: 'Row du Layout',
  type: 'object',
  propertiesList: [
    {
      _cls: 'ObjectPropertySchema',
      key: 'withSeparator',
      label: "Séparateur avec la ligne d'après",
      type: 'bool',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'noPaddingTop',
      label: "Retirer l'espace au dessus",
      type: 'bool',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'noPaddingBottom',
      label: "Retirer l'espace au dessous",
      type: 'bool',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'loadImagesSync',
      label: 'Charger les images immédiatement',
      type: 'bool',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'wrapperClassName',
      label: 'Classe du wrapper de la row',
      type: 'string',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'breakpointViewport',
      label: 'Viewport en dessous duquel les colonnes "break"',
      type: 'enum',
      default: 'tablet',
      values: [
        {
          label: 'desktop',
          value: 'desktop',
        },
        {
          label: 'tablet (par défaut)',
          value: 'tablet',
        },
        {
          label: 'Jamais (ne "break" jamais)',
          value: 'mobile',
        },
      ],
    },
    ...CSS_PROPERTIES.filter((property) => !['Element'].includes(property.key)),
  ],
};

export const ColumnSchema = {
  _id: 'LayoutColumn',
  label: 'Colonne du Layout',
  type: 'object',
  propertiesList: [
    {
      _cls: 'ObjectPropertySchema',
      key: 'size',
      label: 'Taille (entre 1 et 12)',
      type: 'int',
      min: 1,
      max: 12,
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'offset',
      label: 'Décalage, en nombre de colonne (entre 1 et 12)',
      type: 'int',
      min: 0,
      max: 12,
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'loadImagesSync',
      label: 'Charger les images immédiatement',
      type: 'bool',
    },
    {
      _cls: 'ObjectPropertySchema',
      key: 'options',
      label: 'Options',
      type: 'object',
      propertiesList: [
        {
          _cls: 'ObjectPropertySchema',
          key: 'align',
          label: 'Alignement du contenu',
          type: 'enum',
          values: [
            {
              value: 'left',
              label: 'A gauche (par défaut)',
            },
            {
              value: 'center',
              label: 'Au milieu',
            },
            {
              value: 'right',
              label: 'A droite',
            },
          ],
        },
        ...CSS_PROPERTIES.filter(
          (property) => !['Element'].includes(property.key)
        ),
        {
          _cls: 'ObjectPropertySchema',
          key: 'className',
          label: 'Classe à ajouter',
          type: 'string',
        },
      ],
    },
  ],
};
