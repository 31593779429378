import * as React from 'react';

import { Text, Box } from 'src/components';

export default function UnusedModes({ modes }) {
  return (
    <Box additionalClassName="unused-modes">
      <Text isTitle element="h3">
        {Object.keys(modes).length} composants définissant des modes non utilisé
      </Text>

      <ul>
        {Object.keys(modes).map((componentId) => (
          <li key={componentId}>
            <UnusedModeComponent
              componentId={componentId}
              modes={modes[componentId]}
            />
          </li>
        ))}
      </ul>
    </Box>
  );
}

const UnusedModeComponent = ({ componentId, modes }) => (
  <>
    <b>{componentId}</b> :
    <br />
    {modes.join(', ')}
  </>
);
